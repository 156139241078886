import { ReactComponent as StopWatch } from '_components/Common/img/stop-watch.svg'
import { ReactComponent as DfpBadge } from '_components/Common/img/dfp-badge.svg'
import type { TCourse } from '_generated/axon.graphql'

type TProps = {
  className?: string
  course: TCourse
}

export default function CourseSessionCard({ className, course }: TProps) {
  return (
    <a
      className={`${className} session-card`}
      href={course.fullUrl ?? 'https://learn.diagnosia.com/'}
      rel="noreferrer"
      target="_blank"
    >
      <div className="card-content">
        {course.photo ? (
          <img
            alt={course.name ?? 'Diagnosia Learn Kurs'}
            className="card-banner"
            src={course.photo}
          />
        ) : (
          <div className="card-banner bg-blue-grey-30"></div>
        )}
        <div className="mt-2 card-description">{course.shortDescription}</div>
        <div className="mt-2 color-blue-grey-50 text-sm">{course.name}</div>
      </div>
      <div className="mt-3 card-infos">
        <div className="d-flex align-items-center">
          <StopWatch />
          <span className="ms-2">{course.duration} Std.</span>
        </div>
        <div className="d-flex align-items-center">
          <DfpBadge />
          <span className="ms-2">{course.dfp} DFP</span>
        </div>
      </div>
    </a>
  )
}

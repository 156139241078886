import { useDropzone } from 'react-dropzone'
import classNames from 'classnames'
import { useState } from 'react'
import { userService } from '_services/user.service'
import { ReactComponent as DownloadIcon } from '_components/Common/img/download.svg'
import toast from 'react-hot-toast'

type ExtendedFile = File & { preview: string }

export default function ProfilePicUploader() {
  const [file, setFile] = useState<ExtendedFile | null>(null)
  const [isUploading, setIsUploading] = useState(false)

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: { 'image/png': [], 'image/jpeg': [] },
      onDrop: (acceptedFiles) => {
        setFile(
          Object.assign(acceptedFiles[0], {
            preview: URL.createObjectURL(acceptedFiles[0]),
          }) as ExtendedFile,
        )
      },
    })

  async function handleUpload() {
    if (!file) {
      return
    }

    setIsUploading(true)

    try {
      await userService.imageUpload(file)
      toast.success(<span>Dein Profilbild wurde erfolgreich hochgeladen</span>)
      setFile(null)
    } catch (error) {
      toast.error(
        <span>
          Beim Upload deines Bildes ist ein Fehler aufgetreten.
          <br />
          Bitte probier es erneut.
        </span>,
        {
          style: {
            maxWidth: '450px',
          },
        },
      )
    } finally {
      setIsUploading(false)
    }
  }

  const styledDropzoneClass = classNames({
    'dropzone-container': true,
    focused: isFocused,
    accepted: isDragAccept,
    rejected: isDragReject,
    'text-center': true,
  })

  return (
    <section>
      {!file ? (
        <div {...getRootProps({ className: `${styledDropzoneClass}` })}>
          <input {...getInputProps()} />
          <p>
            Ziehe die Datei per Drag-and-Drop in das Feld oder klicke, um eine
            Datei auszuwählen.
            <br />
            <i className="text-sm">
              (Bitte nur *.jpeg and *.png Dateien verwenden)
            </i>
          </p>
          <DownloadIcon />
        </div>
      ) : (
        <aside>
          <div className="thumbnail">
            <span
              className="delete-profile-pic"
              onClick={() => setFile(null)}
            />
            <img src={file.preview} alt="Vorschau des Profilbildes" />
          </div>
        </aside>
      )}
      <button
        className="d-inline-flex align-items-center mt-2 btn btn-info"
        type="button"
        onClick={handleUpload}
        disabled={isUploading || !file}
      >
        Upload
      </button>
    </section>
  )
}

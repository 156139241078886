import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null | undefined
export type InputMaybe<T> = T | null | undefined
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  Date: any
  NaiveDateTime: any
  Time: any
}

/** A additional product for a pharma notification (eg an reference to an Ifap or Khix2 product) */
export type TAdditionalProduct = {
  __typename?: 'AdditionalProduct'
  dosageUsage?: Maybe<Scalars['String']>
  /** Legacy field/value from old enterprise */
  drugName?: Maybe<Scalars['String']>
  /** Legacy field/value from old enterprise */
  drugUkey?: Maybe<Scalars['String']>
  duration?: Maybe<Scalars['String']>
  /** ID given by the database */
  id?: Maybe<Scalars['Int']>
  /** Indicates if product is referencing an external product (eg from a hospital) */
  isExternalProduct?: Maybe<Scalars['Boolean']>
  /** The corresponding product which belongs to the item (only query this if you need more data than id and name) */
  product?: Maybe<TDgnsProduct>
  productCountry?: Maybe<TCountry>
  /** The id of the product/drug */
  productId?: Maybe<Scalars['Int']>
  productName?: Maybe<Scalars['String']>
  usageReason?: Maybe<Scalars['String']>
}

export type TAdditionalProductInput = {
  dosageUsage?: InputMaybe<Scalars['String']>
  duration?: InputMaybe<Scalars['String']>
  /** Indicates if item is referencing an external product (eg from a hospital) */
  isExternalProduct?: InputMaybe<Scalars['Boolean']>
  productCountry: TCountry
  /** The id of the product/drug */
  productId: Scalars['Int']
  productName: Scalars['String']
  usageReason?: InputMaybe<Scalars['String']>
}

export type TAggregatedRisk = {
  __typename?: 'AggregatedRisk'
  categories?: Maybe<Array<TCategoryWithAggregatedRisk>>
  maxRiskbaseLevel?: Maybe<TRiskbaseLevel>
}

/** Object containing an announcement */
export type TAnnouncement = {
  __typename?: 'Announcement'
  /** Main content/text of the announcement */
  body?: Maybe<Scalars['String']>
  /** Custom id which was given by the user */
  customId?: Maybe<Scalars['String']>
  /** File name of the uploaded file */
  file?: Maybe<Scalars['String']>
  /** ID given by the database */
  id?: Maybe<Scalars['Int']>
  /** Created at date */
  insertedAt?: Maybe<Scalars['NaiveDateTime']>
  /** Items which belong to the announcement */
  items?: Maybe<Array<TAnnouncementItem>>
  /** ID of the organisation of the user who created the announcement (comes from Phoenix) */
  organisationId?: Maybe<Scalars['String']>
  /** Title of the announcment */
  title?: Maybe<Scalars['String']>
  /** Type of the announcement */
  type?: Maybe<TAnnouncementTypeEnum>
  /** Updated at date */
  updatedAt?: Maybe<Scalars['NaiveDateTime']>
  /** ID of the user who created the announcement (comes from Phoenix) */
  userId?: Maybe<Scalars['String']>
}

/** An announcement item (eg an reference to an Ifap or Khix2 product) */
export type TAnnouncementItem = {
  __typename?: 'AnnouncementItem'
  /** ID of the parent announcement */
  announcementId?: Maybe<Scalars['Int']>
  /** Legacy field/value from old enterprise */
  drugUkey?: Maybe<Scalars['String']>
  /** ID given by the database */
  id?: Maybe<Scalars['Int']>
  /** Indicates if item is referencing an external product (eg from a hospital) */
  isExternalProduct?: Maybe<Scalars['Boolean']>
  /** The corresponding product which belongs to the item */
  product?: Maybe<TDgnsProduct>
  /** ID of the product */
  productId?: Maybe<Scalars['ID']>
}

export enum TAnnouncementOrderField {
  CreatedAt = 'CREATED_AT',
  Title = 'TITLE',
}

/** The available announcement types */
export enum TAnnouncementTypeEnum {
  Rundschreiben = 'RUNDSCHREIBEN',
  Warnhinweis = 'WARNHINWEIS',
}

export type TAnnouncementsConnection = {
  __typename?: 'AnnouncementsConnection'
  /** List of announcements. */
  items?: Maybe<Array<TAnnouncement>>
  /** Total count of announcements. */
  totalCount: Scalars['Int']
}

export type TAnnouncementsFilter = {
  title?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Scalars['String']>
}

export type TAnnouncementsOrderBy = {
  direction: TDirection
  field: TAnnouncementOrderField
}

export type TAtcsConnectionFilter = {
  /** Filter by ATC description. */
  description?: InputMaybe<Scalars['String']>
  /** Filter by ATC level. */
  level?: InputMaybe<Scalars['Int']>
  /** Indicates if only top level ATC codes should be shown. (= ATC codes without a parent) */
  topLevelOnly?: InputMaybe<Scalars['Boolean']>
}

export type TAtcsConnectionOrderBy = {
  /** Order result ASC or DESC. */
  direction?: InputMaybe<TDirection>
  /** Order result by field. */
  field?: InputMaybe<TAtcsOrderField>
}

export enum TAtcsOrderField {
  Code = 'CODE',
  CodeParent = 'CODE_PARENT',
  Description = 'DESCRIPTION',
  Level = 'LEVEL',
}

export type TCategoryWithAggregatedRisk = {
  __typename?: 'CategoryWithAggregatedRisk'
  /** This is the aggregated riskbase score of all queried substances aggregated according to the calculation algorithm of this RiskbaseCategory */
  aggregatedRiskScore?: Maybe<Scalars['Int']>
  riskbaseCategory?: Maybe<TRiskbaseCategory>
  riskbaseLevel?: Maybe<TRiskbaseLevel>
  substances?: Maybe<Array<Maybe<TSubstanceWithCategoryRiskValue>>>
}

/** A classification */
export type TClassification = {
  __typename?: 'Classification'
  combinations?: Maybe<Array<Maybe<TCombination>>>
  docClassification?: Maybe<Scalars['String']>
  sevClassification?: Maybe<Scalars['String']>
}

/** An interaction combination */
export type TCombination = {
  __typename?: 'Combination'
  substanceFormCombination?: Maybe<Array<Maybe<TSubstanceFormCombination>>>
}

export enum TComponentTypeFilter {
  Active = 'ACTIVE',
  All = 'ALL',
}

export enum TCountry {
  At = 'AT',
  De = 'DE',
}

export type TCrossHypersensitivity = {
  __typename?: 'CrossHypersensitivity'
  /** Medbase substance id of the estimated substance */
  estimatedSubstanceId?: Maybe<Scalars['Int']>
  /** Medbase substance id of the known substance */
  knownSubstanceId?: Maybe<Scalars['Int']>
}

export type TDgnsAdmissionForm = {
  __typename?: 'DgnsAdmissionForm'
  /** Full name of the admission form. */
  name?: Maybe<Scalars['String']>
  /** Short name of the admission form. (eg INF, SAM,...) */
  short?: Maybe<Scalars['String']>
}

export type TDgnsArticle = {
  __typename?: 'DgnsArticle'
  /** e.g. Kapsel, Creme, Brausetabletten, etc */
  admission?: Maybe<TDgnsAdmissionForm>
  /** List of Barcodes for an article */
  barcodes?: Maybe<Array<Maybe<TDgnsArticleBarcode>>>
  /**
   * (AT) Possible values are:
   * changed | new | removed | unchanged
   */
  changeStatus?: Maybe<Scalars['String']>
  /**
   * (AT) Possible values are:
   * N No-box | R Red box | Y Yellow box | G Green box
   */
  ecoBox?: Maybe<Scalars['String']>
  ecoBoxMaxQuantity?: Maybe<Scalars['Int']>
  /** This is the PCN for the article */
  id?: Maybe<Scalars['ID']>
  /** Indicates if the article is available in the market. */
  inMarket?: Maybe<Scalars['Boolean']>
  /** Indicates if the Article is a generic */
  isBiosimilar?: Maybe<Scalars['Boolean']>
  /** e.g. ALENDRONSRE ARC 70MG TBL */
  name?: Maybe<Scalars['String']>
  /** Quantity per pack. In conjuction with packageContentUnit. (e.g. 40.0) */
  packageContent?: Maybe<Scalars['String']>
  /** Sign for package content. (e.g. ST, G, ML, Pack,... */
  packageContentUnit?: Maybe<Scalars['String']>
  /** Quantity per container. In conjuction with packageSizeUnit. (e.g. 20.0) */
  packageSize?: Maybe<Scalars['String']>
  /** Sign for the package size. (e.g. ST, G, ML, Pack,...) */
  packageSizeUnit?: Maybe<Scalars['String']>
  /** If article comes from a parallel import product */
  parallelImport?: Maybe<TDgnsArticleParallelImport>
  prescriptionInfos?: Maybe<Array<Maybe<TDgnsArticlePrescriptionInfo>>>
  /** List of prices for an article. */
  prices?: Maybe<Array<Maybe<TDgnsArticlePrice>>>
}

export type TDgnsArticleBarcode = {
  __typename?: 'DgnsArticleBarcode'
  /** Barcode for the articles, longitude depends on the type. */
  code?: Maybe<Scalars['String']>
  /** e.g. GTIN-13, GTIN-14, CODE-39(DE) */
  type?: Maybe<Scalars['String']>
}

export type TDgnsArticleParallelImport = {
  __typename?: 'DgnsArticleParallelImport'
  originalProductCode?: Maybe<Scalars['Int']>
  parallelImportProductCode?: Maybe<Scalars['Int']>
  seller?: Maybe<TDgnsSeller>
}

export type TDgnsArticlePrescriptionInfo = {
  __typename?: 'DgnsArticlePrescriptionInfo'
  additionalInfo?: Maybe<Scalars['String']>
  alternativeName?: Maybe<Scalars['String']>
  category?: Maybe<TFeatureCategory>
  description?: Maybe<Scalars['String']>
  descriptionShort?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

export type TDgnsArticlePrice = {
  __typename?: 'DgnsArticlePrice'
  /** Amount of currency. */
  amount?: Maybe<Scalars['Float']>
  /** Currency. e.g. EUR */
  currency?: Maybe<Scalars['String']>
  /** Articles can have different prices. e.g. insurance, pharmacy, etc */
  type?: Maybe<Scalars['String']>
}

/** The Dgns ATC Code */
export type TDgnsAtc = {
  __typename?: 'DgnsAtc'
  /** List of children of the ATC. */
  children?: Maybe<Array<TDgnsAtc>>
  /** ATC code (id). */
  code?: Maybe<Scalars['ID']>
  /** Parent ATC code. */
  codeParent?: Maybe<Scalars['String']>
  /** Description (name) of the ATC. */
  description?: Maybe<Scalars['String']>
  /** Level of the ATC. */
  level?: Maybe<Scalars['Int']>
  productsConnection?: Maybe<TDgnsProductConnection>
}

/** The Dgns ATC Code */
export type TDgnsAtcProductsConnectionArgs = {
  country: TCountry
  page?: InputMaybe<TPagination>
}

export type TDgnsAtcConnection = {
  __typename?: 'DgnsAtcConnection'
  /** The paginated found items. If no items can be found, an empty List is returned. */
  items?: Maybe<Array<TDgnsAtc>>
  /** The total count of found items. */
  totalCount?: Maybe<Scalars['Int']>
}

export type TDgnsComponent = {
  __typename?: 'DgnsComponent'
  /** Links to the substance for this component. */
  substance?: Maybe<TDgnsSubstance>
  /** Indicates the component type. e.g. ACTIVE, HELPER, OTHER */
  type?: Maybe<TDgnsComponentType>
}

export enum TDgnsComponentType {
  Active = 'ACTIVE',
  Helper = 'HELPER',
  Other = 'OTHER',
}

/** The Dgns ICD Code */
export type TDgnsIcd = {
  __typename?: 'DgnsIcd'
  /** ICD code */
  code?: Maybe<Scalars['ID']>
  /** Parent ICD code. */
  codeParent?: Maybe<Scalars['String']>
  /** The name for this ICD code. */
  description?: Maybe<Scalars['String']>
  productsConnection?: Maybe<TDgnsProductConnection>
}

/** The Dgns ICD Code */
export type TDgnsIcdProductsConnectionArgs = {
  page?: InputMaybe<TPagination>
}

export type TDgnsIcdConnection = {
  __typename?: 'DgnsIcdConnection'
  /** The paginated found items. If no items can be found, an empty List is returned. */
  items?: Maybe<Array<TDgnsIcd>>
  /** The total count of found items. */
  totalCount?: Maybe<Scalars['Int']>
}

/** The sections of the medical information for a product. */
export type TDgnsMedicalInfo = {
  __typename?: 'DgnsMedicalInfo'
  composition?: Maybe<Scalars['String']>
  dosage?: Maybe<Scalars['String']>
  indications?: Maybe<Scalars['String']>
  interactions?: Maybe<Scalars['String']>
  /** List of the all other medical information sections. */
  other?: Maybe<Array<TDgnsOtherMedicalInfo>>
}

export type TDgnsOtherMedicalInfo = {
  __typename?: 'DgnsOtherMedicalInfo'
  body?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type TDgnsProduct = {
  __typename?: 'DgnsProduct'
  /** Admission numbers can be reused when a product becomes unavailable. */
  admissionNumber?: Maybe<Scalars['String']>
  /** Get all announcements which belong to this product */
  announcements?: Maybe<Array<TAnnouncement>>
  /** List of articles belonging to a product (by default does not include articles of parallel imports). */
  articles?: Maybe<Array<TDgnsArticle>>
  /** List of ATC codes associated with a product. */
  atcCodes?: Maybe<Array<Scalars['String']>>
  /**
   * (AT) Possible values are:
   * changed | new | removed | unchanged
   */
  changeStatus?: Maybe<Scalars['String']>
  /** List of substances included in the product */
  components?: Maybe<Array<TDgnsComponent>>
  /** Indicates if parallel imports exist for that product */
  hasParallelImports?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['ID']>
  /** Indicates if the product is available over the counter, without prescription. e.g. true = no prescrption needed */
  isOtc?: Maybe<Scalars['Boolean']>
  /**
   * The sections of the medical information for a product.
   * @deprecated Use smpcs instead.
   */
  medicalInfo?: Maybe<TDgnsMedicalInfo>
  /** The full url for the medical info PDF. */
  medicalInfoPdfUrl?: Maybe<Scalars['String']>
  /** The name of the drug/product. */
  name?: Maybe<Scalars['String']>
  /** Get all pharma notifications which belong to this product */
  pharmaNotifications?: Maybe<Array<TPharmaNotification>>
  prescriptionInfos?: Maybe<Array<Maybe<TDgnsProductPrescriptionInfo>>>
  /** Summary of product characterisics: the sections of the medical information for a product. */
  smpcs?: Maybe<TDgnsMedicalInfo>
  /** The company selling this product. */
  vendor?: Maybe<TDgnsVendor>
}

export type TDgnsProductArticlesArgs = {
  inMarket?: InputMaybe<Scalars['Boolean']>
  withParallelImports?: InputMaybe<Scalars['Boolean']>
}

export type TDgnsProductComponentsArgs = {
  type?: InputMaybe<TComponentTypeFilter>
}

export type TDgnsProductConnection = {
  __typename?: 'DgnsProductConnection'
  /** List of products matching the current query. */
  items?: Maybe<Array<TDgnsProduct>>
  /** The number of products matching the current query. */
  totalCount?: Maybe<Scalars['Int']>
}

export type TDgnsProductPrescriptionInfo = {
  __typename?: 'DgnsProductPrescriptionInfo'
  alternativeName?: Maybe<Scalars['String']>
  category?: Maybe<TFeatureCategory>
  description?: Maybe<Scalars['String']>
  descriptionShort?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

export type TDgnsSeller = {
  __typename?: 'DgnsSeller'
  /** The unique identifier for a seller. e.g. 01BEI */
  id?: Maybe<Scalars['ID']>
  /** The name of a seller. e.g. BEIERSDORF AG / D */
  name?: Maybe<Scalars['String']>
}

export type TDgnsSubstance = {
  __typename?: 'DgnsSubstance'
  /** The unique identifier for a diagnosia substance. */
  id?: Maybe<Scalars['ID']>
  /** The medbase substance mapped to this diagnosia substance. */
  medbaseSubstance?: Maybe<TSubstance>
  /**
   * The unique identifier for a medbase substance.
   * It is used to match the substances contained in products to the medbase KB.
   */
  medbaseTopParentId?: Maybe<Scalars['ID']>
  /** Substance name. e.g. Abacavir. */
  name?: Maybe<Scalars['String']>
  productsConnection?: Maybe<TDgnsProductConnection>
}

export type TDgnsSubstanceProductsConnectionArgs = {
  page?: InputMaybe<TPagination>
}

export type TDgnsSubstanceConnection = {
  __typename?: 'DgnsSubstanceConnection'
  /** List of substances matching the current query. */
  items?: Maybe<Array<TDgnsSubstance>>
  /** The number of substances matching the current query. */
  totalCount: Scalars['Int']
}

export type TDgnsSubstanceConnectionFilter = {
  /** Filter by brain substance ids */
  brainSubstanceIds?: InputMaybe<Array<Scalars['ID']>>
  /** Filter by substance ids */
  ids?: InputMaybe<Array<Scalars['ID']>>
  /** Filter by the name of the substance. */
  name?: InputMaybe<Scalars['String']>
  /** If true, only return substances which are linked to at least one product. */
  onlyWithProducts?: InputMaybe<Scalars['Boolean']>
  /** Filter by a specific substance type. */
  type?: InputMaybe<TDgnsSubstanceTypeFilter>
}

export type TDgnsSubstanceConnectionOrderBy = {
  direction: TDirection
  field: TDgnsSubstanceOrderField
}

export enum TDgnsSubstanceOrderField {
  /** Order by id. (default) */
  Id = 'ID',
  /** Order by the substance name. */
  Name = 'NAME',
}

export enum TDgnsSubstanceTypeFilter {
  /** Only query for active substances. */
  Active = 'ACTIVE',
  /** Return all matching substances. (default) */
  All = 'ALL',
}

export type TDgnsVendor = {
  __typename?: 'DgnsVendor'
  /** The unique identifier for a vendor. e.g. 01BEI */
  id?: Maybe<Scalars['ID']>
  /** The name of a vendor. e.g. BEIERSDORF AG / D */
  name?: Maybe<Scalars['String']>
}

export enum TDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum TFeatureCategory {
  Hinweiszeichen = 'HINWEISZEICHEN',
  Kassenzeichen = 'KASSENZEICHEN',
  Rezeptzeichen = 'REZEPTZEICHEN',
  Warnhinweise = 'WARNHINWEISE',
}

export type TGravbaseArticle = {
  __typename?: 'GravbaseArticle'
  classification?: Maybe<Scalars['String']>
  classificationDescription?: Maybe<Scalars['String']>
  drugFormGroupCode?: Maybe<Scalars['String']>
  drugFormGroupDescription?: Maybe<Scalars['String']>
  drugFormGroupId?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  references?: Maybe<Array<Maybe<TReference>>>
  sections?: Maybe<Array<Maybe<TSection>>>
  substance?: Maybe<TSubstance>
  substanceId?: Maybe<Scalars['Int']>
}

export type TGravbaseArticleFilter = {
  substanceIds: Array<Scalars['ID']>
}

export type TGravbaseArticlesConnection = {
  __typename?: 'GravbaseArticlesConnection'
  items?: Maybe<Array<TGravbaseArticle>>
  totalCount?: Maybe<Scalars['Int']>
}

export type THeparbaseArticle = {
  __typename?: 'HeparbaseArticle'
  drugFormGroupCode?: Maybe<Scalars['String']>
  drugFormGroupDescription?: Maybe<Scalars['String']>
  drugFormGroupId?: Maybe<Scalars['String']>
  hepatotoxic?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  recommendations?: Maybe<Array<Maybe<THeparbaseRecommendation>>>
  references?: Maybe<Array<Maybe<TReference>>>
  sections?: Maybe<Array<Maybe<TSection>>>
  substance?: Maybe<TSubstance>
  substanceId?: Maybe<Scalars['Int']>
}

export type THeparbaseArticleFilter = {
  substanceIds: Array<Scalars['ID']>
}

export type THeparbaseArticlesConnection = {
  __typename?: 'HeparbaseArticlesConnection'
  items?: Maybe<Array<THeparbaseArticle>>
  totalCount?: Maybe<Scalars['Int']>
}

export type THeparbaseRecommendation = {
  __typename?: 'HeparbaseRecommendation'
  classification?: Maybe<Scalars['String']>
  classificationDescription?: Maybe<Scalars['String']>
  dosageMod?: Maybe<Scalars['Int']>
  dosageModCode?: Maybe<Scalars['Int']>
  dosageModDescription?: Maybe<Scalars['String']>
  dosageModMax?: Maybe<Scalars['Int']>
  doseInterval?: Maybe<Scalars['Int']>
  doseIntervalMax?: Maybe<Scalars['Int']>
  hepaticImpairmentDegreeDescription?: Maybe<Scalars['String']>
  hepaticImpairmentDegreeId?: Maybe<Scalars['Int']>
}

export type TIcdsConnectionFilter = {
  /** Filter by ICD description/name. */
  description?: InputMaybe<Scalars['String']>
  /**
   * Indicates if only top level ICD codes should be shown.
   * (= ICD codes without a parent)
   */
  topLevelOnly?: InputMaybe<Scalars['Boolean']>
}

export type TIcdsConnectionOrderBy = {
  /** Order result ASC or DESC. */
  direction?: InputMaybe<TDirection>
  /** Order result by field. */
  field?: InputMaybe<TIcdsOrderField>
}

export enum TIcdsOrderField {
  Code = 'CODE',
  CodeParent = 'CODE_PARENT',
  Description = 'DESCRIPTION',
}

/** An interaction */
export type TInteraction = {
  __typename?: 'Interaction'
  classifications?: Maybe<Array<Maybe<TClassification>>>
  id?: Maybe<Scalars['Int']>
  interactionSubstances?: Maybe<Array<Maybe<TInteractionSubstance>>>
  /** This is the most severe classification of the list of classifications in this interaction */
  maxClassification?: Maybe<TClassification>
  references?: Maybe<Array<Maybe<TInxbaseReference>>>
  sections?: Maybe<Array<Maybe<TInxbaseSection>>>
  substances?: Maybe<Array<Maybe<TSubstance>>>
}

/** A link betweeen interactions and substances */
export type TInteractionSubstance = {
  __typename?: 'InteractionSubstance'
  interactionId?: Maybe<Scalars['Int']>
  substanceId?: Maybe<Scalars['Int']>
}

/** An interaction text. This is referenced by the sections part of an interaction object. */
export type TInteractionText = {
  __typename?: 'InteractionText'
  id?: Maybe<Scalars['Int']>
  text?: Maybe<Array<Maybe<TInteractionTextTranslationVersion>>>
}

/** An interaction text translation */
export type TInteractionTextTranslation = {
  __typename?: 'InteractionTextTranslation'
  langCode?: Maybe<Scalars['String']>
  text?: Maybe<Scalars['String']>
}

/** An interaction text translation version. */
export type TInteractionTextTranslationVersion = {
  __typename?: 'InteractionTextTranslationVersion'
  translation?: Maybe<Array<Maybe<TInteractionTextTranslation>>>
  version?: Maybe<Scalars['Int']>
}

/**
 * Set the search mode for interaction queries to one of the following modes:
 *
 * * `AUTO` (default): If only one top_parent_id is given it uses the `ALL` search mode. For multiple top_parent_ids it switches to the `CROSS` search mode.
 * * `ALL`: It lists all interactions of all given top_parent_ids.
 * * `CROSS`: It lists interactions between the given top_parent_ids only.
 *
 * Typically you want to use the `ALL` mode for single-drug interaction checks and
 * the `CROSS` mode for interaction checks between multiple drugs.
 *
 * The `AUTO` mode is for backwards compatibility, therefore its the default. The `AUTO` mode
 * will be removed in the future as it is error prone for drugs with more than one substance.
 */
export enum TInteractionsSearchMode {
  /** It lists all interactions of all given top_parent_ids. */
  All = 'ALL',
  /**
   * If only one top_parent_id is given it uses the `ALL` search mode. For multiple top_parent_ids it switches to the `CROSS` search mode.
   * @deprecated For backwards compatibility only. This will be removed in the future as it is error prone for drugs with more than one substance.
   */
  Auto = 'AUTO',
  /** It lists interactions between the given top_parent_ids only. */
  Cross = 'CROSS',
}

/** A list of interactions with it's calculated max_classification value attached */
export type TInteractionsWithMaxClassification = {
  __typename?: 'InteractionsWithMaxClassification'
  interactions?: Maybe<Array<TInteraction>>
  /** This is the most severe classification of the interactions listed in the `interactions` list */
  maxClassification?: Maybe<TClassification>
  /** This is the total number of interactions found for the given query */
  totalInteractions?: Maybe<Scalars['Int']>
}

/** A reference */
export type TInxbaseReference = {
  __typename?: 'InxbaseReference'
  articleId?: Maybe<Scalars['Int']>
  referenceDetails?: Maybe<TReferenceDetails>
  referenceId?: Maybe<Scalars['Int']>
}

/** A section. At the moment the potential types are 0: Consequences, 1: Recommendation, 2: Mechanism, 3: Background, 4: Patient */
export type TInxbaseSection = {
  __typename?: 'InxbaseSection'
  id?: Maybe<Scalars['Int']>
  interactionText?: Maybe<TInteractionText>
  sectionType?: Maybe<TSectionType>
  typeId?: Maybe<Scalars['Int']>
  version?: Maybe<Scalars['Int']>
}

export type TItemInput = {
  /** The webMedCountry of the user who creates the announcement */
  country: TCountry
  /** Indicates if the product/drug is external */
  isExternalProduct?: InputMaybe<Scalars['Boolean']>
  /** The id of the product/drug */
  productId: Scalars['Int']
}

export type TLactbaseArticle = {
  __typename?: 'LactbaseArticle'
  classification?: Maybe<Scalars['String']>
  classificationDescription?: Maybe<Scalars['String']>
  drugFormGroupCode?: Maybe<Scalars['String']>
  drugFormGroupDescription?: Maybe<Scalars['String']>
  drugFormGroupId?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  references?: Maybe<Array<Maybe<TReference>>>
  sections?: Maybe<Array<Maybe<TSection>>>
  substance?: Maybe<TSubstance>
  substanceId?: Maybe<Scalars['Int']>
}

export type TLactbaseArticleFilter = {
  substanceIds: Array<Scalars['ID']>
}

export type TLactbaseArticlesConnection = {
  __typename?: 'LactbaseArticlesConnection'
  items?: Maybe<Array<TLactbaseArticle>>
  totalCount?: Maybe<Scalars['Int']>
}

export type TPagination = {
  /** Page number. Starts at 1. */
  number?: InputMaybe<Scalars['Int']>
  /** Items per page. */
  size?: InputMaybe<Scalars['Int']>
}

export enum TParallelImportsFilter {
  /** Get all products */
  All = 'ALL',
  /** Only get products which are not parallel imports */
  OnlyNotParallelImports = 'ONLY_NOT_PARALLEL_IMPORTS',
  /** Only get products which are parallel imports */
  OnlyParallelImports = 'ONLY_PARALLEL_IMPORTS',
}

/** A Pharao Property */
export type TPharaoProperty = {
  __typename?: 'PharaoProperty'
  category?: Maybe<TRiskbaseCategory>
  categoryId?: Maybe<Scalars['Int']>
  value?: Maybe<Scalars['Int']>
}

/** A Pharao Text */
export type TPharaoText = {
  __typename?: 'PharaoText'
  consequence?: Maybe<Array<Maybe<TPharaoTextTranslation>>>
  levelId?: Maybe<Scalars['Int']>
  recommendation?: Maybe<Array<Maybe<TPharaoTextTranslation>>>
}

/** A Pharao Text Translation */
export type TPharaoTextTranslation = {
  __typename?: 'PharaoTextTranslation'
  langCode?: Maybe<Scalars['String']>
  text?: Maybe<Scalars['String']>
}

/** Object containing a pharma notification */
export type TPharmaNotification = {
  __typename?: 'PharmaNotification'
  /** Additional products which belong to the pharma notification */
  additionalProducts?: Maybe<Array<TAdditionalProduct>>
  dataAccuracyConfirmed?: Maybe<Scalars['Boolean']>
  drugName?: Maybe<Scalars['String']>
  drugUkey?: Maybe<Scalars['String']>
  /** ID given by the database */
  id?: Maybe<Scalars['Int']>
  /** Created at date */
  insertedAt?: Maybe<Scalars['NaiveDateTime']>
  /** Indicates if product is referencing an external product (eg from a hospital) */
  isExternalProduct?: Maybe<Scalars['Boolean']>
  notificationTransmitted?: Maybe<Scalars['Boolean']>
  /** ID of the organisation of the user who created the announcement (comes from Plexus) */
  organisationId?: Maybe<Scalars['String']>
  /** The corresponding product which belongs to the item (only query this if you need more data than id and name) */
  product?: Maybe<TDgnsProduct>
  productCountry?: Maybe<TProductCountryEnum>
  /** The id of the product/drug */
  productId?: Maybe<Scalars['Int']>
  productName?: Maybe<Scalars['String']>
  qdAdditionalRelevantInfo?: Maybe<Scalars['String']>
  qdChargenCode?: Maybe<Scalars['String']>
  qdChargenSupplier?: Maybe<Scalars['String']>
  qdDeliveryNotPossible?: Maybe<Scalars['Boolean']>
  qdDescription?: Maybe<Scalars['String']>
  qdDigitalphotoViaMail?: Maybe<Scalars['Boolean']>
  qdExampleDescription?: Maybe<Scalars['String']>
  qdFurtherPlannedActivities?: Maybe<Scalars['String']>
  qdInformedSources?: Maybe<Scalars['String']>
  qdListSupplementsForNotification?: Maybe<Scalars['String']>
  qdOriginalViaPost?: Maybe<Scalars['Boolean']>
  qdRecognizedBy?: Maybe<TQdRecognizedByEnum>
  qdRelationOtherSideeffect?: Maybe<Scalars['String']>
  qdReportedAt?: Maybe<Scalars['Date']>
  qdReportedTime?: Maybe<Scalars['Time']>
  qdSuspicionDangerPublicHealth?: Maybe<Scalars['Boolean']>
  qdTakenActivities?: Maybe<Scalars['String']>
  reporterCity?: Maybe<Scalars['String']>
  reporterEmail?: Maybe<Scalars['String']>
  reporterFax?: Maybe<Scalars['String']>
  reporterFirstname?: Maybe<Scalars['String']>
  reporterJobDescription?: Maybe<Scalars['String']>
  reporterLastname?: Maybe<Scalars['String']>
  reporterOrganisation?: Maybe<Scalars['String']>
  reporterPhone?: Maybe<Scalars['String']>
  reporterStreet?: Maybe<Scalars['String']>
  reporterTitle?: Maybe<Scalars['String']>
  reporterZip?: Maybe<Scalars['String']>
  samplesSent?: Maybe<Scalars['Boolean']>
  seAgeInMonths?: Maybe<Scalars['Int']>
  seAgeInYears?: Maybe<Scalars['Int']>
  seContextWithSuspiciousDrug?: Maybe<TSeContextWithSuspiciousDrugEnum>
  seDescription?: Maybe<Scalars['String']>
  seDosageUsage?: Maybe<Scalars['String']>
  seEditorData?: Maybe<Scalars['String']>
  seGender?: Maybe<TSeGenderEnum>
  seNotification?: Maybe<Scalars['Boolean']>
  seOccurredAt?: Maybe<Scalars['NaiveDateTime']>
  sePatientIdentification?: Maybe<Scalars['String']>
  sePatientInitials?: Maybe<Scalars['String']>
  seProcess?: Maybe<TSeProcessEnum>
  seReporterData?: Maybe<Scalars['String']>
  seResult?: Maybe<TSeResultEnum>
  seTreatmentSideEffect?: Maybe<Scalars['String']>
  seUsageFrom?: Maybe<Scalars['Date']>
  seUsageFurtherDiseases?: Maybe<Scalars['String']>
  seUsageReason?: Maybe<Scalars['String']>
  seUsageUntil?: Maybe<Scalars['Date']>
  /** Type of the pharma notification */
  type?: Maybe<TPharmaNotificationTypeEnum>
  /** Updated at date */
  updatedAt?: Maybe<Scalars['NaiveDateTime']>
  /** ID of the user who created the announcement (comes from Plexus) */
  userId?: Maybe<Scalars['String']>
  validForAllPackSizes?: Maybe<Scalars['Boolean']>
}

export enum TPharmaNotificationOrderField {
  CreatedAt = 'CREATED_AT',
  Type = 'TYPE',
}

/** The available pharma notification types */
export enum TPharmaNotificationTypeEnum {
  QualityDeficit = 'QUALITY_DEFICIT',
  SideEffect = 'SIDE_EFFECT',
}

export type TPharmaNotificationsConnection = {
  __typename?: 'PharmaNotificationsConnection'
  /** List of pharma notifications. */
  items?: Maybe<Array<TPharmaNotification>>
  /** Total count of pharma notifications. */
  totalCount: Scalars['Int']
}

export type TPharmaNotificationsFilter = {
  type?: InputMaybe<TPharmaNotificationTypeEnum>
}

export type TPharmaNotificationsOrderBy = {
  direction?: InputMaybe<TDirection>
  field?: InputMaybe<TPharmaNotificationOrderField>
}

export type TProductConnectionFilter = {
  barcode?: InputMaybe<Scalars['String']>
  /** Filter by product ids. */
  ids?: InputMaybe<Array<Scalars['ID']>>
  name?: InputMaybe<Scalars['String']>
  /** Filter products by parallel_imports */
  parallelImports?: InputMaybe<TParallelImportsFilter>
  /** Filter by pzns. */
  pzns?: InputMaybe<Array<Scalars['ID']>>
  /** Filter by active substances with that ids. */
  substanceIds?: InputMaybe<Array<Scalars['ID']>>
  /** Filter by ukeys. (only AT) */
  ukeys?: InputMaybe<Array<Scalars['ID']>>
}

export type TProductConnectionOrderBy = {
  direction: TDirection
  field: TProductOrderField
}

export enum TProductCountryEnum {
  At = 'AT',
  De = 'DE',
}

export enum TProductOrderField {
  ChangeStatus = 'CHANGE_STATUS',
  Code = 'CODE',
  Name = 'NAME',
}

export enum TQdRecognizedByEnum {
  Doctor = 'DOCTOR',
  Patient = 'PATIENT',
}

export type TReference = {
  __typename?: 'Reference'
  body?: Maybe<Scalars['String']>
  pmid?: Maybe<Scalars['String']>
  sortKey?: Maybe<Scalars['Int']>
}

/** A Reference Details Object that has the pmid and text details. */
export type TReferenceDetails = {
  __typename?: 'ReferenceDetails'
  id?: Maybe<Scalars['Int']>
  pmid?: Maybe<Scalars['Int']>
  /** When there is a PubMed reference you can find it's url here. Otherwise the field is `null`. */
  pubMedUrl?: Maybe<Scalars['String']>
  text?: Maybe<Scalars['String']>
}

export type TRenbaseArticle = {
  __typename?: 'RenbaseArticle'
  classification?: Maybe<Array<Maybe<Scalars['String']>>>
  drugFormGroupCode?: Maybe<Scalars['String']>
  drugFormGroupDescription?: Maybe<Scalars['String']>
  drugFormGroupId?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  nephrotoxic?: Maybe<Scalars['String']>
  recommendations?: Maybe<Array<Maybe<TRenbaseRecommendation>>>
  references?: Maybe<Array<Maybe<TReference>>>
  sections?: Maybe<Array<Maybe<TSection>>>
  substance?: Maybe<TSubstance>
  substanceId?: Maybe<Scalars['Int']>
}

export type TRenbaseArticleFilter = {
  substanceIds: Array<Scalars['ID']>
}

export type TRenbaseArticlesConnection = {
  __typename?: 'RenbaseArticlesConnection'
  items?: Maybe<Array<TRenbaseArticle>>
  totalCount?: Maybe<Scalars['Int']>
}

export type TRenbaseRecommendation = {
  __typename?: 'RenbaseRecommendation'
  classification?: Maybe<Scalars['String']>
  classificationDescription?: Maybe<Scalars['String']>
  dosageMod?: Maybe<Scalars['Int']>
  dosageModCode?: Maybe<Scalars['String']>
  dosageModDescription?: Maybe<Scalars['String']>
  dosageModMax?: Maybe<Scalars['Int']>
  doseInterval?: Maybe<Scalars['Int']>
  doseIntervalMax?: Maybe<Scalars['Int']>
  renalFailureDegreeDescription?: Maybe<Scalars['String']>
  renalFailureDegreeId?: Maybe<Scalars['Int']>
}

/** A Riskbase Category Algorithm */
export type TRiskbaseAlgorithm = {
  __typename?: 'RiskbaseAlgorithm'
  name?: Maybe<Scalars['String']>
}

/** A Riskbase Calculation */
export type TRiskbaseCalculation = {
  __typename?: 'RiskbaseCalculation'
  level?: Maybe<Scalars['Int']>
  max?: Maybe<Scalars['Int']>
  min?: Maybe<Scalars['Int']>
}

/** A Riskbase Category */
export type TRiskbaseCategory = {
  __typename?: 'RiskbaseCategory'
  algorithm?: Maybe<TRiskbaseAlgorithm>
  calculations?: Maybe<Array<Maybe<TRiskbaseCalculation>>>
  categoryId?: Maybe<Scalars['Int']>
  categoryNames?: Maybe<Array<Maybe<TRiskbaseCategoryName>>>
  pharaoTexts?: Maybe<Array<Maybe<TPharaoText>>>
}

/** A Riskbase Category Name */
export type TRiskbaseCategoryName = {
  __typename?: 'RiskbaseCategoryName'
  categoryName?: Maybe<Scalars['String']>
  langCode?: Maybe<Scalars['String']>
}

export type TRiskbaseLevel = {
  __typename?: 'RiskbaseLevel'
  classification?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  levelClassification?: Maybe<Array<Maybe<TRiskbaseLevelClassification>>>
}

export type TRiskbaseLevelClassification = {
  __typename?: 'RiskbaseLevelClassification'
  classification?: Maybe<Scalars['String']>
  langCode?: Maybe<Scalars['String']>
}

export type TRootMutationType = {
  __typename?: 'RootMutationType'
  /** Create a new announcement with its items */
  createAnnouncement?: Maybe<TAnnouncement>
  /** Create a new pharma notifications with its items */
  createPharmaNotification?: Maybe<TPharmaNotification>
  /** Update an announcement */
  updateAnnouncement?: Maybe<TAnnouncement>
  /** Update a pharma notification */
  updatePharmaNotification?: Maybe<TPharmaNotification>
}

export type TRootMutationTypeCreateAnnouncementArgs = {
  body?: InputMaybe<Scalars['String']>
  customId?: InputMaybe<Scalars['String']>
  file?: InputMaybe<Scalars['String']>
  items?: InputMaybe<Array<TItemInput>>
  organisationId: Scalars['String']
  title: Scalars['String']
  type: TAnnouncementTypeEnum
  userId: Scalars['String']
}

export type TRootMutationTypeCreatePharmaNotificationArgs = {
  additionalProducts?: InputMaybe<Array<TAdditionalProductInput>>
  dataAccuracyConfirmed?: InputMaybe<Scalars['Boolean']>
  isExternalProduct?: InputMaybe<Scalars['Boolean']>
  notificationTransmitted?: InputMaybe<Scalars['Boolean']>
  organisationId: Scalars['String']
  productCountry: TProductCountryEnum
  productId: Scalars['Int']
  productName: Scalars['String']
  qdAdditionalRelevantInfo?: InputMaybe<Scalars['String']>
  qdChargenCode?: InputMaybe<Scalars['String']>
  qdChargenSupplier?: InputMaybe<Scalars['String']>
  qdDeliveryNotPossible?: InputMaybe<Scalars['Boolean']>
  qdDescription?: InputMaybe<Scalars['String']>
  qdDigitalphotoViaMail?: InputMaybe<Scalars['Boolean']>
  qdExampleDescription?: InputMaybe<Scalars['String']>
  qdFurtherPlannedActivities?: InputMaybe<Scalars['String']>
  qdInformedSources?: InputMaybe<Scalars['String']>
  qdListSupplementsForNotification?: InputMaybe<Scalars['String']>
  qdOriginalViaPost?: InputMaybe<Scalars['Boolean']>
  qdRecognizedBy?: InputMaybe<TQdRecognizedByEnum>
  qdRelationOtherSideeffect?: InputMaybe<Scalars['String']>
  qdReportedAt?: InputMaybe<Scalars['String']>
  qdReportedTime?: InputMaybe<Scalars['String']>
  qdSuspicionDangerPublicHealth?: InputMaybe<Scalars['Boolean']>
  qdTakenActivities?: InputMaybe<Scalars['String']>
  reporterCity: Scalars['String']
  reporterEmail: Scalars['String']
  reporterFax?: InputMaybe<Scalars['String']>
  reporterFirstname: Scalars['String']
  reporterJobDescription?: InputMaybe<Scalars['String']>
  reporterLastname: Scalars['String']
  reporterOrganisation?: InputMaybe<Scalars['String']>
  reporterPhone: Scalars['String']
  reporterStreet?: InputMaybe<Scalars['String']>
  reporterTitle?: InputMaybe<Scalars['String']>
  reporterZip: Scalars['String']
  samplesSent?: InputMaybe<Scalars['Boolean']>
  seAgeInMonths?: InputMaybe<Scalars['Int']>
  seAgeInYears?: InputMaybe<Scalars['Int']>
  seContextWithSuspiciousDrug?: InputMaybe<TSeContextWithSuspiciousDrugEnum>
  seDescription?: InputMaybe<Scalars['String']>
  seDosageUsage?: InputMaybe<Scalars['String']>
  seEditorData?: InputMaybe<Scalars['String']>
  seGender?: InputMaybe<TSeGenderEnum>
  seNotification?: InputMaybe<Scalars['Boolean']>
  seOccurredAt?: InputMaybe<Scalars['String']>
  sePatientIdentification?: InputMaybe<Scalars['String']>
  sePatientInitials?: InputMaybe<Scalars['String']>
  seProcess?: InputMaybe<TSeProcessEnum>
  seReporterData?: InputMaybe<Scalars['String']>
  seResult?: InputMaybe<TSeResultEnum>
  seTreatmentSideEffect?: InputMaybe<Scalars['String']>
  seUsageFrom?: InputMaybe<Scalars['String']>
  seUsageFurtherDiseases?: InputMaybe<Scalars['String']>
  seUsageReason?: InputMaybe<Scalars['String']>
  seUsageUntil?: InputMaybe<Scalars['String']>
  type: TPharmaNotificationTypeEnum
  userId: Scalars['String']
  validForAllPackSizes?: InputMaybe<Scalars['Boolean']>
}

export type TRootMutationTypeUpdateAnnouncementArgs = {
  body?: InputMaybe<Scalars['String']>
  customId?: InputMaybe<Scalars['String']>
  file?: InputMaybe<Scalars['String']>
  id: Scalars['Int']
  items?: InputMaybe<Array<TItemInput>>
  organisationId: Scalars['String']
  title: Scalars['String']
  type: TAnnouncementTypeEnum
  userId: Scalars['String']
}

export type TRootMutationTypeUpdatePharmaNotificationArgs = {
  additionalProducts?: InputMaybe<Array<TAdditionalProductInput>>
  dataAccuracyConfirmed?: InputMaybe<Scalars['Boolean']>
  id: Scalars['Int']
  notificationTransmitted?: InputMaybe<Scalars['Boolean']>
  qdAdditionalRelevantInfo?: InputMaybe<Scalars['String']>
  qdChargenCode?: InputMaybe<Scalars['String']>
  qdChargenSupplier?: InputMaybe<Scalars['String']>
  qdDeliveryNotPossible?: InputMaybe<Scalars['Boolean']>
  qdDescription?: InputMaybe<Scalars['String']>
  qdDigitalphotoViaMail?: InputMaybe<Scalars['Boolean']>
  qdExampleDescription?: InputMaybe<Scalars['String']>
  qdFurtherPlannedActivities?: InputMaybe<Scalars['String']>
  qdInformedSources?: InputMaybe<Scalars['String']>
  qdListSupplementsForNotification?: InputMaybe<Scalars['String']>
  qdOriginalViaPost?: InputMaybe<Scalars['Boolean']>
  qdRecognizedBy?: InputMaybe<TQdRecognizedByEnum>
  qdRelationOtherSideeffect?: InputMaybe<Scalars['String']>
  qdReportedAt?: InputMaybe<Scalars['String']>
  qdReportedTime?: InputMaybe<Scalars['String']>
  qdSuspicionDangerPublicHealth?: InputMaybe<Scalars['Boolean']>
  qdTakenActivities?: InputMaybe<Scalars['String']>
  reporterCity?: InputMaybe<Scalars['String']>
  reporterEmail?: InputMaybe<Scalars['String']>
  reporterFax?: InputMaybe<Scalars['String']>
  reporterFirstname?: InputMaybe<Scalars['String']>
  reporterJobDescription?: InputMaybe<Scalars['String']>
  reporterLastname?: InputMaybe<Scalars['String']>
  reporterOrganisation?: InputMaybe<Scalars['String']>
  reporterPhone?: InputMaybe<Scalars['String']>
  reporterStreet?: InputMaybe<Scalars['String']>
  reporterTitle?: InputMaybe<Scalars['String']>
  reporterZip?: InputMaybe<Scalars['String']>
  samplesSent?: InputMaybe<Scalars['Boolean']>
  seAgeInMonths?: InputMaybe<Scalars['Int']>
  seAgeInYears?: InputMaybe<Scalars['Int']>
  seContextWithSuspiciousDrug?: InputMaybe<TSeContextWithSuspiciousDrugEnum>
  seDescription?: InputMaybe<Scalars['String']>
  seDosageUsage?: InputMaybe<Scalars['String']>
  seEditorData?: InputMaybe<Scalars['String']>
  seGender?: InputMaybe<TSeGenderEnum>
  seNotification?: InputMaybe<Scalars['Boolean']>
  seOccurredAt?: InputMaybe<Scalars['String']>
  sePatientIdentification?: InputMaybe<Scalars['String']>
  sePatientInitials?: InputMaybe<Scalars['String']>
  seProcess?: InputMaybe<TSeProcessEnum>
  seReporterData?: InputMaybe<Scalars['String']>
  seResult?: InputMaybe<TSeResultEnum>
  seTreatmentSideEffect?: InputMaybe<Scalars['String']>
  seUsageFrom?: InputMaybe<Scalars['String']>
  seUsageFurtherDiseases?: InputMaybe<Scalars['String']>
  seUsageReason?: InputMaybe<Scalars['String']>
  seUsageUntil?: InputMaybe<Scalars['String']>
  type?: InputMaybe<TPharmaNotificationTypeEnum>
  validForAllPackSizes?: InputMaybe<Scalars['Boolean']>
}

export type TRootQueryType = {
  __typename?: 'RootQueryType'
  /** Get aggregated risk information for a list of substances by it's substance ids */
  aggregatedRisksBySubstanceIds?: Maybe<TAggregatedRisk>
  /** Get an announcement by its id */
  announcement?: Maybe<TAnnouncement>
  /** Get paginated announcements */
  announcements: TAnnouncementsConnection
  atc?: Maybe<TDgnsAtc>
  atcs: TDgnsAtcConnection
  gravbaseArticles?: Maybe<TGravbaseArticlesConnection>
  heparbaseArticles?: Maybe<THeparbaseArticlesConnection>
  icd?: Maybe<TDgnsIcd>
  icds: TDgnsIcdConnection
  /** Get a specific interaction by its id */
  interaction?: Maybe<TInteraction>
  /** Get a specific interaction text by id */
  interactionText?: Maybe<TInteractionText>
  /** Get interactions by a list of top substance ids */
  interactions?: Maybe<Array<Maybe<TInteraction>>>
  /**
   * Get interactions by a list of top_parent_ids of the substances you want to check for interactions.
   *
   * In order to stay backwards compatible the pagination is turned off by default.
   * You can start to paginate the results by using the `page` and/or `pageSize` argument.
   */
  interactionsByTopParentIds?: Maybe<TInteractionsWithMaxClassification>
  lactbaseArticles?: Maybe<TLactbaseArticlesConnection>
  /** Get a pharma notification by its id */
  pharmaNotification?: Maybe<TPharmaNotification>
  /** Get paginated pharma notifications */
  pharmaNotifications: TPharmaNotificationsConnection
  /** Get an product by country and id */
  product?: Maybe<TDgnsProduct>
  /** Get paginated products by country and filtered */
  products?: Maybe<TDgnsProductConnection>
  renbaseArticles?: Maybe<TRenbaseArticlesConnection>
  /** Get all risbase categories */
  riskbaseCategories?: Maybe<Array<Maybe<TRiskbaseCategory>>>
  substance?: Maybe<TDgnsSubstance>
  substances?: Maybe<TDgnsSubstanceConnection>
  xreactbaseArticles?: Maybe<TXreactbaseArticlesConnection>
}

export type TRootQueryTypeAggregatedRisksBySubstanceIdsArgs = {
  substanceIds: Array<Scalars['ID']>
}

export type TRootQueryTypeAnnouncementArgs = {
  id: Scalars['Int']
}

export type TRootQueryTypeAnnouncementsArgs = {
  filter?: InputMaybe<TAnnouncementsFilter>
  orderBy?: InputMaybe<TAnnouncementsOrderBy>
}

export type TRootQueryTypeAtcArgs = {
  code: Scalars['ID']
}

export type TRootQueryTypeAtcsArgs = {
  filter?: InputMaybe<TAtcsConnectionFilter>
  orderBy?: InputMaybe<TAtcsConnectionOrderBy>
  page?: InputMaybe<TPagination>
}

export type TRootQueryTypeGravbaseArticlesArgs = {
  filter?: InputMaybe<TGravbaseArticleFilter>
  page?: InputMaybe<Scalars['Int']>
  pageSize?: InputMaybe<Scalars['Int']>
}

export type TRootQueryTypeHeparbaseArticlesArgs = {
  filter?: InputMaybe<THeparbaseArticleFilter>
  page?: InputMaybe<Scalars['Int']>
  pageSize?: InputMaybe<Scalars['Int']>
}

export type TRootQueryTypeIcdArgs = {
  code: Scalars['ID']
  country: TCountry
}

export type TRootQueryTypeIcdsArgs = {
  country: TCountry
  filter?: InputMaybe<TIcdsConnectionFilter>
  orderBy?: InputMaybe<TIcdsConnectionOrderBy>
  page?: InputMaybe<TPagination>
}

export type TRootQueryTypeInteractionArgs = {
  id?: InputMaybe<Scalars['Int']>
}

export type TRootQueryTypeInteractionTextArgs = {
  interactionTextId: Scalars['Int']
}

export type TRootQueryTypeInteractionsArgs = {
  page?: InputMaybe<Scalars['Int']>
  pageSize?: InputMaybe<Scalars['Int']>
  topParentIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
}

export type TRootQueryTypeInteractionsByTopParentIdsArgs = {
  page?: InputMaybe<Scalars['Int']>
  pageSize?: InputMaybe<Scalars['Int']>
  searchMode?: InputMaybe<TInteractionsSearchMode>
  topParentIds: Array<Scalars['ID']>
}

export type TRootQueryTypeLactbaseArticlesArgs = {
  filter?: InputMaybe<TLactbaseArticleFilter>
  page?: InputMaybe<Scalars['Int']>
  pageSize?: InputMaybe<Scalars['Int']>
}

export type TRootQueryTypePharmaNotificationArgs = {
  id: Scalars['Int']
}

export type TRootQueryTypePharmaNotificationsArgs = {
  filter?: InputMaybe<TPharmaNotificationsFilter>
  orderBy?: InputMaybe<TPharmaNotificationsOrderBy>
}

export type TRootQueryTypeProductArgs = {
  country: TCountry
  id: Scalars['ID']
}

export type TRootQueryTypeProductsArgs = {
  country: TCountry
  filter?: InputMaybe<TProductConnectionFilter>
  orderBy?: InputMaybe<TProductConnectionOrderBy>
  page?: InputMaybe<TPagination>
}

export type TRootQueryTypeRenbaseArticlesArgs = {
  filter?: InputMaybe<TRenbaseArticleFilter>
  page?: InputMaybe<Scalars['Int']>
  pageSize?: InputMaybe<Scalars['Int']>
}

export type TRootQueryTypeSubstanceArgs = {
  country: TCountry
  id: Scalars['ID']
}

export type TRootQueryTypeSubstancesArgs = {
  country: TCountry
  filter?: InputMaybe<TDgnsSubstanceConnectionFilter>
  orderBy?: InputMaybe<TDgnsSubstanceConnectionOrderBy>
  page?: InputMaybe<TPagination>
}

export type TRootQueryTypeXreactbaseArticlesArgs = {
  filter?: InputMaybe<TXreactbaseArticleFilter>
  page?: InputMaybe<Scalars['Int']>
  pageSize?: InputMaybe<Scalars['Int']>
}

export enum TSeContextWithSuspiciousDrugEnum {
  CanNotBeAssessed = 'CAN_NOT_BE_ASSESSED',
  Possible = 'POSSIBLE',
  Probably = 'PROBABLY',
  Secured = 'SECURED',
  Unlikely = 'UNLIKELY',
  Unpunished = 'UNPUNISHED',
}

export enum TSeGenderEnum {
  Female = 'FEMALE',
  Male = 'MALE',
  Other = 'OTHER',
  Undefined = 'UNDEFINED',
}

export enum TSeProcessEnum {
  CongenitalDamage = 'CONGENITAL_DAMAGE',
  Deadly = 'DEADLY',
  HospitalStayRequiredExtended = 'HOSPITAL_STAY_REQUIRED_EXTENDED',
  LifeThreatening = 'LIFE_THREATENING',
  NoneOfThese = 'NONE_OF_THESE',
  PermanentSevereDisability = 'PERMANENT_SEVERE_DISABILITY',
}

export enum TSeResultEnum {
  NotYetSolved = 'NOT_YET_SOLVED',
  PermanentDamage = 'PERMANENT_DAMAGE',
  Solved = 'SOLVED',
  Unknown = 'UNKNOWN',
}

export type TSection = {
  __typename?: 'Section'
  body?: Maybe<Scalars['String']>
  locale?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  sectionTypeId?: Maybe<Scalars['String']>
}

/** A Section Type */
export type TSectionType = {
  __typename?: 'SectionType'
  id?: Maybe<Scalars['Int']>
  text?: Maybe<Scalars['String']>
}

/** Medbase substance */
export type TSubstance = {
  __typename?: 'Substance'
  children?: Maybe<Array<Maybe<TSubstance>>>
  codes?: Maybe<Array<Maybe<TSubstanceCode>>>
  hasInteractions?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['Int']>
  /** @deprecated Just use the id of the substance. */
  parentId?: Maybe<Scalars['Int']>
  pharaoProperties?: Maybe<Array<Maybe<TPharaoProperty>>>
  substanceNames?: Maybe<Array<Maybe<TSubstanceName>>>
  /** @deprecated Just use the id of the substance. */
  topParentId?: Maybe<Scalars['Int']>
}

/** Medbase substance code */
export type TSubstanceCode = {
  __typename?: 'SubstanceCode'
  code?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
}

/** A substance form */
export type TSubstanceForm = {
  __typename?: 'SubstanceForm'
  drugFormGroupCode?: Maybe<Scalars['String']>
  drugFormGroupId?: Maybe<Scalars['Int']>
  drugPartId?: Maybe<Scalars['Int']>
  interactingPartId?: Maybe<Scalars['Int']>
}

/** A substance form combination */
export type TSubstanceFormCombination = {
  __typename?: 'SubstanceFormCombination'
  substanceForm?: Maybe<Array<Maybe<TSubstanceForm>>>
}

/** Medbase substance name */
export type TSubstanceName = {
  __typename?: 'SubstanceName'
  default?: Maybe<Scalars['Boolean']>
  languageCode?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  substanceId?: Maybe<Scalars['Int']>
}

export type TSubstanceWithCategoryRiskValue = {
  __typename?: 'SubstanceWithCategoryRiskValue'
  /** The id of the substance */
  id?: Maybe<Scalars['Int']>
  /** The `value` in the `pharaoProperties` of this substance for this RiskbaseCategory */
  riskValue?: Maybe<Scalars['Int']>
}

export type TSymptom = {
  __typename?: 'Symptom'
  code?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
}

export type TXreactbaseArticle = {
  __typename?: 'XreactbaseArticle'
  classification?: Maybe<Scalars['String']>
  classificationDescription?: Maybe<Scalars['String']>
  crossHypersensitivities?: Maybe<Array<TCrossHypersensitivity>>
  drugFormGroupCode?: Maybe<Scalars['String']>
  drugFormGroupDescription?: Maybe<Scalars['String']>
  drugFormGroupId?: Maybe<Scalars['String']>
  frequencyCode?: Maybe<Scalars['String']>
  frequencyDescription?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  references?: Maybe<Array<Maybe<TReference>>>
  sections?: Maybe<Array<Maybe<TSection>>>
  substanceId?: Maybe<Scalars['Int']>
  symptoms?: Maybe<Array<Maybe<TSymptom>>>
}

export type TXreactbaseArticleFilter = {
  /** List of IDs of substances the patient gets through drugs */
  estimatedSubstanceIds?: InputMaybe<Array<Scalars['Int']>>
  /** List of IDs of substances the patient is allergic to */
  knownSubstanceIds?: InputMaybe<Array<Scalars['Int']>>
}

export type TXreactbaseArticlesConnection = {
  __typename?: 'XreactbaseArticlesConnection'
  /** List of found xreact articles. */
  items?: Maybe<Array<TXreactbaseArticle>>
  /** Total count of found xreact articles. */
  totalCount?: Maybe<Scalars['Int']>
}

export type TProductFragment = {
  __typename?: 'DgnsProduct'
  atcCodes?: Array<string> | null | undefined
  changeStatus?: string | null | undefined
  hasParallelImports?: boolean | null | undefined
  id?: string | null | undefined
  isOtc?: boolean | null | undefined
  name?: string | null | undefined
  medicalInfoPdfUrl?: string | null | undefined
  announcements?:
    | Array<{
        __typename?: 'Announcement'
        customId?: string | null | undefined
        file?: string | null | undefined
        id?: number | null | undefined
        insertedAt?: any | null | undefined
        title?: string | null | undefined
        type?: TAnnouncementTypeEnum | null | undefined
      }>
    | null
    | undefined
  articles?:
    | Array<{
        __typename?: 'DgnsArticle'
        changeStatus?: string | null | undefined
        ecoBox?: string | null | undefined
        ecoBoxMaxQuantity?: number | null | undefined
        id?: string | null | undefined
        inMarket?: boolean | null | undefined
        isBiosimilar?: boolean | null | undefined
        name?: string | null | undefined
        packageContent?: string | null | undefined
        packageContentUnit?: string | null | undefined
        packageSize?: string | null | undefined
        packageSizeUnit?: string | null | undefined
        admission?:
          | {
              __typename?: 'DgnsAdmissionForm'
              name?: string | null | undefined
              short?: string | null | undefined
            }
          | null
          | undefined
        prices?:
          | Array<
              | {
                  __typename?: 'DgnsArticlePrice'
                  amount?: number | null | undefined
                  currency?: string | null | undefined
                  type?: string | null | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
        parallelImport?:
          | {
              __typename?: 'DgnsArticleParallelImport'
              seller?:
                | {
                    __typename?: 'DgnsSeller'
                    name?: string | null | undefined
                  }
                | null
                | undefined
            }
          | null
          | undefined
        prescriptionInfos?:
          | Array<
              | {
                  __typename?: 'DgnsArticlePrescriptionInfo'
                  additionalInfo?: string | null | undefined
                  alternativeName?: string | null | undefined
                  category?: TFeatureCategory | null | undefined
                  description?: string | null | undefined
                  descriptionShort?: string | null | undefined
                  name?: string | null | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
      }>
    | null
    | undefined
  components?:
    | Array<{
        __typename?: 'DgnsComponent'
        type?: TDgnsComponentType | null | undefined
        substance?:
          | {
              __typename?: 'DgnsSubstance'
              id?: string | null | undefined
              medbaseTopParentId?: string | null | undefined
              name?: string | null | undefined
            }
          | null
          | undefined
      }>
    | null
    | undefined
  smpcs?:
    | {
        __typename?: 'DgnsMedicalInfo'
        composition?: string | null | undefined
        dosage?: string | null | undefined
        indications?: string | null | undefined
        interactions?: string | null | undefined
        other?:
          | Array<{
              __typename?: 'DgnsOtherMedicalInfo'
              body?: string | null | undefined
              title?: string | null | undefined
            }>
          | null
          | undefined
      }
    | null
    | undefined
  pharmaNotifications?:
    | Array<{
        __typename?: 'PharmaNotification'
        id?: number | null | undefined
        productId?: number | null | undefined
        productName?: string | null | undefined
        type?: TPharmaNotificationTypeEnum | null | undefined
        sePatientInitials?: string | null | undefined
        reporterFirstname?: string | null | undefined
        reporterLastname?: string | null | undefined
        insertedAt?: any | null | undefined
      }>
    | null
    | undefined
  prescriptionInfos?:
    | Array<
        | {
            __typename?: 'DgnsProductPrescriptionInfo'
            alternativeName?: string | null | undefined
            category?: TFeatureCategory | null | undefined
            description?: string | null | undefined
            descriptionShort?: string | null | undefined
            name?: string | null | undefined
          }
        | null
        | undefined
      >
    | null
    | undefined
  vendor?:
    | {
        __typename?: 'DgnsVendor'
        id?: string | null | undefined
        name?: string | null | undefined
      }
    | null
    | undefined
}

export type TFetchFilteredIndexDataQueryVariables = Exact<{
  country: TCountry
  barcode?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  page: Scalars['Int']
  size: Scalars['Int']
  componentFilter?: InputMaybe<TComponentTypeFilter>
}>

export type TFetchFilteredIndexDataQuery = {
  __typename?: 'RootQueryType'
  products?:
    | {
        __typename?: 'DgnsProductConnection'
        totalCount?: number | null | undefined
        items?:
          | Array<{
              __typename?: 'DgnsProduct'
              atcCodes?: Array<string> | null | undefined
              changeStatus?: string | null | undefined
              id?: string | null | undefined
              isOtc?: boolean | null | undefined
              hasParallelImports?: boolean | null | undefined
              name?: string | null | undefined
              announcements?:
                | Array<{
                    __typename?: 'Announcement'
                    id?: number | null | undefined
                    title?: string | null | undefined
                    type?: TAnnouncementTypeEnum | null | undefined
                  }>
                | null
                | undefined
              articles?:
                | Array<{
                    __typename?: 'DgnsArticle'
                    changeStatus?: string | null | undefined
                    ecoBox?: string | null | undefined
                    ecoBoxMaxQuantity?: number | null | undefined
                    id?: string | null | undefined
                    inMarket?: boolean | null | undefined
                    isBiosimilar?: boolean | null | undefined
                    name?: string | null | undefined
                    packageContent?: string | null | undefined
                    packageContentUnit?: string | null | undefined
                    packageSize?: string | null | undefined
                    packageSizeUnit?: string | null | undefined
                    admission?:
                      | {
                          __typename?: 'DgnsAdmissionForm'
                          name?: string | null | undefined
                          short?: string | null | undefined
                        }
                      | null
                      | undefined
                    prices?:
                      | Array<
                          | {
                              __typename?: 'DgnsArticlePrice'
                              amount?: number | null | undefined
                              currency?: string | null | undefined
                              type?: string | null | undefined
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined
                    prescriptionInfos?:
                      | Array<
                          | {
                              __typename?: 'DgnsArticlePrescriptionInfo'
                              category?: TFeatureCategory | null | undefined
                              descriptionShort?: string | null | undefined
                              name?: string | null | undefined
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined
                  }>
                | null
                | undefined
              components?:
                | Array<{
                    __typename?: 'DgnsComponent'
                    type?: TDgnsComponentType | null | undefined
                    substance?:
                      | {
                          __typename?: 'DgnsSubstance'
                          id?: string | null | undefined
                          name?: string | null | undefined
                        }
                      | null
                      | undefined
                  }>
                | null
                | undefined
              pharmaNotifications?:
                | Array<{
                    __typename?: 'PharmaNotification'
                    id?: number | null | undefined
                    type?: TPharmaNotificationTypeEnum | null | undefined
                  }>
                | null
                | undefined
              vendor?:
                | {
                    __typename?: 'DgnsVendor'
                    name?: string | null | undefined
                  }
                | null
                | undefined
            }>
          | null
          | undefined
      }
    | null
    | undefined
  substances?:
    | {
        __typename?: 'DgnsSubstanceConnection'
        totalCount: number
        items?:
          | Array<{
              __typename?: 'DgnsSubstance'
              id?: string | null | undefined
              name?: string | null | undefined
              productsConnection?:
                | {
                    __typename?: 'DgnsProductConnection'
                    totalCount?: number | null | undefined
                  }
                | null
                | undefined
            }>
          | null
          | undefined
      }
    | null
    | undefined
  atcs: {
    __typename?: 'DgnsAtcConnection'
    totalCount?: number | null | undefined
    items?:
      | Array<{
          __typename?: 'DgnsAtc'
          code?: string | null | undefined
          description?: string | null | undefined
          productsConnection?:
            | {
                __typename?: 'DgnsProductConnection'
                totalCount?: number | null | undefined
              }
            | null
            | undefined
        }>
      | null
      | undefined
  }
  icds: {
    __typename?: 'DgnsIcdConnection'
    totalCount?: number | null | undefined
    items?:
      | Array<{
          __typename?: 'DgnsIcd'
          code?: string | null | undefined
          codeParent?: string | null | undefined
          description?: string | null | undefined
          productsConnection?:
            | {
                __typename?: 'DgnsProductConnection'
                totalCount?: number | null | undefined
              }
            | null
            | undefined
        }>
      | null
      | undefined
  }
}

export type TFetchProductDetailsQueryVariables = Exact<{
  country: TCountry
  productId: Scalars['ID']
  componentFilter?: InputMaybe<TComponentTypeFilter>
}>

export type TFetchProductDetailsQuery = {
  __typename?: 'RootQueryType'
  product?:
    | {
        __typename?: 'DgnsProduct'
        atcCodes?: Array<string> | null | undefined
        changeStatus?: string | null | undefined
        hasParallelImports?: boolean | null | undefined
        id?: string | null | undefined
        isOtc?: boolean | null | undefined
        name?: string | null | undefined
        medicalInfoPdfUrl?: string | null | undefined
        announcements?:
          | Array<{
              __typename?: 'Announcement'
              customId?: string | null | undefined
              file?: string | null | undefined
              id?: number | null | undefined
              insertedAt?: any | null | undefined
              title?: string | null | undefined
              type?: TAnnouncementTypeEnum | null | undefined
            }>
          | null
          | undefined
        articles?:
          | Array<{
              __typename?: 'DgnsArticle'
              changeStatus?: string | null | undefined
              ecoBox?: string | null | undefined
              ecoBoxMaxQuantity?: number | null | undefined
              id?: string | null | undefined
              inMarket?: boolean | null | undefined
              isBiosimilar?: boolean | null | undefined
              name?: string | null | undefined
              packageContent?: string | null | undefined
              packageContentUnit?: string | null | undefined
              packageSize?: string | null | undefined
              packageSizeUnit?: string | null | undefined
              admission?:
                | {
                    __typename?: 'DgnsAdmissionForm'
                    name?: string | null | undefined
                    short?: string | null | undefined
                  }
                | null
                | undefined
              prices?:
                | Array<
                    | {
                        __typename?: 'DgnsArticlePrice'
                        amount?: number | null | undefined
                        currency?: string | null | undefined
                        type?: string | null | undefined
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined
              parallelImport?:
                | {
                    __typename?: 'DgnsArticleParallelImport'
                    seller?:
                      | {
                          __typename?: 'DgnsSeller'
                          name?: string | null | undefined
                        }
                      | null
                      | undefined
                  }
                | null
                | undefined
              prescriptionInfos?:
                | Array<
                    | {
                        __typename?: 'DgnsArticlePrescriptionInfo'
                        additionalInfo?: string | null | undefined
                        alternativeName?: string | null | undefined
                        category?: TFeatureCategory | null | undefined
                        description?: string | null | undefined
                        descriptionShort?: string | null | undefined
                        name?: string | null | undefined
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined
            }>
          | null
          | undefined
        components?:
          | Array<{
              __typename?: 'DgnsComponent'
              type?: TDgnsComponentType | null | undefined
              substance?:
                | {
                    __typename?: 'DgnsSubstance'
                    id?: string | null | undefined
                    medbaseTopParentId?: string | null | undefined
                    name?: string | null | undefined
                  }
                | null
                | undefined
            }>
          | null
          | undefined
        smpcs?:
          | {
              __typename?: 'DgnsMedicalInfo'
              composition?: string | null | undefined
              dosage?: string | null | undefined
              indications?: string | null | undefined
              interactions?: string | null | undefined
              other?:
                | Array<{
                    __typename?: 'DgnsOtherMedicalInfo'
                    body?: string | null | undefined
                    title?: string | null | undefined
                  }>
                | null
                | undefined
            }
          | null
          | undefined
        pharmaNotifications?:
          | Array<{
              __typename?: 'PharmaNotification'
              id?: number | null | undefined
              productId?: number | null | undefined
              productName?: string | null | undefined
              type?: TPharmaNotificationTypeEnum | null | undefined
              sePatientInitials?: string | null | undefined
              reporterFirstname?: string | null | undefined
              reporterLastname?: string | null | undefined
              insertedAt?: any | null | undefined
            }>
          | null
          | undefined
        prescriptionInfos?:
          | Array<
              | {
                  __typename?: 'DgnsProductPrescriptionInfo'
                  alternativeName?: string | null | undefined
                  category?: TFeatureCategory | null | undefined
                  description?: string | null | undefined
                  descriptionShort?: string | null | undefined
                  name?: string | null | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
        vendor?:
          | {
              __typename?: 'DgnsVendor'
              id?: string | null | undefined
              name?: string | null | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type TProductForSelectQueryVariables = Exact<{
  country: TCountry
  productId: Scalars['ID']
}>

export type TProductForSelectQuery = {
  __typename?: 'RootQueryType'
  product?:
    | {
        __typename?: 'DgnsProduct'
        id?: string | null | undefined
        name?: string | null | undefined
        components?:
          | Array<{
              __typename?: 'DgnsComponent'
              type?: TDgnsComponentType | null | undefined
              substance?:
                | {
                    __typename?: 'DgnsSubstance'
                    id?: string | null | undefined
                    medbaseTopParentId?: string | null | undefined
                    name?: string | null | undefined
                  }
                | null
                | undefined
            }>
          | null
          | undefined
      }
    | null
    | undefined
}

export type TProductsQueryVariables = Exact<{
  country: TCountry
  name: Scalars['String']
  size: Scalars['Int']
}>

export type TProductsQuery = {
  __typename?: 'RootQueryType'
  products?:
    | {
        __typename?: 'DgnsProductConnection'
        items?:
          | Array<{
              __typename?: 'DgnsProduct'
              id?: string | null | undefined
              name?: string | null | undefined
              vendor?:
                | {
                    __typename?: 'DgnsVendor'
                    name?: string | null | undefined
                  }
                | null
                | undefined
            }>
          | null
          | undefined
      }
    | null
    | undefined
}

export type TProductsByIdsQueryVariables = Exact<{
  country: TCountry
  ids: Array<Scalars['ID']> | Scalars['ID']
  size: Scalars['Int']
}>

export type TProductsByIdsQuery = {
  __typename?: 'RootQueryType'
  products?:
    | {
        __typename?: 'DgnsProductConnection'
        items?:
          | Array<{
              __typename?: 'DgnsProduct'
              id?: string | null | undefined
              name?: string | null | undefined
            }>
          | null
          | undefined
      }
    | null
    | undefined
}

export type TProductsForSelectByIdsQueryVariables = Exact<{
  country: TCountry
  productIds: Array<Scalars['ID']> | Scalars['ID']
  size: Scalars['Int']
}>

export type TProductsForSelectByIdsQuery = {
  __typename?: 'RootQueryType'
  products?:
    | {
        __typename?: 'DgnsProductConnection'
        items?:
          | Array<{
              __typename?: 'DgnsProduct'
              id?: string | null | undefined
              name?: string | null | undefined
              components?:
                | Array<{
                    __typename?: 'DgnsComponent'
                    substance?:
                      | {
                          __typename?: 'DgnsSubstance'
                          id?: string | null | undefined
                          medbaseTopParentId?: string | null | undefined
                          name?: string | null | undefined
                        }
                      | null
                      | undefined
                  }>
                | null
                | undefined
            }>
          | null
          | undefined
      }
    | null
    | undefined
}

export type TProductsByPznsQueryVariables = Exact<{
  pzns: Array<Scalars['ID']> | Scalars['ID']
  size: Scalars['Int']
}>

export type TProductsByPznsQuery = {
  __typename?: 'RootQueryType'
  products?:
    | {
        __typename?: 'DgnsProductConnection'
        items?:
          | Array<{
              __typename?: 'DgnsProduct'
              id?: string | null | undefined
              name?: string | null | undefined
            }>
          | null
          | undefined
      }
    | null
    | undefined
}

export type TProductsForSelectByPznsQueryVariables = Exact<{
  pzns: Array<Scalars['ID']> | Scalars['ID']
  size: Scalars['Int']
}>

export type TProductsForSelectByPznsQuery = {
  __typename?: 'RootQueryType'
  products?:
    | {
        __typename?: 'DgnsProductConnection'
        items?:
          | Array<{
              __typename?: 'DgnsProduct'
              id?: string | null | undefined
              name?: string | null | undefined
              components?:
                | Array<{
                    __typename?: 'DgnsComponent'
                    substance?:
                      | {
                          __typename?: 'DgnsSubstance'
                          id?: string | null | undefined
                          medbaseTopParentId?: string | null | undefined
                          name?: string | null | undefined
                        }
                      | null
                      | undefined
                  }>
                | null
                | undefined
            }>
          | null
          | undefined
      }
    | null
    | undefined
}

export type TProductsByUkeysQueryVariables = Exact<{
  ukeys: Array<Scalars['ID']> | Scalars['ID']
  size: Scalars['Int']
}>

export type TProductsByUkeysQuery = {
  __typename?: 'RootQueryType'
  products?:
    | {
        __typename?: 'DgnsProductConnection'
        items?:
          | Array<{
              __typename?: 'DgnsProduct'
              id?: string | null | undefined
              name?: string | null | undefined
            }>
          | null
          | undefined
      }
    | null
    | undefined
}

export type TProductsForSelectByUkeysQueryVariables = Exact<{
  ukeys: Array<Scalars['ID']> | Scalars['ID']
  size: Scalars['Int']
}>

export type TProductsForSelectByUkeysQuery = {
  __typename?: 'RootQueryType'
  products?:
    | {
        __typename?: 'DgnsProductConnection'
        items?:
          | Array<{
              __typename?: 'DgnsProduct'
              id?: string | null | undefined
              name?: string | null | undefined
              components?:
                | Array<{
                    __typename?: 'DgnsComponent'
                    substance?:
                      | {
                          __typename?: 'DgnsSubstance'
                          id?: string | null | undefined
                          medbaseTopParentId?: string | null | undefined
                          name?: string | null | undefined
                        }
                      | null
                      | undefined
                  }>
                | null
                | undefined
            }>
          | null
          | undefined
      }
    | null
    | undefined
}

export type TSubstanceForSelectQueryVariables = Exact<{
  country: TCountry
  substanceId: Scalars['ID']
}>

export type TSubstanceForSelectQuery = {
  __typename?: 'RootQueryType'
  substance?:
    | {
        __typename?: 'DgnsSubstance'
        id?: string | null | undefined
        name?: string | null | undefined
        medbaseTopParentId?: string | null | undefined
      }
    | null
    | undefined
}

export type TSubstancesForSelectByIdsQueryVariables = Exact<{
  country: TCountry
  size: Scalars['Int']
  substanceIds: Array<Scalars['ID']> | Scalars['ID']
}>

export type TSubstancesForSelectByIdsQuery = {
  __typename?: 'RootQueryType'
  substances?:
    | {
        __typename?: 'DgnsSubstanceConnection'
        items?:
          | Array<{
              __typename?: 'DgnsSubstance'
              id?: string | null | undefined
              name?: string | null | undefined
              medbaseTopParentId?: string | null | undefined
            }>
          | null
          | undefined
      }
    | null
    | undefined
}

export type TSubstancesForSelectByBrainIdsQueryVariables = Exact<{
  country: TCountry
  size: Scalars['Int']
  substanceIds: Array<Scalars['ID']> | Scalars['ID']
}>

export type TSubstancesForSelectByBrainIdsQuery = {
  __typename?: 'RootQueryType'
  substances?:
    | {
        __typename?: 'DgnsSubstanceConnection'
        items?:
          | Array<{
              __typename?: 'DgnsSubstance'
              id?: string | null | undefined
              name?: string | null | undefined
              medbaseTopParentId?: string | null | undefined
            }>
          | null
          | undefined
      }
    | null
    | undefined
}

export type TFetchSubstanceProductsQueryVariables = Exact<{
  country: TCountry
  substanceId: Scalars['ID']
  page: Scalars['Int']
  size: Scalars['Int']
}>

export type TFetchSubstanceProductsQuery = {
  __typename?: 'RootQueryType'
  substance?:
    | {
        __typename?: 'DgnsSubstance'
        id?: string | null | undefined
        name?: string | null | undefined
        productsConnection?:
          | {
              __typename?: 'DgnsProductConnection'
              totalCount?: number | null | undefined
              items?:
                | Array<{
                    __typename?: 'DgnsProduct'
                    id?: string | null | undefined
                    atcCodes?: Array<string> | null | undefined
                    name?: string | null | undefined
                    prescriptionInfos?:
                      | Array<
                          | {
                              __typename?: 'DgnsProductPrescriptionInfo'
                              category?: TFeatureCategory | null | undefined
                              name?: string | null | undefined
                              descriptionShort?: string | null | undefined
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined
                    articles?:
                      | Array<{
                          __typename?: 'DgnsArticle'
                          ecoBox?: string | null | undefined
                          ecoBoxMaxQuantity?: number | null | undefined
                          id?: string | null | undefined
                          inMarket?: boolean | null | undefined
                          admission?:
                            | {
                                __typename?: 'DgnsAdmissionForm'
                                name?: string | null | undefined
                                short?: string | null | undefined
                              }
                            | null
                            | undefined
                          prescriptionInfos?:
                            | Array<
                                | {
                                    __typename?: 'DgnsArticlePrescriptionInfo'
                                    category?:
                                      | TFeatureCategory
                                      | null
                                      | undefined
                                    descriptionShort?: string | null | undefined
                                    name?: string | null | undefined
                                  }
                                | null
                                | undefined
                              >
                            | null
                            | undefined
                          prices?:
                            | Array<
                                | {
                                    __typename?: 'DgnsArticlePrice'
                                    amount?: number | null | undefined
                                    currency?: string | null | undefined
                                    type?: string | null | undefined
                                  }
                                | null
                                | undefined
                              >
                            | null
                            | undefined
                        }>
                      | null
                      | undefined
                    components?:
                      | Array<{
                          __typename?: 'DgnsComponent'
                          substance?:
                            | {
                                __typename?: 'DgnsSubstance'
                                id?: string | null | undefined
                                name?: string | null | undefined
                              }
                            | null
                            | undefined
                        }>
                      | null
                      | undefined
                    vendor?:
                      | {
                          __typename?: 'DgnsVendor'
                          name?: string | null | undefined
                        }
                      | null
                      | undefined
                  }>
                | null
                | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type TSubstancesWithProductsByIdsQueryVariables = Exact<{
  country: TCountry
  substanceIds: Array<Scalars['ID']> | Scalars['ID']
  size: Scalars['Int']
}>

export type TSubstancesWithProductsByIdsQuery = {
  __typename?: 'RootQueryType'
  substances?:
    | {
        __typename?: 'DgnsSubstanceConnection'
        items?:
          | Array<{
              __typename?: 'DgnsSubstance'
              id?: string | null | undefined
              name?: string | null | undefined
              productsConnection?:
                | {
                    __typename?: 'DgnsProductConnection'
                    totalCount?: number | null | undefined
                    items?:
                      | Array<{
                          __typename?: 'DgnsProduct'
                          changeStatus?: string | null | undefined
                          id?: string | null | undefined
                          name?: string | null | undefined
                          vendor?:
                            | {
                                __typename?: 'DgnsVendor'
                                name?: string | null | undefined
                              }
                            | null
                            | undefined
                        }>
                      | null
                      | undefined
                  }
                | null
                | undefined
            }>
          | null
          | undefined
      }
    | null
    | undefined
}

export type TSubstancesWithProductsByBrainSubstanceIdsQueryVariables = Exact<{
  country: TCountry
  substanceIds: Array<Scalars['ID']> | Scalars['ID']
  size: Scalars['Int']
}>

export type TSubstancesWithProductsByBrainSubstanceIdsQuery = {
  __typename?: 'RootQueryType'
  substances?:
    | {
        __typename?: 'DgnsSubstanceConnection'
        items?:
          | Array<{
              __typename?: 'DgnsSubstance'
              id?: string | null | undefined
              name?: string | null | undefined
              productsConnection?:
                | {
                    __typename?: 'DgnsProductConnection'
                    totalCount?: number | null | undefined
                    items?:
                      | Array<{
                          __typename?: 'DgnsProduct'
                          changeStatus?: string | null | undefined
                          id?: string | null | undefined
                          name?: string | null | undefined
                          vendor?:
                            | {
                                __typename?: 'DgnsVendor'
                                name?: string | null | undefined
                              }
                            | null
                            | undefined
                        }>
                      | null
                      | undefined
                  }
                | null
                | undefined
            }>
          | null
          | undefined
      }
    | null
    | undefined
}

export type TFetchAtcLevelQueryVariables = Exact<{
  code: Scalars['ID']
  country: TCountry
  a1: Scalars['ID']
  a2: Scalars['ID']
  a3: Scalars['ID']
  a4: Scalars['ID']
  a5: Scalars['ID']
  page?: InputMaybe<Scalars['Int']>
  size?: InputMaybe<Scalars['Int']>
}>

export type TFetchAtcLevelQuery = {
  __typename?: 'RootQueryType'
  a1?:
    | {
        __typename?: 'DgnsAtc'
        code?: string | null | undefined
        description?: string | null | undefined
      }
    | null
    | undefined
  a2?:
    | {
        __typename?: 'DgnsAtc'
        code?: string | null | undefined
        description?: string | null | undefined
      }
    | null
    | undefined
  a3?:
    | {
        __typename?: 'DgnsAtc'
        code?: string | null | undefined
        description?: string | null | undefined
      }
    | null
    | undefined
  a4?:
    | {
        __typename?: 'DgnsAtc'
        code?: string | null | undefined
        description?: string | null | undefined
      }
    | null
    | undefined
  a5?:
    | {
        __typename?: 'DgnsAtc'
        code?: string | null | undefined
        description?: string | null | undefined
      }
    | null
    | undefined
  details?:
    | {
        __typename?: 'DgnsAtc'
        code?: string | null | undefined
        description?: string | null | undefined
        children?:
          | Array<{
              __typename?: 'DgnsAtc'
              code?: string | null | undefined
              description?: string | null | undefined
            }>
          | null
          | undefined
        productsConnection?:
          | {
              __typename?: 'DgnsProductConnection'
              totalCount?: number | null | undefined
              items?:
                | Array<{
                    __typename?: 'DgnsProduct'
                    id?: string | null | undefined
                    name?: string | null | undefined
                    articles?:
                      | Array<{
                          __typename?: 'DgnsArticle'
                          ecoBox?: string | null | undefined
                          ecoBoxMaxQuantity?: number | null | undefined
                          id?: string | null | undefined
                          inMarket?: boolean | null | undefined
                          admission?:
                            | {
                                __typename?: 'DgnsAdmissionForm'
                                name?: string | null | undefined
                                short?: string | null | undefined
                              }
                            | null
                            | undefined
                          prescriptionInfos?:
                            | Array<
                                | {
                                    __typename?: 'DgnsArticlePrescriptionInfo'
                                    category?:
                                      | TFeatureCategory
                                      | null
                                      | undefined
                                    descriptionShort?: string | null | undefined
                                    name?: string | null | undefined
                                  }
                                | null
                                | undefined
                              >
                            | null
                            | undefined
                          prices?:
                            | Array<
                                | {
                                    __typename?: 'DgnsArticlePrice'
                                    amount?: number | null | undefined
                                    currency?: string | null | undefined
                                    type?: string | null | undefined
                                  }
                                | null
                                | undefined
                              >
                            | null
                            | undefined
                        }>
                      | null
                      | undefined
                    components?:
                      | Array<{
                          __typename?: 'DgnsComponent'
                          substance?:
                            | {
                                __typename?: 'DgnsSubstance'
                                id?: string | null | undefined
                                name?: string | null | undefined
                              }
                            | null
                            | undefined
                        }>
                      | null
                      | undefined
                    prescriptionInfos?:
                      | Array<
                          | {
                              __typename?: 'DgnsProductPrescriptionInfo'
                              category?: TFeatureCategory | null | undefined
                              name?: string | null | undefined
                              descriptionShort?: string | null | undefined
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined
                    vendor?:
                      | {
                          __typename?: 'DgnsVendor'
                          name?: string | null | undefined
                        }
                      | null
                      | undefined
                  }>
                | null
                | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type TFetchAtcTreeQueryVariables = Exact<{
  code: Scalars['ID']
  country: TCountry
  page?: InputMaybe<Scalars['Int']>
  size?: InputMaybe<Scalars['Int']>
}>

export type TFetchAtcTreeQuery = {
  __typename?: 'RootQueryType'
  details?:
    | {
        __typename?: 'DgnsAtc'
        code?: string | null | undefined
        description?: string | null | undefined
        children?:
          | Array<{
              __typename?: 'DgnsAtc'
              code?: string | null | undefined
              description?: string | null | undefined
            }>
          | null
          | undefined
        productsConnection?:
          | {
              __typename?: 'DgnsProductConnection'
              totalCount?: number | null | undefined
              items?:
                | Array<{
                    __typename?: 'DgnsProduct'
                    id?: string | null | undefined
                    changeStatus?: string | null | undefined
                    name?: string | null | undefined
                  }>
                | null
                | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type TAtcsQueryVariables = Exact<{
  name: Scalars['String']
  country: TCountry
  page: Scalars['Int']
  size: Scalars['Int']
}>

export type TAtcsQuery = {
  __typename?: 'RootQueryType'
  atcs: {
    __typename?: 'DgnsAtcConnection'
    totalCount?: number | null | undefined
    items?:
      | Array<{
          __typename?: 'DgnsAtc'
          code?: string | null | undefined
          description?: string | null | undefined
          productsConnection?:
            | {
                __typename?: 'DgnsProductConnection'
                totalCount?: number | null | undefined
              }
            | null
            | undefined
        }>
      | null
      | undefined
  }
}

export type TFetchIcdQueryVariables = Exact<{
  code: Scalars['ID']
  country: TCountry
  page: Scalars['Int']
  size: Scalars['Int']
}>

export type TFetchIcdQuery = {
  __typename?: 'RootQueryType'
  icd?:
    | {
        __typename?: 'DgnsIcd'
        code?: string | null | undefined
        description?: string | null | undefined
        productsConnection?:
          | {
              __typename?: 'DgnsProductConnection'
              totalCount?: number | null | undefined
              items?:
                | Array<{
                    __typename?: 'DgnsProduct'
                    id?: string | null | undefined
                    name?: string | null | undefined
                    components?:
                      | Array<{
                          __typename?: 'DgnsComponent'
                          substance?:
                            | {
                                __typename?: 'DgnsSubstance'
                                id?: string | null | undefined
                                name?: string | null | undefined
                              }
                            | null
                            | undefined
                        }>
                      | null
                      | undefined
                    vendor?:
                      | {
                          __typename?: 'DgnsVendor'
                          name?: string | null | undefined
                        }
                      | null
                      | undefined
                  }>
                | null
                | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type TAnnouncementsQueryVariables = Exact<{ [key: string]: never }>

export type TAnnouncementsQuery = {
  __typename?: 'RootQueryType'
  announcements: {
    __typename?: 'AnnouncementsConnection'
    totalCount: number
    items?:
      | Array<{
          __typename?: 'Announcement'
          body?: string | null | undefined
          customId?: string | null | undefined
          file?: string | null | undefined
          id?: number | null | undefined
          insertedAt?: any | null | undefined
          title?: string | null | undefined
          type?: TAnnouncementTypeEnum | null | undefined
          updatedAt?: any | null | undefined
        }>
      | null
      | undefined
  }
}

export type TAnnouncementQueryVariables = Exact<{
  id: Scalars['Int']
}>

export type TAnnouncementQuery = {
  __typename?: 'RootQueryType'
  announcement?:
    | {
        __typename?: 'Announcement'
        body?: string | null | undefined
        customId?: string | null | undefined
        file?: string | null | undefined
        id?: number | null | undefined
        insertedAt?: any | null | undefined
        title?: string | null | undefined
        type?: TAnnouncementTypeEnum | null | undefined
        updatedAt?: any | null | undefined
        items?:
          | Array<{
              __typename?: 'AnnouncementItem'
              isExternalProduct?: boolean | null | undefined
              product?:
                | {
                    __typename?: 'DgnsProduct'
                    id?: string | null | undefined
                    name?: string | null | undefined
                    vendor?:
                      | {
                          __typename?: 'DgnsVendor'
                          name?: string | null | undefined
                        }
                      | null
                      | undefined
                  }
                | null
                | undefined
            }>
          | null
          | undefined
      }
    | null
    | undefined
}

export type TPharmaNotificationsQueryVariables = Exact<{ [key: string]: never }>

export type TPharmaNotificationsQuery = {
  __typename?: 'RootQueryType'
  pharmaNotifications: {
    __typename?: 'PharmaNotificationsConnection'
    totalCount: number
    items?:
      | Array<{
          __typename?: 'PharmaNotification'
          id?: number | null | undefined
          productId?: number | null | undefined
          productName?: string | null | undefined
          type?: TPharmaNotificationTypeEnum | null | undefined
          sePatientInitials?: string | null | undefined
          reporterFirstname?: string | null | undefined
          reporterLastname?: string | null | undefined
          insertedAt?: any | null | undefined
        }>
      | null
      | undefined
  }
}

export type TPharmaNotificationQueryVariables = Exact<{
  id: Scalars['Int']
}>

export type TPharmaNotificationQuery = {
  __typename?: 'RootQueryType'
  pharmaNotification?:
    | {
        __typename?: 'PharmaNotification'
        id?: number | null | undefined
        userId?: string | null | undefined
        organisationId?: string | null | undefined
        isExternalProduct?: boolean | null | undefined
        type?: TPharmaNotificationTypeEnum | null | undefined
        productId?: number | null | undefined
        productName?: string | null | undefined
        productCountry?: TProductCountryEnum | null | undefined
        validForAllPackSizes?: boolean | null | undefined
        reporterTitle?: string | null | undefined
        reporterFirstname?: string | null | undefined
        reporterLastname?: string | null | undefined
        reporterJobDescription?: string | null | undefined
        reporterOrganisation?: string | null | undefined
        reporterStreet?: string | null | undefined
        reporterZip?: string | null | undefined
        reporterCity?: string | null | undefined
        reporterPhone?: string | null | undefined
        reporterFax?: string | null | undefined
        reporterEmail?: string | null | undefined
        qdReportedTime?: any | null | undefined
        qdReportedAt?: any | null | undefined
        qdChargenCode?: string | null | undefined
        qdChargenSupplier?: string | null | undefined
        qdRecognizedBy?: TQdRecognizedByEnum | null | undefined
        qdDescription?: string | null | undefined
        qdRelationOtherSideeffect?: string | null | undefined
        qdSuspicionDangerPublicHealth?: boolean | null | undefined
        qdDigitalphotoViaMail?: boolean | null | undefined
        qdOriginalViaPost?: boolean | null | undefined
        qdDeliveryNotPossible?: boolean | null | undefined
        qdExampleDescription?: string | null | undefined
        qdInformedSources?: string | null | undefined
        qdTakenActivities?: string | null | undefined
        qdFurtherPlannedActivities?: string | null | undefined
        qdAdditionalRelevantInfo?: string | null | undefined
        qdListSupplementsForNotification?: string | null | undefined
        sePatientInitials?: string | null | undefined
        sePatientIdentification?: string | null | undefined
        seAgeInYears?: number | null | undefined
        seAgeInMonths?: number | null | undefined
        seGender?: TSeGenderEnum | null | undefined
        seDescription?: string | null | undefined
        seOccurredAt?: any | null | undefined
        seProcess?: TSeProcessEnum | null | undefined
        seResult?: TSeResultEnum | null | undefined
        seDosageUsage?: string | null | undefined
        seUsageFrom?: any | null | undefined
        seUsageUntil?: any | null | undefined
        seUsageReason?: string | null | undefined
        seUsageFurtherDiseases?: string | null | undefined
        seTreatmentSideEffect?: string | null | undefined
        seContextWithSuspiciousDrug?:
          | TSeContextWithSuspiciousDrugEnum
          | null
          | undefined
        seReporterData?: string | null | undefined
        seEditorData?: string | null | undefined
        samplesSent?: boolean | null | undefined
        dataAccuracyConfirmed?: boolean | null | undefined
        notificationTransmitted?: boolean | null | undefined
        insertedAt?: any | null | undefined
        updatedAt?: any | null | undefined
        additionalProducts?:
          | Array<{
              __typename?: 'AdditionalProduct'
              id?: number | null | undefined
              isExternalProduct?: boolean | null | undefined
              productCountry?: TCountry | null | undefined
              productId?: number | null | undefined
              productName?: string | null | undefined
              dosageUsage?: string | null | undefined
              duration?: string | null | undefined
              usageReason?: string | null | undefined
            }>
          | null
          | undefined
      }
    | null
    | undefined
}

export type TSubstancesProductsQueryVariables = Exact<{
  country: TCountry
  name: Scalars['String']
  size: Scalars['Int']
}>

export type TSubstancesProductsQuery = {
  __typename?: 'RootQueryType'
  products?:
    | {
        __typename?: 'DgnsProductConnection'
        items?:
          | Array<{
              __typename?: 'DgnsProduct'
              id?: string | null | undefined
              name?: string | null | undefined
              components?:
                | Array<{
                    __typename?: 'DgnsComponent'
                    substance?:
                      | {
                          __typename?: 'DgnsSubstance'
                          id?: string | null | undefined
                          medbaseTopParentId?: string | null | undefined
                          name?: string | null | undefined
                        }
                      | null
                      | undefined
                  }>
                | null
                | undefined
            }>
          | null
          | undefined
      }
    | null
    | undefined
  substances?:
    | {
        __typename?: 'DgnsSubstanceConnection'
        items?:
          | Array<{
              __typename?: 'DgnsSubstance'
              id?: string | null | undefined
              medbaseTopParentId?: string | null | undefined
              name?: string | null | undefined
            }>
          | null
          | undefined
      }
    | null
    | undefined
}

export type TSubstancesProductsMedicationQueryVariables = Exact<{
  country: TCountry
  name: Scalars['String']
  size: Scalars['Int']
}>

export type TSubstancesProductsMedicationQuery = {
  __typename?: 'RootQueryType'
  products?:
    | {
        __typename?: 'DgnsProductConnection'
        items?:
          | Array<{
              __typename?: 'DgnsProduct'
              id?: string | null | undefined
              name?: string | null | undefined
              components?:
                | Array<{
                    __typename?: 'DgnsComponent'
                    substance?:
                      | {
                          __typename?: 'DgnsSubstance'
                          id?: string | null | undefined
                          name?: string | null | undefined
                        }
                      | null
                      | undefined
                  }>
                | null
                | undefined
            }>
          | null
          | undefined
      }
    | null
    | undefined
  substances?:
    | {
        __typename?: 'DgnsSubstanceConnection'
        items?:
          | Array<{
              __typename?: 'DgnsSubstance'
              id?: string | null | undefined
              name?: string | null | undefined
              productsConnection?:
                | {
                    __typename?: 'DgnsProductConnection'
                    totalCount?: number | null | undefined
                    items?:
                      | Array<{
                          __typename?: 'DgnsProduct'
                          id?: string | null | undefined
                          name?: string | null | undefined
                        }>
                      | null
                      | undefined
                  }
                | null
                | undefined
            }>
          | null
          | undefined
      }
    | null
    | undefined
}

export type TInteractionsQueryVariables = Exact<{
  medbaseParentTopIds: Array<Scalars['ID']> | Scalars['ID']
  interactionSearchMode?: InputMaybe<TInteractionsSearchMode>
}>

export type TInteractionsQuery = {
  __typename?: 'RootQueryType'
  interactionsByTopParentIds?:
    | {
        __typename?: 'InteractionsWithMaxClassification'
        totalInteractions?: number | null | undefined
        interactions?:
          | Array<{
              __typename?: 'Interaction'
              classifications?:
                | Array<
                    | {
                        __typename?: 'Classification'
                        docClassification?: string | null | undefined
                        sevClassification?: string | null | undefined
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined
              maxClassification?:
                | {
                    __typename?: 'Classification'
                    docClassification?: string | null | undefined
                    sevClassification?: string | null | undefined
                  }
                | null
                | undefined
              references?:
                | Array<
                    | {
                        __typename?: 'InxbaseReference'
                        referenceDetails?:
                          | {
                              __typename?: 'ReferenceDetails'
                              text?: string | null | undefined
                              pubMedUrl?: string | null | undefined
                            }
                          | null
                          | undefined
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined
              sections?:
                | Array<
                    | {
                        __typename?: 'InxbaseSection'
                        typeId?: number | null | undefined
                        interactionText?:
                          | {
                              __typename?: 'InteractionText'
                              text?:
                                | Array<
                                    | {
                                        __typename?: 'InteractionTextTranslationVersion'
                                        version?: number | null | undefined
                                        translation?:
                                          | Array<
                                              | {
                                                  __typename?: 'InteractionTextTranslation'
                                                  langCode?:
                                                    | string
                                                    | null
                                                    | undefined
                                                  text?:
                                                    | string
                                                    | null
                                                    | undefined
                                                }
                                              | null
                                              | undefined
                                            >
                                          | null
                                          | undefined
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined
                            }
                          | null
                          | undefined
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined
              substances?:
                | Array<
                    | {
                        __typename?: 'Substance'
                        id?: number | null | undefined
                        substanceNames?:
                          | Array<
                              | {
                                  __typename?: 'SubstanceName'
                                  default?: boolean | null | undefined
                                  languageCode?: string | null | undefined
                                  name?: string | null | undefined
                                  substanceId?: number | null | undefined
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined
            }>
          | null
          | undefined
      }
    | null
    | undefined
}

export type TAggregatedRisksQueryVariables = Exact<{
  medbaseParentTopIds: Array<Scalars['ID']> | Scalars['ID']
}>

export type TAggregatedRisksQuery = {
  __typename?: 'RootQueryType'
  aggregatedRisksBySubstanceIds?:
    | {
        __typename?: 'AggregatedRisk'
        categories?:
          | Array<{
              __typename?: 'CategoryWithAggregatedRisk'
              aggregatedRiskScore?: number | null | undefined
              riskbaseLevel?:
                | {
                    __typename?: 'RiskbaseLevel'
                    id?: number | null | undefined
                    classification?: string | null | undefined
                    levelClassification?:
                      | Array<
                          | {
                              __typename?: 'RiskbaseLevelClassification'
                              classification?: string | null | undefined
                              langCode?: string | null | undefined
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined
                  }
                | null
                | undefined
              riskbaseCategory?:
                | {
                    __typename?: 'RiskbaseCategory'
                    categoryId?: number | null | undefined
                    algorithm?:
                      | {
                          __typename?: 'RiskbaseAlgorithm'
                          name?: string | null | undefined
                        }
                      | null
                      | undefined
                    categoryNames?:
                      | Array<
                          | {
                              __typename?: 'RiskbaseCategoryName'
                              categoryName?: string | null | undefined
                              langCode?: string | null | undefined
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined
                    calculations?:
                      | Array<
                          | {
                              __typename?: 'RiskbaseCalculation'
                              level?: number | null | undefined
                              max?: number | null | undefined
                              min?: number | null | undefined
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined
                    pharaoTexts?:
                      | Array<
                          | {
                              __typename?: 'PharaoText'
                              levelId?: number | null | undefined
                              consequence?:
                                | Array<
                                    | {
                                        __typename?: 'PharaoTextTranslation'
                                        langCode?: string | null | undefined
                                        text?: string | null | undefined
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined
                              recommendation?:
                                | Array<
                                    | {
                                        __typename?: 'PharaoTextTranslation'
                                        langCode?: string | null | undefined
                                        text?: string | null | undefined
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined
                  }
                | null
                | undefined
              substances?:
                | Array<
                    | {
                        __typename?: 'SubstanceWithCategoryRiskValue'
                        id?: number | null | undefined
                        riskValue?: number | null | undefined
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined
            }>
          | null
          | undefined
        maxRiskbaseLevel?:
          | {
              __typename?: 'RiskbaseLevel'
              classification?: string | null | undefined
              id?: number | null | undefined
              levelClassification?:
                | Array<
                    | {
                        __typename?: 'RiskbaseLevelClassification'
                        classification?: string | null | undefined
                        langCode?: string | null | undefined
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type TRenalBaseArticlesQueryVariables = Exact<{
  filter?: InputMaybe<TRenbaseArticleFilter>
  page?: InputMaybe<Scalars['Int']>
  pageSize?: InputMaybe<Scalars['Int']>
}>

export type TRenalBaseArticlesQuery = {
  __typename?: 'RootQueryType'
  renbaseArticles?:
    | {
        __typename?: 'RenbaseArticlesConnection'
        totalCount?: number | null | undefined
        items?:
          | Array<{
              __typename?: 'RenbaseArticle'
              classification?:
                | Array<string | null | undefined>
                | null
                | undefined
              drugFormGroupId?: string | null | undefined
              drugFormGroupCode?: string | null | undefined
              drugFormGroupDescription?: string | null | undefined
              id?: number | null | undefined
              nephrotoxic?: string | null | undefined
              substanceId?: number | null | undefined
              recommendations?:
                | Array<
                    | {
                        __typename?: 'RenbaseRecommendation'
                        classification?: string | null | undefined
                        classificationDescription?: string | null | undefined
                        dosageMod?: number | null | undefined
                        dosageModCode?: string | null | undefined
                        dosageModDescription?: string | null | undefined
                        dosageModMax?: number | null | undefined
                        doseInterval?: number | null | undefined
                        doseIntervalMax?: number | null | undefined
                        renalFailureDegreeDescription?:
                          | string
                          | null
                          | undefined
                        renalFailureDegreeId?: number | null | undefined
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined
              references?:
                | Array<
                    | {
                        __typename?: 'Reference'
                        body?: string | null | undefined
                        pmid?: string | null | undefined
                        sortKey?: number | null | undefined
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined
              sections?:
                | Array<
                    | {
                        __typename?: 'Section'
                        body?: string | null | undefined
                        locale?: string | null | undefined
                        name?: string | null | undefined
                        sectionTypeId?: string | null | undefined
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined
              substance?:
                | {
                    __typename?: 'Substance'
                    id?: number | null | undefined
                    topParentId?: number | null | undefined
                    substanceNames?:
                      | Array<
                          | {
                              __typename?: 'SubstanceName'
                              default?: boolean | null | undefined
                              languageCode?: string | null | undefined
                              name?: string | null | undefined
                              substanceId?: number | null | undefined
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined
                  }
                | null
                | undefined
            }>
          | null
          | undefined
      }
    | null
    | undefined
}

export type THeparBaseArticlesQueryVariables = Exact<{
  filter?: InputMaybe<THeparbaseArticleFilter>
  page?: InputMaybe<Scalars['Int']>
  pageSize?: InputMaybe<Scalars['Int']>
}>

export type THeparBaseArticlesQuery = {
  __typename?: 'RootQueryType'
  heparbaseArticles?:
    | {
        __typename?: 'HeparbaseArticlesConnection'
        totalCount?: number | null | undefined
        items?:
          | Array<{
              __typename?: 'HeparbaseArticle'
              drugFormGroupId?: string | null | undefined
              drugFormGroupCode?: string | null | undefined
              drugFormGroupDescription?: string | null | undefined
              hepatotoxic?: string | null | undefined
              id?: number | null | undefined
              substanceId?: number | null | undefined
              recommendations?:
                | Array<
                    | {
                        __typename?: 'HeparbaseRecommendation'
                        classification?: string | null | undefined
                        classificationDescription?: string | null | undefined
                        dosageMod?: number | null | undefined
                        dosageModCode?: number | null | undefined
                        dosageModDescription?: string | null | undefined
                        dosageModMax?: number | null | undefined
                        doseInterval?: number | null | undefined
                        doseIntervalMax?: number | null | undefined
                        hepaticImpairmentDegreeId?: number | null | undefined
                        hepaticImpairmentDegreeDescription?:
                          | string
                          | null
                          | undefined
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined
              references?:
                | Array<
                    | {
                        __typename?: 'Reference'
                        body?: string | null | undefined
                        pmid?: string | null | undefined
                        sortKey?: number | null | undefined
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined
              sections?:
                | Array<
                    | {
                        __typename?: 'Section'
                        body?: string | null | undefined
                        locale?: string | null | undefined
                        name?: string | null | undefined
                        sectionTypeId?: string | null | undefined
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined
              substance?:
                | {
                    __typename?: 'Substance'
                    substanceNames?:
                      | Array<
                          | {
                              __typename?: 'SubstanceName'
                              default?: boolean | null | undefined
                              languageCode?: string | null | undefined
                              name?: string | null | undefined
                              substanceId?: number | null | undefined
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined
                  }
                | null
                | undefined
            }>
          | null
          | undefined
      }
    | null
    | undefined
}

export type TGravBaseArticlesQueryVariables = Exact<{
  filter?: InputMaybe<TGravbaseArticleFilter>
  page?: InputMaybe<Scalars['Int']>
  pageSize?: InputMaybe<Scalars['Int']>
}>

export type TGravBaseArticlesQuery = {
  __typename?: 'RootQueryType'
  gravbaseArticles?:
    | {
        __typename?: 'GravbaseArticlesConnection'
        totalCount?: number | null | undefined
        items?:
          | Array<{
              __typename?: 'GravbaseArticle'
              classification?: string | null | undefined
              classificationDescription?: string | null | undefined
              drugFormGroupId?: string | null | undefined
              drugFormGroupCode?: string | null | undefined
              drugFormGroupDescription?: string | null | undefined
              id?: number | null | undefined
              substanceId?: number | null | undefined
              references?:
                | Array<
                    | {
                        __typename?: 'Reference'
                        body?: string | null | undefined
                        pmid?: string | null | undefined
                        sortKey?: number | null | undefined
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined
              sections?:
                | Array<
                    | {
                        __typename?: 'Section'
                        body?: string | null | undefined
                        locale?: string | null | undefined
                        name?: string | null | undefined
                        sectionTypeId?: string | null | undefined
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined
              substance?:
                | {
                    __typename?: 'Substance'
                    substanceNames?:
                      | Array<
                          | {
                              __typename?: 'SubstanceName'
                              default?: boolean | null | undefined
                              languageCode?: string | null | undefined
                              name?: string | null | undefined
                              substanceId?: number | null | undefined
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined
                  }
                | null
                | undefined
            }>
          | null
          | undefined
      }
    | null
    | undefined
}

export type TLactBaseArticlesQueryVariables = Exact<{
  filter?: InputMaybe<TLactbaseArticleFilter>
  page?: InputMaybe<Scalars['Int']>
  pageSize?: InputMaybe<Scalars['Int']>
}>

export type TLactBaseArticlesQuery = {
  __typename?: 'RootQueryType'
  lactbaseArticles?:
    | {
        __typename?: 'LactbaseArticlesConnection'
        totalCount?: number | null | undefined
        items?:
          | Array<{
              __typename?: 'LactbaseArticle'
              classification?: string | null | undefined
              classificationDescription?: string | null | undefined
              drugFormGroupId?: string | null | undefined
              drugFormGroupCode?: string | null | undefined
              drugFormGroupDescription?: string | null | undefined
              id?: number | null | undefined
              substanceId?: number | null | undefined
              references?:
                | Array<
                    | {
                        __typename?: 'Reference'
                        body?: string | null | undefined
                        pmid?: string | null | undefined
                        sortKey?: number | null | undefined
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined
              sections?:
                | Array<
                    | {
                        __typename?: 'Section'
                        body?: string | null | undefined
                        locale?: string | null | undefined
                        name?: string | null | undefined
                        sectionTypeId?: string | null | undefined
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined
              substance?:
                | {
                    __typename?: 'Substance'
                    substanceNames?:
                      | Array<
                          | {
                              __typename?: 'SubstanceName'
                              default?: boolean | null | undefined
                              languageCode?: string | null | undefined
                              name?: string | null | undefined
                              substanceId?: number | null | undefined
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined
                  }
                | null
                | undefined
            }>
          | null
          | undefined
      }
    | null
    | undefined
}

export type TCreateAnnouncementMutationVariables = Exact<{
  body?: InputMaybe<Scalars['String']>
  customId?: InputMaybe<Scalars['String']>
  file?: InputMaybe<Scalars['String']>
  items?: InputMaybe<Array<TItemInput> | TItemInput>
  organisationId: Scalars['String']
  title: Scalars['String']
  type: TAnnouncementTypeEnum
  userId: Scalars['String']
}>

export type TCreateAnnouncementMutation = {
  __typename?: 'RootMutationType'
  createAnnouncement?:
    | { __typename?: 'Announcement'; id?: number | null | undefined }
    | null
    | undefined
}

export type TUpdateAnnouncementMutationVariables = Exact<{
  body?: InputMaybe<Scalars['String']>
  customId?: InputMaybe<Scalars['String']>
  file?: InputMaybe<Scalars['String']>
  id: Scalars['Int']
  items?: InputMaybe<Array<TItemInput> | TItemInput>
  organisationId: Scalars['String']
  title: Scalars['String']
  type: TAnnouncementTypeEnum
  userId: Scalars['String']
}>

export type TUpdateAnnouncementMutation = {
  __typename?: 'RootMutationType'
  updateAnnouncement?:
    | { __typename?: 'Announcement'; id?: number | null | undefined }
    | null
    | undefined
}

export type TCreatePharmaNotificationMutationVariables = Exact<{
  userId: Scalars['String']
  organisationId: Scalars['String']
  isExternalProduct?: InputMaybe<Scalars['Boolean']>
  additionalProducts?: InputMaybe<
    Array<TAdditionalProductInput> | TAdditionalProductInput
  >
  type: TPharmaNotificationTypeEnum
  productId: Scalars['Int']
  productName: Scalars['String']
  productCountry: TProductCountryEnum
  validForAllPackSizes?: InputMaybe<Scalars['Boolean']>
  reporterTitle?: InputMaybe<Scalars['String']>
  reporterFirstname: Scalars['String']
  reporterLastname: Scalars['String']
  reporterJobDescription?: InputMaybe<Scalars['String']>
  reporterOrganisation: Scalars['String']
  reporterStreet?: InputMaybe<Scalars['String']>
  reporterZip: Scalars['String']
  reporterCity: Scalars['String']
  reporterPhone: Scalars['String']
  reporterFax?: InputMaybe<Scalars['String']>
  reporterEmail: Scalars['String']
  qdReportedTime?: InputMaybe<Scalars['String']>
  qdReportedAt?: InputMaybe<Scalars['String']>
  qdChargenCode?: InputMaybe<Scalars['String']>
  qdChargenSupplier?: InputMaybe<Scalars['String']>
  qdRecognizedBy?: InputMaybe<TQdRecognizedByEnum>
  qdDescription?: InputMaybe<Scalars['String']>
  qdRelationOtherSideeffect?: InputMaybe<Scalars['String']>
  qdSuspicionDangerPublicHealth?: InputMaybe<Scalars['Boolean']>
  qdDigitalphotoViaMail?: InputMaybe<Scalars['Boolean']>
  qdOriginalViaPost?: InputMaybe<Scalars['Boolean']>
  qdDeliveryNotPossible?: InputMaybe<Scalars['Boolean']>
  qdExampleDescription?: InputMaybe<Scalars['String']>
  qdInformedSources?: InputMaybe<Scalars['String']>
  qdTakenActivities?: InputMaybe<Scalars['String']>
  qdFurtherPlannedActivities?: InputMaybe<Scalars['String']>
  qdAdditionalRelevantInfo?: InputMaybe<Scalars['String']>
  qdListSupplementsForNotification?: InputMaybe<Scalars['String']>
  sePatientInitials?: InputMaybe<Scalars['String']>
  sePatientIdentification?: InputMaybe<Scalars['String']>
  seAgeInYears?: InputMaybe<Scalars['Int']>
  seAgeInMonths?: InputMaybe<Scalars['Int']>
  seGender?: InputMaybe<TSeGenderEnum>
  seDescription?: InputMaybe<Scalars['String']>
  seOccurredAt?: InputMaybe<Scalars['String']>
  seProcess?: InputMaybe<TSeProcessEnum>
  seResult?: InputMaybe<TSeResultEnum>
  seDosageUsage?: InputMaybe<Scalars['String']>
  seUsageFrom?: InputMaybe<Scalars['String']>
  seUsageUntil?: InputMaybe<Scalars['String']>
  seUsageReason?: InputMaybe<Scalars['String']>
  seUsageFurtherDiseases?: InputMaybe<Scalars['String']>
  seTreatmentSideEffect?: InputMaybe<Scalars['String']>
  seContextWithSuspiciousDrug?: InputMaybe<TSeContextWithSuspiciousDrugEnum>
  seReporterData?: InputMaybe<Scalars['String']>
  seEditorData?: InputMaybe<Scalars['String']>
  samplesSent?: InputMaybe<Scalars['Boolean']>
  dataAccuracyConfirmed?: InputMaybe<Scalars['Boolean']>
  notificationTransmitted?: InputMaybe<Scalars['Boolean']>
}>

export type TCreatePharmaNotificationMutation = {
  __typename?: 'RootMutationType'
  createPharmaNotification?:
    | { __typename?: 'PharmaNotification'; id?: number | null | undefined }
    | null
    | undefined
}

export type TUpdatePharmaNotificationMutationVariables = Exact<{
  id: Scalars['Int']
  additionalProducts?: InputMaybe<
    Array<TAdditionalProductInput> | TAdditionalProductInput
  >
  type?: InputMaybe<TPharmaNotificationTypeEnum>
  validForAllPackSizes?: InputMaybe<Scalars['Boolean']>
  reporterTitle?: InputMaybe<Scalars['String']>
  reporterFirstname?: InputMaybe<Scalars['String']>
  reporterLastname?: InputMaybe<Scalars['String']>
  reporterJobDescription?: InputMaybe<Scalars['String']>
  reporterOrganisation?: InputMaybe<Scalars['String']>
  reporterStreet?: InputMaybe<Scalars['String']>
  reporterZip?: InputMaybe<Scalars['String']>
  reporterCity?: InputMaybe<Scalars['String']>
  reporterPhone?: InputMaybe<Scalars['String']>
  reporterFax?: InputMaybe<Scalars['String']>
  reporterEmail?: InputMaybe<Scalars['String']>
  qdReportedTime?: InputMaybe<Scalars['String']>
  qdReportedAt?: InputMaybe<Scalars['String']>
  qdChargenCode?: InputMaybe<Scalars['String']>
  qdChargenSupplier?: InputMaybe<Scalars['String']>
  qdRecognizedBy?: InputMaybe<TQdRecognizedByEnum>
  qdDescription?: InputMaybe<Scalars['String']>
  qdRelationOtherSideeffect?: InputMaybe<Scalars['String']>
  qdSuspicionDangerPublicHealth?: InputMaybe<Scalars['Boolean']>
  qdDigitalphotoViaMail?: InputMaybe<Scalars['Boolean']>
  qdOriginalViaPost?: InputMaybe<Scalars['Boolean']>
  qdDeliveryNotPossible?: InputMaybe<Scalars['Boolean']>
  qdExampleDescription?: InputMaybe<Scalars['String']>
  qdInformedSources?: InputMaybe<Scalars['String']>
  qdTakenActivities?: InputMaybe<Scalars['String']>
  qdFurtherPlannedActivities?: InputMaybe<Scalars['String']>
  qdAdditionalRelevantInfo?: InputMaybe<Scalars['String']>
  qdListSupplementsForNotification?: InputMaybe<Scalars['String']>
  sePatientInitials?: InputMaybe<Scalars['String']>
  sePatientIdentification?: InputMaybe<Scalars['String']>
  seAgeInYears?: InputMaybe<Scalars['Int']>
  seAgeInMonths?: InputMaybe<Scalars['Int']>
  seGender?: InputMaybe<TSeGenderEnum>
  seDescription?: InputMaybe<Scalars['String']>
  seOccurredAt?: InputMaybe<Scalars['String']>
  seProcess?: InputMaybe<TSeProcessEnum>
  seResult?: InputMaybe<TSeResultEnum>
  seDosageUsage?: InputMaybe<Scalars['String']>
  seUsageFrom?: InputMaybe<Scalars['String']>
  seUsageUntil?: InputMaybe<Scalars['String']>
  seUsageReason?: InputMaybe<Scalars['String']>
  seUsageFurtherDiseases?: InputMaybe<Scalars['String']>
  seTreatmentSideEffect?: InputMaybe<Scalars['String']>
  seContextWithSuspiciousDrug?: InputMaybe<TSeContextWithSuspiciousDrugEnum>
  seReporterData?: InputMaybe<Scalars['String']>
  seEditorData?: InputMaybe<Scalars['String']>
  samplesSent?: InputMaybe<Scalars['Boolean']>
  dataAccuracyConfirmed?: InputMaybe<Scalars['Boolean']>
  notificationTransmitted?: InputMaybe<Scalars['Boolean']>
}>

export type TUpdatePharmaNotificationMutation = {
  __typename?: 'RootMutationType'
  updatePharmaNotification?:
    | { __typename?: 'PharmaNotification'; id?: number | null | undefined }
    | null
    | undefined
}

export const ProductFragmentDoc = gql`
  fragment Product on DgnsProduct {
    announcements {
      customId
      file
      id
      insertedAt
      title
      type
    }
    articles(withParallelImports: true) {
      admission {
        name
        short
      }
      changeStatus
      ecoBox
      ecoBoxMaxQuantity
      id
      inMarket
      isBiosimilar
      name
      packageContent
      packageContentUnit
      packageSize
      packageSizeUnit
      prices {
        amount
        currency
        type
      }
      parallelImport {
        seller {
          name
        }
      }
      prescriptionInfos {
        additionalInfo
        alternativeName
        category
        description
        descriptionShort
        name
      }
    }
    atcCodes
    changeStatus
    components(type: $componentFilter) {
      substance {
        id
        medbaseTopParentId
        name
      }
      type
    }
    hasParallelImports
    id
    isOtc
    name
    smpcs {
      composition
      dosage
      indications
      interactions
      other {
        body
        title
      }
    }
    medicalInfoPdfUrl
    pharmaNotifications {
      id
      productId
      productName
      type
      sePatientInitials
      reporterFirstname
      reporterLastname
      insertedAt
    }
    prescriptionInfos {
      alternativeName
      category
      description
      descriptionShort
      name
    }
    vendor {
      id
      name
    }
  }
`
export const FetchFilteredIndexDataDocument = gql`
  query FetchFilteredIndexData(
    $country: Country!
    $barcode: String
    $name: String!
    $page: Int!
    $size: Int!
    $componentFilter: ComponentTypeFilter
  ) {
    products(
      country: $country
      filter: {
        barcode: $barcode
        name: $name
        parallelImports: ONLY_NOT_PARALLEL_IMPORTS
      }
      orderBy: { direction: ASC, field: CHANGE_STATUS }
      page: { number: $page, size: $size }
    ) {
      items {
        announcements {
          id
          title
          type
        }
        articles {
          admission {
            name
            short
          }
          changeStatus
          ecoBox
          ecoBoxMaxQuantity
          id
          inMarket
          isBiosimilar
          name
          packageContent
          packageContentUnit
          packageSize
          packageSizeUnit
          prices {
            amount
            currency
            type
          }
          prescriptionInfos {
            category
            descriptionShort
            name
          }
        }
        atcCodes
        changeStatus
        components(type: $componentFilter) {
          substance {
            id
            name
          }
          type
        }
        id
        isOtc
        hasParallelImports
        name
        pharmaNotifications {
          id
          type
        }
        vendor {
          name
        }
      }
      totalCount
    }
    substances(
      country: $country
      filter: { name: $name, onlyWithProducts: true }
      page: { number: $page, size: $size }
    ) {
      items {
        id
        name
        productsConnection {
          totalCount
        }
      }
      totalCount
    }
    atcs(filter: { description: $name }, page: { number: $page, size: $size }) {
      items {
        code
        description
        productsConnection(country: $country) {
          totalCount
        }
      }
      totalCount
    }
    icds(
      country: $country
      filter: { description: $name }
      page: { number: $page, size: $size }
    ) {
      items {
        code
        codeParent
        description
        productsConnection {
          totalCount
        }
      }
      totalCount
    }
  }
`

/**
 * __useFetchFilteredIndexDataQuery__
 *
 * To run a query within a React component, call `useFetchFilteredIndexDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchFilteredIndexDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchFilteredIndexDataQuery({
 *   variables: {
 *      country: // value for 'country'
 *      barcode: // value for 'barcode'
 *      name: // value for 'name'
 *      page: // value for 'page'
 *      size: // value for 'size'
 *      componentFilter: // value for 'componentFilter'
 *   },
 * });
 */
export function useFetchFilteredIndexDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    TFetchFilteredIndexDataQuery,
    TFetchFilteredIndexDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    TFetchFilteredIndexDataQuery,
    TFetchFilteredIndexDataQueryVariables
  >(FetchFilteredIndexDataDocument, options)
}
export function useFetchFilteredIndexDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TFetchFilteredIndexDataQuery,
    TFetchFilteredIndexDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    TFetchFilteredIndexDataQuery,
    TFetchFilteredIndexDataQueryVariables
  >(FetchFilteredIndexDataDocument, options)
}
export type FetchFilteredIndexDataQueryHookResult = ReturnType<
  typeof useFetchFilteredIndexDataQuery
>
export type FetchFilteredIndexDataLazyQueryHookResult = ReturnType<
  typeof useFetchFilteredIndexDataLazyQuery
>
export type FetchFilteredIndexDataQueryResult = Apollo.QueryResult<
  TFetchFilteredIndexDataQuery,
  TFetchFilteredIndexDataQueryVariables
>
export const FetchProductDetailsDocument = gql`
  query FetchProductDetails(
    $country: Country!
    $productId: ID!
    $componentFilter: ComponentTypeFilter
  ) {
    product(country: $country, id: $productId) {
      ...Product
    }
  }
  ${ProductFragmentDoc}
`

/**
 * __useFetchProductDetailsQuery__
 *
 * To run a query within a React component, call `useFetchProductDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchProductDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchProductDetailsQuery({
 *   variables: {
 *      country: // value for 'country'
 *      productId: // value for 'productId'
 *      componentFilter: // value for 'componentFilter'
 *   },
 * });
 */
export function useFetchProductDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    TFetchProductDetailsQuery,
    TFetchProductDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    TFetchProductDetailsQuery,
    TFetchProductDetailsQueryVariables
  >(FetchProductDetailsDocument, options)
}
export function useFetchProductDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TFetchProductDetailsQuery,
    TFetchProductDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    TFetchProductDetailsQuery,
    TFetchProductDetailsQueryVariables
  >(FetchProductDetailsDocument, options)
}
export type FetchProductDetailsQueryHookResult = ReturnType<
  typeof useFetchProductDetailsQuery
>
export type FetchProductDetailsLazyQueryHookResult = ReturnType<
  typeof useFetchProductDetailsLazyQuery
>
export type FetchProductDetailsQueryResult = Apollo.QueryResult<
  TFetchProductDetailsQuery,
  TFetchProductDetailsQueryVariables
>
export const ProductForSelectDocument = gql`
  query ProductForSelect($country: Country!, $productId: ID!) {
    product(country: $country, id: $productId) {
      components(type: ACTIVE) {
        substance {
          id
          medbaseTopParentId
          name
        }
        type
      }
      id
      name
    }
  }
`

/**
 * __useProductForSelectQuery__
 *
 * To run a query within a React component, call `useProductForSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductForSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductForSelectQuery({
 *   variables: {
 *      country: // value for 'country'
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useProductForSelectQuery(
  baseOptions: Apollo.QueryHookOptions<
    TProductForSelectQuery,
    TProductForSelectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    TProductForSelectQuery,
    TProductForSelectQueryVariables
  >(ProductForSelectDocument, options)
}
export function useProductForSelectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TProductForSelectQuery,
    TProductForSelectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    TProductForSelectQuery,
    TProductForSelectQueryVariables
  >(ProductForSelectDocument, options)
}
export type ProductForSelectQueryHookResult = ReturnType<
  typeof useProductForSelectQuery
>
export type ProductForSelectLazyQueryHookResult = ReturnType<
  typeof useProductForSelectLazyQuery
>
export type ProductForSelectQueryResult = Apollo.QueryResult<
  TProductForSelectQuery,
  TProductForSelectQueryVariables
>
export const ProductsDocument = gql`
  query Products($country: Country!, $name: String!, $size: Int!) {
    products(
      country: $country
      filter: { name: $name }
      page: { size: $size }
    ) {
      items {
        id
        name
        vendor {
          name
        }
      }
    }
  }
`

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *      country: // value for 'country'
 *      name: // value for 'name'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useProductsQuery(
  baseOptions: Apollo.QueryHookOptions<TProductsQuery, TProductsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TProductsQuery, TProductsQueryVariables>(
    ProductsDocument,
    options,
  )
}
export function useProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TProductsQuery,
    TProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TProductsQuery, TProductsQueryVariables>(
    ProductsDocument,
    options,
  )
}
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>
export type ProductsLazyQueryHookResult = ReturnType<
  typeof useProductsLazyQuery
>
export type ProductsQueryResult = Apollo.QueryResult<
  TProductsQuery,
  TProductsQueryVariables
>
export const ProductsByIdsDocument = gql`
  query ProductsByIds($country: Country!, $ids: [ID!]!, $size: Int!) {
    products(country: $country, filter: { ids: $ids }, page: { size: $size }) {
      items {
        id
        name
      }
    }
  }
`

/**
 * __useProductsByIdsQuery__
 *
 * To run a query within a React component, call `useProductsByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsByIdsQuery({
 *   variables: {
 *      country: // value for 'country'
 *      ids: // value for 'ids'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useProductsByIdsQuery(
  baseOptions: Apollo.QueryHookOptions<
    TProductsByIdsQuery,
    TProductsByIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TProductsByIdsQuery, TProductsByIdsQueryVariables>(
    ProductsByIdsDocument,
    options,
  )
}
export function useProductsByIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TProductsByIdsQuery,
    TProductsByIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TProductsByIdsQuery, TProductsByIdsQueryVariables>(
    ProductsByIdsDocument,
    options,
  )
}
export type ProductsByIdsQueryHookResult = ReturnType<
  typeof useProductsByIdsQuery
>
export type ProductsByIdsLazyQueryHookResult = ReturnType<
  typeof useProductsByIdsLazyQuery
>
export type ProductsByIdsQueryResult = Apollo.QueryResult<
  TProductsByIdsQuery,
  TProductsByIdsQueryVariables
>
export const ProductsForSelectByIdsDocument = gql`
  query ProductsForSelectByIds(
    $country: Country!
    $productIds: [ID!]!
    $size: Int!
  ) {
    products(
      country: $country
      filter: { ids: $productIds }
      page: { size: $size }
    ) {
      items {
        components(type: ACTIVE) {
          substance {
            id
            medbaseTopParentId
            name
          }
        }
        id
        name
      }
    }
  }
`

/**
 * __useProductsForSelectByIdsQuery__
 *
 * To run a query within a React component, call `useProductsForSelectByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsForSelectByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsForSelectByIdsQuery({
 *   variables: {
 *      country: // value for 'country'
 *      productIds: // value for 'productIds'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useProductsForSelectByIdsQuery(
  baseOptions: Apollo.QueryHookOptions<
    TProductsForSelectByIdsQuery,
    TProductsForSelectByIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    TProductsForSelectByIdsQuery,
    TProductsForSelectByIdsQueryVariables
  >(ProductsForSelectByIdsDocument, options)
}
export function useProductsForSelectByIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TProductsForSelectByIdsQuery,
    TProductsForSelectByIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    TProductsForSelectByIdsQuery,
    TProductsForSelectByIdsQueryVariables
  >(ProductsForSelectByIdsDocument, options)
}
export type ProductsForSelectByIdsQueryHookResult = ReturnType<
  typeof useProductsForSelectByIdsQuery
>
export type ProductsForSelectByIdsLazyQueryHookResult = ReturnType<
  typeof useProductsForSelectByIdsLazyQuery
>
export type ProductsForSelectByIdsQueryResult = Apollo.QueryResult<
  TProductsForSelectByIdsQuery,
  TProductsForSelectByIdsQueryVariables
>
export const ProductsByPznsDocument = gql`
  query ProductsByPzns($pzns: [ID!]!, $size: Int!) {
    products(country: AT, filter: { pzns: $pzns }, page: { size: $size }) {
      items {
        id
        name
      }
    }
  }
`

/**
 * __useProductsByPznsQuery__
 *
 * To run a query within a React component, call `useProductsByPznsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsByPznsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsByPznsQuery({
 *   variables: {
 *      pzns: // value for 'pzns'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useProductsByPznsQuery(
  baseOptions: Apollo.QueryHookOptions<
    TProductsByPznsQuery,
    TProductsByPznsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TProductsByPznsQuery, TProductsByPznsQueryVariables>(
    ProductsByPznsDocument,
    options,
  )
}
export function useProductsByPznsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TProductsByPznsQuery,
    TProductsByPznsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    TProductsByPznsQuery,
    TProductsByPznsQueryVariables
  >(ProductsByPznsDocument, options)
}
export type ProductsByPznsQueryHookResult = ReturnType<
  typeof useProductsByPznsQuery
>
export type ProductsByPznsLazyQueryHookResult = ReturnType<
  typeof useProductsByPznsLazyQuery
>
export type ProductsByPznsQueryResult = Apollo.QueryResult<
  TProductsByPznsQuery,
  TProductsByPznsQueryVariables
>
export const ProductsForSelectByPznsDocument = gql`
  query ProductsForSelectByPzns($pzns: [ID!]!, $size: Int!) {
    products(country: AT, filter: { pzns: $pzns }, page: { size: $size }) {
      items {
        components(type: ACTIVE) {
          substance {
            id
            medbaseTopParentId
            name
          }
        }
        id
        name
      }
    }
  }
`

/**
 * __useProductsForSelectByPznsQuery__
 *
 * To run a query within a React component, call `useProductsForSelectByPznsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsForSelectByPznsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsForSelectByPznsQuery({
 *   variables: {
 *      pzns: // value for 'pzns'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useProductsForSelectByPznsQuery(
  baseOptions: Apollo.QueryHookOptions<
    TProductsForSelectByPznsQuery,
    TProductsForSelectByPznsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    TProductsForSelectByPznsQuery,
    TProductsForSelectByPznsQueryVariables
  >(ProductsForSelectByPznsDocument, options)
}
export function useProductsForSelectByPznsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TProductsForSelectByPznsQuery,
    TProductsForSelectByPznsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    TProductsForSelectByPznsQuery,
    TProductsForSelectByPznsQueryVariables
  >(ProductsForSelectByPznsDocument, options)
}
export type ProductsForSelectByPznsQueryHookResult = ReturnType<
  typeof useProductsForSelectByPznsQuery
>
export type ProductsForSelectByPznsLazyQueryHookResult = ReturnType<
  typeof useProductsForSelectByPznsLazyQuery
>
export type ProductsForSelectByPznsQueryResult = Apollo.QueryResult<
  TProductsForSelectByPznsQuery,
  TProductsForSelectByPznsQueryVariables
>
export const ProductsByUkeysDocument = gql`
  query ProductsByUkeys($ukeys: [ID!]!, $size: Int!) {
    products(country: AT, filter: { ukeys: $ukeys }, page: { size: $size }) {
      items {
        id
        name
      }
    }
  }
`

/**
 * __useProductsByUkeysQuery__
 *
 * To run a query within a React component, call `useProductsByUkeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsByUkeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsByUkeysQuery({
 *   variables: {
 *      ukeys: // value for 'ukeys'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useProductsByUkeysQuery(
  baseOptions: Apollo.QueryHookOptions<
    TProductsByUkeysQuery,
    TProductsByUkeysQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TProductsByUkeysQuery, TProductsByUkeysQueryVariables>(
    ProductsByUkeysDocument,
    options,
  )
}
export function useProductsByUkeysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TProductsByUkeysQuery,
    TProductsByUkeysQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    TProductsByUkeysQuery,
    TProductsByUkeysQueryVariables
  >(ProductsByUkeysDocument, options)
}
export type ProductsByUkeysQueryHookResult = ReturnType<
  typeof useProductsByUkeysQuery
>
export type ProductsByUkeysLazyQueryHookResult = ReturnType<
  typeof useProductsByUkeysLazyQuery
>
export type ProductsByUkeysQueryResult = Apollo.QueryResult<
  TProductsByUkeysQuery,
  TProductsByUkeysQueryVariables
>
export const ProductsForSelectByUkeysDocument = gql`
  query ProductsForSelectByUkeys($ukeys: [ID!]!, $size: Int!) {
    products(country: AT, filter: { ukeys: $ukeys }, page: { size: $size }) {
      items {
        components(type: ACTIVE) {
          substance {
            id
            medbaseTopParentId
            name
          }
        }
        id
        name
      }
    }
  }
`

/**
 * __useProductsForSelectByUkeysQuery__
 *
 * To run a query within a React component, call `useProductsForSelectByUkeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsForSelectByUkeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsForSelectByUkeysQuery({
 *   variables: {
 *      ukeys: // value for 'ukeys'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useProductsForSelectByUkeysQuery(
  baseOptions: Apollo.QueryHookOptions<
    TProductsForSelectByUkeysQuery,
    TProductsForSelectByUkeysQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    TProductsForSelectByUkeysQuery,
    TProductsForSelectByUkeysQueryVariables
  >(ProductsForSelectByUkeysDocument, options)
}
export function useProductsForSelectByUkeysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TProductsForSelectByUkeysQuery,
    TProductsForSelectByUkeysQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    TProductsForSelectByUkeysQuery,
    TProductsForSelectByUkeysQueryVariables
  >(ProductsForSelectByUkeysDocument, options)
}
export type ProductsForSelectByUkeysQueryHookResult = ReturnType<
  typeof useProductsForSelectByUkeysQuery
>
export type ProductsForSelectByUkeysLazyQueryHookResult = ReturnType<
  typeof useProductsForSelectByUkeysLazyQuery
>
export type ProductsForSelectByUkeysQueryResult = Apollo.QueryResult<
  TProductsForSelectByUkeysQuery,
  TProductsForSelectByUkeysQueryVariables
>
export const SubstanceForSelectDocument = gql`
  query SubstanceForSelect($country: Country!, $substanceId: ID!) {
    substance(country: $country, id: $substanceId) {
      id
      name
      medbaseTopParentId
    }
  }
`

/**
 * __useSubstanceForSelectQuery__
 *
 * To run a query within a React component, call `useSubstanceForSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubstanceForSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubstanceForSelectQuery({
 *   variables: {
 *      country: // value for 'country'
 *      substanceId: // value for 'substanceId'
 *   },
 * });
 */
export function useSubstanceForSelectQuery(
  baseOptions: Apollo.QueryHookOptions<
    TSubstanceForSelectQuery,
    TSubstanceForSelectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    TSubstanceForSelectQuery,
    TSubstanceForSelectQueryVariables
  >(SubstanceForSelectDocument, options)
}
export function useSubstanceForSelectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TSubstanceForSelectQuery,
    TSubstanceForSelectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    TSubstanceForSelectQuery,
    TSubstanceForSelectQueryVariables
  >(SubstanceForSelectDocument, options)
}
export type SubstanceForSelectQueryHookResult = ReturnType<
  typeof useSubstanceForSelectQuery
>
export type SubstanceForSelectLazyQueryHookResult = ReturnType<
  typeof useSubstanceForSelectLazyQuery
>
export type SubstanceForSelectQueryResult = Apollo.QueryResult<
  TSubstanceForSelectQuery,
  TSubstanceForSelectQueryVariables
>
export const SubstancesForSelectByIdsDocument = gql`
  query SubstancesForSelectByIds(
    $country: Country!
    $size: Int!
    $substanceIds: [ID!]!
  ) {
    substances(
      country: $country
      filter: { ids: $substanceIds }
      page: { size: $size }
    ) {
      items {
        id
        name
        medbaseTopParentId
      }
    }
  }
`

/**
 * __useSubstancesForSelectByIdsQuery__
 *
 * To run a query within a React component, call `useSubstancesForSelectByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubstancesForSelectByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubstancesForSelectByIdsQuery({
 *   variables: {
 *      country: // value for 'country'
 *      size: // value for 'size'
 *      substanceIds: // value for 'substanceIds'
 *   },
 * });
 */
export function useSubstancesForSelectByIdsQuery(
  baseOptions: Apollo.QueryHookOptions<
    TSubstancesForSelectByIdsQuery,
    TSubstancesForSelectByIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    TSubstancesForSelectByIdsQuery,
    TSubstancesForSelectByIdsQueryVariables
  >(SubstancesForSelectByIdsDocument, options)
}
export function useSubstancesForSelectByIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TSubstancesForSelectByIdsQuery,
    TSubstancesForSelectByIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    TSubstancesForSelectByIdsQuery,
    TSubstancesForSelectByIdsQueryVariables
  >(SubstancesForSelectByIdsDocument, options)
}
export type SubstancesForSelectByIdsQueryHookResult = ReturnType<
  typeof useSubstancesForSelectByIdsQuery
>
export type SubstancesForSelectByIdsLazyQueryHookResult = ReturnType<
  typeof useSubstancesForSelectByIdsLazyQuery
>
export type SubstancesForSelectByIdsQueryResult = Apollo.QueryResult<
  TSubstancesForSelectByIdsQuery,
  TSubstancesForSelectByIdsQueryVariables
>
export const SubstancesForSelectByBrainIdsDocument = gql`
  query SubstancesForSelectByBrainIds(
    $country: Country!
    $size: Int!
    $substanceIds: [ID!]!
  ) {
    substances(
      country: $country
      filter: { brainSubstanceIds: $substanceIds }
      page: { size: $size }
    ) {
      items {
        id
        name
        medbaseTopParentId
      }
    }
  }
`

/**
 * __useSubstancesForSelectByBrainIdsQuery__
 *
 * To run a query within a React component, call `useSubstancesForSelectByBrainIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubstancesForSelectByBrainIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubstancesForSelectByBrainIdsQuery({
 *   variables: {
 *      country: // value for 'country'
 *      size: // value for 'size'
 *      substanceIds: // value for 'substanceIds'
 *   },
 * });
 */
export function useSubstancesForSelectByBrainIdsQuery(
  baseOptions: Apollo.QueryHookOptions<
    TSubstancesForSelectByBrainIdsQuery,
    TSubstancesForSelectByBrainIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    TSubstancesForSelectByBrainIdsQuery,
    TSubstancesForSelectByBrainIdsQueryVariables
  >(SubstancesForSelectByBrainIdsDocument, options)
}
export function useSubstancesForSelectByBrainIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TSubstancesForSelectByBrainIdsQuery,
    TSubstancesForSelectByBrainIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    TSubstancesForSelectByBrainIdsQuery,
    TSubstancesForSelectByBrainIdsQueryVariables
  >(SubstancesForSelectByBrainIdsDocument, options)
}
export type SubstancesForSelectByBrainIdsQueryHookResult = ReturnType<
  typeof useSubstancesForSelectByBrainIdsQuery
>
export type SubstancesForSelectByBrainIdsLazyQueryHookResult = ReturnType<
  typeof useSubstancesForSelectByBrainIdsLazyQuery
>
export type SubstancesForSelectByBrainIdsQueryResult = Apollo.QueryResult<
  TSubstancesForSelectByBrainIdsQuery,
  TSubstancesForSelectByBrainIdsQueryVariables
>
export const FetchSubstanceProductsDocument = gql`
  query FetchSubstanceProducts(
    $country: Country!
    $substanceId: ID!
    $page: Int!
    $size: Int!
  ) {
    substance(country: $country, id: $substanceId) {
      id
      name
      productsConnection(page: { number: $page, size: $size }) {
        items {
          id
          prescriptionInfos {
            category
            name
            descriptionShort
          }
          articles(withParallelImports: false) {
            admission {
              name
              short
            }
            ecoBox
            ecoBoxMaxQuantity
            id
            inMarket
            prescriptionInfos {
              category
              descriptionShort
              name
            }
            prices {
              amount
              currency
              type
            }
          }
          atcCodes
          components(type: ACTIVE) {
            substance {
              id
              name
            }
          }
          id
          name
          vendor {
            name
          }
        }
        totalCount
      }
    }
  }
`

/**
 * __useFetchSubstanceProductsQuery__
 *
 * To run a query within a React component, call `useFetchSubstanceProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSubstanceProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSubstanceProductsQuery({
 *   variables: {
 *      country: // value for 'country'
 *      substanceId: // value for 'substanceId'
 *      page: // value for 'page'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useFetchSubstanceProductsQuery(
  baseOptions: Apollo.QueryHookOptions<
    TFetchSubstanceProductsQuery,
    TFetchSubstanceProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    TFetchSubstanceProductsQuery,
    TFetchSubstanceProductsQueryVariables
  >(FetchSubstanceProductsDocument, options)
}
export function useFetchSubstanceProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TFetchSubstanceProductsQuery,
    TFetchSubstanceProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    TFetchSubstanceProductsQuery,
    TFetchSubstanceProductsQueryVariables
  >(FetchSubstanceProductsDocument, options)
}
export type FetchSubstanceProductsQueryHookResult = ReturnType<
  typeof useFetchSubstanceProductsQuery
>
export type FetchSubstanceProductsLazyQueryHookResult = ReturnType<
  typeof useFetchSubstanceProductsLazyQuery
>
export type FetchSubstanceProductsQueryResult = Apollo.QueryResult<
  TFetchSubstanceProductsQuery,
  TFetchSubstanceProductsQueryVariables
>
export const SubstancesWithProductsByIdsDocument = gql`
  query SubstancesWithProductsByIds(
    $country: Country!
    $substanceIds: [ID!]!
    $size: Int!
  ) {
    substances(
      country: $country
      filter: { ids: $substanceIds, type: ACTIVE }
      page: { size: $size }
    ) {
      items {
        id
        name
        productsConnection {
          items {
            changeStatus
            id
            name
            vendor {
              name
            }
          }
          totalCount
        }
      }
    }
  }
`

/**
 * __useSubstancesWithProductsByIdsQuery__
 *
 * To run a query within a React component, call `useSubstancesWithProductsByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubstancesWithProductsByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubstancesWithProductsByIdsQuery({
 *   variables: {
 *      country: // value for 'country'
 *      substanceIds: // value for 'substanceIds'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useSubstancesWithProductsByIdsQuery(
  baseOptions: Apollo.QueryHookOptions<
    TSubstancesWithProductsByIdsQuery,
    TSubstancesWithProductsByIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    TSubstancesWithProductsByIdsQuery,
    TSubstancesWithProductsByIdsQueryVariables
  >(SubstancesWithProductsByIdsDocument, options)
}
export function useSubstancesWithProductsByIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TSubstancesWithProductsByIdsQuery,
    TSubstancesWithProductsByIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    TSubstancesWithProductsByIdsQuery,
    TSubstancesWithProductsByIdsQueryVariables
  >(SubstancesWithProductsByIdsDocument, options)
}
export type SubstancesWithProductsByIdsQueryHookResult = ReturnType<
  typeof useSubstancesWithProductsByIdsQuery
>
export type SubstancesWithProductsByIdsLazyQueryHookResult = ReturnType<
  typeof useSubstancesWithProductsByIdsLazyQuery
>
export type SubstancesWithProductsByIdsQueryResult = Apollo.QueryResult<
  TSubstancesWithProductsByIdsQuery,
  TSubstancesWithProductsByIdsQueryVariables
>
export const SubstancesWithProductsByBrainSubstanceIdsDocument = gql`
  query SubstancesWithProductsByBrainSubstanceIds(
    $country: Country!
    $substanceIds: [ID!]!
    $size: Int!
  ) {
    substances(
      country: $country
      filter: { brainSubstanceIds: $substanceIds, type: ACTIVE }
      page: { size: $size }
    ) {
      items {
        id
        name
        productsConnection {
          items {
            changeStatus
            id
            name
            vendor {
              name
            }
          }
          totalCount
        }
      }
    }
  }
`

/**
 * __useSubstancesWithProductsByBrainSubstanceIdsQuery__
 *
 * To run a query within a React component, call `useSubstancesWithProductsByBrainSubstanceIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubstancesWithProductsByBrainSubstanceIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubstancesWithProductsByBrainSubstanceIdsQuery({
 *   variables: {
 *      country: // value for 'country'
 *      substanceIds: // value for 'substanceIds'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useSubstancesWithProductsByBrainSubstanceIdsQuery(
  baseOptions: Apollo.QueryHookOptions<
    TSubstancesWithProductsByBrainSubstanceIdsQuery,
    TSubstancesWithProductsByBrainSubstanceIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    TSubstancesWithProductsByBrainSubstanceIdsQuery,
    TSubstancesWithProductsByBrainSubstanceIdsQueryVariables
  >(SubstancesWithProductsByBrainSubstanceIdsDocument, options)
}
export function useSubstancesWithProductsByBrainSubstanceIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TSubstancesWithProductsByBrainSubstanceIdsQuery,
    TSubstancesWithProductsByBrainSubstanceIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    TSubstancesWithProductsByBrainSubstanceIdsQuery,
    TSubstancesWithProductsByBrainSubstanceIdsQueryVariables
  >(SubstancesWithProductsByBrainSubstanceIdsDocument, options)
}
export type SubstancesWithProductsByBrainSubstanceIdsQueryHookResult =
  ReturnType<typeof useSubstancesWithProductsByBrainSubstanceIdsQuery>
export type SubstancesWithProductsByBrainSubstanceIdsLazyQueryHookResult =
  ReturnType<typeof useSubstancesWithProductsByBrainSubstanceIdsLazyQuery>
export type SubstancesWithProductsByBrainSubstanceIdsQueryResult =
  Apollo.QueryResult<
    TSubstancesWithProductsByBrainSubstanceIdsQuery,
    TSubstancesWithProductsByBrainSubstanceIdsQueryVariables
  >
export const FetchAtcLevelDocument = gql`
  query FetchAtcLevel(
    $code: ID!
    $country: Country!
    $a1: ID!
    $a2: ID!
    $a3: ID!
    $a4: ID!
    $a5: ID!
    $page: Int
    $size: Int
  ) {
    a1: atc(code: $a1) {
      code
      description
    }
    a2: atc(code: $a2) {
      code
      description
    }
    a3: atc(code: $a3) {
      code
      description
    }
    a4: atc(code: $a4) {
      code
      description
    }
    a5: atc(code: $a5) {
      code
      description
    }
    details: atc(code: $code) {
      children {
        code
        description
      }
      code
      description
      productsConnection(
        country: $country
        page: { number: $page, size: $size }
      ) {
        items {
          articles(withParallelImports: false) {
            admission {
              name
              short
            }
            ecoBox
            ecoBoxMaxQuantity
            id
            inMarket
            prescriptionInfos {
              category
              descriptionShort
              name
            }
            prices {
              amount
              currency
              type
            }
          }
          components(type: ACTIVE) {
            substance {
              id
              name
            }
          }
          id
          name
          prescriptionInfos {
            category
            name
            descriptionShort
          }
          vendor {
            name
          }
        }
        totalCount
      }
    }
  }
`

/**
 * __useFetchAtcLevelQuery__
 *
 * To run a query within a React component, call `useFetchAtcLevelQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAtcLevelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAtcLevelQuery({
 *   variables: {
 *      code: // value for 'code'
 *      country: // value for 'country'
 *      a1: // value for 'a1'
 *      a2: // value for 'a2'
 *      a3: // value for 'a3'
 *      a4: // value for 'a4'
 *      a5: // value for 'a5'
 *      page: // value for 'page'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useFetchAtcLevelQuery(
  baseOptions: Apollo.QueryHookOptions<
    TFetchAtcLevelQuery,
    TFetchAtcLevelQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TFetchAtcLevelQuery, TFetchAtcLevelQueryVariables>(
    FetchAtcLevelDocument,
    options,
  )
}
export function useFetchAtcLevelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TFetchAtcLevelQuery,
    TFetchAtcLevelQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TFetchAtcLevelQuery, TFetchAtcLevelQueryVariables>(
    FetchAtcLevelDocument,
    options,
  )
}
export type FetchAtcLevelQueryHookResult = ReturnType<
  typeof useFetchAtcLevelQuery
>
export type FetchAtcLevelLazyQueryHookResult = ReturnType<
  typeof useFetchAtcLevelLazyQuery
>
export type FetchAtcLevelQueryResult = Apollo.QueryResult<
  TFetchAtcLevelQuery,
  TFetchAtcLevelQueryVariables
>
export const FetchAtcTreeDocument = gql`
  query FetchAtcTree($code: ID!, $country: Country!, $page: Int, $size: Int) {
    details: atc(code: $code) {
      children {
        code
        description
      }
      code
      description
      productsConnection(
        country: $country
        page: { number: $page, size: $size }
      ) {
        items {
          id
          changeStatus
          name
        }
        totalCount
      }
    }
  }
`

/**
 * __useFetchAtcTreeQuery__
 *
 * To run a query within a React component, call `useFetchAtcTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAtcTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAtcTreeQuery({
 *   variables: {
 *      code: // value for 'code'
 *      country: // value for 'country'
 *      page: // value for 'page'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useFetchAtcTreeQuery(
  baseOptions: Apollo.QueryHookOptions<
    TFetchAtcTreeQuery,
    TFetchAtcTreeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TFetchAtcTreeQuery, TFetchAtcTreeQueryVariables>(
    FetchAtcTreeDocument,
    options,
  )
}
export function useFetchAtcTreeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TFetchAtcTreeQuery,
    TFetchAtcTreeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TFetchAtcTreeQuery, TFetchAtcTreeQueryVariables>(
    FetchAtcTreeDocument,
    options,
  )
}
export type FetchAtcTreeQueryHookResult = ReturnType<
  typeof useFetchAtcTreeQuery
>
export type FetchAtcTreeLazyQueryHookResult = ReturnType<
  typeof useFetchAtcTreeLazyQuery
>
export type FetchAtcTreeQueryResult = Apollo.QueryResult<
  TFetchAtcTreeQuery,
  TFetchAtcTreeQueryVariables
>
export const AtcsDocument = gql`
  query Atcs($name: String!, $country: Country!, $page: Int!, $size: Int!) {
    atcs(filter: { description: $name }, page: { number: $page, size: $size }) {
      items {
        code
        description
        productsConnection(country: $country) {
          totalCount
        }
      }
      totalCount
    }
  }
`

/**
 * __useAtcsQuery__
 *
 * To run a query within a React component, call `useAtcsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAtcsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAtcsQuery({
 *   variables: {
 *      name: // value for 'name'
 *      country: // value for 'country'
 *      page: // value for 'page'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useAtcsQuery(
  baseOptions: Apollo.QueryHookOptions<TAtcsQuery, TAtcsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TAtcsQuery, TAtcsQueryVariables>(AtcsDocument, options)
}
export function useAtcsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TAtcsQuery, TAtcsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TAtcsQuery, TAtcsQueryVariables>(
    AtcsDocument,
    options,
  )
}
export type AtcsQueryHookResult = ReturnType<typeof useAtcsQuery>
export type AtcsLazyQueryHookResult = ReturnType<typeof useAtcsLazyQuery>
export type AtcsQueryResult = Apollo.QueryResult<
  TAtcsQuery,
  TAtcsQueryVariables
>
export const FetchIcdDocument = gql`
  query FetchIcd($code: ID!, $country: Country!, $page: Int!, $size: Int!) {
    icd(country: $country, code: $code) {
      code
      description
      productsConnection(page: { number: $page, size: $size }) {
        items {
          components(type: ACTIVE) {
            substance {
              id
              name
            }
          }
          id
          name
          vendor {
            name
          }
        }
        totalCount
      }
    }
  }
`

/**
 * __useFetchIcdQuery__
 *
 * To run a query within a React component, call `useFetchIcdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchIcdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchIcdQuery({
 *   variables: {
 *      code: // value for 'code'
 *      country: // value for 'country'
 *      page: // value for 'page'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useFetchIcdQuery(
  baseOptions: Apollo.QueryHookOptions<TFetchIcdQuery, TFetchIcdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TFetchIcdQuery, TFetchIcdQueryVariables>(
    FetchIcdDocument,
    options,
  )
}
export function useFetchIcdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TFetchIcdQuery,
    TFetchIcdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TFetchIcdQuery, TFetchIcdQueryVariables>(
    FetchIcdDocument,
    options,
  )
}
export type FetchIcdQueryHookResult = ReturnType<typeof useFetchIcdQuery>
export type FetchIcdLazyQueryHookResult = ReturnType<
  typeof useFetchIcdLazyQuery
>
export type FetchIcdQueryResult = Apollo.QueryResult<
  TFetchIcdQuery,
  TFetchIcdQueryVariables
>
export const AnnouncementsDocument = gql`
  query Announcements {
    announcements {
      items {
        body
        customId
        file
        id
        insertedAt
        title
        type
        updatedAt
      }
      totalCount
    }
  }
`

/**
 * __useAnnouncementsQuery__
 *
 * To run a query within a React component, call `useAnnouncementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnnouncementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnnouncementsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAnnouncementsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TAnnouncementsQuery,
    TAnnouncementsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TAnnouncementsQuery, TAnnouncementsQueryVariables>(
    AnnouncementsDocument,
    options,
  )
}
export function useAnnouncementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TAnnouncementsQuery,
    TAnnouncementsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TAnnouncementsQuery, TAnnouncementsQueryVariables>(
    AnnouncementsDocument,
    options,
  )
}
export type AnnouncementsQueryHookResult = ReturnType<
  typeof useAnnouncementsQuery
>
export type AnnouncementsLazyQueryHookResult = ReturnType<
  typeof useAnnouncementsLazyQuery
>
export type AnnouncementsQueryResult = Apollo.QueryResult<
  TAnnouncementsQuery,
  TAnnouncementsQueryVariables
>
export const AnnouncementDocument = gql`
  query Announcement($id: Int!) {
    announcement(id: $id) {
      body
      customId
      file
      id
      insertedAt
      items {
        isExternalProduct
        product {
          id
          name
          vendor {
            name
          }
        }
      }
      title
      type
      updatedAt
    }
  }
`

/**
 * __useAnnouncementQuery__
 *
 * To run a query within a React component, call `useAnnouncementQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnnouncementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnnouncementQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAnnouncementQuery(
  baseOptions: Apollo.QueryHookOptions<
    TAnnouncementQuery,
    TAnnouncementQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TAnnouncementQuery, TAnnouncementQueryVariables>(
    AnnouncementDocument,
    options,
  )
}
export function useAnnouncementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TAnnouncementQuery,
    TAnnouncementQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TAnnouncementQuery, TAnnouncementQueryVariables>(
    AnnouncementDocument,
    options,
  )
}
export type AnnouncementQueryHookResult = ReturnType<
  typeof useAnnouncementQuery
>
export type AnnouncementLazyQueryHookResult = ReturnType<
  typeof useAnnouncementLazyQuery
>
export type AnnouncementQueryResult = Apollo.QueryResult<
  TAnnouncementQuery,
  TAnnouncementQueryVariables
>
export const PharmaNotificationsDocument = gql`
  query PharmaNotifications {
    pharmaNotifications {
      items {
        id
        productId
        productName
        type
        sePatientInitials
        reporterFirstname
        reporterLastname
        insertedAt
      }
      totalCount
    }
  }
`

/**
 * __usePharmaNotificationsQuery__
 *
 * To run a query within a React component, call `usePharmaNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePharmaNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePharmaNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePharmaNotificationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TPharmaNotificationsQuery,
    TPharmaNotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    TPharmaNotificationsQuery,
    TPharmaNotificationsQueryVariables
  >(PharmaNotificationsDocument, options)
}
export function usePharmaNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TPharmaNotificationsQuery,
    TPharmaNotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    TPharmaNotificationsQuery,
    TPharmaNotificationsQueryVariables
  >(PharmaNotificationsDocument, options)
}
export type PharmaNotificationsQueryHookResult = ReturnType<
  typeof usePharmaNotificationsQuery
>
export type PharmaNotificationsLazyQueryHookResult = ReturnType<
  typeof usePharmaNotificationsLazyQuery
>
export type PharmaNotificationsQueryResult = Apollo.QueryResult<
  TPharmaNotificationsQuery,
  TPharmaNotificationsQueryVariables
>
export const PharmaNotificationDocument = gql`
  query PharmaNotification($id: Int!) {
    pharmaNotification(id: $id) {
      id
      userId
      organisationId
      isExternalProduct
      additionalProducts {
        id
        isExternalProduct
        productCountry
        productId
        productName
        dosageUsage
        duration
        usageReason
      }
      type
      productId
      productName
      productCountry
      validForAllPackSizes
      reporterTitle
      reporterFirstname
      reporterLastname
      reporterJobDescription
      reporterOrganisation
      reporterStreet
      reporterZip
      reporterCity
      reporterPhone
      reporterFax
      reporterEmail
      qdReportedTime
      qdReportedAt
      qdChargenCode
      qdChargenSupplier
      qdRecognizedBy
      qdDescription
      qdRelationOtherSideeffect
      qdSuspicionDangerPublicHealth
      qdDigitalphotoViaMail
      qdOriginalViaPost
      qdDeliveryNotPossible
      qdExampleDescription
      qdInformedSources
      qdTakenActivities
      qdFurtherPlannedActivities
      qdAdditionalRelevantInfo
      qdListSupplementsForNotification
      sePatientInitials
      sePatientIdentification
      seAgeInYears
      seAgeInMonths
      seGender
      seDescription
      seOccurredAt
      seProcess
      seResult
      seDosageUsage
      seUsageFrom
      seUsageUntil
      seUsageReason
      seUsageFurtherDiseases
      seTreatmentSideEffect
      seContextWithSuspiciousDrug
      seReporterData
      seEditorData
      samplesSent
      dataAccuracyConfirmed
      notificationTransmitted
      insertedAt
      updatedAt
    }
  }
`

/**
 * __usePharmaNotificationQuery__
 *
 * To run a query within a React component, call `usePharmaNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePharmaNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePharmaNotificationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePharmaNotificationQuery(
  baseOptions: Apollo.QueryHookOptions<
    TPharmaNotificationQuery,
    TPharmaNotificationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    TPharmaNotificationQuery,
    TPharmaNotificationQueryVariables
  >(PharmaNotificationDocument, options)
}
export function usePharmaNotificationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TPharmaNotificationQuery,
    TPharmaNotificationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    TPharmaNotificationQuery,
    TPharmaNotificationQueryVariables
  >(PharmaNotificationDocument, options)
}
export type PharmaNotificationQueryHookResult = ReturnType<
  typeof usePharmaNotificationQuery
>
export type PharmaNotificationLazyQueryHookResult = ReturnType<
  typeof usePharmaNotificationLazyQuery
>
export type PharmaNotificationQueryResult = Apollo.QueryResult<
  TPharmaNotificationQuery,
  TPharmaNotificationQueryVariables
>
export const SubstancesProductsDocument = gql`
  query SubstancesProducts($country: Country!, $name: String!, $size: Int!) {
    products(
      country: $country
      filter: { name: $name }
      orderBy: { direction: ASC, field: CHANGE_STATUS }
      page: { size: $size }
    ) {
      items {
        components(type: ACTIVE) {
          substance {
            id
            medbaseTopParentId
            name
          }
        }
        id
        name
      }
    }
    substances(
      country: $country
      filter: { name: $name }
      page: { size: $size }
    ) {
      items {
        id
        medbaseTopParentId
        name
      }
    }
  }
`

/**
 * __useSubstancesProductsQuery__
 *
 * To run a query within a React component, call `useSubstancesProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubstancesProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubstancesProductsQuery({
 *   variables: {
 *      country: // value for 'country'
 *      name: // value for 'name'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useSubstancesProductsQuery(
  baseOptions: Apollo.QueryHookOptions<
    TSubstancesProductsQuery,
    TSubstancesProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    TSubstancesProductsQuery,
    TSubstancesProductsQueryVariables
  >(SubstancesProductsDocument, options)
}
export function useSubstancesProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TSubstancesProductsQuery,
    TSubstancesProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    TSubstancesProductsQuery,
    TSubstancesProductsQueryVariables
  >(SubstancesProductsDocument, options)
}
export type SubstancesProductsQueryHookResult = ReturnType<
  typeof useSubstancesProductsQuery
>
export type SubstancesProductsLazyQueryHookResult = ReturnType<
  typeof useSubstancesProductsLazyQuery
>
export type SubstancesProductsQueryResult = Apollo.QueryResult<
  TSubstancesProductsQuery,
  TSubstancesProductsQueryVariables
>
export const SubstancesProductsMedicationDocument = gql`
  query SubstancesProductsMedication(
    $country: Country!
    $name: String!
    $size: Int!
  ) {
    products(
      country: $country
      filter: { name: $name }
      orderBy: { direction: ASC, field: CHANGE_STATUS }
      page: { size: $size }
    ) {
      items {
        components(type: ACTIVE) {
          substance {
            id
            name
          }
        }
        id
        name
      }
    }
    substances(
      country: $country
      filter: { name: $name }
      page: { size: $size }
    ) {
      items {
        id
        name
        productsConnection {
          items {
            id
            name
          }
          totalCount
        }
      }
    }
  }
`

/**
 * __useSubstancesProductsMedicationQuery__
 *
 * To run a query within a React component, call `useSubstancesProductsMedicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubstancesProductsMedicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubstancesProductsMedicationQuery({
 *   variables: {
 *      country: // value for 'country'
 *      name: // value for 'name'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useSubstancesProductsMedicationQuery(
  baseOptions: Apollo.QueryHookOptions<
    TSubstancesProductsMedicationQuery,
    TSubstancesProductsMedicationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    TSubstancesProductsMedicationQuery,
    TSubstancesProductsMedicationQueryVariables
  >(SubstancesProductsMedicationDocument, options)
}
export function useSubstancesProductsMedicationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TSubstancesProductsMedicationQuery,
    TSubstancesProductsMedicationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    TSubstancesProductsMedicationQuery,
    TSubstancesProductsMedicationQueryVariables
  >(SubstancesProductsMedicationDocument, options)
}
export type SubstancesProductsMedicationQueryHookResult = ReturnType<
  typeof useSubstancesProductsMedicationQuery
>
export type SubstancesProductsMedicationLazyQueryHookResult = ReturnType<
  typeof useSubstancesProductsMedicationLazyQuery
>
export type SubstancesProductsMedicationQueryResult = Apollo.QueryResult<
  TSubstancesProductsMedicationQuery,
  TSubstancesProductsMedicationQueryVariables
>
export const InteractionsDocument = gql`
  query Interactions(
    $medbaseParentTopIds: [ID!]!
    $interactionSearchMode: InteractionsSearchMode
  ) {
    interactionsByTopParentIds(
      topParentIds: $medbaseParentTopIds
      searchMode: $interactionSearchMode
    ) {
      interactions {
        classifications {
          docClassification
          sevClassification
        }
        maxClassification {
          docClassification
          sevClassification
        }
        references {
          referenceDetails {
            text
            pubMedUrl
          }
        }
        sections {
          typeId
          interactionText {
            text {
              translation {
                langCode
                text
              }
              version
            }
          }
        }
        substances {
          id
          substanceNames {
            default
            languageCode
            name
            substanceId
          }
        }
      }
      totalInteractions
    }
  }
`

/**
 * __useInteractionsQuery__
 *
 * To run a query within a React component, call `useInteractionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInteractionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInteractionsQuery({
 *   variables: {
 *      medbaseParentTopIds: // value for 'medbaseParentTopIds'
 *      interactionSearchMode: // value for 'interactionSearchMode'
 *   },
 * });
 */
export function useInteractionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    TInteractionsQuery,
    TInteractionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TInteractionsQuery, TInteractionsQueryVariables>(
    InteractionsDocument,
    options,
  )
}
export function useInteractionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TInteractionsQuery,
    TInteractionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TInteractionsQuery, TInteractionsQueryVariables>(
    InteractionsDocument,
    options,
  )
}
export type InteractionsQueryHookResult = ReturnType<
  typeof useInteractionsQuery
>
export type InteractionsLazyQueryHookResult = ReturnType<
  typeof useInteractionsLazyQuery
>
export type InteractionsQueryResult = Apollo.QueryResult<
  TInteractionsQuery,
  TInteractionsQueryVariables
>
export const AggregatedRisksDocument = gql`
  query AggregatedRisks($medbaseParentTopIds: [ID!]!) {
    aggregatedRisksBySubstanceIds(substanceIds: $medbaseParentTopIds) {
      categories {
        aggregatedRiskScore
        riskbaseLevel {
          id
          classification
          levelClassification {
            classification
            langCode
          }
        }
        riskbaseCategory {
          algorithm {
            name
          }
          categoryId
          categoryNames {
            categoryName
            langCode
          }
          calculations {
            level
            max
            min
          }
          pharaoTexts {
            levelId
            consequence {
              langCode
              text
            }
            recommendation {
              langCode
              text
            }
          }
        }
        substances {
          id
          riskValue
        }
      }
      maxRiskbaseLevel {
        classification
        id
        levelClassification {
          classification
          langCode
        }
      }
    }
  }
`

/**
 * __useAggregatedRisksQuery__
 *
 * To run a query within a React component, call `useAggregatedRisksQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregatedRisksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregatedRisksQuery({
 *   variables: {
 *      medbaseParentTopIds: // value for 'medbaseParentTopIds'
 *   },
 * });
 */
export function useAggregatedRisksQuery(
  baseOptions: Apollo.QueryHookOptions<
    TAggregatedRisksQuery,
    TAggregatedRisksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TAggregatedRisksQuery, TAggregatedRisksQueryVariables>(
    AggregatedRisksDocument,
    options,
  )
}
export function useAggregatedRisksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TAggregatedRisksQuery,
    TAggregatedRisksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    TAggregatedRisksQuery,
    TAggregatedRisksQueryVariables
  >(AggregatedRisksDocument, options)
}
export type AggregatedRisksQueryHookResult = ReturnType<
  typeof useAggregatedRisksQuery
>
export type AggregatedRisksLazyQueryHookResult = ReturnType<
  typeof useAggregatedRisksLazyQuery
>
export type AggregatedRisksQueryResult = Apollo.QueryResult<
  TAggregatedRisksQuery,
  TAggregatedRisksQueryVariables
>
export const RenalBaseArticlesDocument = gql`
  query RenalBaseArticles(
    $filter: RenbaseArticleFilter
    $page: Int
    $pageSize: Int
  ) {
    renbaseArticles(filter: $filter, page: $page, pageSize: $pageSize) {
      items {
        classification
        drugFormGroupId
        drugFormGroupCode
        drugFormGroupDescription
        id
        nephrotoxic
        recommendations {
          classification
          classificationDescription
          dosageMod
          dosageModCode
          dosageModDescription
          dosageModMax
          doseInterval
          doseIntervalMax
          renalFailureDegreeDescription
          renalFailureDegreeId
        }
        references {
          body
          pmid
          sortKey
        }
        sections {
          body
          locale
          name
          sectionTypeId
        }
        substance {
          id
          substanceNames {
            default
            languageCode
            name
            substanceId
          }
          topParentId
        }
        substanceId
      }
      totalCount
    }
  }
`

/**
 * __useRenalBaseArticlesQuery__
 *
 * To run a query within a React component, call `useRenalBaseArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRenalBaseArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRenalBaseArticlesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useRenalBaseArticlesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TRenalBaseArticlesQuery,
    TRenalBaseArticlesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    TRenalBaseArticlesQuery,
    TRenalBaseArticlesQueryVariables
  >(RenalBaseArticlesDocument, options)
}
export function useRenalBaseArticlesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TRenalBaseArticlesQuery,
    TRenalBaseArticlesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    TRenalBaseArticlesQuery,
    TRenalBaseArticlesQueryVariables
  >(RenalBaseArticlesDocument, options)
}
export type RenalBaseArticlesQueryHookResult = ReturnType<
  typeof useRenalBaseArticlesQuery
>
export type RenalBaseArticlesLazyQueryHookResult = ReturnType<
  typeof useRenalBaseArticlesLazyQuery
>
export type RenalBaseArticlesQueryResult = Apollo.QueryResult<
  TRenalBaseArticlesQuery,
  TRenalBaseArticlesQueryVariables
>
export const HeparBaseArticlesDocument = gql`
  query HeparBaseArticles(
    $filter: HeparbaseArticleFilter
    $page: Int
    $pageSize: Int
  ) {
    heparbaseArticles(filter: $filter, page: $page, pageSize: $pageSize) {
      items {
        drugFormGroupId
        drugFormGroupCode
        drugFormGroupDescription
        hepatotoxic
        id
        recommendations {
          classification
          classificationDescription
          dosageMod
          dosageModCode
          dosageModDescription
          dosageModMax
          doseInterval
          doseIntervalMax
          hepaticImpairmentDegreeId
          hepaticImpairmentDegreeDescription
        }
        references {
          body
          pmid
          sortKey
        }
        sections {
          body
          locale
          name
          sectionTypeId
        }
        substance {
          substanceNames {
            default
            languageCode
            name
            substanceId
          }
        }
        substanceId
      }
      totalCount
    }
  }
`

/**
 * __useHeparBaseArticlesQuery__
 *
 * To run a query within a React component, call `useHeparBaseArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useHeparBaseArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHeparBaseArticlesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useHeparBaseArticlesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    THeparBaseArticlesQuery,
    THeparBaseArticlesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    THeparBaseArticlesQuery,
    THeparBaseArticlesQueryVariables
  >(HeparBaseArticlesDocument, options)
}
export function useHeparBaseArticlesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    THeparBaseArticlesQuery,
    THeparBaseArticlesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    THeparBaseArticlesQuery,
    THeparBaseArticlesQueryVariables
  >(HeparBaseArticlesDocument, options)
}
export type HeparBaseArticlesQueryHookResult = ReturnType<
  typeof useHeparBaseArticlesQuery
>
export type HeparBaseArticlesLazyQueryHookResult = ReturnType<
  typeof useHeparBaseArticlesLazyQuery
>
export type HeparBaseArticlesQueryResult = Apollo.QueryResult<
  THeparBaseArticlesQuery,
  THeparBaseArticlesQueryVariables
>
export const GravBaseArticlesDocument = gql`
  query GravBaseArticles(
    $filter: GravbaseArticleFilter
    $page: Int
    $pageSize: Int
  ) {
    gravbaseArticles(filter: $filter, page: $page, pageSize: $pageSize) {
      items {
        classification
        classificationDescription
        drugFormGroupId
        drugFormGroupCode
        drugFormGroupDescription
        id
        references {
          body
          pmid
          sortKey
        }
        sections {
          body
          locale
          name
          sectionTypeId
        }
        substance {
          substanceNames {
            default
            languageCode
            name
            substanceId
          }
        }
        substanceId
      }
      totalCount
    }
  }
`

/**
 * __useGravBaseArticlesQuery__
 *
 * To run a query within a React component, call `useGravBaseArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGravBaseArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGravBaseArticlesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useGravBaseArticlesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TGravBaseArticlesQuery,
    TGravBaseArticlesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    TGravBaseArticlesQuery,
    TGravBaseArticlesQueryVariables
  >(GravBaseArticlesDocument, options)
}
export function useGravBaseArticlesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TGravBaseArticlesQuery,
    TGravBaseArticlesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    TGravBaseArticlesQuery,
    TGravBaseArticlesQueryVariables
  >(GravBaseArticlesDocument, options)
}
export type GravBaseArticlesQueryHookResult = ReturnType<
  typeof useGravBaseArticlesQuery
>
export type GravBaseArticlesLazyQueryHookResult = ReturnType<
  typeof useGravBaseArticlesLazyQuery
>
export type GravBaseArticlesQueryResult = Apollo.QueryResult<
  TGravBaseArticlesQuery,
  TGravBaseArticlesQueryVariables
>
export const LactBaseArticlesDocument = gql`
  query LactBaseArticles(
    $filter: LactbaseArticleFilter
    $page: Int
    $pageSize: Int
  ) {
    lactbaseArticles(filter: $filter, page: $page, pageSize: $pageSize) {
      items {
        classification
        classificationDescription
        drugFormGroupId
        drugFormGroupCode
        drugFormGroupDescription
        id
        references {
          body
          pmid
          sortKey
        }
        sections {
          body
          locale
          name
          sectionTypeId
        }
        substance {
          substanceNames {
            default
            languageCode
            name
            substanceId
          }
        }
        substanceId
      }
      totalCount
    }
  }
`

/**
 * __useLactBaseArticlesQuery__
 *
 * To run a query within a React component, call `useLactBaseArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLactBaseArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLactBaseArticlesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useLactBaseArticlesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TLactBaseArticlesQuery,
    TLactBaseArticlesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    TLactBaseArticlesQuery,
    TLactBaseArticlesQueryVariables
  >(LactBaseArticlesDocument, options)
}
export function useLactBaseArticlesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TLactBaseArticlesQuery,
    TLactBaseArticlesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    TLactBaseArticlesQuery,
    TLactBaseArticlesQueryVariables
  >(LactBaseArticlesDocument, options)
}
export type LactBaseArticlesQueryHookResult = ReturnType<
  typeof useLactBaseArticlesQuery
>
export type LactBaseArticlesLazyQueryHookResult = ReturnType<
  typeof useLactBaseArticlesLazyQuery
>
export type LactBaseArticlesQueryResult = Apollo.QueryResult<
  TLactBaseArticlesQuery,
  TLactBaseArticlesQueryVariables
>
export const CreateAnnouncementDocument = gql`
  mutation CreateAnnouncement(
    $body: String
    $customId: String
    $file: String
    $items: [ItemInput!]
    $organisationId: String!
    $title: String!
    $type: AnnouncementTypeEnum!
    $userId: String!
  ) {
    createAnnouncement(
      body: $body
      customId: $customId
      file: $file
      items: $items
      organisationId: $organisationId
      title: $title
      type: $type
      userId: $userId
    ) {
      id
    }
  }
`
export type TCreateAnnouncementMutationFn = Apollo.MutationFunction<
  TCreateAnnouncementMutation,
  TCreateAnnouncementMutationVariables
>

/**
 * __useCreateAnnouncementMutation__
 *
 * To run a mutation, you first call `useCreateAnnouncementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnnouncementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAnnouncementMutation, { data, loading, error }] = useCreateAnnouncementMutation({
 *   variables: {
 *      body: // value for 'body'
 *      customId: // value for 'customId'
 *      file: // value for 'file'
 *      items: // value for 'items'
 *      organisationId: // value for 'organisationId'
 *      title: // value for 'title'
 *      type: // value for 'type'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCreateAnnouncementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TCreateAnnouncementMutation,
    TCreateAnnouncementMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    TCreateAnnouncementMutation,
    TCreateAnnouncementMutationVariables
  >(CreateAnnouncementDocument, options)
}
export type CreateAnnouncementMutationHookResult = ReturnType<
  typeof useCreateAnnouncementMutation
>
export type CreateAnnouncementMutationResult =
  Apollo.MutationResult<TCreateAnnouncementMutation>
export type CreateAnnouncementMutationOptions = Apollo.BaseMutationOptions<
  TCreateAnnouncementMutation,
  TCreateAnnouncementMutationVariables
>
export const UpdateAnnouncementDocument = gql`
  mutation UpdateAnnouncement(
    $body: String
    $customId: String
    $file: String
    $id: Int!
    $items: [ItemInput!]
    $organisationId: String!
    $title: String!
    $type: AnnouncementTypeEnum!
    $userId: String!
  ) {
    updateAnnouncement(
      body: $body
      customId: $customId
      file: $file
      id: $id
      items: $items
      organisationId: $organisationId
      title: $title
      type: $type
      userId: $userId
    ) {
      id
    }
  }
`
export type TUpdateAnnouncementMutationFn = Apollo.MutationFunction<
  TUpdateAnnouncementMutation,
  TUpdateAnnouncementMutationVariables
>

/**
 * __useUpdateAnnouncementMutation__
 *
 * To run a mutation, you first call `useUpdateAnnouncementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAnnouncementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAnnouncementMutation, { data, loading, error }] = useUpdateAnnouncementMutation({
 *   variables: {
 *      body: // value for 'body'
 *      customId: // value for 'customId'
 *      file: // value for 'file'
 *      id: // value for 'id'
 *      items: // value for 'items'
 *      organisationId: // value for 'organisationId'
 *      title: // value for 'title'
 *      type: // value for 'type'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUpdateAnnouncementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TUpdateAnnouncementMutation,
    TUpdateAnnouncementMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    TUpdateAnnouncementMutation,
    TUpdateAnnouncementMutationVariables
  >(UpdateAnnouncementDocument, options)
}
export type UpdateAnnouncementMutationHookResult = ReturnType<
  typeof useUpdateAnnouncementMutation
>
export type UpdateAnnouncementMutationResult =
  Apollo.MutationResult<TUpdateAnnouncementMutation>
export type UpdateAnnouncementMutationOptions = Apollo.BaseMutationOptions<
  TUpdateAnnouncementMutation,
  TUpdateAnnouncementMutationVariables
>
export const CreatePharmaNotificationDocument = gql`
  mutation CreatePharmaNotification(
    $userId: String!
    $organisationId: String!
    $isExternalProduct: Boolean
    $additionalProducts: [AdditionalProductInput!]
    $type: PharmaNotificationTypeEnum!
    $productId: Int!
    $productName: String!
    $productCountry: ProductCountryEnum!
    $validForAllPackSizes: Boolean
    $reporterTitle: String
    $reporterFirstname: String!
    $reporterLastname: String!
    $reporterJobDescription: String
    $reporterOrganisation: String!
    $reporterStreet: String
    $reporterZip: String!
    $reporterCity: String!
    $reporterPhone: String!
    $reporterFax: String
    $reporterEmail: String!
    $qdReportedTime: String
    $qdReportedAt: String
    $qdChargenCode: String
    $qdChargenSupplier: String
    $qdRecognizedBy: QdRecognizedByEnum
    $qdDescription: String
    $qdRelationOtherSideeffect: String
    $qdSuspicionDangerPublicHealth: Boolean
    $qdDigitalphotoViaMail: Boolean
    $qdOriginalViaPost: Boolean
    $qdDeliveryNotPossible: Boolean
    $qdExampleDescription: String
    $qdInformedSources: String
    $qdTakenActivities: String
    $qdFurtherPlannedActivities: String
    $qdAdditionalRelevantInfo: String
    $qdListSupplementsForNotification: String
    $sePatientInitials: String
    $sePatientIdentification: String
    $seAgeInYears: Int
    $seAgeInMonths: Int
    $seGender: SeGenderEnum
    $seDescription: String
    $seOccurredAt: String
    $seProcess: SeProcessEnum
    $seResult: SeResultEnum
    $seDosageUsage: String
    $seUsageFrom: String
    $seUsageUntil: String
    $seUsageReason: String
    $seUsageFurtherDiseases: String
    $seTreatmentSideEffect: String
    $seContextWithSuspiciousDrug: SeContextWithSuspiciousDrugEnum
    $seReporterData: String
    $seEditorData: String
    $samplesSent: Boolean
    $dataAccuracyConfirmed: Boolean
    $notificationTransmitted: Boolean
  ) {
    createPharmaNotification(
      userId: $userId
      organisationId: $organisationId
      isExternalProduct: $isExternalProduct
      additionalProducts: $additionalProducts
      type: $type
      productId: $productId
      productName: $productName
      productCountry: $productCountry
      validForAllPackSizes: $validForAllPackSizes
      reporterTitle: $reporterTitle
      reporterFirstname: $reporterFirstname
      reporterLastname: $reporterLastname
      reporterJobDescription: $reporterJobDescription
      reporterOrganisation: $reporterOrganisation
      reporterStreet: $reporterStreet
      reporterZip: $reporterZip
      reporterCity: $reporterCity
      reporterPhone: $reporterPhone
      reporterFax: $reporterFax
      reporterEmail: $reporterEmail
      qdReportedTime: $qdReportedTime
      qdReportedAt: $qdReportedAt
      qdChargenCode: $qdChargenCode
      qdChargenSupplier: $qdChargenSupplier
      qdRecognizedBy: $qdRecognizedBy
      qdDescription: $qdDescription
      qdRelationOtherSideeffect: $qdRelationOtherSideeffect
      qdSuspicionDangerPublicHealth: $qdSuspicionDangerPublicHealth
      qdDigitalphotoViaMail: $qdDigitalphotoViaMail
      qdOriginalViaPost: $qdOriginalViaPost
      qdDeliveryNotPossible: $qdDeliveryNotPossible
      qdExampleDescription: $qdExampleDescription
      qdInformedSources: $qdInformedSources
      qdTakenActivities: $qdTakenActivities
      qdFurtherPlannedActivities: $qdFurtherPlannedActivities
      qdAdditionalRelevantInfo: $qdAdditionalRelevantInfo
      qdListSupplementsForNotification: $qdListSupplementsForNotification
      sePatientInitials: $sePatientInitials
      sePatientIdentification: $sePatientIdentification
      seAgeInYears: $seAgeInYears
      seAgeInMonths: $seAgeInMonths
      seGender: $seGender
      seDescription: $seDescription
      seOccurredAt: $seOccurredAt
      seProcess: $seProcess
      seResult: $seResult
      seDosageUsage: $seDosageUsage
      seUsageFrom: $seUsageFrom
      seUsageUntil: $seUsageUntil
      seUsageReason: $seUsageReason
      seUsageFurtherDiseases: $seUsageFurtherDiseases
      seTreatmentSideEffect: $seTreatmentSideEffect
      seContextWithSuspiciousDrug: $seContextWithSuspiciousDrug
      seReporterData: $seReporterData
      seEditorData: $seEditorData
      samplesSent: $samplesSent
      dataAccuracyConfirmed: $dataAccuracyConfirmed
      notificationTransmitted: $notificationTransmitted
    ) {
      id
    }
  }
`
export type TCreatePharmaNotificationMutationFn = Apollo.MutationFunction<
  TCreatePharmaNotificationMutation,
  TCreatePharmaNotificationMutationVariables
>

/**
 * __useCreatePharmaNotificationMutation__
 *
 * To run a mutation, you first call `useCreatePharmaNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePharmaNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPharmaNotificationMutation, { data, loading, error }] = useCreatePharmaNotificationMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      organisationId: // value for 'organisationId'
 *      isExternalProduct: // value for 'isExternalProduct'
 *      additionalProducts: // value for 'additionalProducts'
 *      type: // value for 'type'
 *      productId: // value for 'productId'
 *      productName: // value for 'productName'
 *      productCountry: // value for 'productCountry'
 *      validForAllPackSizes: // value for 'validForAllPackSizes'
 *      reporterTitle: // value for 'reporterTitle'
 *      reporterFirstname: // value for 'reporterFirstname'
 *      reporterLastname: // value for 'reporterLastname'
 *      reporterJobDescription: // value for 'reporterJobDescription'
 *      reporterOrganisation: // value for 'reporterOrganisation'
 *      reporterStreet: // value for 'reporterStreet'
 *      reporterZip: // value for 'reporterZip'
 *      reporterCity: // value for 'reporterCity'
 *      reporterPhone: // value for 'reporterPhone'
 *      reporterFax: // value for 'reporterFax'
 *      reporterEmail: // value for 'reporterEmail'
 *      qdReportedTime: // value for 'qdReportedTime'
 *      qdReportedAt: // value for 'qdReportedAt'
 *      qdChargenCode: // value for 'qdChargenCode'
 *      qdChargenSupplier: // value for 'qdChargenSupplier'
 *      qdRecognizedBy: // value for 'qdRecognizedBy'
 *      qdDescription: // value for 'qdDescription'
 *      qdRelationOtherSideeffect: // value for 'qdRelationOtherSideeffect'
 *      qdSuspicionDangerPublicHealth: // value for 'qdSuspicionDangerPublicHealth'
 *      qdDigitalphotoViaMail: // value for 'qdDigitalphotoViaMail'
 *      qdOriginalViaPost: // value for 'qdOriginalViaPost'
 *      qdDeliveryNotPossible: // value for 'qdDeliveryNotPossible'
 *      qdExampleDescription: // value for 'qdExampleDescription'
 *      qdInformedSources: // value for 'qdInformedSources'
 *      qdTakenActivities: // value for 'qdTakenActivities'
 *      qdFurtherPlannedActivities: // value for 'qdFurtherPlannedActivities'
 *      qdAdditionalRelevantInfo: // value for 'qdAdditionalRelevantInfo'
 *      qdListSupplementsForNotification: // value for 'qdListSupplementsForNotification'
 *      sePatientInitials: // value for 'sePatientInitials'
 *      sePatientIdentification: // value for 'sePatientIdentification'
 *      seAgeInYears: // value for 'seAgeInYears'
 *      seAgeInMonths: // value for 'seAgeInMonths'
 *      seGender: // value for 'seGender'
 *      seDescription: // value for 'seDescription'
 *      seOccurredAt: // value for 'seOccurredAt'
 *      seProcess: // value for 'seProcess'
 *      seResult: // value for 'seResult'
 *      seDosageUsage: // value for 'seDosageUsage'
 *      seUsageFrom: // value for 'seUsageFrom'
 *      seUsageUntil: // value for 'seUsageUntil'
 *      seUsageReason: // value for 'seUsageReason'
 *      seUsageFurtherDiseases: // value for 'seUsageFurtherDiseases'
 *      seTreatmentSideEffect: // value for 'seTreatmentSideEffect'
 *      seContextWithSuspiciousDrug: // value for 'seContextWithSuspiciousDrug'
 *      seReporterData: // value for 'seReporterData'
 *      seEditorData: // value for 'seEditorData'
 *      samplesSent: // value for 'samplesSent'
 *      dataAccuracyConfirmed: // value for 'dataAccuracyConfirmed'
 *      notificationTransmitted: // value for 'notificationTransmitted'
 *   },
 * });
 */
export function useCreatePharmaNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TCreatePharmaNotificationMutation,
    TCreatePharmaNotificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    TCreatePharmaNotificationMutation,
    TCreatePharmaNotificationMutationVariables
  >(CreatePharmaNotificationDocument, options)
}
export type CreatePharmaNotificationMutationHookResult = ReturnType<
  typeof useCreatePharmaNotificationMutation
>
export type CreatePharmaNotificationMutationResult =
  Apollo.MutationResult<TCreatePharmaNotificationMutation>
export type CreatePharmaNotificationMutationOptions =
  Apollo.BaseMutationOptions<
    TCreatePharmaNotificationMutation,
    TCreatePharmaNotificationMutationVariables
  >
export const UpdatePharmaNotificationDocument = gql`
  mutation UpdatePharmaNotification(
    $id: Int!
    $additionalProducts: [AdditionalProductInput!]
    $type: PharmaNotificationTypeEnum
    $validForAllPackSizes: Boolean
    $reporterTitle: String
    $reporterFirstname: String
    $reporterLastname: String
    $reporterJobDescription: String
    $reporterOrganisation: String
    $reporterStreet: String
    $reporterZip: String
    $reporterCity: String
    $reporterPhone: String
    $reporterFax: String
    $reporterEmail: String
    $qdReportedTime: String
    $qdReportedAt: String
    $qdChargenCode: String
    $qdChargenSupplier: String
    $qdRecognizedBy: QdRecognizedByEnum
    $qdDescription: String
    $qdRelationOtherSideeffect: String
    $qdSuspicionDangerPublicHealth: Boolean
    $qdDigitalphotoViaMail: Boolean
    $qdOriginalViaPost: Boolean
    $qdDeliveryNotPossible: Boolean
    $qdExampleDescription: String
    $qdInformedSources: String
    $qdTakenActivities: String
    $qdFurtherPlannedActivities: String
    $qdAdditionalRelevantInfo: String
    $qdListSupplementsForNotification: String
    $sePatientInitials: String
    $sePatientIdentification: String
    $seAgeInYears: Int
    $seAgeInMonths: Int
    $seGender: SeGenderEnum
    $seDescription: String
    $seOccurredAt: String
    $seProcess: SeProcessEnum
    $seResult: SeResultEnum
    $seDosageUsage: String
    $seUsageFrom: String
    $seUsageUntil: String
    $seUsageReason: String
    $seUsageFurtherDiseases: String
    $seTreatmentSideEffect: String
    $seContextWithSuspiciousDrug: SeContextWithSuspiciousDrugEnum
    $seReporterData: String
    $seEditorData: String
    $samplesSent: Boolean
    $dataAccuracyConfirmed: Boolean
    $notificationTransmitted: Boolean
  ) {
    updatePharmaNotification(
      id: $id
      additionalProducts: $additionalProducts
      type: $type
      validForAllPackSizes: $validForAllPackSizes
      reporterTitle: $reporterTitle
      reporterFirstname: $reporterFirstname
      reporterLastname: $reporterLastname
      reporterJobDescription: $reporterJobDescription
      reporterOrganisation: $reporterOrganisation
      reporterStreet: $reporterStreet
      reporterZip: $reporterZip
      reporterCity: $reporterCity
      reporterPhone: $reporterPhone
      reporterFax: $reporterFax
      reporterEmail: $reporterEmail
      qdReportedTime: $qdReportedTime
      qdReportedAt: $qdReportedAt
      qdChargenCode: $qdChargenCode
      qdChargenSupplier: $qdChargenSupplier
      qdRecognizedBy: $qdRecognizedBy
      qdDescription: $qdDescription
      qdRelationOtherSideeffect: $qdRelationOtherSideeffect
      qdSuspicionDangerPublicHealth: $qdSuspicionDangerPublicHealth
      qdDigitalphotoViaMail: $qdDigitalphotoViaMail
      qdOriginalViaPost: $qdOriginalViaPost
      qdDeliveryNotPossible: $qdDeliveryNotPossible
      qdExampleDescription: $qdExampleDescription
      qdInformedSources: $qdInformedSources
      qdTakenActivities: $qdTakenActivities
      qdFurtherPlannedActivities: $qdFurtherPlannedActivities
      qdAdditionalRelevantInfo: $qdAdditionalRelevantInfo
      qdListSupplementsForNotification: $qdListSupplementsForNotification
      sePatientInitials: $sePatientInitials
      sePatientIdentification: $sePatientIdentification
      seAgeInYears: $seAgeInYears
      seAgeInMonths: $seAgeInMonths
      seGender: $seGender
      seDescription: $seDescription
      seOccurredAt: $seOccurredAt
      seProcess: $seProcess
      seResult: $seResult
      seDosageUsage: $seDosageUsage
      seUsageFrom: $seUsageFrom
      seUsageUntil: $seUsageUntil
      seUsageReason: $seUsageReason
      seUsageFurtherDiseases: $seUsageFurtherDiseases
      seTreatmentSideEffect: $seTreatmentSideEffect
      seContextWithSuspiciousDrug: $seContextWithSuspiciousDrug
      seReporterData: $seReporterData
      seEditorData: $seEditorData
      samplesSent: $samplesSent
      dataAccuracyConfirmed: $dataAccuracyConfirmed
      notificationTransmitted: $notificationTransmitted
    ) {
      id
    }
  }
`
export type TUpdatePharmaNotificationMutationFn = Apollo.MutationFunction<
  TUpdatePharmaNotificationMutation,
  TUpdatePharmaNotificationMutationVariables
>

/**
 * __useUpdatePharmaNotificationMutation__
 *
 * To run a mutation, you first call `useUpdatePharmaNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePharmaNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePharmaNotificationMutation, { data, loading, error }] = useUpdatePharmaNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      additionalProducts: // value for 'additionalProducts'
 *      type: // value for 'type'
 *      validForAllPackSizes: // value for 'validForAllPackSizes'
 *      reporterTitle: // value for 'reporterTitle'
 *      reporterFirstname: // value for 'reporterFirstname'
 *      reporterLastname: // value for 'reporterLastname'
 *      reporterJobDescription: // value for 'reporterJobDescription'
 *      reporterOrganisation: // value for 'reporterOrganisation'
 *      reporterStreet: // value for 'reporterStreet'
 *      reporterZip: // value for 'reporterZip'
 *      reporterCity: // value for 'reporterCity'
 *      reporterPhone: // value for 'reporterPhone'
 *      reporterFax: // value for 'reporterFax'
 *      reporterEmail: // value for 'reporterEmail'
 *      qdReportedTime: // value for 'qdReportedTime'
 *      qdReportedAt: // value for 'qdReportedAt'
 *      qdChargenCode: // value for 'qdChargenCode'
 *      qdChargenSupplier: // value for 'qdChargenSupplier'
 *      qdRecognizedBy: // value for 'qdRecognizedBy'
 *      qdDescription: // value for 'qdDescription'
 *      qdRelationOtherSideeffect: // value for 'qdRelationOtherSideeffect'
 *      qdSuspicionDangerPublicHealth: // value for 'qdSuspicionDangerPublicHealth'
 *      qdDigitalphotoViaMail: // value for 'qdDigitalphotoViaMail'
 *      qdOriginalViaPost: // value for 'qdOriginalViaPost'
 *      qdDeliveryNotPossible: // value for 'qdDeliveryNotPossible'
 *      qdExampleDescription: // value for 'qdExampleDescription'
 *      qdInformedSources: // value for 'qdInformedSources'
 *      qdTakenActivities: // value for 'qdTakenActivities'
 *      qdFurtherPlannedActivities: // value for 'qdFurtherPlannedActivities'
 *      qdAdditionalRelevantInfo: // value for 'qdAdditionalRelevantInfo'
 *      qdListSupplementsForNotification: // value for 'qdListSupplementsForNotification'
 *      sePatientInitials: // value for 'sePatientInitials'
 *      sePatientIdentification: // value for 'sePatientIdentification'
 *      seAgeInYears: // value for 'seAgeInYears'
 *      seAgeInMonths: // value for 'seAgeInMonths'
 *      seGender: // value for 'seGender'
 *      seDescription: // value for 'seDescription'
 *      seOccurredAt: // value for 'seOccurredAt'
 *      seProcess: // value for 'seProcess'
 *      seResult: // value for 'seResult'
 *      seDosageUsage: // value for 'seDosageUsage'
 *      seUsageFrom: // value for 'seUsageFrom'
 *      seUsageUntil: // value for 'seUsageUntil'
 *      seUsageReason: // value for 'seUsageReason'
 *      seUsageFurtherDiseases: // value for 'seUsageFurtherDiseases'
 *      seTreatmentSideEffect: // value for 'seTreatmentSideEffect'
 *      seContextWithSuspiciousDrug: // value for 'seContextWithSuspiciousDrug'
 *      seReporterData: // value for 'seReporterData'
 *      seEditorData: // value for 'seEditorData'
 *      samplesSent: // value for 'samplesSent'
 *      dataAccuracyConfirmed: // value for 'dataAccuracyConfirmed'
 *      notificationTransmitted: // value for 'notificationTransmitted'
 *   },
 * });
 */
export function useUpdatePharmaNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TUpdatePharmaNotificationMutation,
    TUpdatePharmaNotificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    TUpdatePharmaNotificationMutation,
    TUpdatePharmaNotificationMutationVariables
  >(UpdatePharmaNotificationDocument, options)
}
export type UpdatePharmaNotificationMutationHookResult = ReturnType<
  typeof useUpdatePharmaNotificationMutation
>
export type UpdatePharmaNotificationMutationResult =
  Apollo.MutationResult<TUpdatePharmaNotificationMutation>
export type UpdatePharmaNotificationMutationOptions =
  Apollo.BaseMutationOptions<
    TUpdatePharmaNotificationMutation,
    TUpdatePharmaNotificationMutationVariables
  >

import { Navigate, useLocation } from 'react-router-dom'
import { routeConstants } from '_constants'
import {
  EOnboardingStep,
  fullOnboardingState,
} from '_helpers/onboarding.helper'
import { userHelper as uh } from '_helpers'
import { TUserManagementPermissionsEnum } from '_generated/plexus.graphql'
import { useAppSelector } from '_hooks/store'

type TProps = {
  children?: JSX.Element
  permissions?: Array<TUserManagementPermissionsEnum>
}

export function RequireAuth({ children, permissions }: TProps) {
  const location = useLocation()
  const isAuthenticated = useAppSelector(
    (state) => state.authentication.isAuthenticated,
  )
  const user = useAppSelector((state) => state.authentication.user)

  if (!children) {
    throw new Error('Children cannot be null in RequireAuth.')
  }

  // public access is not allowed
  if (!isAuthenticated) {
    return (
      <Navigate
        to={routeConstants.LOGIN_PAGE}
        state={{ from: location }}
        replace
      />
    )
  }

  // make sure the user is forced to choose a webMedCountry
  // or to finalize his verification before the trial period ends
  // or to block him from using the app until he finishes the verification
  const { pathname } = location
  let redirectToVerification = false
  if (
    pathname !== routeConstants.VERIFICATION_PAGE &&
    pathname !== routeConstants.RESEND_ACTIVATION_PAGE &&
    !uh.isEnterpriseUser(user)
  ) {
    const { nextOnboardingStep, forceUserToFinishVerification } =
      fullOnboardingState(user)

    redirectToVerification =
      nextOnboardingStep === EOnboardingStep.choose_med_country ||
      forceUserToFinishVerification
  }

  // authenticated but verification unfinished
  if (redirectToVerification) {
    return (
      <Navigate
        to={routeConstants.VERIFICATION_PAGE}
        state={{ from: location }}
        replace
      />
    )
  }

  // If permissions are given, we need to check
  // if the user has those permissions.
  if (permissions) {
    const isAuthorized = uh.isAuthorized(
      user?.permissionSet,
      permissions,
      'some',
    )

    if (!isAuthorized) {
      return (
        <Navigate
          to={routeConstants.INDEX_PAGE}
          state={{ from: location }}
          replace
        />
      )
    }
  }

  // all good - authenticated and verified
  return children
}

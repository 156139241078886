import { EHeparChildPughOptions, ERenalGFROptions } from '_types'

export const gfrOptions = [
  {
    value: ERenalGFROptions.Mild,
    label: '< 90 - 60 ml/min (leicht)',
    labelShort: 'leicht',
  },
  {
    value: ERenalGFROptions.Moderate,
    label: '< 60 - 30 ml/min (mäßig)',
    labelShort: 'mäßig',
  },
  {
    value: ERenalGFROptions.Severe,
    label: '< 30 - 15 ml/min (schwer)',
    labelShort: 'schwer',
  },
  {
    value: ERenalGFROptions.Terminal,
    label: '< 15 ml/min (terminal)',
    labelShort: 'terminal',
  },
]

export const childPughOptions = [
  {
    value: EHeparChildPughOptions.Mild,
    label: 'A: Child-Pugh 5-6 (leicht)',
    labelShort: 'A: 5-6',
  },
  {
    value: EHeparChildPughOptions.Moderate,
    label: 'B: Child-Pugh 7-9 (mäßig)',
    labelShort: 'B: 7-9',
  },
  {
    value: EHeparChildPughOptions.Severe,
    label: 'C: Child-Pugh 10-15 (schwer)',
    labelShort: 'C: 10-15',
  },
]

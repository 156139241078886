import queryString from 'query-string'
import type { Maybe } from 'graphql/jsutils/Maybe'
import {
  TUserManagementBookmarkTypeEnum,
  TUserManagementCountryEnum as ECountry,
} from '_generated/plexus.graphql'
import {
  THistoryItem,
  TBookmarkItem,
  EAppConstantsKeys,
  EProductFachinfoTabTypes,
  EGravLactType,
} from '_types'
import { configHelper } from './config.helper'
import { routeConstants } from '_constants'
import isEqual from 'lodash/isEqual'
import { TParsedLegacyUrlParams } from './legacy.helper'
import { EQueryParams as EQP } from '_types'

export type TParsedUrlParams = {
  drugIds: Array<string> | null
  substanceIds: Array<string> | null
  grav: boolean
  lact: boolean
  renal: string | null
  hepar: string | null
}

const buildInteractionsParams = (
  params: Partial<TParsedLegacyUrlParams & TParsedUrlParams>,
): string => {
  const drugsStr = params.drugIds?.join(',')
  const brainSubstanceIdsStr = params.brainSubstanceIds?.join(',')
  const substancesStr = params.substanceIds?.join(',')
  const grav = params.grav
    ? params.grav
    : params.gravLactType === EGravLactType.Grav
    ? true
    : false
  const lact = params.lact
    ? params.lact
    : params.gravLactType === EGravLactType.Lact
    ? true
    : false
  const hepar = params.hepar
    ? params.hepar
    : params.hidCode
    ? params.hidCode
    : null
  const renal = params.renal
    ? params.renal
    : params.gfrCode
    ? params.gfrCode
    : null

  const url = new URLSearchParams()

  if (brainSubstanceIdsStr) {
    url.append(EQP.BrainSubstances, brainSubstanceIdsStr)
  }

  if (drugsStr) {
    url.append(EQP.Drugs, drugsStr)
  }

  if (grav) {
    url.append(EQP.Grav, '1')
  }

  if (hepar) {
    url.append(EQP.Hepar, hepar)
  }

  if (lact) {
    url.append(EQP.Lact, '1')
  }

  if (renal) {
    url.append(EQP.Renal, renal)
  }

  if (substancesStr) {
    url.append(EQP.Substances, substancesStr)
  }

  return url.toString()
}

const buildMedicationParams = (
  params: Partial<TParsedLegacyUrlParams & TParsedUrlParams>,
): string => {
  const drugsStr = params.drugIds?.join(',')
  const drugUkeysStr = params.drugUkeys?.join(',')
  const pznsStr = params.pzns?.join(',')
  const brainSubstanceIdsStr = params.brainSubstanceIds?.join(',')
  const substanceIdsStr = params.substanceIds?.join(',')

  const urlParams = new URLSearchParams()

  if (drugUkeysStr) {
    urlParams.append(EQP.Ukeys, drugUkeysStr)
  }

  if (drugsStr) {
    urlParams.append(EQP.Drugs, drugsStr)
  }

  if (pznsStr) {
    urlParams.append(EQP.Pzns, pznsStr)
  }

  if (brainSubstanceIdsStr) {
    urlParams.append(EQP.BrainSubstances, brainSubstanceIdsStr)
  }

  if (substanceIdsStr) {
    urlParams.append(EQP.Substances, substanceIdsStr)
  }

  return urlParams.toString()
}

const buildInteractionsQueryString = (
  search: Partial<TParsedUrlParams> = {
    drugIds: null,
    substanceIds: null,
    grav: false,
    lact: false,
    renal: null,
    hepar: null,
  },
): string => {
  const drugsStr = search.drugIds ? search.drugIds.join(',') : ''
  const substancesStr = search.substanceIds ? search.substanceIds.join(',') : ''
  const grav = search.grav ?? ''
  const lact = search.lact ?? ''
  const hepar = search.hepar ?? ''
  const renal = search.renal ?? ''

  return `drugs=${drugsStr}&substances=${substancesStr}&grav=${grav}&lact=${lact}&hepar=${hepar}&renal=${renal}`
}

const buildInteractionsQueryStringFromItem = (
  item?: THistoryItem | TBookmarkItem,
  search: TParsedUrlParams = {
    drugIds: null,
    substanceIds: null,
    grav: false,
    lact: false,
    renal: null,
    hepar: null,
  },
): Maybe<string> => {
  switch (item?.type) {
    case TUserManagementBookmarkTypeEnum.Drug:
      if (!item.drugs || !item.drugs[0]) {
        return undefined
      }
      if (search.drugIds && search.drugIds.length > 0) {
        search.drugIds.push(item.drugs[0])
      } else {
        search.drugIds = [item.drugs[0]]
      }
      break
    case TUserManagementBookmarkTypeEnum.Substance:
      if (!item.substances || !item.substances[0]) {
        return undefined
      }
      if (search.substanceIds && search.substanceIds.length > 0) {
        search.substanceIds.push(item.substances[0])
      } else {
        search.substanceIds = [item.substances[0]]
      }
      break
    default:
      return undefined
  }

  return buildInteractionsParams({
    drugIds: search.drugIds?.filter(Boolean).filter(unique) ?? null,
    substanceIds: search.substanceIds?.filter(Boolean).filter(unique) ?? null,
    grav: search.grav ?? false,
    lact: search.lact ?? false,
    hepar: search.hepar,
    renal: search.renal,
  })
}

const buildInteractionsUrl = (obj: Partial<TParsedUrlParams>): string => {
  const params = buildInteractionsParams(obj)
  let query = ''

  if (params.length > 0) {
    query += `?${params}`
  }

  return routeConstants.INTERACTIONS_PAGE + query
}

const buildUrl = (
  type: TUserManagementBookmarkTypeEnum,
  id: Maybe<string | number>,
  productId?: number,
): string => {
  if (!id) return ''

  switch (type) {
    case TUserManagementBookmarkTypeEnum.Atc:
      return `${routeConstants.ATC_PAGE}/${id}`
    case TUserManagementBookmarkTypeEnum.Drug:
      return `${routeConstants.PRODUCT_PAGE}/${id}`
    case TUserManagementBookmarkTypeEnum.Icd10:
      // return `/icd10/${id}`
      return `${routeConstants.ICD_PAGE}/${id}`
    case TUserManagementBookmarkTypeEnum.Substance:
      return `${routeConstants.SUBSTANCE_PAGE}/${id}`
    case TUserManagementBookmarkTypeEnum.Substitute:
      return `${routeConstants.PRODUCT_PAGE}/${productId}/substitutes/${id}`
    default:
      return ''
  }
}

const buildPrettyFromToDate = (
  from: Maybe<string>,
  to: Maybe<string>,
): string => {
  if (!from || !to) {
    return ''
  }

  const fromDate = new Date(Date.parse(from))
  const toDate = new Date(Date.parse(to))

  const prettyDay = fromDate.toLocaleDateString('de-AT', {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
  })

  const fromTime = fromDate.getHours()
  const toTime = toDate.getHours()

  return `${prettyDay}, ${fromTime}-${toTime} Uhr`
}

const checkIfResourceExists = async (url: string): Promise<boolean> => {
  try {
    const response = await fetch(url, {
      method: 'HEAD',
      cache: 'no-store',
    })

    if (!response.ok) {
      return false
    }
  } catch (e) {
    return false
  }

  return true
}

const countryFromISO = (iso: string): Maybe<string> => {
  switch (iso) {
    case 'AT':
      return 'Österreich'
    case 'DE':
      return 'Deutschland'
    default:
      return undefined
  }
}

const getExtension = (fileName: string): string => {
  return fileName.slice(((fileName.lastIndexOf('.') - 1) >>> 0) + 2)
}

const getLocaleDateAndTime = (
  dateTimeStr: string,
): { date: string; time: string } => {
  const dateTime = new Date(Date.parse(dateTimeStr))
  const date = dateTime.toLocaleDateString('de-DE', {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  })
  const time = dateTime.toLocaleTimeString('de-DE', {
    hour: '2-digit',
    minute: '2-digit',
  })

  return { date, time }
}

const getMobileAppLink = (
  country: ECountry,
  platform: 'android' | 'ios',
): string => {
  switch (country) {
    case ECountry.De:
      if (platform === 'android')
        return configHelper.get(EAppConstantsKeys.MOBILE_APP_LINK_ANDROID_DE)
      return configHelper.get(EAppConstantsKeys.MOBILE_APP_LINK_IOS_DE)
    case ECountry.At:
    case ECountry.Undefined:
    default:
      if (platform === 'android')
        return configHelper.get(EAppConstantsKeys.MOBILE_APP_LINK_ANDROID_AT)
      return configHelper.get(EAppConstantsKeys.MOBILE_APP_LINK_IOS_AT)
  }
}

const getProductFachinfoTab = (
  value: string | null,
): EProductFachinfoTabTypes => {
  if (value) {
    value = ucfirst(value)
    if (value in EProductFachinfoTabTypes) {
      //@ts-ignore
      return EProductFachinfoTabTypes[value]
    }
  }

  return EProductFachinfoTabTypes.Dosage
}

const getSearchParams = (paramsString: string): URLSearchParams => {
  const searchParams = new URLSearchParams(paramsString)
  return searchParams
}

const generateHighlightedWord = (
  text: string,
  wordToHighlight: string,
): string => {
  if (text.length === 0) {
    return ''
  }

  if (wordToHighlight.length === 0) {
    return text
  }

  wordToHighlight = wordToHighlight.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1')
  const regex = RegExp(wordToHighlight, 'gi')
  return text.replace(regex, `<span class="font-bold">${'$&'}</span>`)
}

const generateUrlFromPreviewItem = (
  item: THistoryItem | TBookmarkItem,
): string => {
  switch (item.type) {
    case TUserManagementBookmarkTypeEnum.Atc:
      return buildUrl(TUserManagementBookmarkTypeEnum.Atc, item.atc)
    case TUserManagementBookmarkTypeEnum.Drug:
      if (!item.drugs || !item.drugs[0]) return ''
      return buildUrl(TUserManagementBookmarkTypeEnum.Drug, item.drugs[0])
    case TUserManagementBookmarkTypeEnum.Icd10:
      return buildUrl(TUserManagementBookmarkTypeEnum.Icd10, item.icd10)
    case TUserManagementBookmarkTypeEnum.Substance:
      return buildUrl(
        TUserManagementBookmarkTypeEnum.Substance,
        item.substances![0]!,
      )
    case TUserManagementBookmarkTypeEnum.Interaction:
      return buildInteractionsUrl({
        drugIds: item.drugs?.filter(Boolean) ?? null,
        substanceIds: item.substances?.filter(Boolean) ?? null,
        grav: item.filter?.grav ?? false,
        lact: item.filter?.lact ?? false,
        hepar: item.filter?.hepar,
        renal: item.filter?.renal,
      })
    default:
      return ''
  }
}

const isEqualStringArray = (a: Array<string>, b: Array<string>): boolean => {
  a.sort()
  b.sort()

  return isEqual(a, b)
}

const parseUrlParams = (search: string): TParsedUrlParams => {
  const urlParams = queryString.parse(search, {
    arrayFormat: 'comma',
  })
  const grav = urlParams.grav ? (urlParams.grav === '1' ? true : false) : false
  const lact = urlParams.lact ? (urlParams.lact === '1' ? true : false) : false

  return {
    drugIds: urlParams.drugs
      ? [urlParams.drugs].flatMap((d) => d).filter(Boolean)
      : null,
    substanceIds: urlParams.substances
      ? [urlParams.substances].flatMap((s) => s).filter(Boolean)
      : null,
    grav,
    lact,
    renal: urlParams.renal ? (urlParams.renal as string) : null,
    hepar: urlParams.hepar ? (urlParams.hepar as string) : null,
  }
}

const sanitizeUrl = (url: Maybe<string>): string => {
  if (!url) return ''
  return url.startsWith('http') ? url : `//${url}`
}

const ucfirst = (text: string): string => {
  if (text.length === 0) {
    return ''
  }

  return text.charAt(0).toUpperCase() + text.slice(1)
}

// To be used in the array filter function.
// eg: ['a', 'a', 'b', 'c'].filter(unique)
const unique = <T>(value: T, index: number, self: T[]): boolean => {
  return self.indexOf(value) === index
}

const uuid = (): string => {
  const lut = []
  for (let i = 0; i < 256; i++) {
    lut[i] = (i < 16 ? '0' : '') + i.toString(16)
  }

  const d0 = (Math.random() * 0xffffffff) | 0
  const d1 = (Math.random() * 0xffffffff) | 0
  const d2 = (Math.random() * 0xffffffff) | 0
  const d3 = (Math.random() * 0xffffffff) | 0

  return (
    lut[d0 & 0xff] +
    lut[(d0 >> 8) & 0xff] +
    lut[(d0 >> 16) & 0xff] +
    lut[(d0 >> 24) & 0xff] +
    '-' +
    lut[d1 & 0xff] +
    lut[(d1 >> 8) & 0xff] +
    '-' +
    lut[((d1 >> 16) & 0x0f) | 0x40] +
    lut[(d1 >> 24) & 0xff] +
    '-' +
    lut[(d2 & 0x3f) | 0x80] +
    lut[(d2 >> 8) & 0xff] +
    '-' +
    lut[(d2 >> 16) & 0xff] +
    lut[(d2 >> 24) & 0xff] +
    lut[d3 & 0xff] +
    lut[(d3 >> 8) & 0xff] +
    lut[(d3 >> 16) & 0xff] +
    lut[(d3 >> 24) & 0xff]
  )
}

export const utils = {
  buildInteractionsParams,
  buildInteractionsQueryString,
  buildInteractionsQueryStringFromItem,
  buildInteractionsUrl,
  buildMedicationParams,
  buildUrl,
  buildPrettyFromToDate,
  checkIfResourceExists,
  countryFromISO,
  getSearchParams,
  generateUrlFromPreviewItem,
  generateHighlightedWord,
  getExtension,
  getLocaleDateAndTime,
  getMobileAppLink,
  getProductFachinfoTab,
  isEqualStringArray,
  parseUrlParams,
  sanitizeUrl,
  ucfirst,
  unique,
  uuid,
}

import { createSlice } from '@reduxjs/toolkit'
import { LocalStorage } from '_helpers'

export type TAdsState = {
  showAdsAt: number
}

function getInitialAdsState(): TAdsState {
  const dateTimeInMs = LocalStorage.get('_adt')

  return {
    showAdsAt: dateTimeInMs ? parseInt(dateTimeInMs) : Date.now(),
  }
}

const adsSlice = createSlice({
  name: 'ads',
  initialState: getInitialAdsState(),
  reducers: {
    resetAdsTimer(state) {
      // Set a new time in the future, when ads should
      // be shown again to the user.
      // We defined a timespan of 72h (= 259.200.000 ms)
      const newDate = Date.now() + 259_200_000
      LocalStorage.set('_adt', String(newDate))
      state.showAdsAt = newDate
    },
  },
})

export const { resetAdsTimer } = adsSlice.actions
export default adsSlice.reducer

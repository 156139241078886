import DefaultBtn from '_components/Common/Buttons/DefaultBtn'
import type { TEventSession } from '_generated/axon.graphql'
import { utils } from '_helpers'

type TProps = {
  webinar: TEventSession
}

export default function WebinarItem({ webinar }: TProps) {
  const fromDate = utils.buildPrettyFromToDate(webinar.from, webinar.to)

  return (
    <div className="session-item">
      {webinar.thumbnail ? (
        <img
          alt={webinar.title ?? ''}
          className="session-img flex-shrink-0"
          src={webinar.thumbnail}
        />
      ) : (
        <div className="session-img flex-shrink-0"></div>
      )}
      <div className="session-content me-3">
        <a
          href={webinar.fullUrl ?? 'https://learn.diagnosia.com/events'}
          rel="noreferrer"
          target="_blank"
        >
          <div className="session-description">{webinar.title}</div>
        </a>
        <div className="mt-1 color-blue-grey-70 text-sm">{fromDate}</div>
      </div>
      <DefaultBtn
        buttonText="Teilnehmen"
        link={
          webinar.fullUrl
            ? `${webinar.fullUrl}?modal=true`
            : 'https://learn.diagnosia.com/events'
        }
      />
    </div>
  )
}

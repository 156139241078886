export enum ETelementryEvents {
  // Ad geöffnet
  ADS_OPENED = 'ads.opened',
  // Ad gesehen
  ADS_SEEN = 'ads.seen',
  // Favorit angelegt
  BOOKMARKS_CREATE = 'bookmarks.create',
  // Interaktionssuche
  CHECK_INTERACTIONS_OVERVIEW = 'check.interactions.overview',
  // Wechsel der Datenbasis (web med country)
  COUNTRY_SELECTED = 'country.selected',
  // Detailansicht eines ATC Code
  INDEX_ATC = 'index.atc',
  // Detailansicht eines Arzneimittel nach Indexsuche
  INDEX_DRUG = 'index.drug',
  // Arzneimittel zu Favorite hinzufügen
  INDEX_DRUG_FAB = 'index.drug.fab',
  // News wurde gesehen
  INDEX_NEWS_PREVIEW = 'index.news.preview',
  // News wurde angegklickt
  INDEX_NEWS_SELECTED = 'index.news.selected',
  // Suche im Index
  INDEX_SEARCH = 'index.search',
  // Aufruf eines Fachinfo-Kapitels
  INDEX_SMPC_CHAPTER = 'index.smpc.chapter',
  // Download des Fachinfo PDFs
  INDEX_SMPC_PDF = 'index.smpc.pdf',
  // Suche in den Fachinfo Artikeln
  INDEX_SMPC_SEARCH = 'index.smpc.search',
  // Indexsuche mit Wirkstoff
  INDEX_SUBSTANCE = 'index.substance',
  // der User logged sich ein
  LOGIN = 'login',
  // Passwort wird vom User zurückgesetzt
  RESET_PASSWORD = 'reset-password',
  // laden der App mit Info zur Hardware und Browser
  START = 'start',
}

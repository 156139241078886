import { Link, useLocation } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
import * as Sentry from '@sentry/react'
import DgnsButton from './DgnsButton'
import { routeConstants } from '_constants'
import { useAppSelector } from '_hooks/store'

export default function NoMatch() {
  const location = useLocation()
  const userId = useAppSelector((state) => state.authentication.user?.uuid)
  const userIsAuthenticated = useAppSelector(
    (state) => state.authentication.isAuthenticated,
  )

  try {
    Sentry.captureMessage('Unknown route got called', {
      user: {
        id: userId ?? 'Unknown user',
      },
      level: 'info',
      extra: {
        location,
        userWasAuthenticated: userIsAuthenticated ? 'yes' : 'no',
      },
    })
  } catch (e) {}

  return (
    <Container className="py-5" data-testid="no-match">
      {userIsAuthenticated ? (
        <Row>
          <Col md={{ span: 4, offset: 4 }}>
            <div className="py-2 font-bold text-4xl">404</div>
            Es tut uns leid. Wir konnten diese Seite leider nicht finden.
          </Col>
        </Row>
      ) : (
        <Row>
          <Col md={{ span: 4, offset: 4 }}>
            Es tut uns leid. Wir konnten diese Seite leider nicht finden. Bitte{' '}
            <Link to={routeConstants.LOGIN_PAGE}>logge dich ein</Link> oder{' '}
            <Link to={routeConstants.SIGNUP_PAGE}>erstelle ein Konto</Link>.
            Gerne kannst du auch unseren Hilfe-Chat benutzen.
          </Col>
        </Row>
      )}
      <Row>
        <Col md={{ span: 4, offset: 4 }}>
          <div className="pt-3">
            {userIsAuthenticated ? (
              <Link to={routeConstants.INDEX_PAGE}>
                <DgnsButton title="Zurück zur Startseite">
                  Zurück zur Startseite
                </DgnsButton>
              </Link>
            ) : (
              <Link to={routeConstants.LOGIN_PAGE}>
                <DgnsButton title="Einloggen">Einloggen</DgnsButton>
              </Link>
            )}
          </div>
          {!userIsAuthenticated && (
            <>
              <div className="pt-3 text-sm">Du bist neu bei Diagnosia?</div>
              <Link className="text-sm link" to={routeConstants.SIGNUP_PAGE}>
                Konto erstellen
              </Link>
            </>
          )}
        </Col>
      </Row>
    </Container>
  )
}

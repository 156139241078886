export const routeConstants = {
  // Non authenticated routes
  ACTIVATION_PAGE: '/activate/:emailConfirmationToken',
  ACTIVATION_NEW_EMAIL_PAGE: '/activate-new-email/:emailConfirmationToken',
  LOGIN_PAGE: '/login',
  LOGOUT_PAGE: '/logout',
  PASSWORD_RECOVERY_PAGE: '/password-recovery',
  PASSWORD_RESET_PAGE: '/password-reset/:resetToken',
  SIGNUP_PAGE: '/signup',

  // Authenticated routes
  ANNOUNCEMENTS_PAGE: '/announcements',
  ANNOUNCEMENTS_CREATE: '/announcements/create',
  ANNOUNCEMENTS_EDIT: '/announcements/edit',
  ANNOUNCEMENTS_SHOW: '/announcements/show',
  ATC_PAGE: '/atc',
  ATC_TREE: '/atc-tree',
  ICD_PAGE: '/icd',
  INDEX_PAGE: '/index',
  INTERACTIONS_PAGE: '/interactions',
  LEGACY_INDEX_DRUG_SEARCH: '/index/drug/search',
  LEGACY_INDEX_DRUG_SHOW: '/index/drug/show',
  LEGACY_CHECK_INTERACTIONS_SEARCH: '/check/interactions/search',
  LEGACY_CHECK_GRAVLACT_SEARCH: '/check/gravlact/search',
  LEGACY_CHECK_HEPAR_SEARCH: '/dose/hepar/search',
  LEGACY_CHECK_RENAL_SEARCH: '/dose/renal/search',
  LEGACY_INDEX_ATC_SEARCH: '/index/atc/search',
  MEDICATION_PAGE: '/medication',
  PHARMA_NOTIFICATION_CREATE: '/pharma-notifications/create',
  PHARMA_NOTIFICATION_EDIT: '/pharma-notifications/edit',
  // TODO: implement pharma notification show page
  // PHARMA_NOTIFICATION_SHOW: '/pharma-notifications/show',
  PRODUCT_PAGE: '/product',
  PROFILE_PAGE: '/profile',
  RESEND_ACTIVATION_PAGE: '/resend-activation',
  ROOT_PAGE: '/',
  SUBSTANCE_PAGE: '/substance',
  VERIFICATION_PAGE: '/verification',
}

import { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { logoutRequest } from '_slices/authentication.slice'
import { routeConstants } from '_constants'
import { useAppDispatch } from '_hooks/store'

export function LogoutPage() {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(logoutRequest())
  }, [dispatch])

  return <Navigate to={routeConstants.LOGIN_PAGE} />
}

import { connect } from 'react-redux'
import OnboardingDialog from '_components/Common/OnboardingDialog'
import {
  TUserManagementOccupationEnum,
  useUserManagementConfigQuery,
  TUserManagementVerificationProcessStateEnum,
  TUserManagementUser,
} from '_generated/plexus.graphql'
import {
  EOnboardingStep,
  fullOnboardingState,
} from '_helpers/onboarding.helper'
import { gql, TRootState } from '_helpers'
import { ONBOARDING_IMAGE_UPLOAD_MUTATION } from '_constants/plexus.gql.queries'
import { VerificationStatusDialog } from './Dialogs/VerificationStatusDialog'
import { ChooseWebMedCountryDialog } from './Dialogs/ChooseMedCountryDialog'
import { NameSexDialog } from './Dialogs/NameSexDialog'
import { VerifyProfessionDialog } from './Dialogs/VerifyProfessionDialog'
import { DoctorWorkplaceDialog } from './Dialogs/DoctorWorkplaceDialog'
import { DoctorSpecialityDialog } from './Dialogs/DoctorSpecialityDialog'
import { StudyFieldsDialog } from './Dialogs/StudyFieldsDialog'
import { clearAlert, EAlertActionTypeKeys } from '_slices/alert.slice'
import {
  profileUpdateRequest,
  TAuthenticationState,
} from '_slices/authentication.slice'
import type { ComponentProps } from 'react'
import { useAppDispatch } from '_hooks/store'

type TProps = {
  alertMessage: string
  alertType: EAlertActionTypeKeys | null
  isUserProfileUpdating: boolean
  redirectTo?: TAuthenticationState['redirectTo']
  user: TUserManagementUser | undefined
}

const mapStateToProps = (state: TRootState) => {
  const { authentication } = state
  return {
    alertMessage: state.alert.message,
    alertType: state.alert.type,
    isUserProfileUpdating: authentication.isUserProfileUpdating,
    redirectTo: authentication.redirectTo,
    user: authentication.user || undefined,
  }
}

function ConnectedVerificationPage({
  alertMessage,
  alertType,
  isUserProfileUpdating,
  redirectTo,
  user,
}: TProps) {
  const dispatch = useAppDispatch()

  const {
    data: userManagementConfigData,
    loading: userManagementConfigLoading,
  } = useUserManagementConfigQuery({
    client: gql.plexusClient,
  })

  // verification image upload to Plexus
  async function imageUpload(files: Array<File>): Promise<any> {
    const response = await gql.plexusUploadClient.query({
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      query: ONBOARDING_IMAGE_UPLOAD_MUTATION,
      variables: { images: files },
    })

    if (!response.loading && !response.errors) {
      return Promise.resolve(true)
    }

    if (response.errors) {
      return Promise.reject({ graphQLErrors: response.errors })
    }

    return Promise.reject(Error('No data or errors returned from API.'))
  }

  // merge new user profile values with the user from state and upload the changes via user saga
  const prepareUpdateUserProfile = (values: any) => {
    // clear the alert message before updating the user profile again
    dispatch(clearAlert())

    const currentProfile = user?.profile
    if (currentProfile) {
      // remove any files before merging the values with the current user profile
      delete values.files
      delete values.graduationYearString
      delete values.singleFieldOfStudy
      const profile = {
        ...currentProfile,
        ...values,
      }
      dispatch(profileUpdateRequest(profile, { ...currentProfile }))
    }
  }

  const { nextOnboardingStep, forceUserToFinishVerification } =
    fullOnboardingState(user)

  redirectTo = redirectTo ?? undefined
  let dialogProps: ComponentProps<typeof OnboardingDialog>
  let dialogChildren: JSX.Element

  switch (nextOnboardingStep) {
    case EOnboardingStep.choose_med_country:
      dialogProps = {
        title: 'Arzneimittel-Index',
        paragraph: 'Wir zeigen die österreichischen Arzneimitteldaten an.',
        alertMessage: alertMessage,
        alertType: alertType,
        showSkipVerificationLink: false,
        showTrialExpiredInfo: forceUserToFinishVerification,
      }

      dialogChildren = (
        <ChooseWebMedCountryDialog
          user={user}
          userManagementConfigLoading={userManagementConfigLoading}
          prepareUpdateUserProfile={prepareUpdateUserProfile}
          isUserProfileUpdating={isUserProfileUpdating}
        />
      )
      break

    case EOnboardingStep.name_sex:
      dialogProps = {
        title: 'Persönliche Daten',
        alertMessage: alertMessage,
        alertType: alertType,
        showSkipVerificationLink: !forceUserToFinishVerification,
        skipVerificationLink: redirectTo,
        showTrialExpiredInfo: forceUserToFinishVerification,
      }

      dialogChildren = (
        <NameSexDialog
          user={user}
          userManagementConfigLoading={userManagementConfigLoading}
          prepareUpdateUserProfile={prepareUpdateUserProfile}
          isUserProfileUpdating={isUserProfileUpdating}
        />
      )
      break

    case EOnboardingStep.verify_profession:
      dialogProps = {
        title: 'Verifizierung',
        paragraph:
          user?.profile?.occupation === TUserManagementOccupationEnum.Doctor
            ? 'Du wirst sofort von uns verifiziert, wenn du deine Ausweisnummer direkt eingibst:'
            : 'Bitte lade ein Foto deines Berufsnachweises bzw. deiner Ausbildungsbestätigung hoch.',
        alertMessage: [alertMessage, user?.verificationProcess?.denyReason]
          .filter(Boolean)
          .join('\n'),
        alertType: alertType,
        showSkipVerificationLink: !forceUserToFinishVerification,
        skipVerificationLink: redirectTo,
        showTrialExpiredInfo: forceUserToFinishVerification,
      }

      dialogChildren = (
        <VerifyProfessionDialog
          user={user}
          userManagementConfigLoading={userManagementConfigLoading}
          userManagementConfigData={userManagementConfigData}
          prepareUpdateUserProfile={prepareUpdateUserProfile}
          isUserProfileUpdating={isUserProfileUpdating}
          imageUpload={imageUpload}
        />
      )
      break

    case EOnboardingStep.workplace:
      dialogProps = {
        title: 'Arbeitsort',
        paragraph:
          'Wir benötigen diese Information, um dir relevante Inhalte anzuzeigen.',
        alertMessage: alertMessage,
        alertType: alertType,
        showSkipVerificationLink: !forceUserToFinishVerification,
        skipVerificationLink: redirectTo,
        showTrialExpiredInfo: forceUserToFinishVerification,
      }

      dialogChildren = (
        <DoctorWorkplaceDialog
          user={user}
          userManagementConfigLoading={userManagementConfigLoading}
          userManagementConfigData={userManagementConfigData}
          prepareUpdateUserProfile={prepareUpdateUserProfile}
          isUserProfileUpdating={isUserProfileUpdating}
        />
      )
      break

    case EOnboardingStep.speciality:
      dialogProps = {
        title: 'Fachrichtung',
        paragraph: 'Wähle bis zu drei Fachrichtungen:',
        alertMessage: alertMessage,
        alertType: alertType,
        showSkipVerificationLink: !forceUserToFinishVerification,
        skipVerificationLink: redirectTo,
        showTrialExpiredInfo: forceUserToFinishVerification,
      }

      dialogChildren = (
        <DoctorSpecialityDialog
          user={user}
          userManagementConfigLoading={userManagementConfigLoading}
          userManagementConfigData={userManagementConfigData}
          prepareUpdateUserProfile={prepareUpdateUserProfile}
          isUserProfileUpdating={isUserProfileUpdating}
        />
      )
      break

    case EOnboardingStep.study_field:
      dialogProps = {
        title: 'Studienrichtung',
        paragraph: 'Bitte verrate uns deine Studienrichtung:',
        alertMessage: alertMessage,
        alertType: alertType,
        showSkipVerificationLink: !forceUserToFinishVerification,
        skipVerificationLink: redirectTo,
        showTrialExpiredInfo: forceUserToFinishVerification,
      }

      dialogChildren = (
        <StudyFieldsDialog
          user={user}
          userManagementConfigLoading={userManagementConfigLoading}
          userManagementConfigData={userManagementConfigData}
          prepareUpdateUserProfile={prepareUpdateUserProfile}
          isUserProfileUpdating={isUserProfileUpdating}
        />
      )
      break

    default:
    case EOnboardingStep.verification_pending:
      dialogProps = {
        title: 'Verifizierung',
        alertMessage: forceUserToFinishVerification
          ? 'Deine Trial Version is abgelaufen! Bei Problemen kontaktiere bitte den Support.'
          : undefined,
        alertType: alertType,
        showTrialExpiredInfo: forceUserToFinishVerification,
      }
      dialogChildren = (
        <VerificationStatusDialog
          forceUserToFinishVerification={forceUserToFinishVerification}
          userEmailConfirmed={user?.emailConfirmed || false}
          verificationProcessState={
            user?.verificationProcess?.state ||
            TUserManagementVerificationProcessStateEnum.NotStarted
          }
          redirectToExternalSource={redirectTo}
        />
      )
      break
  }

  return <OnboardingDialog {...dialogProps}>{dialogChildren}</OnboardingDialog>
}

const VerificationPage = connect(mapStateToProps)(ConnectedVerificationPage)

export { VerificationPage, ConnectedVerificationPage }

import { Spinner } from 'react-bootstrap'

type TProps = {
  msg?: string
  small?: boolean
}

export default function Loading({
  msg = 'Daten werden geladen...',
  small,
}: TProps) {
  return (
    <div className="text-center">
      <Spinner
        animation="border"
        variant="primary"
        size={small ? 'sm' : undefined}
      />
      <div className="mt-2 subline" data-testid="loading-msg">
        {msg}
      </div>
    </div>
  )
}

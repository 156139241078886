import {
  type TUserManagementInvoiceData,
  TUserManagementInvoiceDataEmploymentEnum as TEmployment,
} from '_generated/plexus.graphql'
import { useFormik } from 'formik'
import { Button, Form, Row, Col, Container } from 'react-bootstrap'
import Select from 'react-select'
import * as yup from 'yup'
import * as IBAN from 'iban'
import { invoiceDataUpdateRequest } from '_slices/authentication.slice'
import { EAlertActionTypeKeys } from '_slices/alert.slice'
import { useAppDispatch, useAppSelector } from '_hooks/store'

const validationSchema = yup.object({
  street: yup.string().required('Bitte gib deinen Straßennahmen an.'),
  iban: yup
    .string()
    .test('iban-check', 'Bitte gib einen gültigen IBan an.', function (value) {
      if (value) {
        return IBAN.isValid(value)
      }
      return false
    }),
  zip: yup.string().required('Bitte gibt deine Postleitzahl an.'),
  city: yup.string().required('Bitte gib deinen Wohnort an.'),
  taxNumber: yup.string().when('employment', {
    is: TEmployment.Independent,
    then: (taxNumber) =>
      taxNumber
        .required('Bitte gib deine UID-Nummer an.')
        .matches(
          /^((AT)?U[0-9]{8})|((DE)?[0-9]{9})$/,
          'Bitte gib eine gültige UID-Nummer an.',
        ),
  }),
})

type TProps = {
  invoiceData?: TUserManagementInvoiceData | null
}

const employmentOptions = [
  {
    value: TEmployment.Employed,
    label: 'Angestellt',
  },
  {
    value: TEmployment.Independent,
    label: 'Selbstständig',
  },
]

const ustOptions = [
  {
    value: true,
    label: 'Ja',
  },
  {
    value: false,
    label: 'Nein',
  },
]

type TInitialValues = {
  city: string
  employment: TEmployment
  iban: string
  invoiceWithUst: boolean
  street: string
  taxNumber: string
  zip: string
}

export default function InvoiceForm({ invoiceData }: TProps) {
  const isInvoiceDataUpdating = useAppSelector(
    (state) => state.authentication.isInvoiceDataUpdating,
  )

  const flashMessage = useAppSelector((state) => state.alert.message)
  const flashType = useAppSelector((state) => state.alert.type)
  const dispatch = useAppDispatch()

  const initialValues: TInitialValues = {
    city: invoiceData?.city ?? '',
    employment: invoiceData?.employment ?? TEmployment.Employed,
    iban: invoiceData?.iban ?? '',
    invoiceWithUst: invoiceData?.invoiceWithUst ?? false,
    street: invoiceData?.street ?? '',
    taxNumber: invoiceData?.taxNumber ?? '',
    zip: invoiceData?.zip ?? '',
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      if (invoiceData) {
        const newInvoiceData = {
          ...invoiceData,
          ...values,
        }
        dispatch(invoiceDataUpdateRequest(newInvoiceData))
        setSubmitting(false)
      }
    },
    validationSchema,
  })

  return (
    <Form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
      <Container className="p-0">
        <Row>
          <Col>
            <div className="text-lg font-bold">Rechnungsdaten</div>
            <div className="color-blue-grey-80 py-3">
              Um dir die Abrechnung für Umfragen so einfach wie möglich zu
              machen, kannst du uns hier deine Daten eintragen.
            </div>
            <Form.Group className="mb-3" controlId="employment">
              <Form.Label>Arbeitsverhältnis</Form.Label>
              <Select
                isDisabled={isInvoiceDataUpdating}
                options={employmentOptions}
                value={employmentOptions.find(
                  (o) => o.value === formik.values.employment,
                )}
                name="employment"
                onChange={(value, actionMeta) => {
                  if (actionMeta.name && value) {
                    formik.setFieldValue(actionMeta.name, value.value)
                  }
                }}
              />
            </Form.Group>
            {formik.values.employment === TEmployment.Independent && (
              <>
                <Form.Group className="mb-3" controlId="taxNumber">
                  <Form.Label>UID-Nummer</Form.Label>
                  <Form.Control
                    disabled={isInvoiceDataUpdating}
                    isInvalid={
                      !!formik.errors.taxNumber && !!formik.touched.taxNumber
                    }
                    placeholder="UID-Nummer"
                    name="taxNumber"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.taxNumber}
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid" className="text-end">
                    {formik.errors.taxNumber}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Rechnung mit ausgewiesener Ust. Steuer
                  </Form.Label>
                  <Select
                    isDisabled={isInvoiceDataUpdating}
                    options={ustOptions}
                    name="invoiceWithUst"
                    value={ustOptions.find(
                      (o) => o.value === formik.values.invoiceWithUst,
                    )}
                    onChange={(value, actionMeta) => {
                      if (actionMeta.name && value) {
                        formik.setFieldValue(actionMeta.name, value.value)
                      }
                    }}
                  />
                </Form.Group>
              </>
            )}
            <Form.Group className="mb-3" controlId="iban">
              <Form.Label>IBAN</Form.Label>
              <Form.Control
                disabled={isInvoiceDataUpdating}
                isInvalid={!!formik.errors.iban && !!formik.touched.iban}
                placeholder="Gib deinen IBAN an"
                name="iban"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.iban}
              ></Form.Control>
              <Form.Control.Feedback type="invalid" className="text-end">
                {formik.errors.iban}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="street">
              <Form.Label>Straße</Form.Label>
              <Form.Control
                disabled={isInvoiceDataUpdating}
                isInvalid={!!formik.errors.street && !!formik.touched.street}
                placeholder="Gib deine Straße an"
                name="street"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.street}
              ></Form.Control>
              <Form.Control.Feedback type="invalid" className="text-end">
                {formik.errors.street}
              </Form.Control.Feedback>
            </Form.Group>
            <Row className="mb-3">
              <Col md={3}>
                <Form.Group controlId="zip">
                  <Form.Label>PLZ</Form.Label>
                  <Form.Control
                    disabled={isInvoiceDataUpdating}
                    isInvalid={!!formik.errors.zip && !!formik.touched.zip}
                    name="zip"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.zip}
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid" className="text-end">
                    {formik.errors.zip}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={9}>
                <Form.Group controlId="city">
                  <Form.Label>Ort</Form.Label>
                  <Form.Control
                    disabled={isInvoiceDataUpdating}
                    isInvalid={!!formik.errors.city && !!formik.touched.city}
                    placeholder="Gib deine Stadt an"
                    name="city"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.city}
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid" className="text-end">
                    {formik.errors.city}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Button
              disabled={isInvoiceDataUpdating}
              variant="success"
              type="submit"
            >
              Speichern{' '}
              {isInvoiceDataUpdating && (
                <div
                  className="ms-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </Button>
            {flashType === EAlertActionTypeKeys.Success && (
              <div className="pt-2 color-mobile-green">{flashMessage}</div>
            )}
          </Col>
        </Row>
      </Container>
    </Form>
  )
}

import orderBy from 'lodash/orderBy'
import { TUserManagementItems } from '_generated/plexus.graphql'
import type { Maybe } from 'graphql/jsutils/Maybe'
import { ApolloError } from '@apollo/client'
import { plexusErrorMessageMap } from '_constants/error.constants'
import * as Sentry from '@sentry/react'

export type TPlexusUserManagementConfigFilteredItem<T = string, U = string> = {
  value: T
  label: U
}

export const defaultErrorMessage =
  'Es ist ein unerwarteter Fehler aufgetreten. Wir wurden bereits informiert.'

export function getPlexusConfig<T = string>(
  items: Array<Maybe<TUserManagementItems>>,
  lang = 'de',
): TPlexusUserManagementConfigFilteredItem<T>[] {
  if (items.length === 0) {
    return []
  }
  // remove inactive items
  let filteredItems = items.filter(Boolean).filter((o) => o.isActive)

  // sort items by sort attribute
  filteredItems = orderBy(filteredItems, ['sort'], ['asc'])

  // filter out the translation text and key
  const mappedItems = filteredItems.map((o) => {
    // find the translation for the given language code
    const t = o.translations?.find((t) => t?.language === lang)

    // only return key and translation text
    return { value: (o.key ?? '') as any as T, label: t?.text ?? '' }
  })

  return mappedItems
}

export const getPlexusErrorCodes = (
  error: ApolloError | undefined,
): string[] => {
  if (!error) {
    return []
  }

  if (!error.graphQLErrors) {
    return []
  }

  return error?.graphQLErrors
    .map((error) => error?.extensions?.code)
    .filter(Boolean)
}

export const getPlexusErrorMessages = (
  error: ApolloError | undefined,
): string[] => {
  if (!error) {
    return []
  }

  let msgs: string[] = []

  // find all error codes and map them to human readable messages
  error.graphQLErrors?.forEach((error) => {
    const code = error.extensions?.code
      ? (error.extensions.code as string)
      : undefined
    const errorMap = plexusErrorMessageMap.find((m) => code && m.code === code)

    const msg = errorMap?.msg
    if (msg) {
      msgs.push(msg)
    } else {
      Sentry.captureMessage(
        'Can not map plexus error code to human readable message: ' + code ??
          error.message,
      )
    }
  })

  if (msgs.length === 0) {
    msgs.push(defaultErrorMessage)
  }

  return msgs
}

export const getCombinedPlexusErrorMessage = (
  error: ApolloError | undefined,
): string => {
  if (!error) {
    return 'Es ist ein unbekannter Fehler aufgetreten.'
  }

  let errorMessages = getPlexusErrorMessages(error)
  let combinedErrorMessage = errorMessages.join('\n')
  return combinedErrorMessage
}

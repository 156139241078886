import {
  ChangeEvent,
  Component,
  DragEvent,
  ReactNode,
  useEffect,
  useState,
} from 'react'
import * as Sentry from '@sentry/react'
import toast from 'react-hot-toast'

// settings
const MAX_FILE_SIZE = 20 * 1024 * 1024
const MAX_FILE_COUNT = 5
const ALLOWED_FILE_TYPES = ['image/png', 'image/jpeg', 'application/pdf']

interface LegacyFile extends File {
  readonly lastModifiedDate: Date
}

type TFileUploaderState = {
  dragging: boolean
  previewItems: Array<TImagePreviewItem>
}

type TFileUploaderProps = {
  className?: string
  isSubmitting: boolean
  onFilesSelected: (files: File[]) => void
}

const imagePreviewItemBack: TImagePreviewItem = {
  id: 'back',
  background: 'back',
  file: null,
}
const imagePreviewItemFront: TImagePreviewItem = {
  id: 'front',
  background: 'front',
  file: null,
}

class FileUploader extends Component<TFileUploaderProps, TFileUploaderState> {
  static counter = 0
  fileUploaderInput: HTMLInputElement | null = null

  constructor(props: TFileUploaderProps) {
    super(props)

    var previewItems: Array<TImagePreviewItem> = [
      imagePreviewItemFront,
      imagePreviewItemBack,
    ]
    this.state = { dragging: false, previewItems: previewItems }
  }

  onFilesSelected = (previewItems: TImagePreviewItem[]) => {
    const files = previewItems
      .map((previewItem) => previewItem.file)
      .filter(Boolean)
    this.props.onFilesSelected(files)
  }

  dragEventCounter = 0
  dragEnterListener = (event: DragEvent<HTMLDivElement>) => {
    this.overrideEventDefaults(event)
    this.dragEventCounter++
    if (event.dataTransfer.items && event.dataTransfer.items[0]) {
      this.setState({ dragging: true })
    } else if (
      event.dataTransfer.types &&
      event.dataTransfer.types[0] === 'Files'
    ) {
      // This block handles support for IE - if you're not worried about
      // that, you can omit this
      this.setState({ dragging: true })
    }
  }

  dragLeaveListener = (event: DragEvent<HTMLDivElement>) => {
    this.overrideEventDefaults(event)
    this.dragEventCounter--

    if (this.dragEventCounter === 0) {
      this.setState({ dragging: false })
    }
  }

  // files were dropped in target div
  dropListener = (event: DragEvent<HTMLDivElement>) => {
    this.overrideEventDefaults(event)
    this.dragEventCounter = 0
    this.setState({ dragging: false })

    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      let updatedPreviewItems = this.filterAndMergeFiles(
        event.dataTransfer.files,
      )
      this.onFilesSelected(updatedPreviewItems)
      this.setState({
        previewItems: updatedPreviewItems,
      })
    }
  }

  overrideEventDefaults = (event: Event | DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
  }

  onSelectFileClick = () => {
    if (this.fileUploaderInput) {
      this.fileUploaderInput.click()
    }
  }

  filterAndMergeFiles = (files: FileList) => {
    var previewItems = this.removeEmptyPreviewItems(this.state.previewItems)

    for (var x = 0; x < files.length; x++) {
      const file = files[x] as LegacyFile

      // limit the number of items the user can add
      if (previewItems.length >= MAX_FILE_COUNT) {
        break
      }

      // IE11 does not have the attibute "lastModified" but rather "lastModifiedDate"
      // to make sure we are cross browser compatible, we have to check the existence of attributes first
      const previewItemId =
        (typeof file.name !== 'undefined' ? file.name : '') +
        (typeof file.type !== 'undefined' ? file.type : '') +
        (typeof file.size !== 'undefined' ? file.size.toString() : '') +
        (typeof file.lastModified !== 'undefined'
          ? file.lastModified.toString()
          : '') +
        (typeof file.lastModifiedDate !== 'undefined'
          ? file.lastModifiedDate.getTime().toString()
          : '')

      if (
        // there is already an identical image in list
        previewItems.every((previewItem) => previewItem.id !== previewItemId) &&
        // check file size
        file.size < MAX_FILE_SIZE &&
        // check image mimetypes
        ALLOWED_FILE_TYPES.some((type) => file.type === type)
      ) {
        const previewItem = {
          id: previewItemId,
          file: file,
        }
        previewItems.push(previewItem)
      }
    }

    // make sure the front and back previews are being displayed
    previewItems = this.addEmptyPreviewItems(previewItems)

    return previewItems
  }

  // remove the front and back items, which actually means
  // remove all items without a "File" attached
  removeEmptyPreviewItems = (previewItems: Array<TImagePreviewItem>) => {
    return previewItems.filter((item) => item.file !== null)
  }

  // add the empty front and back preview items
  addEmptyPreviewItems = (previewItems: Array<TImagePreviewItem>) => {
    if (previewItems.length === 0) {
      previewItems.push(imagePreviewItemFront)
      previewItems.push(imagePreviewItemBack)
    } else if (previewItems.length === 1) {
      previewItems.push(imagePreviewItemBack)
    }
    return previewItems
  }

  // input field was clicked and file(s) selected
  onFileChanged = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      let updatedPreviewItems = this.filterAndMergeFiles(event.target.files)

      // setting the 'value' attribute to an empty string, deletes the 'FileList'
      // otherwise a user can't add, delete and then add the same picture again
      if (this.fileUploaderInput) {
        this.fileUploaderInput.value = ''
      }

      this.onFilesSelected(updatedPreviewItems)
      this.setState({
        previewItems: updatedPreviewItems,
      })
    }
  }

  deleteImagePreviewItem = (previewItem: TImagePreviewItem) => {
    var previewItems = this.state.previewItems.filter(
      (item) => item.id !== previewItem.id,
    )
    previewItems = this.removeEmptyPreviewItems(previewItems)
    previewItems = this.addEmptyPreviewItems(previewItems)
    this.onFilesSelected(previewItems)
    this.setState({
      previewItems: previewItems,
    })
  }

  componentDidMount() {
    window.addEventListener('dragover', (event: Event) => {
      this.overrideEventDefaults(event)
    })
    window.addEventListener('drop', (event: Event) => {
      this.overrideEventDefaults(event)
    })
  }

  componentWillUnmount() {
    window.removeEventListener('dragover', this.overrideEventDefaults)
    window.removeEventListener('drop', this.overrideEventDefaults)
  }

  render() {
    return (
      <FileUploaderPresentationalComponent
        className={this.props.className}
        dragging={this.state.dragging}
        isSubmitting={this.props.isSubmitting}
        previewItems={this.state.previewItems}
        onSelectFileClick={this.onSelectFileClick}
        deleteImagePreviewItem={this.deleteImagePreviewItem}
        onDrag={this.overrideEventDefaults}
        onDragStart={this.overrideEventDefaults}
        onDragEnd={this.overrideEventDefaults}
        onDragOver={this.overrideEventDefaults}
        onDragEnter={this.dragEnterListener}
        onDragLeave={this.dragLeaveListener}
        onDrop={this.dropListener}
      >
        <input
          aria-label="Foto hochladen"
          ref={(el) => (this.fileUploaderInput = el)}
          type="file"
          multiple
          className="file-selection__input"
          onChange={this.onFileChanged}
          accept={ALLOWED_FILE_TYPES.join(', ')}
        />
      </FileUploaderPresentationalComponent>
    )
  }
}

type TPresentationalProps = {
  children?: ReactNode
  className?: string
  dragging: boolean
  isSubmitting: boolean
  previewItems: Array<TImagePreviewItem>
  onSelectFileClick: () => void
  deleteImagePreviewItem: (previewItem: TImagePreviewItem) => void
  onDrag: (event: DragEvent<HTMLDivElement>) => void
  onDragStart: (event: DragEvent<HTMLDivElement>) => void
  onDragEnd: (event: DragEvent<HTMLDivElement>) => void
  onDragOver: (event: DragEvent<HTMLDivElement>) => void
  onDragEnter: (event: DragEvent<HTMLDivElement>) => void
  onDragLeave: (event: DragEvent<HTMLDivElement>) => void
  onDrop: (event: DragEvent<HTMLDivElement>) => void
}

function FileUploaderPresentationalComponent(props: TPresentationalProps) {
  const {
    className,
    dragging,
    isSubmitting,
    previewItems,
    deleteImagePreviewItem,
    onSelectFileClick,
    onDrag,
    onDragStart,
    onDragEnd,
    onDragOver,
    onDragEnter,
    onDragLeave,
    onDrop,
  } = props

  return (
    <div
      className={
        (className ? className : '') +
        (dragging ? ' file-uploader dragging' : ' file-uploader')
      }
      onDrag={onDrag}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDragOver={onDragOver}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
    >
      <div className="d-flex flex-row flex-wrap">
        <div className="bg-border-box"></div>
        <div
          className={`border-box photo plus ${isSubmitting ? 'disabled' : ''}`}
          onClick={onSelectFileClick}
        ></div>

        {previewItems.map((previewItem) => (
          <ImagePreview
            deleteImagePreviewItem={deleteImagePreviewItem}
            key={previewItem.id}
            previewItem={previewItem}
          />
        ))}
      </div>

      {props.children}
    </div>
  )
}

type TImagePreviewItem = {
  id: string
  file: File | null
  background?: 'front' | 'back'
}

type TImagePreviewProps = {
  deleteImagePreviewItem: (previewItem: TImagePreviewItem) => void
  previewItem: TImagePreviewItem
}

const errorMsg =
  'Die Datei konnte nicht geladen werden. Versuch es bitte noch einmal bzw. versuche es mit einem anderen Bild.'

function ImagePreview({
  deleteImagePreviewItem,
  previewItem,
}: TImagePreviewProps) {
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | undefined>()

  useEffect(() => {
    function loadImage(previewItem: TImagePreviewItem) {
      let reader = new FileReader()
      reader.onloadend = () => {
        let result: any = reader.result

        if (result === null) {
          Sentry.captureException('Error: FileReader result is null!')
          toast.error(errorMsg)
          deleteImagePreviewItem(previewItem)
          return
        }

        if (result instanceof ArrayBuffer) {
          Sentry.captureException('Error: FileReader result is an ArrayBuffer!')
          toast.error(errorMsg)
          deleteImagePreviewItem(previewItem)
          return
        }

        if (typeof result === 'string') {
          setImagePreviewUrl(result as string)
        }
      }

      reader.readAsDataURL(previewItem.file!)
    }

    if (previewItem.file !== null) {
      loadImage(previewItem)
    }
  }, [previewItem])

  return (
    <div className={'border-box photo ' + previewItem.background}>
      {imagePreviewUrl && (
        <>
          <div
            className="delete"
            onClick={() => deleteImagePreviewItem(previewItem)}
          ></div>
          <img className="preview" src={imagePreviewUrl} alt="Vorschau" />
        </>
      )}
    </div>
  )
}

export default FileUploader

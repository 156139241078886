// all error codes are defined on the backend in the @error_codes variable on
// https://git.dgns.wtf/dgns/plexus/-/blob/master/lib/plexus_web/absinthe_error_helper.ex
export const errorConstants = {
  BOOKMARK_COUNTRY_REQUIRED: 'bookmark_country_required',
  BOOKMARK_DRUGS_REQUIRED: 'bookmark_drugs_required',
  BOOKMARK_NOT_FOUND: 'bookmark_not_found',
  BOOKMARK_USER_ID_DOES_NOT_MATCH: 'bookmark_user_id_does_not_match',
  MEDICAL_INFO_REPORT_ATTACHMENTS_ENCODING_ERROR:
    'medical_info_report_attachments_encoding_error',
  MEDICAL_INFO_REPORT_VENDOR_ID_NOT_FOUND:
    'medical_info_report_vendor_id_not_found',
  PROFILE_VERIFICATION_NUMBER_INCORRECT:
    'profile_verification_number_incorrect',
  USER_EMAIL_NOT_UNIQUE: 'user_email_not_unique',
  USER_EMAIL_INVALID: 'user_email_invalid',
  USER_EMAIL_NOT_FOUND: 'user_email_not_found',
  USER_EMAIL_CONFIRMED_ALREADY_CONFIRMED:
    'user_email_confirmed_already_confirmed',
  USER_EMAIL_CONFIRMATION_TOKEN_NOT_FOUND:
    'user_email_confirmation_token_not_found',
  USER_ID_NOT_FOUND: 'user_id_not_found',
  USER_ALREADY_VERIFIED: 'user_already_verified',
  USER_DOES_NOT_HAVE_PERMISSION: 'user_does_not_have_permission',
  USER_NOT_AUTHORIZED: 'user_not_authorized',
  USER_NOT_FOUND: 'user_not_found',
  USER_PASSWORD_DOES_NOT_MATCH: 'user_password_does_not_match',
  USER_PASSWORD_RESET_TOKEN_NOT_FOUND: 'user_password_reset_token_not_found',
  USER_REFRESH_TOKEN_INVALID: 'user_refresh_token_invalid',
  USER_REFRESH_TOKEN_NOT_FOUND: 'user_refresh_token_not_found',
  VERIFICATION_PROCESS_IMAGES_CANNOT_BE_EMPTY:
    'verification_process_images_cannot_be_empty',
  VERIFICATION_PROCESS_IMAGES_IMAGE_EXTENSION_DOES_NOT_MATCH:
    'verification_process_images_image_extension_does_not_match',
  VERIFICATION_PROCESS_IMAGES_IMAGE_TOO_LARGE:
    'verification_process_images_image_too_large',
  VERIFICATION_PROCESS_IMAGES_IMAGE_WRONG_TYPE:
    'verification_process_images_image_wrong_type',
  VERIFICATION_PROCESS_IMAGES_TOO_MANY_IMAGE:
    'verification_process_images_too_many_image',
}

export type TErrorConstantMessageMap = {
  code: string
  msg: string
}

export const plexusErrorMessageMap: TErrorConstantMessageMap[] = [
  {
    code: errorConstants.BOOKMARK_COUNTRY_REQUIRED,
    msg: 'Bookmark kann ohne Land nicht gefunden werden.',
  },
  {
    code: errorConstants.BOOKMARK_DRUGS_REQUIRED,
    msg: 'Bookmark kann ohne Drug nicht gefunden werden.',
  },
  { code: errorConstants.BOOKMARK_NOT_FOUND, msg: 'Bookmark nicht gefunden.' },
  {
    code: errorConstants.BOOKMARK_USER_ID_DOES_NOT_MATCH,
    msg: 'Bookmark user id nicht gefunden.',
  },
  {
    code: errorConstants.MEDICAL_INFO_REPORT_ATTACHMENTS_ENCODING_ERROR,
    msg: 'Herstellerkontakt Anhänge sind falsch encoded.',
  },
  {
    code: errorConstants.MEDICAL_INFO_REPORT_VENDOR_ID_NOT_FOUND,
    msg: 'Herstellerkontakt Vendor ID wurde nicht gefunden.',
  },
  {
    code: errorConstants.PROFILE_VERIFICATION_NUMBER_INCORRECT,
    msg: 'Die Verifizierungsnummer ist nicht korrekt.',
  },
  {
    code: errorConstants.USER_EMAIL_NOT_UNIQUE,
    msg: 'Die angegebene E-Mail Adresse existiert bereits.',
  },
  {
    code: errorConstants.USER_EMAIL_INVALID,
    msg: 'Die angegebene E-Mail Adresse ist nicht zulässig.',
  },
  {
    code: errorConstants.USER_EMAIL_NOT_FOUND,
    msg: 'Die angegebene E-Mail Adresse existiert nicht.',
  },
  {
    code: errorConstants.USER_EMAIL_CONFIRMED_ALREADY_CONFIRMED,
    msg: 'Diese E-Mail Adresse wurde bereits verifiziert.',
  },
  {
    code: errorConstants.USER_EMAIL_CONFIRMATION_TOKEN_NOT_FOUND,
    msg: 'Der angegebene E-Mail Confirmation Token konnte nicht gefunden werden.',
  },
  {
    code: errorConstants.USER_ID_NOT_FOUND,
    msg: 'Die User ID konnte nicht gefunden werden.',
  },
  {
    code: errorConstants.USER_ALREADY_VERIFIED,
    msg: 'Du bist bereits verifiziert.',
  },
  {
    code: errorConstants.USER_DOES_NOT_HAVE_PERMISSION,
    msg: 'Dir fehlen Zugriffsrechte.',
  },
  {
    code: errorConstants.USER_NOT_AUTHORIZED,
    msg: 'Darauf hast du keinen Zugriff.',
  },
  {
    code: errorConstants.USER_NOT_FOUND,
    msg: 'Du bist bei uns noch nicht registriert.',
  },
  {
    code: errorConstants.USER_PASSWORD_DOES_NOT_MATCH,
    msg: 'Das angegebene Passwort ist falsch.',
  },
  {
    code: errorConstants.USER_PASSWORD_RESET_TOKEN_NOT_FOUND,
    msg: 'Der angegebene Passwort Reset Token konnte nicht gefunden werden.',
  },
  {
    code: errorConstants.USER_REFRESH_TOKEN_INVALID,
    msg: 'Deine Sitzung ist abgelaufen. Bitte log dich neu ein.',
  },
  {
    code: errorConstants.USER_REFRESH_TOKEN_NOT_FOUND,
    msg: 'Deine Sitzung ist abgelaufen. Bitte log dich neu ein.',
  },
  {
    code: errorConstants.VERIFICATION_PROCESS_IMAGES_CANNOT_BE_EMPTY,
    msg: 'Es wurden keine Bilder für die Verifizierung übermittelt.',
  },
  {
    code: errorConstants.VERIFICATION_PROCESS_IMAGES_IMAGE_EXTENSION_DOES_NOT_MATCH,
    msg: 'Eines der hochgeladenen Bilder hat einen falschen Datentypen.',
  },
  {
    code: errorConstants.VERIFICATION_PROCESS_IMAGES_IMAGE_TOO_LARGE,
    msg: 'Eines der hochgeladenen Bilder ist zu groß.',
  },
  {
    code: errorConstants.VERIFICATION_PROCESS_IMAGES_IMAGE_WRONG_TYPE,
    msg: 'Eines der hochgeladenen Bilder hat einen falschen Datentypen.',
  },
  {
    code: errorConstants.VERIFICATION_PROCESS_IMAGES_TOO_MANY_IMAGE,
    msg: 'Es wurden zu viele Bilder für die Verifizierung hochgeladen',
  },
]

import { gql } from '@apollo/client'

//
// Product (drugs) queries
//

const PRODUCT_FRAGMENT = gql`
  fragment Product on DgnsProduct {
    announcements {
      customId
      file
      id
      insertedAt
      title
      type
    }
    articles(withParallelImports: true) {
      admission {
        name
        short
      }
      changeStatus
      ecoBox
      ecoBoxMaxQuantity
      id
      inMarket
      isBiosimilar
      name
      packageContent
      packageContentUnit
      packageSize
      packageSizeUnit
      prices {
        amount
        currency
        type
      }
      parallelImport {
        seller {
          name
        }
      }
      prescriptionInfos {
        additionalInfo
        alternativeName
        category
        description
        descriptionShort
        name
      }
    }
    atcCodes
    changeStatus
    components(type: $componentFilter) {
      substance {
        id
        medbaseTopParentId
        name
      }
      type
    }
    hasParallelImports
    id
    isOtc
    name
    smpcs {
      composition
      dosage
      indications
      interactions
      other {
        body
        title
      }
    }
    medicalInfoPdfUrl
    pharmaNotifications {
      id
      productId
      productName
      type
      sePatientInitials
      reporterFirstname
      reporterLastname
      insertedAt
    }
    prescriptionInfos {
      alternativeName
      category
      description
      descriptionShort
      name
    }
    vendor {
      id
      name
    }
  }
`

export const FETCH_FILTERED_INDEX_DATA = gql`
  query FetchFilteredIndexData(
    $country: Country!
    $barcode: String
    $name: String!
    $page: Int!
    $size: Int!
    $componentFilter: ComponentTypeFilter
  ) {
    products(
      country: $country
      filter: {
        barcode: $barcode
        name: $name
        parallelImports: ONLY_NOT_PARALLEL_IMPORTS
      }
      orderBy: { direction: ASC, field: CHANGE_STATUS }
      page: { number: $page, size: $size }
    ) {
      items {
        announcements {
          id
          title
          type
        }
        articles {
          admission {
            name
            short
          }
          changeStatus
          ecoBox
          ecoBoxMaxQuantity
          id
          inMarket
          isBiosimilar
          name
          packageContent
          packageContentUnit
          packageSize
          packageSizeUnit
          prices {
            amount
            currency
            type
          }
          prescriptionInfos {
            category
            descriptionShort
            name
          }
        }
        atcCodes
        changeStatus
        components(type: $componentFilter) {
          substance {
            id
            name
          }
          type
        }
        id
        isOtc
        hasParallelImports
        name
        pharmaNotifications {
          id
          type
        }
        vendor {
          name
        }
      }
      totalCount
    }
    substances(
      country: $country
      filter: { name: $name, onlyWithProducts: true }
      page: { number: $page, size: $size }
    ) {
      items {
        id
        name
        productsConnection {
          totalCount
        }
      }
      totalCount
    }
    atcs(filter: { description: $name }, page: { number: $page, size: $size }) {
      items {
        code
        description
        productsConnection(country: $country) {
          totalCount
        }
      }
      totalCount
    }
    icds(
      country: $country
      filter: { description: $name }
      page: { number: $page, size: $size }
    ) {
      items {
        code
        codeParent
        description
        productsConnection {
          totalCount
        }
      }
      totalCount
    }
  }
`

export const FETCH_PRODUCT_DETAILS = gql`
  query FetchProductDetails(
    $country: Country!
    $productId: ID!
    $componentFilter: ComponentTypeFilter
  ) {
    product(country: $country, id: $productId) {
      ...Product
    }
  }
  ${PRODUCT_FRAGMENT}
`

export const FETCH_PRODUCT_FOR_SELECT = gql`
  query ProductForSelect($country: Country!, $productId: ID!) {
    product(country: $country, id: $productId) {
      components(type: ACTIVE) {
        substance {
          id
          medbaseTopParentId
          name
        }
        type
      }
      id
      name
    }
  }
`

export const FETCH_PRODUCTS_BY_NAME = gql`
  query Products($country: Country!, $name: String!, $size: Int!) {
    products(
      country: $country
      filter: { name: $name }
      page: { size: $size }
    ) {
      items {
        id
        name
        vendor {
          name
        }
      }
    }
  }
`

export const FETCH_PRODUCTS_BY_IDS = gql`
  query ProductsByIds($country: Country!, $ids: [ID!]!, $size: Int!) {
    products(country: $country, filter: { ids: $ids }, page: { size: $size }) {
      items {
        id
        name
      }
    }
  }
`

export const FETCH_PRODUCTS_FOR_SELECT_BY_IDS = gql`
  query ProductsForSelectByIds(
    $country: Country!
    $productIds: [ID!]!
    $size: Int!
  ) {
    products(
      country: $country
      filter: { ids: $productIds }
      page: { size: $size }
    ) {
      items {
        components(type: ACTIVE) {
          substance {
            id
            medbaseTopParentId
            name
          }
        }
        id
        name
      }
    }
  }
`

export const FETCH_PRODUCTS_BY_PZNS = gql`
  query ProductsByPzns($pzns: [ID!]!, $size: Int!) {
    products(country: AT, filter: { pzns: $pzns }, page: { size: $size }) {
      items {
        id
        name
      }
    }
  }
`

export const FETCH_PRODUCTS_FOR_SELECT_BY_PZNS = gql`
  query ProductsForSelectByPzns($pzns: [ID!]!, $size: Int!) {
    products(country: AT, filter: { pzns: $pzns }, page: { size: $size }) {
      items {
        components(type: ACTIVE) {
          substance {
            id
            medbaseTopParentId
            name
          }
        }
        id
        name
      }
    }
  }
`

export const FETCH_PRODUCTS_BY_UKEYS = gql`
  query ProductsByUkeys($ukeys: [ID!]!, $size: Int!) {
    products(country: AT, filter: { ukeys: $ukeys }, page: { size: $size }) {
      items {
        id
        name
      }
    }
  }
`

export const FETCH_PRODUCTS_FOR_SELECT_BY_UKEYS = gql`
  query ProductsForSelectByUkeys($ukeys: [ID!]!, $size: Int!) {
    products(country: AT, filter: { ukeys: $ukeys }, page: { size: $size }) {
      items {
        components(type: ACTIVE) {
          substance {
            id
            medbaseTopParentId
            name
          }
        }
        id
        name
      }
    }
  }
`

//
// Substance queries
//

export const FETCH_SUBSTANCE_FOR_SELECT = gql`
  query SubstanceForSelect($country: Country!, $substanceId: ID!) {
    substance(country: $country, id: $substanceId) {
      id
      name
      medbaseTopParentId
    }
  }
`

export const FETCH_SUBSTANCES_FOR_SELECT_BY_IDS = gql`
  query SubstancesForSelectByIds(
    $country: Country!
    $size: Int!
    $substanceIds: [ID!]!
  ) {
    substances(
      country: $country
      filter: { ids: $substanceIds }
      page: { size: $size }
    ) {
      items {
        id
        name
        medbaseTopParentId
      }
    }
  }
`

export const FETCH_SUBSTANCES_FOR_SELECT_BY_BRAIN_SUBSTANCE_IDS = gql`
  query SubstancesForSelectByBrainIds(
    $country: Country!
    $size: Int!
    $substanceIds: [ID!]!
  ) {
    substances(
      country: $country
      filter: { brainSubstanceIds: $substanceIds }
      page: { size: $size }
    ) {
      items {
        id
        name
        medbaseTopParentId
      }
    }
  }
`

export const FETCH_SUBSTANCE_WITH_PRODUCTS = gql`
  query FetchSubstanceProducts(
    $country: Country!
    $substanceId: ID!
    $page: Int!
    $size: Int!
  ) {
    substance(country: $country, id: $substanceId) {
      id
      name
      productsConnection(page: { number: $page, size: $size }) {
        items {
          id
          prescriptionInfos {
            category
            name
            descriptionShort
          }
          articles(withParallelImports: false) {
            admission {
              name
              short
            }
            ecoBox
            ecoBoxMaxQuantity
            id
            inMarket
            prescriptionInfos {
              category
              descriptionShort
              name
            }
            prices {
              amount
              currency
              type
            }
          }
          atcCodes
          components(type: ACTIVE) {
            substance {
              id
              name
            }
          }
          id
          name
          vendor {
            name
          }
        }
        totalCount
      }
    }
  }
`

export const FETCH_SUBSTANCES_WITH_PRODUCTS_BY_IDS = gql`
  query SubstancesWithProductsByIds(
    $country: Country!
    $substanceIds: [ID!]!
    $size: Int!
  ) {
    substances(
      country: $country
      filter: { ids: $substanceIds, type: ACTIVE }
      page: { size: $size }
    ) {
      items {
        id
        name
        productsConnection {
          items {
            changeStatus
            id
            name
            vendor {
              name
            }
          }
          totalCount
        }
      }
    }
  }
`

export const FETCH_SUBSTANCES_WITH_PRODUCTS_BY_BRAIN_SUBSTANCE_IDS = gql`
  query SubstancesWithProductsByBrainSubstanceIds(
    $country: Country!
    $substanceIds: [ID!]!
    $size: Int!
  ) {
    substances(
      country: $country
      filter: { brainSubstanceIds: $substanceIds, type: ACTIVE }
      page: { size: $size }
    ) {
      items {
        id
        name
        productsConnection {
          items {
            changeStatus
            id
            name
            vendor {
              name
            }
          }
          totalCount
        }
      }
    }
  }
`

//
// ATC queries
//

export const FETCH_ATC_LEVEL = gql`
  query FetchAtcLevel(
    $code: ID!
    $country: Country!
    $a1: ID!
    $a2: ID!
    $a3: ID!
    $a4: ID!
    $a5: ID!
    $page: Int
    $size: Int
  ) {
    a1: atc(code: $a1) {
      code
      description
    }
    a2: atc(code: $a2) {
      code
      description
    }
    a3: atc(code: $a3) {
      code
      description
    }
    a4: atc(code: $a4) {
      code
      description
    }
    a5: atc(code: $a5) {
      code
      description
    }
    details: atc(code: $code) {
      children {
        code
        description
      }
      code
      description
      productsConnection(
        country: $country
        page: { number: $page, size: $size }
      ) {
        items {
          articles(withParallelImports: false) {
            admission {
              name
              short
            }
            ecoBox
            ecoBoxMaxQuantity
            id
            inMarket
            prescriptionInfos {
              category
              descriptionShort
              name
            }
            prices {
              amount
              currency
              type
            }
          }
          components(type: ACTIVE) {
            substance {
              id
              name
            }
          }
          id
          name
          prescriptionInfos {
            category
            name
            descriptionShort
          }
          vendor {
            name
          }
        }
        totalCount
      }
    }
  }
`

export const FETCH_ATC_TREE = gql`
  query FetchAtcTree($code: ID!, $country: Country!, $page: Int, $size: Int) {
    details: atc(code: $code) {
      children {
        code
        description
      }
      code
      description
      productsConnection(
        country: $country
        page: { number: $page, size: $size }
      ) {
        items {
          id
          changeStatus
          name
        }
        totalCount
      }
    }
  }
`

export const FETCH_ATCS = gql`
  query Atcs($name: String!, $country: Country!, $page: Int!, $size: Int!) {
    atcs(filter: { description: $name }, page: { number: $page, size: $size }) {
      items {
        code
        description
        productsConnection(country: $country) {
          totalCount
        }
      }
      totalCount
    }
  }
`

// ICD queries

export const FETCH_ICD = gql`
  query FetchIcd($code: ID!, $country: Country!, $page: Int!, $size: Int!) {
    icd(country: $country, code: $code) {
      code
      description
      productsConnection(page: { number: $page, size: $size }) {
        items {
          components(type: ACTIVE) {
            substance {
              id
              name
            }
          }
          id
          name
          vendor {
            name
          }
        }
        totalCount
      }
    }
  }
`

//
// Announcement queries
//

export const FETCH_ANNOUNCEMENTS = gql`
  query Announcements {
    announcements {
      items {
        body
        customId
        file
        id
        insertedAt
        title
        type
        updatedAt
      }
      totalCount
    }
  }
`

export const FETCH_ANNOUNCEMENT = gql`
  query Announcement($id: Int!) {
    announcement(id: $id) {
      body
      customId
      file
      id
      insertedAt
      items {
        isExternalProduct
        product {
          id
          name
          vendor {
            name
          }
        }
      }
      title
      type
      updatedAt
    }
  }
`

export const FETCH_PHARMA_NOTIFICATIONS = gql`
  query PharmaNotifications {
    pharmaNotifications {
      items {
        id
        productId
        productName
        type
        sePatientInitials
        reporterFirstname
        reporterLastname
        insertedAt
      }
      totalCount
    }
  }
`

export const FETCH_PHARMA_NOTIFICATION = gql`
  query PharmaNotification($id: Int!) {
    pharmaNotification(id: $id) {
      id
      userId
      organisationId
      isExternalProduct
      additionalProducts {
        id
        isExternalProduct
        productCountry
        productId
        productName
        dosageUsage
        duration
        usageReason
      }
      type
      productId
      productName
      productCountry
      validForAllPackSizes
      reporterTitle
      reporterFirstname
      reporterLastname
      reporterJobDescription
      reporterOrganisation
      reporterStreet
      reporterZip
      reporterCity
      reporterPhone
      reporterFax
      reporterEmail
      qdReportedTime
      qdReportedAt
      qdChargenCode
      qdChargenSupplier
      qdRecognizedBy
      qdDescription
      qdRelationOtherSideeffect
      qdSuspicionDangerPublicHealth
      qdDigitalphotoViaMail
      qdOriginalViaPost
      qdDeliveryNotPossible
      qdExampleDescription
      qdInformedSources
      qdTakenActivities
      qdFurtherPlannedActivities
      qdAdditionalRelevantInfo
      qdListSupplementsForNotification
      sePatientInitials
      sePatientIdentification
      seAgeInYears
      seAgeInMonths
      seGender
      seDescription
      seOccurredAt
      seProcess
      seResult
      seDosageUsage
      seUsageFrom
      seUsageUntil
      seUsageReason
      seUsageFurtherDiseases
      seTreatmentSideEffect
      seContextWithSuspiciousDrug
      seReporterData
      seEditorData
      samplesSent
      dataAccuracyConfirmed
      notificationTransmitted
      insertedAt
      updatedAt
    }
  }
`

//
// Medbase Queries
//

export const FETCH_SUBSTANCES_AND_PRODUCTS = gql`
  query SubstancesProducts($country: Country!, $name: String!, $size: Int!) {
    products(
      country: $country
      filter: { name: $name }
      orderBy: { direction: ASC, field: CHANGE_STATUS }
      page: { size: $size }
    ) {
      items {
        components(type: ACTIVE) {
          substance {
            id
            medbaseTopParentId
            name
          }
        }
        id
        name
      }
    }
    substances(
      country: $country
      filter: { name: $name }
      page: { size: $size }
    ) {
      items {
        id
        medbaseTopParentId
        name
      }
    }
  }
`

export const FETCH_SUBSTANCES_AND_PRODUCTS_MEDICATION = gql`
  query SubstancesProductsMedication(
    $country: Country!
    $name: String!
    $size: Int!
  ) {
    products(
      country: $country
      filter: { name: $name }
      orderBy: { direction: ASC, field: CHANGE_STATUS }
      page: { size: $size }
    ) {
      items {
        components(type: ACTIVE) {
          substance {
            id
            name
          }
        }
        id
        name
      }
    }
    substances(
      country: $country
      filter: { name: $name }
      page: { size: $size }
    ) {
      items {
        id
        name
        productsConnection {
          items {
            id
            name
          }
          totalCount
        }
      }
    }
  }
`

export const FETCH_INTERACTIONS = gql`
  query Interactions(
    $medbaseParentTopIds: [ID!]!
    $interactionSearchMode: InteractionsSearchMode
  ) {
    interactionsByTopParentIds(
      topParentIds: $medbaseParentTopIds
      searchMode: $interactionSearchMode
    ) {
      interactions {
        classifications {
          docClassification
          sevClassification
        }
        maxClassification {
          docClassification
          sevClassification
        }
        references {
          referenceDetails {
            text
            pubMedUrl
          }
        }
        sections {
          typeId
          interactionText {
            text {
              translation {
                langCode
                text
              }
              version
            }
          }
        }
        substances {
          id
          substanceNames {
            default
            languageCode
            name
            substanceId
          }
        }
      }
      totalInteractions
    }
  }
`

export const FETCH_AGGREGATED_RISKS = gql`
  query AggregatedRisks($medbaseParentTopIds: [ID!]!) {
    aggregatedRisksBySubstanceIds(substanceIds: $medbaseParentTopIds) {
      categories {
        aggregatedRiskScore
        riskbaseLevel {
          id
          classification
          levelClassification {
            classification
            langCode
          }
        }
        riskbaseCategory {
          algorithm {
            name
          }
          categoryId
          categoryNames {
            categoryName
            langCode
          }
          calculations {
            level
            max
            min
          }
          pharaoTexts {
            levelId
            consequence {
              langCode
              text
            }
            recommendation {
              langCode
              text
            }
          }
        }
        substances {
          id
          riskValue
        }
      }
      maxRiskbaseLevel {
        classification
        id
        levelClassification {
          classification
          langCode
        }
      }
    }
  }
`

export const FETCH_RENAL_BASE_ARTICLES = gql`
  query RenalBaseArticles(
    $filter: RenbaseArticleFilter
    $page: Int
    $pageSize: Int
  ) {
    renbaseArticles(filter: $filter, page: $page, pageSize: $pageSize) {
      items {
        classification
        drugFormGroupId
        drugFormGroupCode
        drugFormGroupDescription
        id
        nephrotoxic
        recommendations {
          classification
          classificationDescription
          dosageMod
          dosageModCode
          dosageModDescription
          dosageModMax
          doseInterval
          doseIntervalMax
          renalFailureDegreeDescription
          renalFailureDegreeId
        }
        references {
          body
          pmid
          sortKey
        }
        sections {
          body
          locale
          name
          sectionTypeId
        }
        substance {
          id
          substanceNames {
            default
            languageCode
            name
            substanceId
          }
          topParentId
        }
        substanceId
      }
      totalCount
    }
  }
`

export const FETCH_HEPAR_BASE_ARTICLES = gql`
  query HeparBaseArticles(
    $filter: HeparbaseArticleFilter
    $page: Int
    $pageSize: Int
  ) {
    heparbaseArticles(filter: $filter, page: $page, pageSize: $pageSize) {
      items {
        drugFormGroupId
        drugFormGroupCode
        drugFormGroupDescription
        hepatotoxic
        id
        recommendations {
          classification
          classificationDescription
          dosageMod
          dosageModCode
          dosageModDescription
          dosageModMax
          doseInterval
          doseIntervalMax
          hepaticImpairmentDegreeId
          hepaticImpairmentDegreeDescription
        }
        references {
          body
          pmid
          sortKey
        }
        sections {
          body
          locale
          name
          sectionTypeId
        }
        substance {
          substanceNames {
            default
            languageCode
            name
            substanceId
          }
        }
        substanceId
      }
      totalCount
    }
  }
`

export const FETCH_GRAV_BASE_ARTICLES = gql`
  query GravBaseArticles(
    $filter: GravbaseArticleFilter
    $page: Int
    $pageSize: Int
  ) {
    gravbaseArticles(filter: $filter, page: $page, pageSize: $pageSize) {
      items {
        classification
        classificationDescription
        drugFormGroupId
        drugFormGroupCode
        drugFormGroupDescription
        id
        references {
          body
          pmid
          sortKey
        }
        sections {
          body
          locale
          name
          sectionTypeId
        }
        substance {
          substanceNames {
            default
            languageCode
            name
            substanceId
          }
        }
        substanceId
      }
      totalCount
    }
  }
`

export const FETCH_LACT_BASE_ARTICLES = gql`
  query LactBaseArticles(
    $filter: LactbaseArticleFilter
    $page: Int
    $pageSize: Int
  ) {
    lactbaseArticles(filter: $filter, page: $page, pageSize: $pageSize) {
      items {
        classification
        classificationDescription
        drugFormGroupId
        drugFormGroupCode
        drugFormGroupDescription
        id
        references {
          body
          pmid
          sortKey
        }
        sections {
          body
          locale
          name
          sectionTypeId
        }
        substance {
          substanceNames {
            default
            languageCode
            name
            substanceId
          }
        }
        substanceId
      }
      totalCount
    }
  }
`

//
// Mutations
//

export const CREATE_ANNOUNCEMENT = gql`
  mutation CreateAnnouncement(
    $body: String
    $customId: String
    $file: String
    $items: [ItemInput!]
    $organisationId: String!
    $title: String!
    $type: AnnouncementTypeEnum!
    $userId: String!
  ) {
    createAnnouncement(
      body: $body
      customId: $customId
      file: $file
      items: $items
      organisationId: $organisationId
      title: $title
      type: $type
      userId: $userId
    ) {
      id
    }
  }
`

export const UPDATE_ANNOUNCEMENT = gql`
  mutation UpdateAnnouncement(
    $body: String
    $customId: String
    $file: String
    $id: Int!
    $items: [ItemInput!]
    $organisationId: String!
    $title: String!
    $type: AnnouncementTypeEnum!
    $userId: String!
  ) {
    updateAnnouncement(
      body: $body
      customId: $customId
      file: $file
      id: $id
      items: $items
      organisationId: $organisationId
      title: $title
      type: $type
      userId: $userId
    ) {
      id
    }
  }
`

export const CREATE_PHARMA_NOTIFICATION = gql`
  mutation CreatePharmaNotification(
    $userId: String!
    $organisationId: String!
    $isExternalProduct: Boolean
    $additionalProducts: [AdditionalProductInput!]
    $type: PharmaNotificationTypeEnum!
    $productId: Int!
    $productName: String!
    $productCountry: ProductCountryEnum!
    $validForAllPackSizes: Boolean
    $reporterTitle: String
    $reporterFirstname: String!
    $reporterLastname: String!
    $reporterJobDescription: String
    $reporterOrganisation: String!
    $reporterStreet: String
    $reporterZip: String!
    $reporterCity: String!
    $reporterPhone: String!
    $reporterFax: String
    $reporterEmail: String!
    $qdReportedTime: String
    $qdReportedAt: String
    $qdChargenCode: String
    $qdChargenSupplier: String
    $qdRecognizedBy: QdRecognizedByEnum
    $qdDescription: String
    $qdRelationOtherSideeffect: String
    $qdSuspicionDangerPublicHealth: Boolean
    $qdDigitalphotoViaMail: Boolean
    $qdOriginalViaPost: Boolean
    $qdDeliveryNotPossible: Boolean
    $qdExampleDescription: String
    $qdInformedSources: String
    $qdTakenActivities: String
    $qdFurtherPlannedActivities: String
    $qdAdditionalRelevantInfo: String
    $qdListSupplementsForNotification: String
    $sePatientInitials: String
    $sePatientIdentification: String
    $seAgeInYears: Int
    $seAgeInMonths: Int
    $seGender: SeGenderEnum
    $seDescription: String
    $seOccurredAt: String
    $seProcess: SeProcessEnum
    $seResult: SeResultEnum
    $seDosageUsage: String
    $seUsageFrom: String
    $seUsageUntil: String
    $seUsageReason: String
    $seUsageFurtherDiseases: String
    $seTreatmentSideEffect: String
    $seContextWithSuspiciousDrug: SeContextWithSuspiciousDrugEnum
    $seReporterData: String
    $seEditorData: String
    $samplesSent: Boolean
    $dataAccuracyConfirmed: Boolean
    $notificationTransmitted: Boolean
  ) {
    createPharmaNotification(
      userId: $userId
      organisationId: $organisationId
      isExternalProduct: $isExternalProduct
      additionalProducts: $additionalProducts
      type: $type
      productId: $productId
      productName: $productName
      productCountry: $productCountry
      validForAllPackSizes: $validForAllPackSizes
      reporterTitle: $reporterTitle
      reporterFirstname: $reporterFirstname
      reporterLastname: $reporterLastname
      reporterJobDescription: $reporterJobDescription
      reporterOrganisation: $reporterOrganisation
      reporterStreet: $reporterStreet
      reporterZip: $reporterZip
      reporterCity: $reporterCity
      reporterPhone: $reporterPhone
      reporterFax: $reporterFax
      reporterEmail: $reporterEmail
      qdReportedTime: $qdReportedTime
      qdReportedAt: $qdReportedAt
      qdChargenCode: $qdChargenCode
      qdChargenSupplier: $qdChargenSupplier
      qdRecognizedBy: $qdRecognizedBy
      qdDescription: $qdDescription
      qdRelationOtherSideeffect: $qdRelationOtherSideeffect
      qdSuspicionDangerPublicHealth: $qdSuspicionDangerPublicHealth
      qdDigitalphotoViaMail: $qdDigitalphotoViaMail
      qdOriginalViaPost: $qdOriginalViaPost
      qdDeliveryNotPossible: $qdDeliveryNotPossible
      qdExampleDescription: $qdExampleDescription
      qdInformedSources: $qdInformedSources
      qdTakenActivities: $qdTakenActivities
      qdFurtherPlannedActivities: $qdFurtherPlannedActivities
      qdAdditionalRelevantInfo: $qdAdditionalRelevantInfo
      qdListSupplementsForNotification: $qdListSupplementsForNotification
      sePatientInitials: $sePatientInitials
      sePatientIdentification: $sePatientIdentification
      seAgeInYears: $seAgeInYears
      seAgeInMonths: $seAgeInMonths
      seGender: $seGender
      seDescription: $seDescription
      seOccurredAt: $seOccurredAt
      seProcess: $seProcess
      seResult: $seResult
      seDosageUsage: $seDosageUsage
      seUsageFrom: $seUsageFrom
      seUsageUntil: $seUsageUntil
      seUsageReason: $seUsageReason
      seUsageFurtherDiseases: $seUsageFurtherDiseases
      seTreatmentSideEffect: $seTreatmentSideEffect
      seContextWithSuspiciousDrug: $seContextWithSuspiciousDrug
      seReporterData: $seReporterData
      seEditorData: $seEditorData
      samplesSent: $samplesSent
      dataAccuracyConfirmed: $dataAccuracyConfirmed
      notificationTransmitted: $notificationTransmitted
    ) {
      id
    }
  }
`

export const UPDATE_PHARMA_NOTIFICATION = gql`
  mutation UpdatePharmaNotification(
    $id: Int!
    $additionalProducts: [AdditionalProductInput!]
    $type: PharmaNotificationTypeEnum
    $validForAllPackSizes: Boolean
    $reporterTitle: String
    $reporterFirstname: String
    $reporterLastname: String
    $reporterJobDescription: String
    $reporterOrganisation: String
    $reporterStreet: String
    $reporterZip: String
    $reporterCity: String
    $reporterPhone: String
    $reporterFax: String
    $reporterEmail: String
    $qdReportedTime: String
    $qdReportedAt: String
    $qdChargenCode: String
    $qdChargenSupplier: String
    $qdRecognizedBy: QdRecognizedByEnum
    $qdDescription: String
    $qdRelationOtherSideeffect: String
    $qdSuspicionDangerPublicHealth: Boolean
    $qdDigitalphotoViaMail: Boolean
    $qdOriginalViaPost: Boolean
    $qdDeliveryNotPossible: Boolean
    $qdExampleDescription: String
    $qdInformedSources: String
    $qdTakenActivities: String
    $qdFurtherPlannedActivities: String
    $qdAdditionalRelevantInfo: String
    $qdListSupplementsForNotification: String
    $sePatientInitials: String
    $sePatientIdentification: String
    $seAgeInYears: Int
    $seAgeInMonths: Int
    $seGender: SeGenderEnum
    $seDescription: String
    $seOccurredAt: String
    $seProcess: SeProcessEnum
    $seResult: SeResultEnum
    $seDosageUsage: String
    $seUsageFrom: String
    $seUsageUntil: String
    $seUsageReason: String
    $seUsageFurtherDiseases: String
    $seTreatmentSideEffect: String
    $seContextWithSuspiciousDrug: SeContextWithSuspiciousDrugEnum
    $seReporterData: String
    $seEditorData: String
    $samplesSent: Boolean
    $dataAccuracyConfirmed: Boolean
    $notificationTransmitted: Boolean
  ) {
    updatePharmaNotification(
      id: $id
      additionalProducts: $additionalProducts
      type: $type
      validForAllPackSizes: $validForAllPackSizes
      reporterTitle: $reporterTitle
      reporterFirstname: $reporterFirstname
      reporterLastname: $reporterLastname
      reporterJobDescription: $reporterJobDescription
      reporterOrganisation: $reporterOrganisation
      reporterStreet: $reporterStreet
      reporterZip: $reporterZip
      reporterCity: $reporterCity
      reporterPhone: $reporterPhone
      reporterFax: $reporterFax
      reporterEmail: $reporterEmail
      qdReportedTime: $qdReportedTime
      qdReportedAt: $qdReportedAt
      qdChargenCode: $qdChargenCode
      qdChargenSupplier: $qdChargenSupplier
      qdRecognizedBy: $qdRecognizedBy
      qdDescription: $qdDescription
      qdRelationOtherSideeffect: $qdRelationOtherSideeffect
      qdSuspicionDangerPublicHealth: $qdSuspicionDangerPublicHealth
      qdDigitalphotoViaMail: $qdDigitalphotoViaMail
      qdOriginalViaPost: $qdOriginalViaPost
      qdDeliveryNotPossible: $qdDeliveryNotPossible
      qdExampleDescription: $qdExampleDescription
      qdInformedSources: $qdInformedSources
      qdTakenActivities: $qdTakenActivities
      qdFurtherPlannedActivities: $qdFurtherPlannedActivities
      qdAdditionalRelevantInfo: $qdAdditionalRelevantInfo
      qdListSupplementsForNotification: $qdListSupplementsForNotification
      sePatientInitials: $sePatientInitials
      sePatientIdentification: $sePatientIdentification
      seAgeInYears: $seAgeInYears
      seAgeInMonths: $seAgeInMonths
      seGender: $seGender
      seDescription: $seDescription
      seOccurredAt: $seOccurredAt
      seProcess: $seProcess
      seResult: $seResult
      seDosageUsage: $seDosageUsage
      seUsageFrom: $seUsageFrom
      seUsageUntil: $seUsageUntil
      seUsageReason: $seUsageReason
      seUsageFurtherDiseases: $seUsageFurtherDiseases
      seTreatmentSideEffect: $seTreatmentSideEffect
      seContextWithSuspiciousDrug: $seContextWithSuspiciousDrug
      seReporterData: $seReporterData
      seEditorData: $seEditorData
      samplesSent: $samplesSent
      dataAccuracyConfirmed: $dataAccuracyConfirmed
      notificationTransmitted: $notificationTransmitted
    ) {
      id
    }
  }
`

import { useRef, useState } from 'react'
import { Popover, Overlay } from 'react-bootstrap'
import { Link, NavLink, useLocation } from 'react-router-dom'
import type { Location } from 'react-router-dom'
import classNames from 'classnames'
import { routeConstants } from '_constants'
import logo from './img/diagnosia-logo.svg'
import { ReactComponent as MedicationIcon } from '_components/Common/img/indication.svg'
import { ReactComponent as AtcIcon } from '_components/Common/img/atc.svg'
import { ReactComponent as InteractionIcon } from '_components/Common/img/interaction.svg'
import { ReactComponent as IndexIcon } from '_components/Common/img/icon-search.svg'
import { ReactComponent as HistoryIcon } from '_components/Common/img/search-history.svg'
import { ReactComponent as BookmarkIcon } from '_components/Common/img/star-inactive.svg'
import { ReactComponent as WarningIcon } from '_components/Common/img/pharma-warning.svg'
import { utils, userHelper as uh } from '_helpers'
import { clearSearch } from '_slices/search.slice'
import { EPermissions } from '_types'
import { useWebMedCountry } from '_hooks'
import { TUserManagementCountryEnum } from '_generated/plexus.graphql'
import { useAppDispatch, useAppSelector } from '_hooks/store'
import BookmarksQuickViewContainer from '_components/Bookmarks/BookmarksQuickViewContainer'
import HistoryQuickViewContainer from '_components/History/HistoryQuickViewContainer'
import TrialVersionInfo from '_components/Header/TrialVersionInfo'
import UserDropdown from '_components/Header/UserDropdown'
import ProfilePicture from '_components/Profile/ProfilePicture'

const popperConfig = {
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, 16],
      },
    },
  ],
}

export default function Header() {
  const dispatch = useAppDispatch()
  const webMedCountry = useWebMedCountry()
  const user = useAppSelector((state) => state.authentication.user)
  const isAuthenticated = useAppSelector(
    (state) => state.authentication.isAuthenticated,
  )

  const location = useLocation()
  const [showBookmarksDropdown, setShowBookmarksDropdown] = useState(false)
  const bookmarkTarget = useRef(null)
  const bookmarkRef = useRef(null)

  const [showHistoryDropdown, setShowHistoryDropdown] = useState(false)
  const historyTarget = useRef(null)
  const historyRef = useRef(null)

  const [showUserDropdown, setShowUserDropdown] = useState(false)
  const userTarget = useRef(null)
  const userRef = useRef(null)

  const country = user?.profile?.country ?? TUserManagementCountryEnum.At
  const firstName = user?.profile?.firstName ?? 'Einstellungen'
  const lastName = user?.profile?.lastName ?? ''
  const email = user?.email
  const organisationName = user?.organisation?.name
  const webMedCountryName = webMedCountry
    ? utils.countryFromISO(webMedCountry as string)
    : undefined

  // Do not show header if:
  // - user is not logged in
  // - user is in onboarding process
  if (
    !isAuthenticated ||
    location.pathname.startsWith('/activate') ||
    location.pathname.startsWith('/password-reset') ||
    location.pathname.startsWith('/password-recovery') ||
    location.pathname.startsWith('/verification') ||
    location.pathname.startsWith('/resend-activation')
  ) {
    return null
  }

  function isIndexActive(location: Location) {
    // Index link should also be active, when on product details site
    if (
      location.pathname &&
      (location.pathname.startsWith('/product') ||
        location.pathname.startsWith('/substance') ||
        location.pathname.startsWith('/atc/') ||
        location.pathname.startsWith('/icd') ||
        location.pathname === '/')
    ) {
      return true
    }

    return false
  }

  function isAnnouncementsActive(location: Location) {
    if (
      location.pathname &&
      (location.pathname.startsWith('/announcement') ||
        location.pathname.startsWith('/pharma-notification'))
    ) {
      return true
    }

    return false
  }

  const historyDropdownBtnClass = classNames({
    'me-3': true,
    'header-nav-btn': true,
    active: showHistoryDropdown,
  })

  const bookmarksDropdownBtnClass = classNames({
    'me-4': true,
    'header-nav-btn': true,
    active: showBookmarksDropdown,
  })

  const userDropdownBtnClass = classNames({
    'me-0': true,
    'header-nav-btn': true,
    active: showUserDropdown,
  })

  const dropdownPlacement = 'bottom'

  const showTrial = uh.showTrial(user)
  const showProfileLink = uh.showProfileLink(user)
  const canUseInteractions = uh.isAuthorized(user?.permissionSet, [
    EPermissions.CanUseInteractions,
  ])
  const canViewAnnouncements = uh.isAuthorized(
    user?.permissionSet,
    [EPermissions.CanViewAnnouncements, EPermissions.CanViewPharmacovigilence],
    'some',
  )

  const isEnterpriseUser = uh.isEnterpriseUser(user)

  const navBtnTextClass = classNames({
    // 'ms-2': true,
    'd-none': true,
    'd-xl-inline-block': showTrial,
    'd-md-inline-block': !showTrial,
  })

  return (
    <header className="boxs-inset-4-light">
      <div className="container-xxl d-flex">
        <div className="d-none d-lg-flex align-items-center me-4">
          <Link
            to={routeConstants.INDEX_PAGE}
            onClick={() => dispatch(clearSearch())}
          >
            <img src={logo} alt="Diagnosia Logo" className="header-logo" />
          </Link>
        </div>
        <nav className="d-flex flex-grow-1 justify-content-between">
          <ul className="header-nav d-flex flex-nowrap no-style">
            <li className="d-flex">
              <NavLink
                to={routeConstants.INDEX_PAGE}
                className={({ isActive }) =>
                  `header-nav-link ${
                    isActive || isIndexActive(location)
                      ? 'active svg--web-blue-light'
                      : ''
                  }`
                }
                end
              >
                {/*<IndexIcon width={24} height={24} />*/}
                <span className={navBtnTextClass}>Home</span>
              </NavLink>
            </li>
            {canUseInteractions && (
              <li className="ms-4 d-flex">
                <NavLink
                  to={routeConstants.INTERACTIONS_PAGE}
                  className={({ isActive }) =>
                    `header-nav-link ${
                      isActive ? 'active svg--web-purple' : ''
                    }`
                  }
                  end
                >
                  <InteractionIcon width={24} height={24} />
                  <span className={navBtnTextClass}>Sicherheits-Check</span>
                </NavLink>
              </li>
            )}
            {isEnterpriseUser && (
              <li className="ms-4 d-flex">
                <NavLink
                  to={routeConstants.MEDICATION_PAGE}
                  className={({ isActive }) =>
                    `header-nav-link ${
                      isActive ? 'active svg--web-blue-light' : ''
                    }`
                  }
                  end
                >
                  <MedicationIcon />
                  <span className={navBtnTextClass}>Medikation</span>
                </NavLink>
              </li>
            )}
            {/*<li className="ms-4 d-flex">
              <NavLink
                to={routeConstants.ATC_TREE}
                className={({ isActive }) =>
                  `header-nav-link ${
                    isActive ? 'active svg--web-blue-light' : ''
                  }`
                }
                end
              >
                <AtcIcon width={24} height={24} />
                <span className={navBtnTextClass}>ATC Baum</span>
              </NavLink>
            </li>*/}
          </ul>
          {canViewAnnouncements && (
            <>
              <div className="d-flex">
                <NavLink
                  className={({ isActive }) =>
                    `nav-link index me-4 ${isActive ? 'active' : ''}`
                  }
                  title="Zur Meldungen Übersicht"
                  to={routeConstants.ANNOUNCEMENTS_PAGE}
                  end
                >
                  <WarningIcon />
                </NavLink>
              </div>
              <div style={{ width: '2px' }} className="bg-blue-grey-20 me-4">
                &nbsp;
              </div>
            </>
          )}
        </nav>
        {showTrial && (
          <div className="d-none d-md-flex align-items-center px-3">
            <TrialVersionInfo user={user!} />
          </div>
        )}
        <div className="d-flex align-items-center position-relative">
          {/*<div ref={historyRef}>
            <button
              aria-label="Verlauf"
              className={historyDropdownBtnClass}
              onClick={(event) => {
                event.stopPropagation()
                setShowHistoryDropdown(!showHistoryDropdown)
                setShowBookmarksDropdown(false)
                setShowUserDropdown(false)
              }}
              title="Verlauf anzeigen"
              type="button"
              ref={historyTarget}
            >
              <HistoryIcon width={24} height={24} />
              {showTrial ? null : (
                <span className="ms-2 d-none d-md-block">Verlauf</span>
              )}
            </button>
            <Overlay
              show={showHistoryDropdown}
              target={historyTarget.current!}
              placement={dropdownPlacement}
              container={historyRef.current!}
              rootClose={true}
              onHide={() => setShowHistoryDropdown(false)}
              transition={false}
              popperConfig={popperConfig}
            >
              <Popover
                id={`popover-positioned-${dropdownPlacement}`}
                className="header-dropdown-container fixed-width-340"
              >
                <Popover.Body>
                  <HistoryQuickViewContainer
                    handleExit={() => setShowHistoryDropdown(false)}
                  />
                </Popover.Body>
              </Popover>
            </Overlay>
          </div>
          <div ref={bookmarkRef}>
            <button
              aria-label="Favoriten"
              className={bookmarksDropdownBtnClass}
              onClick={(event) => {
                event.stopPropagation()
                setShowBookmarksDropdown(!showBookmarksDropdown)
                setShowHistoryDropdown(false)
                setShowUserDropdown(false)
              }}
              title="Favoriten anzeigen"
              type="button"
              ref={bookmarkTarget}
            >
              <BookmarkIcon width={24} height={24} />
              {showTrial ? null : (
                <span className="ms-2 d-none d-md-block">Favoriten</span>
              )}
            </button>
            <Overlay
              show={showBookmarksDropdown}
              target={bookmarkTarget.current!}
              placement={dropdownPlacement}
              container={bookmarkRef.current!}
              rootClose={true}
              onHide={() => setShowBookmarksDropdown(false)}
              transition={false}
              popperConfig={popperConfig}
            >
              <Popover
                id={`popover-positioned-${dropdownPlacement}`}
                className="header-dropdown-container fixed-width-340"
              >
                <Popover.Body>
                  <BookmarksQuickViewContainer
                    handleExit={() => setShowBookmarksDropdown(false)}
                  />
                </Popover.Body>
              </Popover>
            </Overlay>
          </div>*/}
          <div style={{ width: '2px' }} className="bg-blue-grey-20 me-4">
            &nbsp;
          </div>
          <div ref={userRef}>
            <button
              aria-label="Einstellungen"
              className={userDropdownBtnClass}
              id="user-dropdown"
              onClick={(event) => {
                event.stopPropagation()
                setShowUserDropdown(!showUserDropdown)
                setShowBookmarksDropdown(false)
                setShowHistoryDropdown(false)
              }}
              ref={userTarget}
              title="User Menü anzeigen"
              type="button"
            >
              <ProfilePicture width={24} height={24} />
              <span className="d-none d-xl-block ms-2">
                {firstName} {lastName}
              </span>
            </button>
            <Overlay
              show={showUserDropdown}
              target={userTarget.current!}
              placement={dropdownPlacement}
              container={userRef.current!}
              rootClose={true}
              onHide={() => setShowUserDropdown(false)}
              transition={false}
              popperConfig={popperConfig}
            >
              <Popover
                id={`popover-positioned-${dropdownPlacement}`}
                className="header-dropdown-container arrow-white fixed-width-500"
              >
                <Popover.Body>
                  <UserDropdown
                    country={country}
                    email={email}
                    handleExit={() => setShowUserDropdown(false)}
                    name={`${firstName} ${lastName}`}
                    organisationName={organisationName}
                    showProfileLink={showProfileLink}
                    webMedCountryName={webMedCountryName}
                  />
                </Popover.Body>
              </Popover>
            </Overlay>
          </div>
        </div>
      </div>
    </header>
  )
}

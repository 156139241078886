import DefaultBtn from '_components/Common/Buttons/DefaultBtn'
import { ReactComponent as LinkIcon } from '_components/Common/img/light-bulb.svg'

type TProps = {
  buttonText: string
  webinarDescription: string
  link: string
}

export default function WebinarItemIdle({
  buttonText,
  webinarDescription,
  link,
}: TProps) {
  return (
    <div className="session-idle-item">
      <LinkIcon className="flex-shrink-0" />
      <div className="color-blue-grey-80 px-0 px-md-3 my-2 my-md-0">
        {webinarDescription}
      </div>
      <DefaultBtn buttonText={buttonText} link={link} />
    </div>
  )
}

import isEqual from 'lodash/isEqual'
import {
  TUserManagementGenderEnum,
  TUserManagementOccupationEnum,
  TUserManagementVerificationProcessStateEnum as VerificationState,
  TUserManagementWorkplaceEnum,
  TUserManagementSpecialityEnum,
  TUserManagementFieldOfStudyEnum,
  TUserManagementVerificationNumberTypeEnum as VerificationNumberType,
  TUserManagementWebMedCountryEnum,
  TUserManagementCountryEnum,
  TUserManagementUser,
} from '_generated/plexus.graphql'
import * as yup from 'yup'
import {
  isValidOeaekNumber,
  isValidEfnNumber,
} from './verificationNumber.helper'

const emailValidation = yup
  .string()
  .required('Bitte gib deine E-Mail Adresse an.')
  .email('Die E-Mail Adresse ist ungültig.')

const passwordValidation = yup
  .string()
  .required('Bitte gib ein Passwort mit min 8 Zeichen ein.')
  .min(8, 'Bitte gib ein Passwort mit min 8 Zeichen ein.')

export const registrationValidationSchema = yup.object({
  email: emailValidation,
  password: passwordValidation,
  occupation: yup
    .mixed()
    .notOneOf(
      [TUserManagementOccupationEnum.Undefined],
      'Bitte gib deinen Fachkreis an.',
    ),
})

export const loginValidationSchema = yup.object({
  email: emailValidation,
  password: yup
    .string()
    .required('Bitte gib ein Passwort mit min 8 Zeichen ein.'),
})

export const passwordRecoveryValidationSchema = yup.object({
  email: emailValidation,
})

export const passwordResetValidationSchema = yup.object({
  password: passwordValidation,
})

export enum EOnboardingStep {
  undetermined,
  choose_med_country,
  name_sex,
  workplace,
  verify_profession,
  study_field,
  speciality,
  verification_pending,
  complete,
}

export type TOnboardingState = {
  nextOnboardingStep: EOnboardingStep
  hasOnboardingFinished: boolean
  trialDuration: number
  forceUserToFinishVerification: boolean
}

export function fullOnboardingState(
  user: TUserManagementUser | undefined | null,
): TOnboardingState {
  const nextOnboardingStep = calculateOnboardingSteps(user)

  // user.profile.hasOnboardingFinished defines that
  // 1) all forms have been filled out
  // 2) the email address was verified
  // 3) the user was verified as a professionell by either submitting a photo of his ID or the number directly
  const hasOnboardingFinished = user?.profile?.hasOnboardingFinished || false
  const trialDuration = user?.profile?.trialDuration || 0 // tage, -1 schon verified, 0 wenn abgelaufen, 10 max
  const forceUserToFinishVerification =
    !hasOnboardingFinished && trialDuration < 1

  return {
    nextOnboardingStep,
    hasOnboardingFinished,
    trialDuration,
    forceUserToFinishVerification,
  }
}

export function calculateOnboardingSteps(
  user: TUserManagementUser | undefined | null,
): EOnboardingStep {
  if (user === undefined || user === null) return EOnboardingStep.undetermined

  if (
    user.profile?.webMedCountry === null ||
    user.profile?.webMedCountry === TUserManagementWebMedCountryEnum.Undefined
  )
    return EOnboardingStep.choose_med_country

  if (
    user.profile?.firstName === null ||
    user.profile?.firstName?.length === 0 ||
    user.profile?.lastName === null ||
    user.profile?.lastName?.length === 0 ||
    user.profile?.gender === null ||
    user.profile?.gender === TUserManagementGenderEnum.Undefined
  )
    return EOnboardingStep.name_sex

  if (
    user.profile?.verifiedProfessional === false &&
    user.verificationProcess?.state !== VerificationState.Pending
  )
    return EOnboardingStep.verify_profession

  if (
    user.profile?.occupation !== TUserManagementOccupationEnum.Student &&
    user.profile?.occupation !== TUserManagementOccupationEnum.Patient &&
    user.profile?.occupation !== TUserManagementOccupationEnum.Other &&
    user.profile?.occupation !== TUserManagementOccupationEnum.Undefined &&
    (user.profile?.workplace === null ||
      user.profile?.workplace === TUserManagementWorkplaceEnum.Undefined ||
      user.profile?.zip === null ||
      user.profile?.country === null ||
      user.profile?.country === TUserManagementCountryEnum.Undefined)
  )
    return EOnboardingStep.workplace

  if (
    user.profile?.occupation === TUserManagementOccupationEnum.Doctor &&
    (user.profile?.specialities?.length === 0 ||
      isEqual(user.profile.specialities, [
        TUserManagementSpecialityEnum.Undefined,
      ]))
  )
    return EOnboardingStep.speciality

  if (
    user.profile?.occupation === TUserManagementOccupationEnum.Student &&
    (user.profile.fieldsOfStudy?.length === 0 ||
      isEqual(user.profile.fieldsOfStudy, [
        TUserManagementFieldOfStudyEnum.Undefined,
      ]) ||
      user.profile.graduationYear === null ||
      user.profile.country === null ||
      user.profile.country === TUserManagementCountryEnum.Undefined)
  )
    return EOnboardingStep.study_field

  if (
    user.emailConfirmed === false ||
    user.verificationProcess?.state === VerificationState.Pending
  )
    return EOnboardingStep.verification_pending

  if (
    user.emailConfirmed === true &&
    user.verificationProcess?.state === VerificationState.Accepted
  )
    return EOnboardingStep.complete

  return EOnboardingStep.undetermined
}

export type TOnboardingValidateProfession = {
  files: File[] | undefined
  verificationNumberType: string
  verificationNumber: string
}

export type TOnboardingValidateProfessionError = {
  files?: string
  verificationNumberType?: string
  verificationNumber?: string
}

export function validateProfessionForm(
  values: TOnboardingValidateProfession,
  isDoctor: boolean,
) {
  const errors: TOnboardingValidateProfessionError = {}

  if (isDoctor) {
    let hasImageFiles = values?.files && values.files.length > 0
    let hasVerificationNumber = values?.verificationNumber.trim().length > 0

    if (!hasImageFiles || hasVerificationNumber) {
      if (
        !values.verificationNumberType ||
        values?.verificationNumberType === VerificationNumberType.Undefined
      ) {
        errors.verificationNumberType = 'Bitte gib deinen Ausweistyp an.'
      }

      if (!values.verificationNumber) {
        errors.verificationNumber =
          'Bitte gib deine Ausweisnummer an, oder lade ein Foto deines Ausweises hoch.'
      } else if (
        (values?.verificationNumberType === VerificationNumberType.Oeaek &&
          !isValidOeaekNumber(values.verificationNumber)) ||
        (values?.verificationNumberType === VerificationNumberType.Efn &&
          !isValidEfnNumber(values.verificationNumber))
      ) {
        errors.verificationNumber = 'Bitte gib eine korrekte Ausweisnummer an.'
      }
    }
  } else {
    if (!values.files || values.files.length === 0) {
      errors.files = 'Bitte füge ein Foto hinzu!'
    }
  }

  return errors
}

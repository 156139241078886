import { Formik } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import {
  TUserManagementConfigQuery,
  TUserManagementWorkplaceEnum,
  TUserManagementCountryEnum,
  TUserManagementWebMedCountryEnum,
  TUserManagementUser,
} from '_generated/plexus.graphql'
import { Form, Button, ToggleButtonGroup, ToggleButton } from 'react-bootstrap'
import Select from 'react-select'
import {
  TPlexusUserManagementConfigFilteredItem,
  getPlexusConfig,
} from '_helpers/plexus.helper'

type TProps = {
  userManagementConfigLoading: boolean
  userManagementConfigData?: TUserManagementConfigQuery
  user?: TUserManagementUser
  prepareUpdateUserProfile: (values: any) => void
  isUserProfileUpdating: boolean
}

export function DoctorWorkplaceDialog({
  userManagementConfigLoading,
  userManagementConfigData,
  user,
  prepareUpdateUserProfile,
  isUserProfileUpdating,
}: TProps) {
  let initialWebMedCountry =
    (user?.profile?.webMedCountry as TUserManagementWebMedCountryEnum) ||
    TUserManagementWebMedCountryEnum.Undefined

  let initialCountry =
    (user?.profile?.country as TUserManagementCountryEnum) ||
    TUserManagementCountryEnum.Undefined

  if (initialCountry === TUserManagementCountryEnum.Undefined) {
    switch (initialWebMedCountry) {
      default:
      case TUserManagementWebMedCountryEnum.At:
        initialCountry = TUserManagementCountryEnum.At
        break

      case TUserManagementWebMedCountryEnum.De:
        initialCountry = TUserManagementCountryEnum.De
        break
    }
  }

  let workplaceOptions = Array<TPlexusUserManagementConfigFilteredItem>()
  if (userManagementConfigData?.userManagementConfig?.workplaces?.length) {
    workplaceOptions = getPlexusConfig(
      userManagementConfigData.userManagementConfig?.workplaces,
    )
  }

  let countryOptions = Array<TPlexusUserManagementConfigFilteredItem>()
  if (userManagementConfigData?.userManagementConfig?.countries?.length) {
    countryOptions = getPlexusConfig(
      userManagementConfigData.userManagementConfig?.countries,
    )
  }

  return (
    <Formik
      initialValues={{
        country: initialCountry,
        zip: user?.profile?.zip || '',
        workplace:
          user?.profile?.workplace || TUserManagementWorkplaceEnum.Undefined,
      }}
      validate={(values) => {
        const errors: {
          country?: string
          zip?: string
          workplace?: string
        } = {}

        if (
          !values.workplace ||
          values?.workplace === TUserManagementWorkplaceEnum.Undefined
        ) {
          errors.workplace = 'Bitte gib deinen Arbeitsort an.'
        }

        if (!values.country) {
          errors.country = 'Bitte wähle ein Land aus.'
        }

        if (!values.zip || values?.zip.length === 0) {
          errors.zip = 'Bitte gib die Postleitzahl deines Arbeitsortes an.'
        } else if (
          (values?.country === TUserManagementCountryEnum.At &&
            values?.zip.length !== 4) ||
          (values?.country === TUserManagementCountryEnum.De &&
            values?.zip.length !== 5)
        ) {
          errors.zip = 'Bitte gib eine korrekte Postleitzahl an.'
        }

        return errors
      }}
      onSubmit={(values) => {
        prepareUpdateUserProfile(values)
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
      }) => (
        <Form onSubmit={handleSubmit} className="mt-4" aria-label="Arbeitsort">
          <Form.Group controlId="workplace" className="mx-sm-3 my-5">
            <ToggleButtonGroup
              aria-label="Arbeitsort Auswahl"
              vertical
              type="radio"
              name="workplace"
              className={
                !!errors.workplace && !!touched.workplace
                  ? 'w-100 is-invalid'
                  : 'w-100'
              }
              value={values.workplace}
            >
              {workplaceOptions.map((item, key) => (
                <ToggleButton
                  value={item.value}
                  disabled={
                    isUserProfileUpdating || userManagementConfigLoading
                  }
                  id={`workplace-${item.value}`}
                  key={key}
                  variant="secondary"
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  {item.label}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
            <Form.Control.Feedback
              type="invalid"
              className="text-end"
              role="alert"
              aria-label="feedback-workplace"
            >
              {errors.workplace}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group
            data-testid="country"
            controlId="country"
            className="mx-sm-3"
          >
            <Select
              aria-label="Wähle das Land deines Arbeitsort aus"
              className={
                !!errors.country && !!touched.country
                  ? 'select-wrapper is-invalid'
                  : 'select-wrapper'
              }
              isDisabled={isUserProfileUpdating || userManagementConfigLoading}
              classNamePrefix="select"
              placeholder="Land"
              name="country"
              options={countryOptions}
              isLoading={userManagementConfigLoading}
              onBlur={() => {
                setFieldTouched('country', true)
              }}
              value={countryOptions.find((o) => o.value === values.country)}
              onChange={(value, actionMeta) => {
                let v = value as TPlexusUserManagementConfigFilteredItem
                if (actionMeta.name && v) {
                  setFieldValue(actionMeta.name, v.value)
                }
              }}
            />
            <Form.Control.Feedback
              type="invalid"
              className="text-end"
              role="alert"
              aria-label="feedback-country"
            >
              {errors.country}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="zip" className="mx-sm-3">
            <Form.Label visuallyHidden={true}>PLZ der Arbeitsstätte</Form.Label>
            <Form.Control
              className="onboarding"
              disabled={isUserProfileUpdating || userManagementConfigLoading}
              isInvalid={!!errors.zip && !!touched.zip}
              name="zip"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="PLZ der Arbeitsstätte"
              type="text"
              value={values.zip}
            />
            <Form.Control.Feedback
              type="invalid"
              className="text-end"
              role="alert"
              aria-label="feedback-zip"
            >
              {errors.zip}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-4 mt-5 mx-sm-3">
            <Button
              aria-label="Weiter"
              className="w-100 btn-green"
              variant="primary"
              type="submit"
              disabled={isUserProfileUpdating || userManagementConfigLoading}
            >
              {isUserProfileUpdating ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                'Weiter'
              )}
            </Button>
          </Form.Group>
        </Form>
      )}
    </Formik>
  )
}

import { Formik } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import {
  TUserManagementConfigQuery,
  TUserManagementSpecialityEnum,
  TUserManagementUser,
} from '_generated/plexus.graphql'
import { Form, Button } from 'react-bootstrap'

import Select from 'react-select'
import {
  TPlexusUserManagementConfigFilteredItem,
  getPlexusConfig,
} from '_helpers/plexus.helper'
import isEqual from 'lodash/isEqual'

type TProps = {
  userManagementConfigLoading: boolean
  userManagementConfigData?: TUserManagementConfigQuery
  user?: TUserManagementUser
  prepareUpdateUserProfile: (values: any) => void
  isUserProfileUpdating: boolean
}

export function DoctorSpecialityDialog({
  userManagementConfigLoading,
  userManagementConfigData,
  user,
  prepareUpdateUserProfile,
  isUserProfileUpdating,
}: TProps) {
  let specialitiesOptions = Array<TPlexusUserManagementConfigFilteredItem>()
  if (userManagementConfigData?.userManagementConfig?.specialities?.length) {
    specialitiesOptions = getPlexusConfig(
      userManagementConfigData.userManagementConfig?.specialities,
    )
  }

  return (
    <Formik
      initialValues={{
        specialities:
          (user?.profile?.specialities as TUserManagementSpecialityEnum[]) ||
          [],
      }}
      validate={(values) => {
        const errors: {
          specialities?: string
        } = {}

        if (
          !values.specialities ||
          values?.specialities.length === 0 ||
          isEqual(values?.specialities, [
            TUserManagementSpecialityEnum.Undefined,
          ])
        ) {
          errors.specialities = 'Bitte gib mindestens eine Fachrichtung an.'
        }

        if (values?.specialities.length > 3) {
          errors.specialities = 'Bitte gib maximal drei Fachrichtungen an.'
        }

        return errors
      }}
      onSubmit={(values) => {
        prepareUpdateUserProfile(values)
      }}
    >
      {({
        setFieldValue,
        setFieldTouched,
        values,
        errors,
        touched,
        handleSubmit,
      }) => (
        <Form
          aria-label="Fachrichtung"
          className="mt-4"
          onSubmit={handleSubmit}
        >
          <Form.Group
            data-testid="specialities"
            controlId="specialities"
            className="mx-sm-3 "
          >
            <Select
              className={
                !!errors.specialities && !!touched.specialities
                  ? 'select-wrapper is-invalid'
                  : 'select-wrapper'
              }
              isDisabled={isUserProfileUpdating || userManagementConfigLoading}
              classNamePrefix="select"
              placeholder="Fachrichtung suchen"
              name="specialities"
              menuIsOpen={true}
              isMulti={true}
              isSearchable={true}
              menuPlacement="bottom"
              styles={{
                menu: (provided) => ({
                  ...provided,
                  position: 'relative',
                  boxShadow: 'none',
                }),
                dropdownIndicator: (provided) => ({
                  ...provided,
                  display: 'none',
                }),
              }}
              options={specialitiesOptions}
              isLoading={userManagementConfigLoading}
              onBlur={() => {
                setFieldTouched('specialities', true)
              }}
              value={values.specialities
                .map((speciality) =>
                  specialitiesOptions.find((o) => o.value === speciality),
                )
                .filter(Boolean)}
              onChange={(selectedValues) => {
                if (!selectedValues) {
                  selectedValues = []
                }

                let items =
                  selectedValues as TPlexusUserManagementConfigFilteredItem[]
                let values = items.map((o) => o.value)
                if (values) {
                  setFieldValue('specialities', values)
                }
              }}
            />
            <Form.Control.Feedback
              type="invalid"
              className="text-end"
              role="alert"
              aria-label="feedback-speciality"
            >
              {errors.specialities}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-4 mt-5 mx-sm-3">
            <Button
              aria-label="Weiter"
              className="w-100 btn-green"
              variant="primary"
              type="submit"
              disabled={
                isUserProfileUpdating ||
                userManagementConfigLoading ||
                values?.specialities.length > 3
              }
            >
              {isUserProfileUpdating ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                'Weiter'
              )}
            </Button>
          </Form.Group>
        </Form>
      )}
    </Formik>
  )
}

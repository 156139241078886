import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
  ErrorPolicy,
  split,
  fromPromise,
} from '@apollo/client'
import { createLink } from 'apollo-absinthe-upload-link'
import { RetryLink } from '@apollo/client/link/retry'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import * as AbsintheSocket from '@absinthe/socket'
import { createAbsintheSocketLink } from 'pluralsh-absinthe-socket-apollo-link'
import { Socket as PhoenixSocket } from 'phoenix'
import * as Sentry from '@sentry/react'
import { userService } from '_services'
import { configHelper } from './config.helper'
import { EAppConstantsKeys } from '_types'
import { errorConstants } from '_constants/error.constants'
import { store } from './store.helper'
import { logoutRequest } from '_slices/authentication.slice'
import { getMainDefinition } from '@apollo/client/utilities'
import { Cookie } from './cookie.helper'
import { errorAlert } from '_slices/alert.slice'
import { LocalStorage } from './localStorage.helper'

// To prevent concurrent refresh token requests we use
// this flag to check if a request is ongoing.
let _currentlyFetchingTokens = false

const whiteListErrorCodes = [
  errorConstants.USER_PASSWORD_DOES_NOT_MATCH,
  errorConstants.USER_EMAIL_CONFIRMATION_TOKEN_NOT_FOUND,
  errorConstants.USER_EMAIL_CONFIRMED_ALREADY_CONFIRMED,
  errorConstants.USER_EMAIL_NOT_UNIQUE,
  errorConstants.USER_EMAIL_NOT_FOUND,
  errorConstants.USER_NOT_FOUND,
]

const setAuthorizationLink = setContext(async (request, previousContext) => {
  let access = Cookie.get('plexus_access_token')
  const refresh = Cookie.get('plexus_refresh_token')
  const expiresAt = LocalStorage.get('expiresAt')

  // First check if the access token is expired. If so,
  // refetch new tokens and proceed. Need seconds so
  // devide by 1000. (Should not happen on the
  // refresh request)
  const now = Math.floor(Date.now() / 1000)

  if (
    expiresAt &&
    refresh &&
    now > parseInt(expiresAt) &&
    request.operationName !== 'FetchRefreshToken'
  ) {
    const { session } = await userService.refreshToken(refresh)
    if (session) {
      access = session.accessToken
    }
  }

  return {
    headers: {
      ...previousContext.headers,
      Authorization: access ? `Bearer ${access}` : '',
    },
  }
})

async function refreshToken() {
  const refreshToken = Cookie.get('plexus_refresh_token')
  if (refreshToken) {
    const { session } = await userService.refreshToken(refreshToken)
    return session.accessToken
  }

  return Promise.reject()
}

const onErrorLink = onError(
  ({ networkError, graphQLErrors, forward, operation }) => {
    function getNewToken() {
      if (_currentlyFetchingTokens) {
        return
      }

      _currentlyFetchingTokens = true

      return fromPromise(
        refreshToken()
          .then((accessToken) => accessToken)
          .catch((error) => {
            store.dispatch(logoutRequest())
            store.dispatch(
              errorAlert(errorConstants['USER_REFRESH_TOKEN_NOT_FOUND']),
            )
            return
          })
          .finally(() => (_currentlyFetchingTokens = false)),
      )
        .filter((value) => Boolean(value))
        .flatMap((accessToken) => {
          const oldHeaders = operation.getContext().headers
          operation.setContext({
            headers: {
              ...oldHeaders,
              Authorization: `Bearer ${accessToken}`,
            },
          })

          return forward(operation)
        })
    }

    // We also need to catch Network errors here because
    // cerebro does not send graphql errors but retruns
    // network errors with status codes >= 400.
    if (networkError && !_currentlyFetchingTokens) {
      Sentry.captureException(networkError, {
        extra: {
          context: 'gql-middleware',
          middleware: 'onErrorLink',
          type: 'networkError',
        },
      })

      if (networkError.name === 'ServerParseError') {
        return
      }

      return getNewToken()
    }

    if (graphQLErrors && graphQLErrors?.length > 0) {
      const errorCode = graphQLErrors[0]?.extensions?.code

      // Refresh token if user is not authorized (= access_token is not valid anymore)
      if (errorCode === errorConstants.USER_NOT_AUTHORIZED) {
        return getNewToken()
      }

      // If the refresh token is not valid anymore,
      // we can completely log out the user.
      if (
        errorCode === errorConstants.USER_REFRESH_TOKEN_NOT_FOUND ||
        errorCode === errorConstants.USER_REFRESH_TOKEN_INVALID
      ) {
        store.dispatch(logoutRequest())
        return
      }

      // If error code is white listed, do not report error to Sentry
      if (errorCode && whiteListErrorCodes.includes(errorCode as string)) {
        return
      }

      graphQLErrors.forEach((error) =>
        Sentry.captureMessage(error.message, {
          tags: {
            errorCode: error.extensions.code as string,
          },
          extra: {
            context: 'gql-middleware',
            middleware: 'onErrorLinkPlexus',
            type: 'graphQLError',
          },
        }),
      )
    }
  },
)

// Important: the RetryLink needs to be before the onErrorLink
const cerebroLink = from([
  setAuthorizationLink,
  new RetryLink(),
  onErrorLink,
  createHttpLink({
    uri: configHelper.get(EAppConstantsKeys.CEREBRO_URL),
  }),
])

const plexusHttpLink = from([
  setAuthorizationLink,
  onErrorLink,
  new RetryLink(),
  createHttpLink({
    uri: configHelper.get(EAppConstantsKeys.PLEXUS_URL),
    credentials: 'include',
  }),
])

const axonLink = from([
  setAuthorizationLink,
  onErrorLink,
  new RetryLink(),
  createHttpLink({
    uri: configHelper.get(EAppConstantsKeys.AXON_URL),
  }),
])

const phoenixSocket = new PhoenixSocket(
  configHelper.get(EAppConstantsKeys.PLEXUS_WS_URL),
  {
    params: () => {
      const accessToken = Cookie.get('plexus_access_token')
      if (accessToken) {
        return {
          Authorization: `Bearer ${accessToken}`,
        }
      }

      return {}
    },
  },
)

const absintheSocket = AbsintheSocket.create(phoenixSocket)
const websocketLink = createAbsintheSocketLink(absintheSocket, (error: any) => {
  absintheSocket.phoenixSocket.disconnect()
})

const plexusLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  // @ts-ignore
  websocketLink,
  plexusHttpLink,
)

const plexusUploadLink = from([
  setAuthorizationLink,
  onErrorLink,
  new RetryLink(),
  createLink({
    uri: configHelper.get(EAppConstantsKeys.PLEXUS_URL),
    credentials: 'include',
  }),
])

const defaultOptions = {
  query: {
    errorPolicy: 'all' as ErrorPolicy,
  },
  mutate: {
    errorPolicy: 'all' as ErrorPolicy,
  },
}

const cerebroClient = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      SubstanceWithCategoryRiskValue: {
        keyFields: ['id', 'riskValue'],
      },
    },
  }),
  link: cerebroLink,
})

const plexusClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: plexusLink,
  defaultOptions,
})

const plexusUploadClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: plexusUploadLink,
})

const axonClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: axonLink,
})

export const gql = {
  axonClient,
  cerebroClient,
  plexusClient,
  plexusUploadClient,
}

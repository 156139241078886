import * as Sentry from '@sentry/react'
import { IntercomWrapper } from '.'
import { EAppConstantsKeys, ETelementryEvents, TBookmarkItem } from '_types'
import { configHelper } from '../_helpers/config.helper'
import { utils } from '../_helpers/utils.helper'
import {
  TAdServiceAd,
  TUserManagementWebMedCountryEnum,
} from '_generated/plexus.graphql'
import type { Maybe } from 'graphql/jsutils/Maybe'

type TEvent = {
  id?: number
  action: ETelementryEvents
  object: any
  session: string
  source: string
  time: number
  user: string | null
}

interface IOptions {
  intervalInMilliSeconds?: number
  limit?: number
  url?: string
  userId?: string
}

type TInteractionsFilter = {
  grav?: boolean
  hepar?: string | null
  lact?: boolean
  renal?: string | null
}

type TStartEventData = {
  app: string
  userAgent: string | null
}

class TelemetryDb {
  private _active = true

  // The localstorage key where data is stored
  private _eventsKey = 'events'

  // Ms in which interval the sending script will be called
  private _intervalInMilliSeconds = 45 * 1000

  // Indicator if events are being sent right now
  private _isCurrentlySendingData = false

  // Max events which should be sent in a batch
  private _limit = 50

  // An uuid which represents a session
  private _session: string = utils.uuid()

  // The id of the interval timer
  private _timerId: NodeJS.Timeout | null = null

  // The url to send the events to
  private _url: string =
    configHelper.get(EAppConstantsKeys.TELEMETRY_URL) + '/api/events'

  // Id of the current user
  private _userId: string | undefined

  constructor(options?: IOptions) {
    if (options?.intervalInMilliSeconds) {
      this._intervalInMilliSeconds = options.intervalInMilliSeconds
    }

    if (options?.limit) {
      this._limit = options.limit
    }

    if (options?.url) {
      this._url = options.url
    }

    if (options?.userId) {
      this._userId = options.userId
    }
  }

  public isActive(): boolean {
    return this._active
  }

  public deactivate() {
    this._active = false
  }

  // Get all events from local storage
  public getEvents(): Array<TEvent> {
    const eventsJson = localStorage.getItem(this._eventsKey)
    return eventsJson ? JSON.parse(eventsJson) : []
  }

  // Set/Store events object in local storage
  public setEvents(events: Array<TEvent>): void {
    localStorage.setItem(this._eventsKey, JSON.stringify(events))
  }

  // User id will be set when user logs in
  public setUserId(userId: string) {
    this._userId = userId
  }

  // Create new session id (eg when user logs out)
  public resetSession() {
    this._session = utils.uuid()
  }

  // Stores data in db
  private _dispatch(
    action: ETelementryEvents,
    additionalData?: object | string,
  ): boolean {
    if (!this._active) {
      return false
    }

    try {
      const events = this.getEvents()

      events.push({
        action: action,
        object: additionalData ?? null,
        session: this._session!,
        source: 'neocortex-web',
        time: Date.now(),
        user: this._userId ?? null,
      })

      this.setEvents(events)

      // Also track events in Intercom and Sentry
      // to be able to better track what happened
      // in case of failures.
      if (process.env.NODE_ENV !== 'test') {
        IntercomWrapper.trackEvent(action)
        Sentry.addBreadcrumb({
          category: 'event',
          message: action,
          level: 'info',
        })
      }
    } catch (error) {
      Sentry.captureException(error)
      return false
    }

    return true
  }

  // "ads.opened" event
  public adsOpened(ad: TAdServiceAd): boolean {
    if (ad.type?.__typename === 'AdServiceTypeBanner46860') {
      return this._dispatch(ETelementryEvents.ADS_OPENED, {
        link: ad.type.link ?? 'no link',
        uuid: ad.uuid ?? 'no uuid',
      })
    }

    return false
  }

  public adsSeen(uuid: Maybe<string>): boolean {
    return this._dispatch(ETelementryEvents.ADS_SEEN, {
      uuid: uuid ?? 'no uuid',
    })
  }

  // "bookmarks.create" event
  public bookmarksCreate(bookmark: TBookmarkItem): boolean {
    delete bookmark.__typename
    delete bookmark.filter?.__typename
    return this._dispatch(ETelementryEvents.BOOKMARKS_CREATE, bookmark)
  }

  // "check.interactions.overview" event
  public checkInteractionsOverview(
    drugs: Array<number> = [],
    substances: Array<number> = [],
    filter?: TInteractionsFilter,
  ): boolean {
    return this._dispatch(ETelementryEvents.CHECK_INTERACTIONS_OVERVIEW, {
      drugs,
      substances,
      filter: Object.assign(
        {
          grav: false,
          hepar: null,
          lact: false,
          renal: null,
        },
        filter,
      ),
    })
  }

  // "country.selected" event
  public countrySelected(country: TUserManagementWebMedCountryEnum): boolean {
    return this._dispatch(ETelementryEvents.COUNTRY_SELECTED, {
      country: country.toLowerCase(),
    })
  }

  // "index.atc" event
  public indexAtc(atcCode: string): boolean {
    return this._dispatch(ETelementryEvents.INDEX_ATC, atcCode)
  }

  // "index.drug" event
  public indexDrug(drugId: string): boolean {
    return this._dispatch(ETelementryEvents.INDEX_DRUG, drugId)
  }

  public indexNewsPreview(title: string, uuid: string): boolean {
    return this._dispatch(ETelementryEvents.INDEX_NEWS_PREVIEW, {
      title,
      uuid,
    })
  }

  // "index.news.selected" event
  public indexNewsSelected(title: string, uuid: string): boolean {
    return this._dispatch(ETelementryEvents.INDEX_NEWS_SELECTED, {
      title,
      uuid,
    })
  }

  // "index.search" event
  public indexSearch(search: string): boolean {
    return this._dispatch(ETelementryEvents.INDEX_SEARCH, search)
  }

  // "index.smpc.pdf" event
  public indexSmpcPdf(drugId: string): boolean {
    return this._dispatch(ETelementryEvents.INDEX_SMPC_PDF, drugId)
  }

  // "index.smpc.search" event
  public indexSmpcSearch(search: string): boolean {
    return this._dispatch(ETelementryEvents.INDEX_SMPC_SEARCH, search)
  }

  // "index.substance" event
  public indexSubstance(substanceId: string): boolean {
    return this._dispatch(ETelementryEvents.INDEX_SUBSTANCE, substanceId)
  }

  // "login" event
  public login(): boolean {
    return this._dispatch(ETelementryEvents.LOGIN)
  }

  // "reset-password" event
  public resetPassword(): boolean {
    return this._dispatch(ETelementryEvents.RESET_PASSWORD)
  }

  // "start" event
  public start(data: TStartEventData): boolean {
    return this._dispatch(ETelementryEvents.START, data)
  }

  // Start the interval (will be called at app start)
  public startTimer() {
    if (!this._active) {
      return
    }

    this._timerId = setInterval(this.sendEvents, this._intervalInMilliSeconds)
    // Trigger here so that it also runs at the beginning
    this.sendEvents()
  }

  // Stop the interval (will be called when user logs out or closed window or when not on tab)
  public stopTimer() {
    if (this._timerId) {
      clearInterval(this._timerId)
      this._timerId = null
    }
  }

  // Check if interval is running
  public isTimerRunning(): boolean {
    return Boolean(this._timerId)
  }

  // This needs to be an arrow function so that "this" is set to
  // the current context. If declared as a normal function,
  // "this" would refer to the window object, because
  // the function is called in "setInterval".
  private sendEvents = async (): Promise<any> => {
    if (!this._active) {
      return
    }

    // Do nothing when currently sending data
    if (this._isCurrentlySendingData) {
      return
    }

    // Check if there are events in the db to send
    const events = this.getEvents()
    if (events.length === 0) {
      return
    }

    // Get all events to send as batch
    // The splice function cuts out the first events up to '_limit'
    // and the remaining events stay in the 'events' object.
    const eventsToSend = events.splice(0, this._limit)
    this._isCurrentlySendingData = true

    try {
      const response = await fetch(this._url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          telemetries: eventsToSend,
        }),
      })
      // const response = { ok: true, statusText: "asdf" };

      // response.ok => when status code is between 200 - 299
      if (!response.ok) {
        // If response was not ok we send an error to Sentry. However
        // we continue with the normal flow to prevent sending
        // events multiple times.
        const errorMsg =
          'Failure sending events to Telemetrie: ' + response.statusText
        Sentry.captureMessage(errorMsg)
      }
    } catch (error) {
      Sentry.captureMessage(
        'Error sending events to Telemetrie: ' + (error as Error).message,
      )
    }

    // If sending of data was successful we
    // store the remaining events back to
    // local storage.
    this.setEvents(events)

    // If there are still events in the db then
    // immediately rerun the sending function
    // and dont wait until the next call.
    this._isCurrentlySendingData = false
    if (events.length > 0) {
      return this.sendEvents()
    }
  }
}

const Telemetry = new TelemetryDb()

export { Telemetry, TelemetryDb }

import { EPermissions } from '_types'
import {
  TUserManagementVerificationNumberTypeEnum as EVN,
  TUserManagementVerificationProcessStateEnum as EVPS,
  TUserManagementUser,
  TUserManagementProfile,
} from '_generated/plexus.graphql'
import type { Maybe } from 'graphql/jsutils/Maybe'

const getReadableVerificationNumberType = (
  user: Maybe<TUserManagementUser>,
): string => {
  switch (user?.profile?.verificationNumberType) {
    case EVN.ApothekernummerAt:
      return 'Apothekernummer AT'
    case EVN.ApothekernummerDe:
      return 'Apothekernummer DE'
    case EVN.Efn:
      return 'EFN'
    case EVN.Oeaek:
      return 'ÖÄK'
    default:
      return 'k.A.'
  }
}

const getReadableVerificationProcessStatus = (
  user: Maybe<TUserManagementUser>,
): string => {
  switch (user?.verificationProcess?.state) {
    case EVPS.Accepted:
      return 'Aktivierung erfolgreich'
    case EVPS.Denied:
      return 'Aktivierung abgelehnt'
    case EVPS.NotStarted:
      return 'Aktivierung noch nicht gestartet'
    case EVPS.Pending:
      return 'Aktivierung ausstehend'
    default:
      return 'k.A.'
  }
}

const getTrialDuration = (user: Maybe<TUserManagementUser>): number => {
  return user?.profile?.trialDuration ?? 0
}

// Checks if the user has the needed permissions.
// @param permissionSet Array of permissions the user has
// @param permissions Array of permissions the user needs to be authorized
// Mode 'every': the user needs all needed permissions
// Mode 'some': the user needs at least one of the needed permissions
const isAuthorized = (
  permissionSet: Maybe<Array<EPermissions>>,
  permissions: Array<EPermissions>,
  mode: 'every' | 'some' = 'every',
): boolean => {
  if (!permissionSet) return false

  if (mode === 'some') {
    for (const p of permissions) {
      if (permissionSet.includes(p)) return true
    }

    return false
  }

  if (mode === 'every') {
    for (const p of permissions) {
      if (permissionSet.includes(p) === false) return false
    }
  }

  return true
}

const isEnterpriseUser = (user: Maybe<TUserManagementUser>): boolean => {
  if (!user) {
    return false
  }

  // Only enterprise users have an enterprise profile
  if (user.enterpriseProfile) {
    return true
  }

  return false
}

const isProfileDirty = (
  oldProfile: TUserManagementProfile,
  newProfile: TUserManagementProfile,
  key: keyof TUserManagementProfile,
): boolean => {
  const itemFromOldProfile = oldProfile[key]
  const itemFromNewProfile = newProfile[key]

  if (!itemFromNewProfile || !itemFromOldProfile) {
    return false
  }

  if (itemFromOldProfile !== itemFromNewProfile) {
    return true
  }

  return false
}

const showProfileLink = (user: Maybe<TUserManagementUser>): boolean => {
  if (isEnterpriseUser(user)) {
    return false
  }

  if (user?.profile?.hasOnboardingFinished) {
    return true
  }

  return false
}

const showTrial = (user: Maybe<TUserManagementUser>): boolean => {
  if (isEnterpriseUser(user)) {
    return false
  }

  if (user?.profile?.hasOnboardingFinished) {
    return false
  }

  return true
}

export const userHelper = {
  getReadableVerificationNumberType,
  getReadableVerificationProcessStatus,
  getTrialDuration,
  isAuthorized,
  isEnterpriseUser,
  isProfileDirty,
  showTrial,
  showProfileLink,
}

import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import logo from './img/diagnosia-logo.svg'
import { routeConstants } from '_constants'
import { EAlertActionTypeKeys } from '_slices/alert.slice'

type TOnboardingDialogFooterProps = {
  title?: string
  linkText: string
  linkPath: string
}

function OnboardingDialogFooter(props: TOnboardingDialogFooterProps) {
  let redirectToExternLocation = false
  const linkPath = props.linkPath
  if (linkPath.startsWith('http')) {
    redirectToExternLocation = true
  }

  return (
    <div>
      {props.title && (
        <div
          className="text-center"
          data-testid="onboarding-dialog-footer-title"
        >
          <span className="font-small-body color-gray-100">{props.title}</span>
        </div>
      )}
      <div className="text-center">
        {redirectToExternLocation ? (
          <a href={linkPath} title={`Zurück zu ${props.linkPath}`}>
            {props.linkText}
          </a>
        ) : (
          <Link
            className="font-small-body color-mobile-blue"
            data-testid="onboarding-dialog-footer-link"
            to={props.linkPath}
          >
            {props.linkText}
          </Link>
        )}
      </div>
    </div>
  )
}

function OnboardingDialogErrorFooter({ title }: { title: string }) {
  return (
    <div className="text-center" data-testid="onboarding-error-footer">
      <span className="font-small-body text-danger">{title}</span>
    </div>
  )
}

type TOnboardingDialogProps = {
  alertMessage?: string
  alertType?: EAlertActionTypeKeys | null
  children?: ReactNode
  title: string
  paragraph?: string
  showCreateAccountLink?: boolean
  showLoginLink?: boolean
  showSkipVerificationLink?: boolean
  skipVerificationLink?: string
  showTrialExpiredInfo?: boolean
}

export default function OnboardingDialog({
  alertMessage,
  alertType,
  children,
  title,
  paragraph,
  showCreateAccountLink = false,
  showLoginLink = false,
  showSkipVerificationLink = false,
  skipVerificationLink = routeConstants.ROOT_PAGE,
  showTrialExpiredInfo = false,
}: TOnboardingDialogProps) {
  let alertBox: JSX.Element | null = null
  if (alertMessage && alertMessage.length > 0) {
    let infoClass = ''
    switch (alertType) {
      case EAlertActionTypeKeys.Success:
        infoClass = 'alert-success'
        break
      case EAlertActionTypeKeys.Error:
      default:
        infoClass = 'alert-danger'
    }

    alertBox = <div className={`alert ${infoClass} my-3`}>{alertMessage}</div>
  }

  return (
    <div className="onboarding-wrapper vh-100 w-100 d-flex flex-column">
      <div className="container-fluid">
        <div
          className="d-flex align-items-center justify-content-between py-4"
          style={{ height: '87px' }}
        >
          <Link to={routeConstants.ROOT_PAGE}>
            <img src={logo} alt="Diagnosia Logo" width={100} height={22} />
          </Link>
          {showTrialExpiredInfo && (
            <Link
              className="px-4 py-2 btn btn-outline-secondary rounded-pill text-sm"
              to={routeConstants.LOGOUT_PAGE}
            >
              Logout
            </Link>
          )}
        </div>
      </div>
      <div className="container-fluid onboarding py-4">
        <div className="row">
          <div className="col-12 col-lg-10 col-xl-6 offset-lg-1 offset-xl-3">
            <div className="card border-0 onboarding">
              <div className="p-sm-4 pt-3">
                <div className="row">
                  <div className="col-12 col-xl-8 offset-xl-2">
                    <div className="form-wrapper">
                      <div className="text-center">
                        <h4 className="mb-2 font-onboarding-title color-gray-100">
                          {title}
                        </h4>
                        {paragraph ? (
                          <p className="text-start mt-3">{paragraph}</p>
                        ) : null}
                      </div>

                      {alertBox}

                      {children}

                      {showCreateAccountLink && (
                        <OnboardingDialogFooter
                          title="Du bist neu bei Diagnosia?"
                          linkText="Konto erstellen"
                          linkPath={routeConstants.SIGNUP_PAGE}
                        />
                      )}

                      {showLoginLink && (
                        <OnboardingDialogFooter
                          title="Du nutzt Diagnosia schon?"
                          linkText="Einloggen"
                          linkPath={routeConstants.LOGIN_PAGE}
                        />
                      )}

                      {showSkipVerificationLink && (
                        <OnboardingDialogFooter
                          linkText="Später ausfüllen"
                          linkPath={skipVerificationLink}
                        />
                      )}

                      {showTrialExpiredInfo && (
                        <OnboardingDialogErrorFooter
                          title="Deine Trial Version is abgelaufen! Du musst dich
                          verifizieren um Diagnosia weiterhin nutzen zu können."
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

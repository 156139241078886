import { Row, Col, Button } from 'react-bootstrap'
import classNames from 'classnames'
import { ReactComponent as Checkmark } from '_components/Common/img/checkmark-green.svg'
import { ReactComponent as ErrorIcon } from '_components/Common/img/icon-error.svg'

export enum EAlertMessageType {
  default,
  success,
  error,
}

type TProps = {
  type: EAlertMessageType
  message: string
  text?: string
  buttonText?: string
  onClick?: () => void
  onBackButtonClick?: () => void
}

export function AlertMessage(props: TProps) {
  const messageClass = classNames({
    'mt-3': true,
    'px-4': true,
    'color-mobile-green': props.type === EAlertMessageType.success,
    'color-web-red': props.type !== EAlertMessageType.success,
  })

  return (
    <>
      <Row>
        <Col>
          <div className="pt-3 px-5 text-center">
            {props.type === EAlertMessageType.success ? (
              <Checkmark data-testid="alert-message-success-icon" />
            ) : (
              <ErrorIcon data-testid="alert-message-error-icon" />
            )}
            <div className={messageClass}>{props.message}</div>
          </div>
        </Col>
      </Row>
      {props.text && (
        <Row>
          <Col>
            <div className="mt-3">{props.text}</div>
          </Col>
        </Row>
      )}
      <Row className="mb-4 mt-3 mx-sm-3">
        <Col>
          <div className="d-grid">
            <Button
              variant="primary"
              className="btn-green"
              onClick={props.onClick}
            >
              {props.buttonText}
            </Button>
          </div>
        </Col>
      </Row>

      {props.type === EAlertMessageType.error && props.onBackButtonClick && (
        <Row className="mb-4 mt-3 mx-sm-3">
          <Col>
            <div className="text-center">
              <Button
                variant="link"
                className="font-small-body color-mobile-blue"
                onClick={props.onBackButtonClick}
              >
                Zurück
              </Button>
            </div>
          </Col>
        </Row>
      )}
    </>
  )
}

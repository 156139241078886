import type { Maybe } from 'graphql/jsutils/Maybe'
import { useSelector } from 'react-redux'
import { TCombinedWMC } from '_types'
import { TRootState, userHelper as uh } from '_helpers'

function useWebMedCountry(): Maybe<TCombinedWMC> {
  const user = useSelector((state: TRootState) => state.authentication.user)

  if (!user) {
    return undefined
  }

  return uh.isEnterpriseUser(user)
    ? user.enterpriseProfile?.webMedCountry
    : user.profile?.webMedCountry
}

export { useWebMedCountry }

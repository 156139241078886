import 'react-app-polyfill/stable'
import { StrictMode, useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { store, configHelper } from './_helpers'
import { Telemetry } from './_services'
import { EAppConstantsKeys } from './_types'

import './styles.scss'

import App from './App/App'

if (configHelper.has(EAppConstantsKeys.SENTRY_DSN)) {
  Sentry.init({
    dsn: configHelper.get(EAppConstantsKeys.SENTRY_DSN),
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          // @ts-ignore
          matchRoutes,
        ),
      }),
    ],
    environment: configHelper.get(EAppConstantsKeys.SENTRY_ENVIRONMENT),
    release: 'neocortex-web@' + configHelper.get(EAppConstantsKeys.APP_VERSION),
    tracesSampleRate: parseFloat(
      configHelper.get(EAppConstantsKeys.SENTRY_TRACES_SAMPLE_RATE),
    ),
  })
}

let ua: string | undefined
if (window && window.navigator) {
  ua = window.navigator.userAgent
}

// send Telemetry start event
Telemetry.start({
  app: configHelper.get(EAppConstantsKeys.APP_VERSION),
  userAgent: ua ?? null,
})

if (process.env.NODE_ENV === 'production') {
  // Starts sending of stored events
  Telemetry.startTimer()
}

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  <StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </StrictMode>,
)

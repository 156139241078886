import type { Maybe } from 'graphql/jsutils/Maybe'
import {
  TDgnsArticle,
  TDgnsArticlePrescriptionInfo,
  TDgnsArticlePrice,
  TFeatureCategory,
} from '_generated/cerebro.graphql'
import { EDgnsArticlePriceType } from '_types'

const getEcoBoxText = (ecoBox: Maybe<string>): string | undefined => {
  switch (ecoBox) {
    case 'N':
      return 'Keine Box'
    case 'R':
      return 'Rote Box'
    case 'Y':
      return 'Gelbe Box'
    case 'G':
      return 'Grüne Box'
    default:
      return undefined
  }
}

const getMaxPrice = (
  articles: Array<TDgnsArticle>,
  type: EDgnsArticlePriceType,
): number => {
  const prices = articles
    .flatMap((a) =>
      a.prices?.filter((p) => p?.type === type).map((p) => p?.amount),
    )
    .filter(Boolean)

  return Math.max(...prices)
}

const getMaxRezeptzeichenCount = (articles: Array<TDgnsArticle>): number => {
  const maxCounts = articles
    .map(
      (a) =>
        a.prescriptionInfos?.filter(
          (p) => p?.category === TFeatureCategory.Rezeptzeichen,
        ).length,
    )
    .filter(Boolean)

  return maxCounts.length > 0 ? Math.max(...maxCounts) : 0
}

const getNormalizedAmount = (amount: number): string => {
  return Intl.NumberFormat('de-DE', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount)
}

const getNormalizedCurrency = (currency: string): string => {
  switch (currency) {
    case 'EUR':
      return '€'
    default:
      return currency
  }
}

const getPackageSizeInfo = (
  size: Maybe<string>,
  unit: Maybe<string>,
): string | null => {
  if (!size) return null
  if (!unit) return null

  let x = parseFloat(size)
  switch (unit) {
    case 'Pack':
    case 'ST':
      const s = x.toFixed(0)
      return `${s} ${unit}`

    default:
      let y = getNormalizedAmount(x)
      y = y.endsWith(',00') ? y.substr(0, y.length - 3) : y
      return `${y} ${unit}`
  }
}

const getPackageSizeCombined = (
  packageSize: string | null,
  packageContent: string | null,
): string | null => {
  if (!packageSize) return null

  return packageContent &&
    packageContent.length > 0 &&
    packageContent !== packageSize
    ? `${packageSize} / ${packageContent}`
    : packageSize.length > 0
    ? packageSize
    : null
}

const getPrescriptionText = (isOtc: Maybe<boolean>): string => {
  return isOtc ? 'Rezeptfrei' : 'Rezeptpflichtig'
}

const getPrescriptions = (
  articlePrescriptions: Array<Maybe<TDgnsArticlePrescriptionInfo>>,
  type?: TFeatureCategory,
): Array<TDgnsArticlePrescriptionInfo> => {
  let prescriptions = articlePrescriptions.filter(Boolean)

  if (type) {
    prescriptions = prescriptions.filter((a) => a?.category === type)
  }

  return prescriptions
}

const getPrescriptionInfoNames = (
  articlePrescriptionInfos: Array<Maybe<TDgnsArticlePrescriptionInfo>>,
  type: TFeatureCategory,
): Array<string> => {
  return articlePrescriptionInfos
    .filter((a) => a?.category === type)
    .map((a) => a?.name)
    .filter(Boolean)
}

const getPrice = (
  type: EDgnsArticlePriceType,
  article: Maybe<TDgnsArticle>,
): Maybe<TDgnsArticlePrice> => {
  return article?.prices?.find((p) => p?.type === type)
}

const getPackageSizeUnitFull = (unit: Maybe<string>): string => {
  switch (unit) {
    case 'CM':
      return 'Zentimeter'
    case 'FL':
      return 'Flasche'
    case 'G':
      return 'Gramm'
    case 'KG':
      return 'Kilogramm'
    case 'L':
      return 'Liter'
    case 'M':
      return 'Meter'
    case 'MG':
      return 'Milligramm'
    case 'ML':
      return 'Milliliter'
    case 'P':
      return 'Packung'
    case 'ST':
      return 'Stück'
    case 'UG':
      return 'Mikrogramm'
    default:
      return ''
  }
}

const marketableText = (marketable: Maybe<string>): string => {
  switch (marketable) {
    case 'ja':
      return 'verkehrsfähig'
    case 'nv':
      return 'nicht verkehrsfähig'
    case 'iP':
      return 'Verkehrsfähigkeit in Prüfung'
    default:
      return 'k.A. zur Verkehrsfähigkeit'
  }
}

const prescriptionText = (
  prescription: Maybe<number>,
  country: string = 'de',
): string => {
  switch (country) {
    case 'at':
      switch (prescription) {
        case 0:
          return 'Rezeptfrei'
        case 1:
          return 'Rezeptpflichtig'
        default:
          return 'Keine Informationen zur Rezeptpflicht'
      }

    case 'de':
    default:
      switch (prescription) {
        case 0:
          return 'Rezeptfrei'
        case 1:
          return 'Rezeptpflichtig'
        case 2:
          return 'Rezeptpflichtig'
        case 3:
          return 'Rezeptfrei'
        default:
          return 'Keine Informationen zur Rezeptpflicht'
      }
  }
}

const sellableText = (sellable: Maybe<string>): string => {
  switch (sellable) {
    case 'iV':
      return 'im Vertrieb'
    case 'aV':
      return 'außer Vertrieb'
    case 'zurückgezogen':
      return 'zurückgezogen'
    default:
      return 'k.A. zum Vertrieb'
  }
}

const sortByPackageSize = (
  articles: Array<TDgnsArticle>,
): Array<TDgnsArticle> => {
  return articles.sort((a, b) => {
    if (!a.packageSize || !b.packageSize) return -1
    let aAmount = parseFloat(a.packageSize)
    let bAmount = parseFloat(b.packageSize)

    if (aAmount < bAmount) return -1
    if (aAmount > bAmount) return 1
    return 0
  })
}

const getAdmissionFormName = (article: Maybe<TDgnsArticle>): Maybe<string> => {
  return article?.admission?.name
}

const getInMarket = (article: Maybe<TDgnsArticle>): Maybe<boolean> => {
  return article?.inMarket
}

const getCurrencySymbol = (article: Maybe<TDgnsArticle>): Maybe<string> => {
  const currency = article?.prices?.[0]?.currency
  if (currency === 'EUR') {
    return '€'
  }
}

const getAdmissionFormShort = (article: Maybe<TDgnsArticle>): Maybe<string> => {
  return article?.admission?.short
}

export const articleHelper = {
  getAdmissionFormName,
  getAdmissionFormShort,
  getCurrencySymbol,
  getEcoBoxText,
  getInMarket,
  getMaxPrice,
  getMaxRezeptzeichenCount,
  getNormalizedAmount,
  getNormalizedCurrency,
  getPackageSizeCombined,
  getPackageSizeInfo,
  getPrescriptions,
  getPackageSizeUnitFull,
  getPrescriptionText,
  getPrescriptionInfoNames,
  getPrice,
  marketableText,
  prescriptionText,
  sellableText,
  sortByPackageSize,
}

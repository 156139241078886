import { appConstants } from '_constants'
import { EAppConstantsKeys } from '_types'

const get = (key: EAppConstantsKeys, defaultValue?: string): string => {
  let value

  if (!(key in appConstants) && !defaultValue) {
    throw new Error(
      `Constant for '${key}' is not defined. No default value provided.`,
    )
  }

  value = appConstants[key]

  return value ?? defaultValue
}

const has = (key: EAppConstantsKeys): boolean => {
  if (key in appConstants && appConstants[key]) {
    return true
  }

  return false
}

export const configHelper = {
  get,
  has,
}

import { TBookmarkItem, TBookmarkState } from '_types'
import {
  TUserManagementBookmarkTypeEnum,
  TUserManagementBookmark,
  TUserManagementWebMedCountryEnum,
} from '_generated/plexus.graphql'
import type { Maybe } from 'graphql/jsutils/Maybe'
import { utils } from './utils.helper'

const bookmarkTypesForIndex = [
  TUserManagementBookmarkTypeEnum.Atc,
  TUserManagementBookmarkTypeEnum.Drug,
  TUserManagementBookmarkTypeEnum.Icd10,
  TUserManagementBookmarkTypeEnum.Substance,
  TUserManagementBookmarkTypeEnum.Substitute,
]

const bookmarkTypesForInteractions = [
  TUserManagementBookmarkTypeEnum.Interaction,
]

const create = (data: any): TBookmarkItem => {
  return {
    atc: data.atc ?? null,
    country: data.country ?? TUserManagementWebMedCountryEnum.De,
    drugs: data.drugs ?? null,
    filter: {
      grav: data.filter?.grav ?? false,
      hepar: data.filter?.hepar ?? null,
      lact: data.filter?.lact ?? false,
      renal: data.filter?.renal ?? null,
    },
    icd10: data.icd10 ?? null,
    // Initially it is set to 0, will be overwritten after the bookmark
    // is sent to the backend and an id is assigned there.
    id: 0,
    name: data.name ?? '',
    substances: data.substances ?? null,
    substitute: data.substitute ?? null,
    type: data.type ?? TUserManagementBookmarkTypeEnum.Drug,
  }
}

const getForIndex = (
  bookmarks: Array<Maybe<TUserManagementBookmark>>,
): Array<TUserManagementBookmark> => {
  return bookmarks
    .filter(Boolean)
    .filter((b) => b.type && bookmarkTypesForIndex.includes(b.type))
}

const getForInteractions = (
  bookmarks: Array<Maybe<TUserManagementBookmark>>,
): Array<TUserManagementBookmark> => {
  return bookmarks
    .filter(Boolean)
    .filter((b) => b.type && bookmarkTypesForInteractions.includes(b.type))
}

const getBookmarkByIds = (
  bookmarks: Array<TUserManagementBookmark>,
  drugIds: Array<string>,
  substanceIds: Array<string>,
): TUserManagementBookmark | undefined => {
  if (drugIds.length === 0 && substanceIds.length === 0) {
    return undefined
  }

  return bookmarks.find((b) => {
    const bDrugIds = b?.drugs?.filter(Boolean) ?? []
    const bSubstanceIds = b?.substances?.filter(Boolean) ?? []

    let drugIdsEqual = false
    let substanceIdsEqual = false

    if (
      bDrugIds.length > 0 &&
      drugIds.length > 0 &&
      bDrugIds.length === drugIds.length
    ) {
      drugIdsEqual = utils.isEqualStringArray(bDrugIds, drugIds)
    }

    if (
      bSubstanceIds.length > 0 &&
      substanceIds.length > 0 &&
      bSubstanceIds.length === substanceIds.length
    ) {
      substanceIdsEqual = utils.isEqualStringArray(bSubstanceIds, substanceIds)
    }

    return drugIdsEqual && substanceIdsEqual
  })
}

const sortByName = (bookmarks: Array<TBookmarkItem>): Array<TBookmarkItem> => {
  return bookmarks?.sort((a, b) => {
    if (!a || !b) return 0
    if (!a.name || !b.name) return 0

    if (a.name > b.name) return 1
    if (a.name < b.name) return -1

    return 0
  })
}

const store = (
  state: TBookmarkState,
  itemToStore: TBookmarkItem,
): TBookmarkState => {
  // Put the new bookmark item at front and filter out
  // all duplicates from the current state.
  return [
    itemToStore,
    ...state.filter((item) => item.id !== itemToStore.id),
  ].slice(0, 10) // Only store the last 10 items
}

const remove = (
  state: TBookmarkState,
  itemToDeleteId: number,
): TBookmarkState => {
  return [...state.filter((item) => item.id !== itemToDeleteId)]
}

export const bookmarkHelper = {
  create,
  getForIndex,
  getForInteractions,
  getBookmarkByIds,
  sortByName,
  store,
  remove,
}

import createSagaMiddleware from 'redux-saga'
import {
  configureStore,
  combineReducers,
  MiddlewareArray,
} from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import adsReducer from '_slices/ads.slice'
import alertReducer from '_slices/alert.slice'
import authenticationReducer from '_slices/authentication.slice'
import historyReducer from '_slices/history.slice'
import tempReducer from '_slices/temp.slice'
import searchReducer from '_slices/search.slice'
import rootSaga from '_sagas'

const initializeSagaMiddleware = createSagaMiddleware()
const rootReducer = combineReducers({
  ads: adsReducer,
  alert: alertReducer,
  authentication: authenticationReducer,
  history: historyReducer,
  search: searchReducer,
  temp: tempReducer,
})

const sentryReduxEnhance = Sentry.createReduxEnhancer()

const store = configureStore({
  reducer: rootReducer,
  middleware: new MiddlewareArray().concat(initializeSagaMiddleware),
  enhancers: [sentryReduxEnhance],
})

initializeSagaMiddleware.run(rootSaga)

export type TAppDispatch = typeof store.dispatch
export type TRootState = ReturnType<typeof store.getState>
export { rootReducer as reducer, store }

import { Link } from 'react-router-dom'
import { routeConstants } from '_constants'
import { ReactComponent as EditProfileIcon } from '_components/Common/img/pencil.svg'
import { ReactComponent as LogoutIcon } from '_components/Common/img/logout.svg'
import { ReactComponent as AppleIcon } from '_components/Header/img/apple-badge.svg'
import { ReactComponent as GoogleIcon } from '_components/Header/img/google-badge.svg'
import GeneralDiagnosiaInfoLinks from '_components/Common/GeneralDiagnosiaInfoLinks'
import type { Maybe } from 'graphql/jsutils/Maybe'
import { utils } from '_helpers'
import { TUserManagementCountryEnum } from '_generated/plexus.graphql'
import ProfilePicture from '_components/Profile/ProfilePicture'

type TProps = {
  country: TUserManagementCountryEnum
  email: Maybe<string>
  handleExit: () => void
  name: string
  organisationName: Maybe<string>
  showProfileLink: boolean
  webMedCountryName: Maybe<string>
}

export default function UserDropdown({
  country,
  email,
  handleExit,
  name,
  organisationName,
  showProfileLink,
  webMedCountryName,
}: TProps) {
  const mobileAppLinkAndroid = utils.getMobileAppLink(country, 'android')
  const mobileAppLinkIOS = utils.getMobileAppLink(country, 'ios')
  const currentYear = new Date().getFullYear()

  return (
    <div>
      <div className="d-flex align-items-top p-4">
        <span>
          <ProfilePicture width={40} height={40} />
        </span>
        <span className="ps-3">
          <strong>{name}</strong>
          {email && (
            <div className="text-sm color-blue-grey-70">
              <span className="color-blue-grey-60">E-Mail Adresse:</span>{' '}
              {email}
            </div>
          )}
          {webMedCountryName && (
            <div className="text-sm color-blue-grey-70">
              <span className="color-blue-grey-60">
                Land der Arzneimittel Daten:
              </span>{' '}
              {webMedCountryName}
            </div>
          )}
          {organisationName && (
            <div className="text-sm color-blue-grey-70">
              <span className="color-blue-grey-60">Organisation:</span>{' '}
              {organisationName}
            </div>
          )}
        </span>
      </div>
      <div className="px-4">
        <div className="bg-blue-grey-10" style={{ height: '2px' }}></div>
      </div>
      <div className="d-flex p-4">
        {showProfileLink && (
          <div className="flex-fill hover:svg--web-blue-light">
            <Link
              onClick={() => handleExit()}
              to={routeConstants.PROFILE_PAGE}
              title="Profil bearbeiten"
            >
              <div className="d-flex align-items-center">
                <EditProfileIcon />
                <span className="ps-3">Profil bearbeiten</span>
              </div>
            </Link>
          </div>
        )}
        <div className="flex-fill hover:svg--web-blue-light">
          <Link
            onClick={() => handleExit()}
            to={routeConstants.LOGOUT_PAGE}
            title="Logout"
          >
            <div className="d-flex align-items-center">
              <LogoutIcon />
              <span className="ps-3">Logout</span>
            </div>
          </Link>
        </div>
      </div>
      <div className="px-4">
        <div className="bg-blue-grey-10" style={{ height: '2px' }}></div>
      </div>
      <div className="d-flex align-items-center p-4">
        <a
          className="d-inline-block"
          href={mobileAppLinkIOS}
          rel="noopener noreferrer"
          target="_blank"
          title="Download Diagnosia App für iOS"
        >
          <AppleIcon height="30" width="90" viewBox="0 0 120 40" />
        </a>
        <a
          className="d-inline-block ms-2"
          href={mobileAppLinkAndroid}
          rel="noopener noreferrer"
          target="_blank"
          title="Download Diagnosia App für Android"
        >
          <GoogleIcon height="30" width="100" />
        </a>
        <div className="ps-3 text-sm color-blue-grey-60">
          Dein täglicher Begleiter als Arzt, Apotheker, Rettungssanitäter und
          Medizinstudent.
        </div>
      </div>
      <div className="d-flex alig-items-center justify-content-between p-4 bg-blue-grey-10 text-xs color-blue-grey-60">
        <GeneralDiagnosiaInfoLinks />
        <span>Copyright {currentYear} Apotheker-Verlagsges. m.b.H.</span>
      </div>
    </div>
  )
}

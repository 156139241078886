import { gql } from '_helpers'
import { TBookmarkItem } from '_types'
import {
  DELETE_USER_BOOKMARK,
  FETCH_USER_BOOKMARKS,
  STORE_USER_BOOKMARK,
  SUBSCRIBE_USER_BOOKMARKS,
} from '_constants/plexus.gql.queries'
import {
  TFetchUserBookmarksQuery,
  TStoreUserBookmarkMutation,
  TStoreUserBookmarkMutationVariables,
  TDeleteUserBookmarkMutation,
  TDeleteUserBookmarkMutationVariables,
  TOnUserBookmarksUpdatedSubscription as TBookmarksSub,
  TOnUserBookmarksUpdatedSubscriptionVariables as TBookmarksSubVariables,
} from '_generated/plexus.graphql'
import { Telemetry } from './telemetry.service'

let userSubscribedToBookmarks = false

function sendTelemetry(bookmark: TBookmarkItem) {
  try {
    if (bookmark.id && bookmark.type) {
      Telemetry.bookmarksCreate(bookmark)
    }
  } catch (e) {}
}

async function getAll(): Promise<any> {
  const response = await gql.plexusClient.query<TFetchUserBookmarksQuery>({
    query: FETCH_USER_BOOKMARKS,
  })

  if (
    !response.loading &&
    !response.errors &&
    response.data?.userManagementBookmarks
  ) {
    return Promise.resolve(true)
  }

  if (response.errors) {
    return Promise.reject({ graphQLErrors: response.errors })
  }

  return Promise.reject(Error('No data or errors returned from API.'))
}

function subscribe(): void {
  // If user already is subscribed
  if (userSubscribedToBookmarks === true) {
    return
  }

  try {
    gql.plexusClient
      .subscribe<TBookmarksSub, TBookmarksSubVariables>({
        query: SUBSCRIBE_USER_BOOKMARKS,
      })
      .subscribe({
        next(data) {
          const newBookmarks = data.data?.userManagementBookmarks ?? []

          gql.plexusClient.writeQuery({
            query: FETCH_USER_BOOKMARKS,
            data: {
              userManagementBookmarks: [...newBookmarks],
            },
          })
        },
        error(error) {
          return Promise.reject({ graphQLErrors: [error] })
        },
      })
  } catch (error) {
    return
  }

  userSubscribedToBookmarks = true
}

async function store(bookmarkItem: TBookmarkItem) {
  const response = await gql.plexusClient.mutate<
    TStoreUserBookmarkMutation,
    TStoreUserBookmarkMutationVariables
  >({
    mutation: STORE_USER_BOOKMARK,
    variables: {
      atc: bookmarkItem.atc ?? '',
      country: bookmarkItem.country,
      drugs: bookmarkItem.drugs,
      filterGrav: bookmarkItem.filter?.grav,
      filterHepar: bookmarkItem.filter?.hepar ?? '',
      filterLact: bookmarkItem.filter?.lact,
      filterRenal: bookmarkItem.filter?.renal ?? '',
      icd10: bookmarkItem.icd10 ?? '',
      name: bookmarkItem.name,
      substances: bookmarkItem.substances,
      substitute: bookmarkItem.substitute ?? '',
      type: bookmarkItem.type,
    },
    update(cache, { data }) {
      if (data) {
        let cachedBookmarks
        try {
          cachedBookmarks = cache.readQuery<TFetchUserBookmarksQuery>({
            query: FETCH_USER_BOOKMARKS,
          })
        } catch (error) {
          return null
        }

        if (cachedBookmarks?.userManagementBookmarks) {
          const bmks = cachedBookmarks.userManagementBookmarks

          // We check here if the bookmark is already in the cache. If so,
          // we can just return. This can be, because the subscribe hook
          // was triggered before this update. And therefore the new
          // bookmark could already be in the cache.
          const isAlreadyInCache = bmks.some(
            (b) => b?.id === data.userManagementBookmark?.id,
          )
          if (isAlreadyInCache) {
            return
          }

          cache.writeQuery({
            query: FETCH_USER_BOOKMARKS,
            data: {
              userManagementBookmarks: [
                ...cachedBookmarks.userManagementBookmarks,
                data.userManagementBookmark,
              ],
            },
          })
        }
      }
    },
  })

  if (response.data?.userManagementBookmark) {
    const bookmark = response.data.userManagementBookmark
    sendTelemetry(bookmark)

    return Promise.resolve(bookmark)
  }

  if (response.errors) {
    return Promise.reject({ graphQLErrors: response.errors })
  }

  return Promise.reject(Error('No data or errors returned from API.'))
}

async function remove(id: number) {
  const response = await gql.plexusClient.mutate<
    TDeleteUserBookmarkMutation,
    TDeleteUserBookmarkMutationVariables
  >({
    mutation: DELETE_USER_BOOKMARK,
    variables: {
      id,
    },
    update(cache, { data }) {
      if (data) {
        let cachedBookmarks
        try {
          cachedBookmarks = cache.readQuery<TFetchUserBookmarksQuery>({
            query: FETCH_USER_BOOKMARKS,
          })
        } catch (error) {
          return null
        }

        if (cachedBookmarks?.userManagementBookmarks?.length) {
          cache.writeQuery({
            query: FETCH_USER_BOOKMARKS,
            data: {
              userManagementBookmarks: [
                ...cachedBookmarks.userManagementBookmarks.filter(
                  (item) => item?.id !== id,
                ),
              ],
            },
          })
        }
      }
    },
  })

  if (response.data?.userManagementBookmarkDelete) {
    return Promise.resolve(true)
  }

  if (response.errors) {
    return Promise.reject({ graphQLErrors: response.errors })
  }

  return Promise.reject(Error('No data or errors returned from API.'))
}

export const bookmarkService = {
  getAll,
  store,
  subscribe,
  remove,
}

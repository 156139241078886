import { TRiskLevels, ERiskLevelLabelTypes, ERiskLevel } from '_types'

type TRiskInfoText = {
  levels: Array<number>
  text: {
    de: string
  }
  riskLevel: ERiskLevel
}

type TInteractionsConstants = {
  RISK_LEVELS: TRiskLevels
  RISK_INFO_TEXTS: Array<TRiskInfoText>
}

export const interactionsConstants: TInteractionsConstants = {
  RISK_LEVELS: {
    A: {
      label: ERiskLevelLabelTypes.NOEFFECT,
      text: {
        en: 'None',
        de: 'Keine',
      },
    },
    B: {
      label: ERiskLevelLabelTypes.MILD,
      text: {
        en: 'Mild',
        de: 'Leichte',
      },
    },
    C: {
      label: ERiskLevelLabelTypes.MODERATE,
      text: {
        en: 'Moderate',
        de: 'Mittlere',
      },
    },
    D: {
      label: ERiskLevelLabelTypes.SEVERE,
      text: {
        en: 'Severe',
        de: 'Schwere',
      },
    },
  },
  RISK_INFO_TEXTS: [
    {
      levels: [-3, 3],
      text: {
        de: 'Schwere UAW',
      },
      riskLevel: ERiskLevel.D,
    },
    {
      levels: [-2, 2],
      text: {
        de: 'Mittlere UAW',
      },
      riskLevel: ERiskLevel.C,
    },
    {
      levels: [-1, 1],
      text: {
        de: 'Leichte UAW',
      },
      riskLevel: ERiskLevel.B,
    },
    {
      levels: [0],
      text: {
        de: 'Keine bekannten UAW',
      },
      riskLevel: ERiskLevel.A,
    },
  ],
}

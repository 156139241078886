import { TDgnsProduct, TDgnsSubstance } from '_generated/cerebro.graphql'
import { TMultiSearchOption, TItemType } from '_types'

const tabEventKeyPrefix = 'medication-product-'

const buildMedicationTabEventKey = (id: string): string => {
  return `${tabEventKeyPrefix}${id}`
}

function createSubstanceOption(
  substance: TDgnsSubstance,
  searchValue: string = '',
): TMultiSearchOption {
  return {
    label: substance.name ?? 'Unknown Name',
    products: substance.productsConnection?.items ?? null,
    type: TItemType.Substance,
    totalCountProducts: substance.productsConnection?.totalCount ?? 0,
    totalCountSubstances: 1,
    searchValue,
    substances: [substance],
    value: substance.id ?? '0',
  }
}

function createProductOption(
  product: TDgnsProduct,
  searchValue: string = '',
): TMultiSearchOption {
  const substances =
    product.components?.map((c) => c.substance).filter(Boolean) ?? null

  return {
    label: product.name ?? 'Unknown Name',
    products: [product],
    type: TItemType.Product,
    totalCountProducts: 1,
    totalCountSubstances: substances?.length ?? 0,
    searchValue,
    substances,
    value: product.id ?? '',
  }
}

const getProductIdFromMedicationTabEventKey = (
  eventKey: string,
): string | undefined => {
  const regex = RegExp(`^${tabEventKeyPrefix}(\\d+)$`)
  const match = regex.exec(eventKey)

  return match ? match[1] : undefined
}

export const medicationHelper = {
  buildMedicationTabEventKey,
  createProductOption,
  createSubstanceOption,
  getProductIdFromMedicationTabEventKey,
}

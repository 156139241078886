import {
  PutObjectCommandInput,
  PutObjectCommand,
  S3Client,
} from '@aws-sdk/client-s3'
import type { Maybe } from 'graphql/jsutils/Maybe'
import md5 from 'md5'
import { configHelper, utils } from '_helpers'
import { EAppConstantsKeys } from '_types'

type TS3Options = {
  bucketName?: string
  bucketUrl?: string
  region?: string
}

type TS3Folder = 'writing_warnings' | 'tirol_kliniken_exports'

class S3Service {
  // The name of the bucket
  private _bucketName: string = configHelper.get(
    EAppConstantsKeys.AWS_BUCKET_NAME,
  )

  // The url of the bucket
  private _bucketUrl: string = configHelper.get(
    EAppConstantsKeys.AWS_BUCKET_URL,
  )

  // The region of the S3 service
  private _region: string = 'eu-central-1'

  // The S3 client
  private _s3: S3Client

  constructor(options?: TS3Options) {
    if (
      !configHelper.has(EAppConstantsKeys.AWS_ACCESS_KEY) ||
      !configHelper.has(EAppConstantsKeys.AWS_SECRET_KEY)
    ) {
      throw new Error('AWS access key or secret key not provided')
    }

    if (options?.bucketName) {
      this._bucketName = options.bucketName
    }

    if (options?.bucketUrl) {
      this._bucketUrl = options.bucketUrl
    }

    if (options?.region) {
      this._region = options.region
    }

    this._s3 = new S3Client({
      credentials: {
        accessKeyId: configHelper.get(EAppConstantsKeys.AWS_ACCESS_KEY),
        secretAccessKey: configHelper.get(EAppConstantsKeys.AWS_SECRET_KEY),
      },
      region: this._region,
    })
  }

  public generateUniqueFileName(file: File): string {
    const extension = utils.getExtension(file.name)
    return md5(file.name + String(Date.now())) + '.' + extension
  }

  public async upload(folder: TS3Folder, file: File) {
    const uniqueFileName = this.generateUniqueFileName(file)
    const uploadParams: PutObjectCommandInput = {
      Bucket: this._bucketName,
      Key: folder + '/' + uniqueFileName,
      Body: file,
      ContentType: file.type,
      ContentDisposition: 'inline; filename="' + file.name + '"',
    }

    await this._s3.send(new PutObjectCommand(uploadParams))

    return uniqueFileName
  }

  public downloadUrl(folder: TS3Folder, fileName: Maybe<string>) {
    if (!fileName) return ''

    const url = this._bucketUrl.endsWith('/')
      ? this._bucketUrl.slice(0, this._bucketUrl.length - 1)
      : this._bucketUrl

    return [url, '/', folder, '/', fileName].join('')
  }
}

const S3 = new S3Service()
export { S3, S3Service }

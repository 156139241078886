import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null | undefined
export type InputMaybe<T> = T | null | undefined
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  Upload: any
}

/** A ad object containing all data needed to present an ad to the user */
export type TAdServiceAd = {
  __typename?: 'AdServiceAd'
  countries?: Maybe<Array<Maybe<TAdServiceAdCountryEnum>>>
  placement?: Maybe<TAdServicePlacement>
  /** true if the ad is a preview and should be marked accordingly; Only users with sufficient permissions (mostly admins) see previews (this is filtered on the backend) */
  preview?: Maybe<Scalars['Boolean']>
  type?: Maybe<TAdServiceType>
  /** Unique identifier to be sent to our telemetry */
  uuid?: Maybe<Scalars['String']>
}

export enum TAdServiceAdCountryEnum {
  At = 'AT',
  De = 'DE',
}

export enum TAdServiceAdPlacementEnum {
  AtcDetail = 'ATC_DETAIL',
  ProductDetail = 'PRODUCT_DETAIL',
  ProductDetailTop = 'PRODUCT_DETAIL_TOP',
  SubstanceDetail = 'SUBSTANCE_DETAIL',
}

export enum TAdServiceAdTypeEnum {
  Banner_320_50 = 'BANNER_320_50',
  Banner_320_100 = 'BANNER_320_100',
  Banner_468_60 = 'BANNER_468_60',
  ProductIntegration = 'PRODUCT_INTEGRATION',
}

export type TAdServicePlacement =
  | TAdServicePlacementAtcDetail
  | TAdServicePlacementAtcDetailTop
  | TAdServicePlacementProductDetail
  | TAdServicePlacementProductDetailBottom
  | TAdServicePlacementProductDetailTop
  | TAdServicePlacementSubstanceDetail
  | TAdServicePlacementSubstanceDetailTop

export type TAdServicePlacementAtcDetail = {
  __typename?: 'AdServicePlacementAtcDetail'
  /** Link which should be used when a user clicks on the ad */
  atcCodes?: Maybe<Array<Maybe<Scalars['String']>>>
  /** The type of the ad (this field is a duplicate to the type from the root ad object!) */
  placement?: Maybe<TAdServiceAdPlacementEnum>
}

export type TAdServicePlacementAtcDetailTop = {
  __typename?: 'AdServicePlacementAtcDetailTop'
  /** Link which should be used when a user clicks on the ad */
  atcCodes?: Maybe<Array<Maybe<Scalars['String']>>>
  /** The type of the ad (this field is a duplicate to the type from the root ad object!) */
  placement?: Maybe<TAdServiceAdPlacementEnum>
}

export type TAdServicePlacementProductDetail = {
  __typename?: 'AdServicePlacementProductDetail'
  /** The type of the ad (this field is a duplicate to the type from the root ad object!) */
  placement?: Maybe<TAdServiceAdPlacementEnum>
  /** Link which should be used when a user clicks on the ad */
  productIds?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type TAdServicePlacementProductDetailBottom = {
  __typename?: 'AdServicePlacementProductDetailBottom'
  /** The type of the ad (this field is a duplicate to the type from the root ad object!) */
  placement?: Maybe<TAdServiceAdPlacementEnum>
  /** Link which should be used when a user clicks on the ad */
  productIds?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type TAdServicePlacementProductDetailTop = {
  __typename?: 'AdServicePlacementProductDetailTop'
  /** The type of the ad (this field is a duplicate to the type from the root ad object!) */
  placement?: Maybe<TAdServiceAdPlacementEnum>
  /** Link which should be used when a user clicks on the ad */
  productIds?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type TAdServicePlacementSubstanceDetail = {
  __typename?: 'AdServicePlacementSubstanceDetail'
  /** The type of the ad (this field is a duplicate to the type from the root ad object!) */
  placement?: Maybe<TAdServiceAdPlacementEnum>
  /** Link which should be used when a user clicks on the ad */
  substances?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type TAdServicePlacementSubstanceDetailTop = {
  __typename?: 'AdServicePlacementSubstanceDetailTop'
  /** The type of the ad (this field is a duplicate to the type from the root ad object!) */
  placement?: Maybe<TAdServiceAdPlacementEnum>
  /** Link which should be used when a user clicks on the ad */
  substances?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** Union representing the different data objects depending on the type */
export type TAdServiceType =
  | TAdServiceTypeBanner32050
  | TAdServiceTypeBanner46860
  | TAdServiceTypeBanner320100
  | TAdServiceTypeProductIntegration

/** Type object for the banner_320_50 type */
export type TAdServiceTypeBanner32050 = {
  __typename?: 'AdServiceTypeBanner32050'
  /** Image to be shown to the user on the given placement */
  image?: Maybe<Scalars['String']>
  /** Link which should be used when a user clicks on the ad */
  link?: Maybe<Scalars['String']>
  /** The type of the ad (this field is a duplicate to the type from the root ad object!) */
  type?: Maybe<TAdServiceAdTypeEnum>
}

/** Type object for the banner_468_60 type */
export type TAdServiceTypeBanner46860 = {
  __typename?: 'AdServiceTypeBanner46860'
  /** Image to be shown to the user on the given placement */
  image?: Maybe<Scalars['String']>
  /** Link which should be used when a user clicks on the ad */
  link?: Maybe<Scalars['String']>
  /** The type of the ad (this field is a duplicate to the type from the root ad object!) */
  type?: Maybe<TAdServiceAdTypeEnum>
}

/** Type object for the banner_320_100 type */
export type TAdServiceTypeBanner320100 = {
  __typename?: 'AdServiceTypeBanner320100'
  /** Image to be shown to the user on the given placement */
  image?: Maybe<Scalars['String']>
  /** Link which should be used when a user clicks on the ad */
  link?: Maybe<Scalars['String']>
  /** The type of the ad (this field is a duplicate to the type from the root ad object!) */
  type?: Maybe<TAdServiceAdTypeEnum>
}

/** Type object for the product_integration type */
export type TAdServiceTypeProductIntegration = {
  __typename?: 'AdServiceTypeProductIntegration'
  /** Link which should be used when a user clicks on the ad */
  disclosure?: Maybe<Scalars['String']>
  /** List of entries (links with title and description) */
  entries?: Maybe<Array<Maybe<TAdServiceTypeProductIntegrationEntry>>>
  /** The type of the ad (this field is a duplicate to the type from the root ad object!) */
  type?: Maybe<TAdServiceAdTypeEnum>
}

/** One entry to be shown within the list of entries for a product integration type */
export type TAdServiceTypeProductIntegrationEntry = {
  __typename?: 'AdServiceTypeProductIntegrationEntry'
  /** Description for this entry */
  description?: Maybe<Scalars['String']>
  /** Fully qualified http link for this entry */
  link?: Maybe<Scalars['String']>
  /** Title for this entry */
  title?: Maybe<Scalars['String']>
}

/** Wrapping tokens for a user session */
export type TAuthenticationServiceSession = {
  __typename?: 'AuthenticationServiceSession'
  /** Access token to be send with every request */
  accessToken?: Maybe<Scalars['String']>
  /** Legacy token generated to be used with Phoenix API */
  legacyToken?: Maybe<Scalars['String']>
  /** Refresh token to be used when access token expires to request new access token */
  refreshToken?: Maybe<Scalars['String']>
}

export type TLinkingServiceLink = {
  __typename?: 'LinkingServiceLink'
  hash?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  insertedAt?: Maybe<Scalars['String']>
  minifiedUrl?: Maybe<Scalars['String']>
  targetUrl?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
}

export type TLinkingServiceOpenGraph = {
  name?: InputMaybe<Scalars['String']>
}

export type TLinkingServiceStructure = {
  country?: InputMaybe<TSchemaCountryEnum>
  pcn?: InputMaybe<Scalars['String']>
  productId?: InputMaybe<Scalars['String']>
  structureType?: InputMaybe<TLinkingServiceStructureTypeEnum>
  ukey?: InputMaybe<Scalars['String']>
}

export enum TLinkingServiceStructureTypeEnum {
  Article = 'ARTICLE',
  General = 'GENERAL',
  Product = 'PRODUCT',
}

export type TMedicalInfoServiceConfig = {
  __typename?: 'MedicalInfoServiceConfig'
  drugs?: Maybe<Array<Maybe<TMedicalInfoServiceDrug>>>
  vendors?: Maybe<Array<Maybe<TMedicalInfoServiceVendor>>>
}

export type TMedicalInfoServiceDrug = {
  __typename?: 'MedicalInfoServiceDrug'
  drugUkey?: Maybe<Scalars['String']>
  productId?: Maybe<Scalars['String']>
  vendor?: Maybe<Scalars['Int']>
}

export type TMedicalInfoServiceEnabledFeature = {
  __typename?: 'MedicalInfoServiceEnabledFeature'
  contactOptions?: Maybe<Array<Maybe<Scalars['String']>>>
  enabled?: Maybe<Scalars['Boolean']>
  openingHours?: Maybe<TMedicalInfoServiceOpeningHours>
  telephone?: Maybe<Scalars['String']>
}

export type TMedicalInfoServiceEnabledFeatures = {
  __typename?: 'MedicalInfoServiceEnabledFeatures'
  medicalInfo?: Maybe<TMedicalInfoServiceEnabledFeature>
  sideEffect?: Maybe<TMedicalInfoServiceEnabledFeature>
}

export type TMedicalInfoServiceFeatureConfig = {
  __typename?: 'MedicalInfoServiceFeatureConfig'
  enabledFeatures?: Maybe<TMedicalInfoServiceEnabledFeatures>
}

export type TMedicalInfoServiceOpeningHours = {
  __typename?: 'MedicalInfoServiceOpeningHours'
  alwaysOpen?: Maybe<Scalars['Boolean']>
  weeklyHours?: Maybe<TMedicalInfoServiceWeeklyHours>
}

export type TMedicalInfoServiceVendor = {
  __typename?: 'MedicalInfoServiceVendor'
  displayName?: Maybe<Scalars['String']>
  featureConfig?: Maybe<TMedicalInfoServiceFeatureConfig>
  id?: Maybe<Scalars['Int']>
  logo?: Maybe<Scalars['String']>
  privacyTextCall?: Maybe<Scalars['String']>
  privacyTextCallBack?: Maybe<Scalars['String']>
  privacyTextContactMe?: Maybe<Scalars['String']>
  privacyTextQualityIssue?: Maybe<Scalars['String']>
  privacyTextSideEffect?: Maybe<Scalars['String']>
}

export type TMedicalInfoServiceWeeklyHours = {
  __typename?: 'MedicalInfoServiceWeeklyHours'
  friday?: Maybe<Array<Maybe<Scalars['String']>>>
  monday?: Maybe<Array<Maybe<Scalars['String']>>>
  saturday?: Maybe<Array<Maybe<Scalars['String']>>>
  sunday?: Maybe<Array<Maybe<Scalars['String']>>>
  thursday?: Maybe<Array<Maybe<Scalars['String']>>>
  tuesday?: Maybe<Array<Maybe<Scalars['String']>>>
  wednesday?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** A news object containing all data needed to present the news to the user */
export type TNewsServiceNews = {
  __typename?: 'NewsServiceNews'
  /** Apps in which this news should be shown */
  apps?: Maybe<Array<Maybe<TNewsServiceNewsAppEnum>>>
  /** Country in which this news should be shown */
  countries?: Maybe<Array<Maybe<TNewsServiceNewsCountryEnum>>>
  /** After clicking the news, the user should be sent to this url */
  externalLink?: Maybe<Scalars['String']>
  /** Fully qualified link to the image. Image might be jpg/gif/png */
  image?: Maybe<Scalars['String']>
  /** true if the news should be shown even though the user has the permission to hide ads */
  overwriteCanHideAds?: Maybe<Scalars['Boolean']>
  /** true if the news is a preview and should be marked accordingly; Only users with sufficient permissions (mostly admins) see previews (this is filtered on the backend) */
  preview?: Maybe<Scalars['Boolean']>
  /** Priority to sort news by */
  priority?: Maybe<TNewsServiceNewsPriorityEnum>
  /** The title of the news */
  title?: Maybe<Scalars['String']>
  /** Unique identifier to be sent to our telemetry */
  uuid?: Maybe<Scalars['String']>
}

export enum TNewsServiceNewsAppEnum {
  NeocortexMobile = 'NEOCORTEX_MOBILE',
  NeocortexWeb = 'NEOCORTEX_WEB',
}

export enum TNewsServiceNewsCountryEnum {
  At = 'AT',
  De = 'DE',
}

export enum TNewsServiceNewsPriorityEnum {
  High = 'HIGH',
  Normal = 'NORMAL',
  VeryHigh = 'VERY_HIGH',
}

export type TRootMutationType = {
  __typename?: 'RootMutationType'
  /** Create a link to be shortened */
  linkingServiceLink?: Maybe<TLinkingServiceLink>
  /** Send a medical info drug inquiry email to company */
  medicalInfoDrugInquiry?: Maybe<TSchemaEmpty>
  /** Send a medical info quality defect email to company */
  medicalInfoQualityDefect?: Maybe<TSchemaEmpty>
  /** Send a medical info recall email to company */
  medicalInfoRecall?: Maybe<TSchemaEmpty>
  /** Send a medical info side effect recall email to company */
  medicalInfoSideEffectRecall?: Maybe<TSchemaEmpty>
  /** Send a medical info side effect report email to company */
  medicalInfoSideEffectReport?: Maybe<TSchemaEmpty>
  /**
   * Request a password reset email
   * @deprecated Use userManagementPasswordResetEmail instead
   */
  passwordResetEmail?: Maybe<TSchemaEmpty>
  /** Upsert a note for the user authenticated by his access token */
  userDrugNote?: Maybe<TUserDrugNote>
  /** Delete an existing note for the user authenticated by his access token */
  userDrugNoteDelete?: Maybe<TSchemaEmpty>
  /** Upsert a bookmark for the user authenticated by his access token */
  userManagementBookmark?: Maybe<TUserManagementBookmark>
  /** Delete an existing bookmark for the user authenticated by his access token */
  userManagementBookmarkDelete?: Maybe<TSchemaEmpty>
  /** Confirm an email after signup */
  userManagementConfirmEmail?: Maybe<TSchemaEmpty>
  /** Resend a new_email confirmation email */
  userManagementConfirmEmailResend?: Maybe<TSchemaEmpty>
  /** Confirm new email address */
  userManagementConfirmNewEmail?: Maybe<TSchemaEmpty>
  /** Update invoice data for users taking part in questionnaires */
  userManagementInvoiceData?: Maybe<TUserManagementUser>
  /** Replace current email address with new email address (happens after user confirms change) */
  userManagementNewEmail?: Maybe<TSchemaEmpty>
  /** Request a password to be changed to a new one */
  userManagementPasswordReset?: Maybe<TSchemaEmpty>
  /** Request a password reset email */
  userManagementPasswordResetEmail?: Maybe<TSchemaEmpty>
  /** Update the profile for the user authenticated by his access token */
  userManagementProfile?: Maybe<TUserManagementUser>
  /** Add profile image in the user profile */
  userManagementProfileImage?: Maybe<TUserManagementUser>
  /** Register a new user */
  userManagementRegister?: Maybe<TUserManagementUserAndSession>
  /** Delete user completely */
  userManagementUserDelete?: Maybe<TSchemaEmpty>
  /** Add verification images for the user authenticated by his access token */
  userManagementVerificationProcess?: Maybe<TUserManagementUser>
}

export type TRootMutationTypeLinkingServiceLinkArgs = {
  openGraph?: InputMaybe<TLinkingServiceOpenGraph>
  referrer?: InputMaybe<Scalars['String']>
  structure: TLinkingServiceStructure
}

export type TRootMutationTypeMedicalInfoDrugInquiryArgs = {
  batchNumber?: InputMaybe<Scalars['String']>
  drugName: Scalars['String']
  drugUkey?: InputMaybe<Scalars['String']>
  emailAddress: Scalars['String']
  name: Scalars['String']
  phoneNumber: Scalars['String']
  question: Scalars['String']
  vendorId: Scalars['Int']
}

export type TRootMutationTypeMedicalInfoQualityDefectArgs = {
  attachments?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  batchNumber: Scalars['String']
  description: Scalars['String']
  drugName: Scalars['String']
  drugUkey?: InputMaybe<Scalars['String']>
  emailAddress: Scalars['String']
  name: Scalars['String']
  phoneNumber: Scalars['String']
  vendorId: Scalars['Int']
}

export type TRootMutationTypeMedicalInfoRecallArgs = {
  date: Scalars['String']
  drugName?: InputMaybe<Scalars['String']>
  drugUkey?: InputMaybe<Scalars['String']>
  emailAddress?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  phoneNumber: Scalars['String']
  time: Scalars['String']
  vendorId: Scalars['Int']
}

export type TRootMutationTypeMedicalInfoSideEffectRecallArgs = {
  date: Scalars['String']
  drugName: Scalars['String']
  drugUkey?: InputMaybe<Scalars['String']>
  emailAddress: Scalars['String']
  name: Scalars['String']
  phoneNumber: Scalars['String']
  time: Scalars['String']
  vendorId: Scalars['Int']
}

export type TRootMutationTypeMedicalInfoSideEffectReportArgs = {
  attachments?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  batchNumber: Scalars['String']
  drugName: Scalars['String']
  drugUkey?: InputMaybe<Scalars['String']>
  emailAddress: Scalars['String']
  name: Scalars['String']
  occurrence: Scalars['String']
  patientAge: Scalars['String']
  patientGender: Scalars['String']
  patientIndication: Scalars['String']
  patientInitials: Scalars['String']
  patientWeight: Scalars['String']
  phoneNumber: Scalars['String']
  result: Scalars['String']
  vendorId: Scalars['Int']
}

export type TRootMutationTypePasswordResetEmailArgs = {
  emailAddress: Scalars['String']
}

export type TRootMutationTypeUserDrugNoteArgs = {
  drugs: Array<Scalars['Int']>
  id?: InputMaybe<Scalars['Int']>
  note: Scalars['String']
}

export type TRootMutationTypeUserDrugNoteDeleteArgs = {
  id: Scalars['Int']
}

export type TRootMutationTypeUserManagementBookmarkArgs = {
  atc?: InputMaybe<Scalars['String']>
  country?: InputMaybe<TSchemaCountryEnum>
  drugs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  filterGrav?: InputMaybe<Scalars['Boolean']>
  filterHepar?: InputMaybe<Scalars['String']>
  filterLact?: InputMaybe<Scalars['Boolean']>
  filterRenal?: InputMaybe<Scalars['String']>
  icd10?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  substances?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  substitute?: InputMaybe<Scalars['String']>
  type?: InputMaybe<TUserManagementBookmarkTypeEnum>
}

export type TRootMutationTypeUserManagementBookmarkDeleteArgs = {
  id: Scalars['Int']
}

export type TRootMutationTypeUserManagementConfirmEmailArgs = {
  emailConfirmationToken: Scalars['String']
}

export type TRootMutationTypeUserManagementConfirmEmailResendArgs = {
  emailAddress?: InputMaybe<Scalars['String']>
}

export type TRootMutationTypeUserManagementConfirmNewEmailArgs = {
  emailConfirmationToken: Scalars['String']
}

export type TRootMutationTypeUserManagementInvoiceDataArgs = {
  city?: InputMaybe<Scalars['String']>
  employment?: InputMaybe<TUserManagementInvoiceDataEmploymentEnum>
  iban?: InputMaybe<Scalars['String']>
  invoiceWithUst?: InputMaybe<Scalars['Boolean']>
  street?: InputMaybe<Scalars['String']>
  taxNumber?: InputMaybe<Scalars['String']>
  zip?: InputMaybe<Scalars['String']>
}

export type TRootMutationTypeUserManagementNewEmailArgs = {
  email: Scalars['String']
}

export type TRootMutationTypeUserManagementPasswordResetArgs = {
  newPassword: Scalars['String']
  resetToken: Scalars['String']
}

export type TRootMutationTypeUserManagementPasswordResetEmailArgs = {
  emailAddress: Scalars['String']
}

export type TRootMutationTypeUserManagementProfileArgs = {
  country?: InputMaybe<TUserManagementCountryEnum>
  fieldsOfStudy?: InputMaybe<Array<InputMaybe<TUserManagementFieldOfStudyEnum>>>
  firstName?: InputMaybe<Scalars['String']>
  gender?: InputMaybe<TUserManagementGenderEnum>
  graduationYear?: InputMaybe<Scalars['Int']>
  interests?: InputMaybe<Array<InputMaybe<TUserManagementInterestEnum>>>
  lastName?: InputMaybe<Scalars['String']>
  occupation?: InputMaybe<TUserManagementOccupationEnum>
  specialities?: InputMaybe<Array<InputMaybe<TUserManagementSpecialityEnum>>>
  suffix?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  verificationNumber?: InputMaybe<Scalars['String']>
  verificationNumberType?: InputMaybe<TUserManagementVerificationNumberTypeEnum>
  webMedCountry?: InputMaybe<TUserManagementWebMedCountryEnum>
  workplace?: InputMaybe<TUserManagementWorkplaceEnum>
  zip?: InputMaybe<Scalars['String']>
}

export type TRootMutationTypeUserManagementProfileImageArgs = {
  image: Scalars['Upload']
}

export type TRootMutationTypeUserManagementRegisterArgs = {
  country?: InputMaybe<TSchemaCountryEnum>
  emailAddress: Scalars['String']
  occupation?: InputMaybe<TUserManagementOccupationEnum>
  password: Scalars['String']
}

export type TRootMutationTypeUserManagementUserDeleteArgs = {
  password: Scalars['String']
}

export type TRootMutationTypeUserManagementVerificationProcessArgs = {
  images: Array<InputMaybe<Scalars['Upload']>>
}

export type TRootQueryType = {
  __typename?: 'RootQueryType'
  /** Get all ads for the user authenticated by his access token */
  adServiceAds?: Maybe<Array<Maybe<TAdServiceAd>>>
  /** Login for an existing user account */
  authenticationServiceLogin?: Maybe<TUserManagementUserAndSession>
  /** Login for an existing user account */
  authenticationServiceLoginWithLegacyToken?: Maybe<TUserManagementUserAndSession>
  /** Using a valid refresh token to get a new access token */
  authenticationServiceRefreshLogin?: Maybe<TUserManagementUserAndSession>
  /** Login for an existing enterprise user account with a token instead of email and password */
  authenticationServiceTokenLogin?: Maybe<TUserManagementUserAndSession>
  /** Login for an existing user account with uuid instead of email */
  authenticationServiceUuidLogin?: Maybe<TUserManagementUserAndSession>
  /** Get information about a link identified by its hash */
  linkingServiceLink?: Maybe<TLinkingServiceLink>
  /** Get full configuration for medical info button */
  medicalInfoConfig?: Maybe<TMedicalInfoServiceConfig>
  /**
   * Get the user object for the user authenticated by his access token
   * @deprecated Use userManagementUser instead
   */
  user?: Maybe<TUserManagementUser>
  /** Get all notes for the user authenticated by his access token */
  userDrugNote?: Maybe<TUserDrugNote>
  /** Get all notes for the given drug ID */
  userDrugNoteForDrug: Array<TUserDrugNote>
  /** Get all bookmarks for the user authenticated by his access token */
  userManagementBookmarks?: Maybe<Array<Maybe<TUserManagementBookmark>>>
  /** Check if an email address already exists (needed for Axon) */
  userManagementCheckEmail?: Maybe<TUserManagementCheckEmailResponse>
  /** Get all enums with translations and if they are active */
  userManagementConfig?: Maybe<TUserManagementConfig>
  /** Get the user object for the user authenticated by his access token */
  userManagementUser?: Maybe<TUserManagementUser>
}

export type TRootQueryTypeAuthenticationServiceLoginArgs = {
  email: Scalars['String']
  password: Scalars['String']
}

export type TRootQueryTypeAuthenticationServiceLoginWithLegacyTokenArgs = {
  token: Scalars['String']
}

export type TRootQueryTypeAuthenticationServiceRefreshLoginArgs = {
  token: Scalars['String']
}

export type TRootQueryTypeAuthenticationServiceTokenLoginArgs = {
  token: Scalars['String']
}

export type TRootQueryTypeAuthenticationServiceUuidLoginArgs = {
  password: Scalars['String']
  uuid: Scalars['String']
}

export type TRootQueryTypeLinkingServiceLinkArgs = {
  hash: Scalars['String']
}

export type TRootQueryTypeMedicalInfoConfigArgs = {
  country: TSchemaCountryEnum
  version?: InputMaybe<Scalars['Int']>
}

export type TRootQueryTypeUserArgs = {
  token?: InputMaybe<Scalars['String']>
}

export type TRootQueryTypeUserDrugNoteArgs = {
  id: Scalars['Int']
}

export type TRootQueryTypeUserDrugNoteForDrugArgs = {
  drugId: Scalars['Int']
}

export type TRootQueryTypeUserManagementCheckEmailArgs = {
  emailAddress: Scalars['String']
}

export type TRootSubscriptionType = {
  __typename?: 'RootSubscriptionType'
  userManagementBookmarks?: Maybe<Array<Maybe<TUserManagementBookmark>>>
  userManagementUser?: Maybe<TUserManagementUser>
}

/** Countries availabe within the API */
export enum TSchemaCountryEnum {
  At = 'AT',
  De = 'DE',
}

/** Object to be used whenever there is no data to be sent back to the client */
export type TSchemaEmpty = {
  __typename?: 'SchemaEmpty'
  /** Always empty */
  empty?: Maybe<Scalars['String']>
}

export type TUserDrugNote = {
  __typename?: 'UserDrugNote'
  drugs: Array<Scalars['Int']>
  id: Scalars['Int']
  insertedAt: Scalars['String']
  note: Scalars['String']
}

/** Bookmark object */
export type TUserManagementBookmark = {
  __typename?: 'UserManagementBookmark'
  /** ATC code if bookmark should point to one ATC code */
  atc?: Maybe<Scalars['String']>
  /** Country in which this bookmark is enabled; This is needed because drugs and substances have different ids in different countries and we can't map them in between */
  country?: Maybe<TSchemaCountryEnum>
  /** List of drugs; contains one drug if index or more than one if interaction check */
  drugs?: Maybe<Array<Maybe<Scalars['String']>>>
  /** All filters the user can apply; Only applicable if type is interaction */
  filter?: Maybe<TUserManagementBookmarkFilter>
  /** ICD10 code if bookmark should point to one ICD10 code */
  icd10?: Maybe<Scalars['String']>
  /** ID given by the backend for the bookmark to be identified */
  id?: Maybe<Scalars['Int']>
  /** Name given by the user for this bookmark for better identification */
  name?: Maybe<Scalars['String']>
  /** List of substances; contains one substance if index or more than one if interaction check */
  substances?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Substitute id if bookmark should point to one Substitute */
  substitute?: Maybe<Scalars['String']>
  /** Type of the bookmark; Generated automatically by the backend */
  type?: Maybe<TUserManagementBookmarkTypeEnum>
}

/** All filters the user can apply; Only applicable if type is interaction */
export type TUserManagementBookmarkFilter = {
  __typename?: 'UserManagementBookmarkFilter'
  /** true if filter for grav should be enabled */
  grav?: Maybe<Scalars['Boolean']>
  /** Setting for hepar filter; Should be either 0,1 or 2 or empty if not used */
  hepar?: Maybe<Scalars['String']>
  /** true if filter for lact should be enabled */
  lact?: Maybe<Scalars['Boolean']>
  /** Setting for renal filter; Should be either 0,1,2 or 3 or empty if not used */
  renal?: Maybe<Scalars['String']>
}

/** Type of bookmark */
export enum TUserManagementBookmarkTypeEnum {
  Atc = 'ATC',
  Drug = 'DRUG',
  Icd10 = 'ICD10',
  Interaction = 'INTERACTION',
  Substance = 'SUBSTANCE',
  Substitute = 'SUBSTITUTE',
}

/** Object containing the response for the check email endpoint */
export type TUserManagementCheckEmailResponse = {
  __typename?: 'UserManagementCheckEmailResponse'
  /** The user object */
  emailExists?: Maybe<Scalars['Boolean']>
}

/** All options to be presented to the user with translations */
export type TUserManagementConfig = {
  __typename?: 'UserManagementConfig'
  /** Possible countries a user can reside in */
  countries?: Maybe<Array<Maybe<TUserManagementItems>>>
  /** Possible fields of study a user which occupation is student can choose */
  fieldsOfStudy?: Maybe<Array<Maybe<TUserManagementItems>>>
  /** Possible genders a user can identify as */
  genders?: Maybe<Array<Maybe<TUserManagementItems>>>
  /** Possible interests a user which occupation is doctor can choose */
  interests?: Maybe<Array<Maybe<TUserManagementItems>>>
  /** Possible occupations a user can choose */
  occupations?: Maybe<Array<Maybe<TUserManagementItems>>>
  /** Possible specialities a user which occupation is doctor can choose */
  specialities?: Maybe<Array<Maybe<TUserManagementItems>>>
  /** Possible types of verification numbers a user can enter */
  verificationNumberTypes?: Maybe<Array<Maybe<TUserManagementItems>>>
  /** Possible countries a user can choose to retrieve data sets from within the web app */
  webMedCountries?: Maybe<Array<Maybe<TUserManagementItems>>>
  /** Possible type of workplace a user can choose */
  workplaces?: Maybe<Array<Maybe<TUserManagementItems>>>
}

export enum TUserManagementCountryEnum {
  At = 'AT',
  De = 'DE',
  Undefined = 'UNDEFINED',
}

/** Object containing all enterprise profile information */
export type TUserManagementEnterpriseProfile = {
  __typename?: 'UserManagementEnterpriseProfile'
  /** The default country data used in the web app for this enterprise user; can only be changed through the admin backend */
  webMedCountry?: Maybe<TUserManagementEnterpriseWebMedCountryEnum>
}

export enum TUserManagementEnterpriseWebMedCountryEnum {
  At = 'AT',
}

export enum TUserManagementFieldOfStudyEnum {
  DentalStudent = 'DENTAL_STUDENT',
  MedicalStudent = 'MEDICAL_STUDENT',
  NursingStudent = 'NURSING_STUDENT',
  OtherStudent = 'OTHER_STUDENT',
  PharmacyStudent = 'PHARMACY_STUDENT',
  Student = 'STUDENT',
  Undefined = 'UNDEFINED',
  VeterinaryStudent = 'VETERINARY_STUDENT',
}

export enum TUserManagementGenderEnum {
  Female = 'FEMALE',
  Male = 'MALE',
  Other = 'OTHER',
  Undefined = 'UNDEFINED',
}

export enum TUserManagementInterestEnum {
  AnaesthiosologyAndIntensiveCare = 'ANAESTHIOSOLOGY_AND_INTENSIVE_CARE',
  Anatomy = 'ANATOMY',
  Angiology = 'ANGIOLOGY',
  AppliedPhysiology = 'APPLIED_PHYSIOLOGY',
  Cancerbiology = 'CANCERBIOLOGY',
  Cardiology = 'CARDIOLOGY',
  ChildAndAdolescentPsychiatry = 'CHILD_AND_ADOLESCENT_PSYCHIATRY',
  ClinicalPharmacology = 'CLINICAL_PHARMACOLOGY',
  EmergencyMedicine = 'EMERGENCY_MEDICINE',
  Endocrinology = 'ENDOCRINOLOGY',
  ForensicMedicine = 'FORENSIC_MEDICINE',
  Gastroenterology = 'GASTROENTEROLOGY',
  Geriatry = 'GERIATRY',
  Histology = 'HISTOLOGY',
  Immunology = 'IMMUNOLOGY',
  InfectiologyAndTropicalMedicine = 'INFECTIOLOGY_AND_TROPICAL_MEDICINE',
  InternalMedicine = 'INTERNAL_MEDICINE',
  LabDiagnostics = 'LAB_DIAGNOSTICS',
  MedicalBiology = 'MEDICAL_BIOLOGY',
  MedicalPhysics = 'MEDICAL_PHYSICS',
  Nephrology = 'NEPHROLOGY',
  Neurobiology = 'NEUROBIOLOGY',
  Neurology = 'NEUROLOGY',
  Neuropathology = 'NEUROPATHOLOGY',
  Neurosurgery = 'NEUROSURGERY',
  NuclearMedicine = 'NUCLEAR_MEDICINE',
  ObstretricsAndGynaecology = 'OBSTRETRICS_AND_GYNAECOLOGY',
  OccupationalMedicine = 'OCCUPATIONAL_MEDICINE',
  Oncology = 'ONCOLOGY',
  Ophthalmology = 'OPHTHALMOLOGY',
  OralAndMaxillofacialSurgery = 'ORAL_AND_MAXILLOFACIAL_SURGERY',
  OralMedicineAndOrthodontics = 'ORAL_MEDICINE_AND_ORTHODONTICS',
  OrthopedicsAndOrthopedicSurgery = 'ORTHOPEDICS_AND_ORTHOPEDIC_SURGERY',
  Otolaryngology = 'OTOLARYNGOLOGY',
  Pathology = 'PATHOLOGY',
  Pathophysiology = 'PATHOPHYSIOLOGY',
  Pediatrics = 'PEDIATRICS',
  PedriaticSurgery = 'PEDRIATIC_SURGERY',
  PharmacologyAndToxicology = 'PHARMACOLOGY_AND_TOXICOLOGY',
  Physiology = 'PHYSIOLOGY',
  PlasticSurgery = 'PLASTIC_SURGERY',
  PrimaryCare = 'PRIMARY_CARE',
  Psychiatry = 'PSYCHIATRY',
  PsychosomaticMedicineAndPsychotherapy = 'PSYCHOSOMATIC_MEDICINE_AND_PSYCHOTHERAPY',
  Pulmology = 'PULMOLOGY',
  Radiology = 'RADIOLOGY',
  RadiotherapyRadiooncology = 'RADIOTHERAPY_RADIOONCOLOGY',
  RehabilitationMedicine = 'REHABILITATION_MEDICINE',
  Rheumatology = 'RHEUMATOLOGY',
  SkinAndVenerealDiseases = 'SKIN_AND_VENEREAL_DISEASES',
  SocialMedicine = 'SOCIAL_MEDICINE',
  Surgery = 'SURGERY',
  ThoracicSurgery = 'THORACIC_SURGERY',
  TransfusionMedicineAndBloodGroupSerology = 'TRANSFUSION_MEDICINE_AND_BLOOD_GROUP_SEROLOGY',
  Traumatology = 'TRAUMATOLOGY',
  Undefined = 'UNDEFINED',
  Urology = 'UROLOGY',
  VeterinaryMedicine = 'VETERINARY_MEDICINE',
  Virology = 'VIROLOGY',
}

/** Object containing invoice data for users taking part in questionnaires */
export type TUserManagementInvoiceData = {
  __typename?: 'UserManagementInvoiceData'
  /** City which should be printed on the invoice */
  city?: Maybe<Scalars['String']>
  /** Type of employment */
  employment?: Maybe<TUserManagementInvoiceDataEmploymentEnum>
  /** IBAN the payment should be sent to */
  iban?: Maybe<Scalars['String']>
  /** Boolean if the invoice should include ust */
  invoiceWithUst?: Maybe<Scalars['Boolean']>
  /** Street including house number which should be printed on the invoice */
  street?: Maybe<Scalars['String']>
  /** Tax number if the users employment is independant */
  taxNumber?: Maybe<Scalars['String']>
  /** ZIP which should be printed on the invoice */
  zip?: Maybe<Scalars['String']>
}

export enum TUserManagementInvoiceDataEmploymentEnum {
  Employed = 'EMPLOYED',
  Independent = 'INDEPENDENT',
}

/** Config Item */
export type TUserManagementItems = {
  __typename?: 'UserManagementItems'
  /** Boolean to indicate if user should be able to choose this option */
  isActive?: Maybe<Scalars['Boolean']>
  /** Key to be sent to the API if user chooses this option */
  key?: Maybe<Scalars['String']>
  /** Integer indicating the ordering; -1 means no specific ordering */
  sort?: Maybe<Scalars['Int']>
  /** Translations for this option to be shown to the user */
  translations?: Maybe<Array<Maybe<TUserManagementTranslation>>>
}

export enum TUserManagementOccupationEnum {
  Doctor = 'DOCTOR',
  Nurse = 'NURSE',
  Other = 'OTHER',
  OtherMedicalProfessional = 'OTHER_MEDICAL_PROFESSIONAL',
  Paramedic = 'PARAMEDIC',
  Patient = 'PATIENT',
  Pharma = 'PHARMA',
  Pharmacist = 'PHARMACIST',
  Resident = 'RESIDENT',
  Student = 'STUDENT',
  Undefined = 'UNDEFINED',
}

/** Object containing the organisation fields */
export type TUserManagementOrganisation = {
  __typename?: 'UserManagementOrganisation'
  /** Name of the organisation */
  name?: Maybe<Scalars['String']>
  /** UUID of the organisation */
  uuid?: Maybe<Scalars['String']>
}

/** Object containing all permissions for this user to be checked by the app */
export type TUserManagementPermissions = {
  __typename?: 'UserManagementPermissions'
  /** Boolean indicating if a user can access our admin backend (i.e. is admin) */
  canAccessAdminBackend: Scalars['Boolean']
  /** Boolean indicating if a user can access the cerebro admin backend */
  canAccessCerebroAdmin: Scalars['Boolean']
  /** Boolean indicating if a user can access the interface to reply to konsil questions */
  canBeAssignedKonsilQuestions: Scalars['Boolean']
  /** Boolean indicating if a user can hide all ads (i.e. is premium user) */
  canHideAds: Scalars['Boolean']
  /** Boolean indicating if a user can log in (i.e. is not locked) */
  canLogIn: Scalars['Boolean']
  /** Boolean indicating if a user can create/edit/delete his organisation announcements */
  canManageAnnouncements: Scalars['Boolean']
  /** Boolean indicating if a user can create/edit/delete his organisation pharmacovigilences */
  canManagePharmacovigilence: Scalars['Boolean']
  /** Boolean indicating if a user can use the filter for grav */
  canUseGravFilter: Scalars['Boolean']
  /** Boolean indicating if a user can use the filter for hepar */
  canUseHeparFilter: Scalars['Boolean']
  /** Boolean indicating if a user can use the interactions */
  canUseInteractions: Scalars['Boolean']
  /** Boolean indicating if a user can use the konsil */
  canUseKonsil: Scalars['Boolean']
  /** Boolean indicating if a user can use the filter for lact */
  canUseLactFilter: Scalars['Boolean']
  /** Boolean indicating if a user can use the filte for renal */
  canUseRenalFilter: Scalars['Boolean']
  /** Boolean indicating if a user can use the filter for xreact */
  canUseXreactFilter: Scalars['Boolean']
  /** Boolean indicating if a user can view the announcement section of his organisation */
  canViewAnnouncements: Scalars['Boolean']
  /** Boolean indicating if a user can see the specific chapters in the grav details */
  canViewGravDetails: Scalars['Boolean']
  /** Boolean indicating if a user can see the specific chapters in the hepar details */
  canViewHeparDetails: Scalars['Boolean']
  /** Boolean indicating if a user can see the specific chapters in the interaction details */
  canViewInteractionDetails: Scalars['Boolean']
  /** Boolean indicating if a user can see the specific chapters in the lact details */
  canViewLactDetails: Scalars['Boolean']
  /** Boolean indicating if a user can view the pharmacovigilence section of his organisation */
  canViewPharmacovigilence: Scalars['Boolean']
  /** Boolean indicating if a user can see the specific chapters in the renal details */
  canViewRenalDetails: Scalars['Boolean']
  /** Boolean indicating if a user can see specific chapters in the xreact details */
  canViewXreactDetails: Scalars['Boolean']
}

export enum TUserManagementPermissionsEnum {
  /** Boolean indicating if a user can access our admin backend (i.e. is admin) */
  CanAccessAdminBackend = 'CAN_ACCESS_ADMIN_BACKEND',
  /** Boolean indicating if a user can access the cerebro admin backend */
  CanAccessCerebroAdmin = 'CAN_ACCESS_CEREBRO_ADMIN',
  /** Boolean indicating if a user can access the interface to reply to konsil questions */
  CanBeAssignedKonsilQuestions = 'CAN_BE_ASSIGNED_KONSIL_QUESTIONS',
  /** Boolean indicating if a user can hide all ads (i.e. is premium user) */
  CanHideAds = 'CAN_HIDE_ADS',
  /** Boolean indicating if a user can log in (i.e. is not locked) */
  CanLogIn = 'CAN_LOG_IN',
  /** Boolean indicating if a user can create/edit/delete his organisation announcements */
  CanManageAnnouncements = 'CAN_MANAGE_ANNOUNCEMENTS',
  /** Boolean indicating if a user can create/edit/delete his organisation pharmacovigilences */
  CanManagePharmacovigilence = 'CAN_MANAGE_PHARMACOVIGILENCE',
  /** Boolean indicating if a user can use the filter for grav */
  CanUseGravFilter = 'CAN_USE_GRAV_FILTER',
  /** Boolean indicating if a user can use the filter for hepar */
  CanUseHeparFilter = 'CAN_USE_HEPAR_FILTER',
  /** Boolean indicating if a user can use the interactions */
  CanUseInteractions = 'CAN_USE_INTERACTIONS',
  /** Boolean indicating if a user can use the konsil */
  CanUseKonsil = 'CAN_USE_KONSIL',
  /** Boolean indicating if a user can use the filter for lact */
  CanUseLactFilter = 'CAN_USE_LACT_FILTER',
  /** Boolean indicating if a user can use the filte for renal */
  CanUseRenalFilter = 'CAN_USE_RENAL_FILTER',
  /** Boolean indicating if a user can use the filter for xreact */
  CanUseXreactFilter = 'CAN_USE_XREACT_FILTER',
  /** Boolean indicating if a user can view the announcement section of his organisation */
  CanViewAnnouncements = 'CAN_VIEW_ANNOUNCEMENTS',
  /** Boolean indicating if a user can see the specific chapters in the grav details */
  CanViewGravDetails = 'CAN_VIEW_GRAV_DETAILS',
  /** Boolean indicating if a user can see the specific chapters in the hepar details */
  CanViewHeparDetails = 'CAN_VIEW_HEPAR_DETAILS',
  /** Boolean indicating if a user can see the specific chapters in the interaction details */
  CanViewInteractionDetails = 'CAN_VIEW_INTERACTION_DETAILS',
  /** Boolean indicating if a user can see the specific chapters in the lact details */
  CanViewLactDetails = 'CAN_VIEW_LACT_DETAILS',
  /** Boolean indicating if a user can view the pharmacovigilence section of his organisation */
  CanViewPharmacovigilence = 'CAN_VIEW_PHARMACOVIGILENCE',
  /** Boolean indicating if a user can see the specific chapters in the renal details */
  CanViewRenalDetails = 'CAN_VIEW_RENAL_DETAILS',
  /** Boolean indicating if a user can see specific chapters in the xreact details */
  CanViewXreactDetails = 'CAN_VIEW_XREACT_DETAILS',
}

/** Object containing all profile information */
export type TUserManagementProfile = {
  __typename?: 'UserManagementProfile'
  /** Country the user works or studies in */
  country?: Maybe<TUserManagementCountryEnum>
  /** Only applicable in case the occupation of the user is student */
  fieldsOfStudy?: Maybe<Array<Maybe<TUserManagementFieldOfStudyEnum>>>
  /** First name supplied by the user */
  firstName?: Maybe<Scalars['String']>
  /** Gender the user identifies with */
  gender?: Maybe<TUserManagementGenderEnum>
  /** The year the user graduated from university at last */
  graduationYear?: Maybe<Scalars['Int']>
  /** Boolean indicating if the user has finished the onboarding */
  hasOnboardingFinished?: Maybe<Scalars['Boolean']>
  /** The full URL to the profile image if it exists */
  image?: Maybe<Scalars['String']>
  /** Only applicable in case the occupation of the user is doctor */
  interests?: Maybe<Array<Maybe<TUserManagementInterestEnum>>>
  invoiceData?: Maybe<TUserManagementInvoiceData>
  /** Last name supplied by the user */
  lastName?: Maybe<Scalars['String']>
  /** Occupation of the user */
  occupation?: Maybe<TUserManagementOccupationEnum>
  /** Only applicable in case the occupation of the user is doctor */
  specialities?: Maybe<Array<Maybe<TUserManagementSpecialityEnum>>>
  /** Suffix the user chooses to use, e.g. 'PhD' */
  suffix?: Maybe<Scalars['String']>
  /** Title the user chooses to use, e.g. 'Dr.' */
  title?: Maybe<Scalars['String']>
  /** Trial duration in days; -1 if user has onboarding finished; if 0 the user should not be allowed to use the app */
  trialDuration?: Maybe<Scalars['Int']>
  /** Currently (!) only applicable in case occupation is doctor */
  verificationNumber?: Maybe<Scalars['String']>
  /** Only applicable if user wants to supply a verification_number to be automatically verified; The type of verification number the user wants to supply; Is used to choose the algorithm to verify the verification_number */
  verificationNumberType?: Maybe<TUserManagementVerificationNumberTypeEnum>
  /** Boolean indicating if the user has verified himself as a HCP */
  verifiedProfessional?: Maybe<Scalars['Boolean']>
  /** After the user chooses which country data to load in our webapp, the choice is stored and restored after the next login */
  webMedCountry?: Maybe<TUserManagementWebMedCountryEnum>
  /** Type of workplace the user works at; Only applicable in certain cases */
  workplace?: Maybe<TUserManagementWorkplaceEnum>
  /** Zip code of the workplace or university of the user */
  zip?: Maybe<Scalars['String']>
}

export enum TUserManagementSpecialityEnum {
  AnaesthiosologyAndIntensiveCare = 'ANAESTHIOSOLOGY_AND_INTENSIVE_CARE',
  Anatomy = 'ANATOMY',
  Angiology = 'ANGIOLOGY',
  AppliedPhysiology = 'APPLIED_PHYSIOLOGY',
  Cancerbiology = 'CANCERBIOLOGY',
  CardiacSurgery = 'CARDIAC_SURGERY',
  Cardiology = 'CARDIOLOGY',
  ChildAndAdolescentPsychiatry = 'CHILD_AND_ADOLESCENT_PSYCHIATRY',
  ClinicalImmunologyAndSpecificProphylaxis = 'CLINICAL_IMMUNOLOGY_AND_SPECIFIC_PROPHYLAXIS',
  ClinicalMicrobiologyAndHygiene = 'CLINICAL_MICROBIOLOGY_AND_HYGIENE',
  ClinicalPharmacology = 'CLINICAL_PHARMACOLOGY',
  EmergencyMedicine = 'EMERGENCY_MEDICINE',
  Endocrinology = 'ENDOCRINOLOGY',
  ForensicMedicine = 'FORENSIC_MEDICINE',
  Gastroenterology = 'GASTROENTEROLOGY',
  GeneralAndVascularSurgery = 'GENERAL_AND_VASCULAR_SURGERY',
  GeneralAndVisceralSurgery = 'GENERAL_AND_VISCERAL_SURGERY',
  Geriatry = 'GERIATRY',
  Histology = 'HISTOLOGY',
  Human = 'HUMAN',
  Immunology = 'IMMUNOLOGY',
  InfectiologyAndTropicalMedicine = 'INFECTIOLOGY_AND_TROPICAL_MEDICINE',
  InternalMedicine = 'INTERNAL_MEDICINE',
  LabDiagnostics = 'LAB_DIAGNOSTICS',
  MedicalBiology = 'MEDICAL_BIOLOGY',
  MedicalGenetics = 'MEDICAL_GENETICS',
  MedicalPhysics = 'MEDICAL_PHYSICS',
  MedicalStudent = 'MEDICAL_STUDENT',
  Nephrology = 'NEPHROLOGY',
  Neurobiology = 'NEUROBIOLOGY',
  Neurology = 'NEUROLOGY',
  Neuropathology = 'NEUROPATHOLOGY',
  Neurosurgery = 'NEUROSURGERY',
  NoSpeciality = 'NO_SPECIALITY',
  NuclearMedicine = 'NUCLEAR_MEDICINE',
  ObstretricsAndGynaecology = 'OBSTRETRICS_AND_GYNAECOLOGY',
  OccupationalMedicine = 'OCCUPATIONAL_MEDICINE',
  Oncology = 'ONCOLOGY',
  Ophthalmology = 'OPHTHALMOLOGY',
  OralAndMaxillofacialSurgery = 'ORAL_AND_MAXILLOFACIAL_SURGERY',
  OralMedicineAndOrthodontics = 'ORAL_MEDICINE_AND_ORTHODONTICS',
  OrthopedicsAndOrthopedicSurgery = 'ORTHOPEDICS_AND_ORTHOPEDIC_SURGERY',
  OtherStudent = 'OTHER_STUDENT',
  Otolaryngology = 'OTOLARYNGOLOGY',
  Pathology = 'PATHOLOGY',
  Pathophysiology = 'PATHOPHYSIOLOGY',
  Pediatrics = 'PEDIATRICS',
  PedriaticSurgery = 'PEDRIATIC_SURGERY',
  PharmacologyAndToxicology = 'PHARMACOLOGY_AND_TOXICOLOGY',
  Pharmacy = 'PHARMACY',
  PharmacyStudent = 'PHARMACY_STUDENT',
  Physiology = 'PHYSIOLOGY',
  PlasticSurgery = 'PLASTIC_SURGERY',
  PrimaryCare = 'PRIMARY_CARE',
  Psychiatry = 'PSYCHIATRY',
  PsychosomaticMedicineAndPsychotherapy = 'PSYCHOSOMATIC_MEDICINE_AND_PSYCHOTHERAPY',
  Pulmology = 'PULMOLOGY',
  Radiology = 'RADIOLOGY',
  RadiotherapyRadiooncology = 'RADIOTHERAPY_RADIOONCOLOGY',
  RehabilitationMedicine = 'REHABILITATION_MEDICINE',
  Rheumatology = 'RHEUMATOLOGY',
  SkinAndVenerealDiseases = 'SKIN_AND_VENEREAL_DISEASES',
  SocialMedicine = 'SOCIAL_MEDICINE',
  Student = 'STUDENT',
  Surgery = 'SURGERY',
  ThoracicSurgery = 'THORACIC_SURGERY',
  TransfusionMedicineAndBloodGroupSerology = 'TRANSFUSION_MEDICINE_AND_BLOOD_GROUP_SEROLOGY',
  Traumatology = 'TRAUMATOLOGY',
  Undefined = 'UNDEFINED',
  Urology = 'UROLOGY',
  VeterinaryMedicine = 'VETERINARY_MEDICINE',
  Virology = 'VIROLOGY',
}

/** Translation to be shown to the user */
export type TUserManagementTranslation = {
  __typename?: 'UserManagementTranslation'
  /** Language for this translation; Currently only de is used */
  language?: Maybe<Scalars['String']>
  /** Text to be shown to the user */
  text?: Maybe<Scalars['String']>
}

/** Object containing the basic user fields */
export type TUserManagementUser = {
  __typename?: 'UserManagementUser'
  /** All ads available for this user */
  ads?: Maybe<Array<TAdServiceAd>>
  /** Email used by this user */
  email?: Maybe<Scalars['String']>
  /** Boolean indicating if user verified his email */
  emailConfirmed?: Maybe<Scalars['Boolean']>
  enterpriseProfile?: Maybe<TUserManagementEnterpriseProfile>
  /** @deprecated Use profile.firstName instead */
  firstName?: Maybe<Scalars['String']>
  /** @deprecated Use profile.lastName instead */
  lastName?: Maybe<Scalars['String']>
  /** All news available for this user */
  news?: Maybe<Array<TNewsServiceNews>>
  /** The organisation the user belongs to (optional) */
  organisation?: Maybe<TUserManagementOrganisation>
  /** List containing all permissions for this user */
  permissionSet?: Maybe<Array<TUserManagementPermissionsEnum>>
  /**
   * Object containing all permissions
   * @deprecated Use permissionSet instead
   */
  permissions?: Maybe<TUserManagementPermissions>
  /** The profile object */
  profile?: Maybe<TUserManagementProfile>
  /** UUID given by the system or copied from the legacy system */
  uuid?: Maybe<Scalars['String']>
  /** Object containing information about the verification process */
  verificationProcess?: Maybe<TUserManagementVerificationProcess>
}

/** Object containing the basic user fields */
export type TUserManagementUserNewsArgs = {
  app?: InputMaybe<TNewsServiceNewsAppEnum>
}

/** Object containing the user and session objects */
export type TUserManagementUserAndSession = {
  __typename?: 'UserManagementUserAndSession'
  /** The session object for this user */
  session?: Maybe<TAuthenticationServiceSession>
  /** The user object */
  user?: Maybe<TUserManagementUser>
}

export enum TUserManagementVerificationNumberTypeEnum {
  ApothekernummerAt = 'APOTHEKERNUMMER_AT',
  ApothekernummerDe = 'APOTHEKERNUMMER_DE',
  Efn = 'EFN',
  Oeaek = 'OEAEK',
  Undefined = 'UNDEFINED',
}

/** Object containing the state of the verification process for a user */
export type TUserManagementVerificationProcess = {
  __typename?: 'UserManagementVerificationProcess'
  /** If the state is denied, a reason for the denial is given */
  denyReason?: Maybe<Scalars['String']>
  /** The current state of the verification process for the user */
  state?: Maybe<TUserManagementVerificationProcessStateEnum>
}

/** State of the verification process for the given user */
export enum TUserManagementVerificationProcessStateEnum {
  Accepted = 'ACCEPTED',
  Denied = 'DENIED',
  NotStarted = 'NOT_STARTED',
  Pending = 'PENDING',
}

export enum TUserManagementWebMedCountryEnum {
  At = 'AT',
  De = 'DE',
  Undefined = 'UNDEFINED',
}

export enum TUserManagementWorkplaceEnum {
  Both = 'BOTH',
  Hospital = 'HOSPITAL',
  Office = 'OFFICE',
  Pharmacy = 'PHARMACY',
  Undefined = 'UNDEFINED',
}

export type TFullSessionFragment = {
  __typename?: 'AuthenticationServiceSession'
  accessToken?: string | null | undefined
  legacyToken?: string | null | undefined
  refreshToken?: string | null | undefined
}

export type TFullUserFragment = {
  __typename?: 'UserManagementUser'
  email?: string | null | undefined
  emailConfirmed?: boolean | null | undefined
  permissionSet?: Array<TUserManagementPermissionsEnum> | null | undefined
  uuid?: string | null | undefined
  ads?:
    | Array<{
        __typename?: 'AdServiceAd'
        countries?:
          | Array<TAdServiceAdCountryEnum | null | undefined>
          | null
          | undefined
        preview?: boolean | null | undefined
        uuid?: string | null | undefined
        placement?:
          | {
              __typename?: 'AdServicePlacementAtcDetail'
              atcCodes?: Array<string | null | undefined> | null | undefined
              placement?: TAdServiceAdPlacementEnum | null | undefined
            }
          | { __typename?: 'AdServicePlacementAtcDetailTop' }
          | {
              __typename?: 'AdServicePlacementProductDetail'
              placement?: TAdServiceAdPlacementEnum | null | undefined
              productIds?: Array<string | null | undefined> | null | undefined
            }
          | { __typename?: 'AdServicePlacementProductDetailBottom' }
          | { __typename?: 'AdServicePlacementProductDetailTop' }
          | {
              __typename?: 'AdServicePlacementSubstanceDetail'
              placement?: TAdServiceAdPlacementEnum | null | undefined
              substances?: Array<string | null | undefined> | null | undefined
            }
          | { __typename?: 'AdServicePlacementSubstanceDetailTop' }
          | null
          | undefined
        type?:
          | { __typename?: 'AdServiceTypeBanner32050' }
          | {
              __typename?: 'AdServiceTypeBanner46860'
              image?: string | null | undefined
              link?: string | null | undefined
              type?: TAdServiceAdTypeEnum | null | undefined
            }
          | { __typename?: 'AdServiceTypeBanner320100' }
          | {
              __typename?: 'AdServiceTypeProductIntegration'
              disclosure?: string | null | undefined
              type?: TAdServiceAdTypeEnum | null | undefined
              entries?:
                | Array<
                    | {
                        __typename?: 'AdServiceTypeProductIntegrationEntry'
                        description?: string | null | undefined
                        link?: string | null | undefined
                        title?: string | null | undefined
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined
            }
          | null
          | undefined
      }>
    | null
    | undefined
  enterpriseProfile?:
    | {
        __typename?: 'UserManagementEnterpriseProfile'
        webMedCountry?:
          | TUserManagementEnterpriseWebMedCountryEnum
          | null
          | undefined
      }
    | null
    | undefined
  news?:
    | Array<{
        __typename?: 'NewsServiceNews'
        countries?:
          | Array<TNewsServiceNewsCountryEnum | null | undefined>
          | null
          | undefined
        externalLink?: string | null | undefined
        image?: string | null | undefined
        overwriteCanHideAds?: boolean | null | undefined
        preview?: boolean | null | undefined
        priority?: TNewsServiceNewsPriorityEnum | null | undefined
        title?: string | null | undefined
        uuid?: string | null | undefined
      }>
    | null
    | undefined
  organisation?:
    | {
        __typename?: 'UserManagementOrganisation'
        name?: string | null | undefined
        uuid?: string | null | undefined
      }
    | null
    | undefined
  profile?:
    | {
        __typename?: 'UserManagementProfile'
        country?: TUserManagementCountryEnum | null | undefined
        fieldsOfStudy?:
          | Array<TUserManagementFieldOfStudyEnum | null | undefined>
          | null
          | undefined
        firstName?: string | null | undefined
        gender?: TUserManagementGenderEnum | null | undefined
        graduationYear?: number | null | undefined
        hasOnboardingFinished?: boolean | null | undefined
        image?: string | null | undefined
        lastName?: string | null | undefined
        occupation?: TUserManagementOccupationEnum | null | undefined
        specialities?:
          | Array<TUserManagementSpecialityEnum | null | undefined>
          | null
          | undefined
        interests?:
          | Array<TUserManagementInterestEnum | null | undefined>
          | null
          | undefined
        title?: string | null | undefined
        suffix?: string | null | undefined
        trialDuration?: number | null | undefined
        verificationNumber?: string | null | undefined
        verificationNumberType?:
          | TUserManagementVerificationNumberTypeEnum
          | null
          | undefined
        verifiedProfessional?: boolean | null | undefined
        webMedCountry?: TUserManagementWebMedCountryEnum | null | undefined
        workplace?: TUserManagementWorkplaceEnum | null | undefined
        zip?: string | null | undefined
        invoiceData?:
          | {
              __typename?: 'UserManagementInvoiceData'
              city?: string | null | undefined
              employment?:
                | TUserManagementInvoiceDataEmploymentEnum
                | null
                | undefined
              iban?: string | null | undefined
              invoiceWithUst?: boolean | null | undefined
              street?: string | null | undefined
              taxNumber?: string | null | undefined
              zip?: string | null | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
  verificationProcess?:
    | {
        __typename?: 'UserManagementVerificationProcess'
        denyReason?: string | null | undefined
        state?: TUserManagementVerificationProcessStateEnum | null | undefined
      }
    | null
    | undefined
}

export type TFullUserAndSessionFragment = {
  __typename?: 'UserManagementUserAndSession'
  session?:
    | {
        __typename?: 'AuthenticationServiceSession'
        accessToken?: string | null | undefined
        legacyToken?: string | null | undefined
        refreshToken?: string | null | undefined
      }
    | null
    | undefined
  user?:
    | {
        __typename?: 'UserManagementUser'
        email?: string | null | undefined
        emailConfirmed?: boolean | null | undefined
        permissionSet?: Array<TUserManagementPermissionsEnum> | null | undefined
        uuid?: string | null | undefined
        ads?:
          | Array<{
              __typename?: 'AdServiceAd'
              countries?:
                | Array<TAdServiceAdCountryEnum | null | undefined>
                | null
                | undefined
              preview?: boolean | null | undefined
              uuid?: string | null | undefined
              placement?:
                | {
                    __typename?: 'AdServicePlacementAtcDetail'
                    atcCodes?:
                      | Array<string | null | undefined>
                      | null
                      | undefined
                    placement?: TAdServiceAdPlacementEnum | null | undefined
                  }
                | { __typename?: 'AdServicePlacementAtcDetailTop' }
                | {
                    __typename?: 'AdServicePlacementProductDetail'
                    placement?: TAdServiceAdPlacementEnum | null | undefined
                    productIds?:
                      | Array<string | null | undefined>
                      | null
                      | undefined
                  }
                | { __typename?: 'AdServicePlacementProductDetailBottom' }
                | { __typename?: 'AdServicePlacementProductDetailTop' }
                | {
                    __typename?: 'AdServicePlacementSubstanceDetail'
                    placement?: TAdServiceAdPlacementEnum | null | undefined
                    substances?:
                      | Array<string | null | undefined>
                      | null
                      | undefined
                  }
                | { __typename?: 'AdServicePlacementSubstanceDetailTop' }
                | null
                | undefined
              type?:
                | { __typename?: 'AdServiceTypeBanner32050' }
                | {
                    __typename?: 'AdServiceTypeBanner46860'
                    image?: string | null | undefined
                    link?: string | null | undefined
                    type?: TAdServiceAdTypeEnum | null | undefined
                  }
                | { __typename?: 'AdServiceTypeBanner320100' }
                | {
                    __typename?: 'AdServiceTypeProductIntegration'
                    disclosure?: string | null | undefined
                    type?: TAdServiceAdTypeEnum | null | undefined
                    entries?:
                      | Array<
                          | {
                              __typename?: 'AdServiceTypeProductIntegrationEntry'
                              description?: string | null | undefined
                              link?: string | null | undefined
                              title?: string | null | undefined
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined
                  }
                | null
                | undefined
            }>
          | null
          | undefined
        enterpriseProfile?:
          | {
              __typename?: 'UserManagementEnterpriseProfile'
              webMedCountry?:
                | TUserManagementEnterpriseWebMedCountryEnum
                | null
                | undefined
            }
          | null
          | undefined
        news?:
          | Array<{
              __typename?: 'NewsServiceNews'
              countries?:
                | Array<TNewsServiceNewsCountryEnum | null | undefined>
                | null
                | undefined
              externalLink?: string | null | undefined
              image?: string | null | undefined
              overwriteCanHideAds?: boolean | null | undefined
              preview?: boolean | null | undefined
              priority?: TNewsServiceNewsPriorityEnum | null | undefined
              title?: string | null | undefined
              uuid?: string | null | undefined
            }>
          | null
          | undefined
        organisation?:
          | {
              __typename?: 'UserManagementOrganisation'
              name?: string | null | undefined
              uuid?: string | null | undefined
            }
          | null
          | undefined
        profile?:
          | {
              __typename?: 'UserManagementProfile'
              country?: TUserManagementCountryEnum | null | undefined
              fieldsOfStudy?:
                | Array<TUserManagementFieldOfStudyEnum | null | undefined>
                | null
                | undefined
              firstName?: string | null | undefined
              gender?: TUserManagementGenderEnum | null | undefined
              graduationYear?: number | null | undefined
              hasOnboardingFinished?: boolean | null | undefined
              image?: string | null | undefined
              lastName?: string | null | undefined
              occupation?: TUserManagementOccupationEnum | null | undefined
              specialities?:
                | Array<TUserManagementSpecialityEnum | null | undefined>
                | null
                | undefined
              interests?:
                | Array<TUserManagementInterestEnum | null | undefined>
                | null
                | undefined
              title?: string | null | undefined
              suffix?: string | null | undefined
              trialDuration?: number | null | undefined
              verificationNumber?: string | null | undefined
              verificationNumberType?:
                | TUserManagementVerificationNumberTypeEnum
                | null
                | undefined
              verifiedProfessional?: boolean | null | undefined
              webMedCountry?:
                | TUserManagementWebMedCountryEnum
                | null
                | undefined
              workplace?: TUserManagementWorkplaceEnum | null | undefined
              zip?: string | null | undefined
              invoiceData?:
                | {
                    __typename?: 'UserManagementInvoiceData'
                    city?: string | null | undefined
                    employment?:
                      | TUserManagementInvoiceDataEmploymentEnum
                      | null
                      | undefined
                    iban?: string | null | undefined
                    invoiceWithUst?: boolean | null | undefined
                    street?: string | null | undefined
                    taxNumber?: string | null | undefined
                    zip?: string | null | undefined
                  }
                | null
                | undefined
            }
          | null
          | undefined
        verificationProcess?:
          | {
              __typename?: 'UserManagementVerificationProcess'
              denyReason?: string | null | undefined
              state?:
                | TUserManagementVerificationProcessStateEnum
                | null
                | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type TDrugNoteFragment = {
  __typename?: 'UserDrugNote'
  drugs: Array<number>
  id: number
  note: string
  insertedAt: string
}

export type TBookmarkFragment = {
  __typename?: 'UserManagementBookmark'
  atc?: string | null | undefined
  country?: TSchemaCountryEnum | null | undefined
  drugs?: Array<string | null | undefined> | null | undefined
  icd10?: string | null | undefined
  id?: number | null | undefined
  name?: string | null | undefined
  substances?: Array<string | null | undefined> | null | undefined
  substitute?: string | null | undefined
  type?: TUserManagementBookmarkTypeEnum | null | undefined
  filter?:
    | {
        __typename?: 'UserManagementBookmarkFilter'
        grav?: boolean | null | undefined
        hepar?: string | null | undefined
        lact?: boolean | null | undefined
        renal?: string | null | undefined
      }
    | null
    | undefined
}

export type TFullUserManagementItemFragment = {
  __typename?: 'UserManagementItems'
  isActive?: boolean | null | undefined
  key?: string | null | undefined
  sort?: number | null | undefined
  translations?:
    | Array<
        | {
            __typename?: 'UserManagementTranslation'
            language?: string | null | undefined
            text?: string | null | undefined
          }
        | null
        | undefined
      >
    | null
    | undefined
}

export type TLoginQueryVariables = Exact<{
  email: Scalars['String']
  password: Scalars['String']
}>

export type TLoginQuery = {
  __typename?: 'RootQueryType'
  authenticationServiceLogin?:
    | {
        __typename?: 'UserManagementUserAndSession'
        session?:
          | {
              __typename?: 'AuthenticationServiceSession'
              accessToken?: string | null | undefined
              legacyToken?: string | null | undefined
              refreshToken?: string | null | undefined
            }
          | null
          | undefined
        user?:
          | {
              __typename?: 'UserManagementUser'
              email?: string | null | undefined
              emailConfirmed?: boolean | null | undefined
              permissionSet?:
                | Array<TUserManagementPermissionsEnum>
                | null
                | undefined
              uuid?: string | null | undefined
              ads?:
                | Array<{
                    __typename?: 'AdServiceAd'
                    countries?:
                      | Array<TAdServiceAdCountryEnum | null | undefined>
                      | null
                      | undefined
                    preview?: boolean | null | undefined
                    uuid?: string | null | undefined
                    placement?:
                      | {
                          __typename?: 'AdServicePlacementAtcDetail'
                          atcCodes?:
                            | Array<string | null | undefined>
                            | null
                            | undefined
                          placement?:
                            | TAdServiceAdPlacementEnum
                            | null
                            | undefined
                        }
                      | { __typename?: 'AdServicePlacementAtcDetailTop' }
                      | {
                          __typename?: 'AdServicePlacementProductDetail'
                          placement?:
                            | TAdServiceAdPlacementEnum
                            | null
                            | undefined
                          productIds?:
                            | Array<string | null | undefined>
                            | null
                            | undefined
                        }
                      | { __typename?: 'AdServicePlacementProductDetailBottom' }
                      | { __typename?: 'AdServicePlacementProductDetailTop' }
                      | {
                          __typename?: 'AdServicePlacementSubstanceDetail'
                          placement?:
                            | TAdServiceAdPlacementEnum
                            | null
                            | undefined
                          substances?:
                            | Array<string | null | undefined>
                            | null
                            | undefined
                        }
                      | { __typename?: 'AdServicePlacementSubstanceDetailTop' }
                      | null
                      | undefined
                    type?:
                      | { __typename?: 'AdServiceTypeBanner32050' }
                      | {
                          __typename?: 'AdServiceTypeBanner46860'
                          image?: string | null | undefined
                          link?: string | null | undefined
                          type?: TAdServiceAdTypeEnum | null | undefined
                        }
                      | { __typename?: 'AdServiceTypeBanner320100' }
                      | {
                          __typename?: 'AdServiceTypeProductIntegration'
                          disclosure?: string | null | undefined
                          type?: TAdServiceAdTypeEnum | null | undefined
                          entries?:
                            | Array<
                                | {
                                    __typename?: 'AdServiceTypeProductIntegrationEntry'
                                    description?: string | null | undefined
                                    link?: string | null | undefined
                                    title?: string | null | undefined
                                  }
                                | null
                                | undefined
                              >
                            | null
                            | undefined
                        }
                      | null
                      | undefined
                  }>
                | null
                | undefined
              enterpriseProfile?:
                | {
                    __typename?: 'UserManagementEnterpriseProfile'
                    webMedCountry?:
                      | TUserManagementEnterpriseWebMedCountryEnum
                      | null
                      | undefined
                  }
                | null
                | undefined
              news?:
                | Array<{
                    __typename?: 'NewsServiceNews'
                    countries?:
                      | Array<TNewsServiceNewsCountryEnum | null | undefined>
                      | null
                      | undefined
                    externalLink?: string | null | undefined
                    image?: string | null | undefined
                    overwriteCanHideAds?: boolean | null | undefined
                    preview?: boolean | null | undefined
                    priority?: TNewsServiceNewsPriorityEnum | null | undefined
                    title?: string | null | undefined
                    uuid?: string | null | undefined
                  }>
                | null
                | undefined
              organisation?:
                | {
                    __typename?: 'UserManagementOrganisation'
                    name?: string | null | undefined
                    uuid?: string | null | undefined
                  }
                | null
                | undefined
              profile?:
                | {
                    __typename?: 'UserManagementProfile'
                    country?: TUserManagementCountryEnum | null | undefined
                    fieldsOfStudy?:
                      | Array<
                          TUserManagementFieldOfStudyEnum | null | undefined
                        >
                      | null
                      | undefined
                    firstName?: string | null | undefined
                    gender?: TUserManagementGenderEnum | null | undefined
                    graduationYear?: number | null | undefined
                    hasOnboardingFinished?: boolean | null | undefined
                    image?: string | null | undefined
                    lastName?: string | null | undefined
                    occupation?:
                      | TUserManagementOccupationEnum
                      | null
                      | undefined
                    specialities?:
                      | Array<TUserManagementSpecialityEnum | null | undefined>
                      | null
                      | undefined
                    interests?:
                      | Array<TUserManagementInterestEnum | null | undefined>
                      | null
                      | undefined
                    title?: string | null | undefined
                    suffix?: string | null | undefined
                    trialDuration?: number | null | undefined
                    verificationNumber?: string | null | undefined
                    verificationNumberType?:
                      | TUserManagementVerificationNumberTypeEnum
                      | null
                      | undefined
                    verifiedProfessional?: boolean | null | undefined
                    webMedCountry?:
                      | TUserManagementWebMedCountryEnum
                      | null
                      | undefined
                    workplace?: TUserManagementWorkplaceEnum | null | undefined
                    zip?: string | null | undefined
                    invoiceData?:
                      | {
                          __typename?: 'UserManagementInvoiceData'
                          city?: string | null | undefined
                          employment?:
                            | TUserManagementInvoiceDataEmploymentEnum
                            | null
                            | undefined
                          iban?: string | null | undefined
                          invoiceWithUst?: boolean | null | undefined
                          street?: string | null | undefined
                          taxNumber?: string | null | undefined
                          zip?: string | null | undefined
                        }
                      | null
                      | undefined
                  }
                | null
                | undefined
              verificationProcess?:
                | {
                    __typename?: 'UserManagementVerificationProcess'
                    denyReason?: string | null | undefined
                    state?:
                      | TUserManagementVerificationProcessStateEnum
                      | null
                      | undefined
                  }
                | null
                | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type TTokenLoginQueryVariables = Exact<{
  token: Scalars['String']
}>

export type TTokenLoginQuery = {
  __typename?: 'RootQueryType'
  authenticationServiceTokenLogin?:
    | {
        __typename?: 'UserManagementUserAndSession'
        session?:
          | {
              __typename?: 'AuthenticationServiceSession'
              accessToken?: string | null | undefined
              legacyToken?: string | null | undefined
              refreshToken?: string | null | undefined
            }
          | null
          | undefined
        user?:
          | {
              __typename?: 'UserManagementUser'
              email?: string | null | undefined
              emailConfirmed?: boolean | null | undefined
              permissionSet?:
                | Array<TUserManagementPermissionsEnum>
                | null
                | undefined
              uuid?: string | null | undefined
              ads?:
                | Array<{
                    __typename?: 'AdServiceAd'
                    countries?:
                      | Array<TAdServiceAdCountryEnum | null | undefined>
                      | null
                      | undefined
                    preview?: boolean | null | undefined
                    uuid?: string | null | undefined
                    placement?:
                      | {
                          __typename?: 'AdServicePlacementAtcDetail'
                          atcCodes?:
                            | Array<string | null | undefined>
                            | null
                            | undefined
                          placement?:
                            | TAdServiceAdPlacementEnum
                            | null
                            | undefined
                        }
                      | { __typename?: 'AdServicePlacementAtcDetailTop' }
                      | {
                          __typename?: 'AdServicePlacementProductDetail'
                          placement?:
                            | TAdServiceAdPlacementEnum
                            | null
                            | undefined
                          productIds?:
                            | Array<string | null | undefined>
                            | null
                            | undefined
                        }
                      | { __typename?: 'AdServicePlacementProductDetailBottom' }
                      | { __typename?: 'AdServicePlacementProductDetailTop' }
                      | {
                          __typename?: 'AdServicePlacementSubstanceDetail'
                          placement?:
                            | TAdServiceAdPlacementEnum
                            | null
                            | undefined
                          substances?:
                            | Array<string | null | undefined>
                            | null
                            | undefined
                        }
                      | { __typename?: 'AdServicePlacementSubstanceDetailTop' }
                      | null
                      | undefined
                    type?:
                      | { __typename?: 'AdServiceTypeBanner32050' }
                      | {
                          __typename?: 'AdServiceTypeBanner46860'
                          image?: string | null | undefined
                          link?: string | null | undefined
                          type?: TAdServiceAdTypeEnum | null | undefined
                        }
                      | { __typename?: 'AdServiceTypeBanner320100' }
                      | {
                          __typename?: 'AdServiceTypeProductIntegration'
                          disclosure?: string | null | undefined
                          type?: TAdServiceAdTypeEnum | null | undefined
                          entries?:
                            | Array<
                                | {
                                    __typename?: 'AdServiceTypeProductIntegrationEntry'
                                    description?: string | null | undefined
                                    link?: string | null | undefined
                                    title?: string | null | undefined
                                  }
                                | null
                                | undefined
                              >
                            | null
                            | undefined
                        }
                      | null
                      | undefined
                  }>
                | null
                | undefined
              enterpriseProfile?:
                | {
                    __typename?: 'UserManagementEnterpriseProfile'
                    webMedCountry?:
                      | TUserManagementEnterpriseWebMedCountryEnum
                      | null
                      | undefined
                  }
                | null
                | undefined
              news?:
                | Array<{
                    __typename?: 'NewsServiceNews'
                    countries?:
                      | Array<TNewsServiceNewsCountryEnum | null | undefined>
                      | null
                      | undefined
                    externalLink?: string | null | undefined
                    image?: string | null | undefined
                    overwriteCanHideAds?: boolean | null | undefined
                    preview?: boolean | null | undefined
                    priority?: TNewsServiceNewsPriorityEnum | null | undefined
                    title?: string | null | undefined
                    uuid?: string | null | undefined
                  }>
                | null
                | undefined
              organisation?:
                | {
                    __typename?: 'UserManagementOrganisation'
                    name?: string | null | undefined
                    uuid?: string | null | undefined
                  }
                | null
                | undefined
              profile?:
                | {
                    __typename?: 'UserManagementProfile'
                    country?: TUserManagementCountryEnum | null | undefined
                    fieldsOfStudy?:
                      | Array<
                          TUserManagementFieldOfStudyEnum | null | undefined
                        >
                      | null
                      | undefined
                    firstName?: string | null | undefined
                    gender?: TUserManagementGenderEnum | null | undefined
                    graduationYear?: number | null | undefined
                    hasOnboardingFinished?: boolean | null | undefined
                    image?: string | null | undefined
                    lastName?: string | null | undefined
                    occupation?:
                      | TUserManagementOccupationEnum
                      | null
                      | undefined
                    specialities?:
                      | Array<TUserManagementSpecialityEnum | null | undefined>
                      | null
                      | undefined
                    interests?:
                      | Array<TUserManagementInterestEnum | null | undefined>
                      | null
                      | undefined
                    title?: string | null | undefined
                    suffix?: string | null | undefined
                    trialDuration?: number | null | undefined
                    verificationNumber?: string | null | undefined
                    verificationNumberType?:
                      | TUserManagementVerificationNumberTypeEnum
                      | null
                      | undefined
                    verifiedProfessional?: boolean | null | undefined
                    webMedCountry?:
                      | TUserManagementWebMedCountryEnum
                      | null
                      | undefined
                    workplace?: TUserManagementWorkplaceEnum | null | undefined
                    zip?: string | null | undefined
                    invoiceData?:
                      | {
                          __typename?: 'UserManagementInvoiceData'
                          city?: string | null | undefined
                          employment?:
                            | TUserManagementInvoiceDataEmploymentEnum
                            | null
                            | undefined
                          iban?: string | null | undefined
                          invoiceWithUst?: boolean | null | undefined
                          street?: string | null | undefined
                          taxNumber?: string | null | undefined
                          zip?: string | null | undefined
                        }
                      | null
                      | undefined
                  }
                | null
                | undefined
              verificationProcess?:
                | {
                    __typename?: 'UserManagementVerificationProcess'
                    denyReason?: string | null | undefined
                    state?:
                      | TUserManagementVerificationProcessStateEnum
                      | null
                      | undefined
                  }
                | null
                | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type TFetchRefreshTokenQueryVariables = Exact<{
  token: Scalars['String']
}>

export type TFetchRefreshTokenQuery = {
  __typename?: 'RootQueryType'
  authenticationServiceRefreshLogin?:
    | {
        __typename?: 'UserManagementUserAndSession'
        session?:
          | {
              __typename?: 'AuthenticationServiceSession'
              accessToken?: string | null | undefined
              legacyToken?: string | null | undefined
              refreshToken?: string | null | undefined
            }
          | null
          | undefined
        user?:
          | {
              __typename?: 'UserManagementUser'
              email?: string | null | undefined
              emailConfirmed?: boolean | null | undefined
              permissionSet?:
                | Array<TUserManagementPermissionsEnum>
                | null
                | undefined
              uuid?: string | null | undefined
              ads?:
                | Array<{
                    __typename?: 'AdServiceAd'
                    countries?:
                      | Array<TAdServiceAdCountryEnum | null | undefined>
                      | null
                      | undefined
                    preview?: boolean | null | undefined
                    uuid?: string | null | undefined
                    placement?:
                      | {
                          __typename?: 'AdServicePlacementAtcDetail'
                          atcCodes?:
                            | Array<string | null | undefined>
                            | null
                            | undefined
                          placement?:
                            | TAdServiceAdPlacementEnum
                            | null
                            | undefined
                        }
                      | { __typename?: 'AdServicePlacementAtcDetailTop' }
                      | {
                          __typename?: 'AdServicePlacementProductDetail'
                          placement?:
                            | TAdServiceAdPlacementEnum
                            | null
                            | undefined
                          productIds?:
                            | Array<string | null | undefined>
                            | null
                            | undefined
                        }
                      | { __typename?: 'AdServicePlacementProductDetailBottom' }
                      | { __typename?: 'AdServicePlacementProductDetailTop' }
                      | {
                          __typename?: 'AdServicePlacementSubstanceDetail'
                          placement?:
                            | TAdServiceAdPlacementEnum
                            | null
                            | undefined
                          substances?:
                            | Array<string | null | undefined>
                            | null
                            | undefined
                        }
                      | { __typename?: 'AdServicePlacementSubstanceDetailTop' }
                      | null
                      | undefined
                    type?:
                      | { __typename?: 'AdServiceTypeBanner32050' }
                      | {
                          __typename?: 'AdServiceTypeBanner46860'
                          image?: string | null | undefined
                          link?: string | null | undefined
                          type?: TAdServiceAdTypeEnum | null | undefined
                        }
                      | { __typename?: 'AdServiceTypeBanner320100' }
                      | {
                          __typename?: 'AdServiceTypeProductIntegration'
                          disclosure?: string | null | undefined
                          type?: TAdServiceAdTypeEnum | null | undefined
                          entries?:
                            | Array<
                                | {
                                    __typename?: 'AdServiceTypeProductIntegrationEntry'
                                    description?: string | null | undefined
                                    link?: string | null | undefined
                                    title?: string | null | undefined
                                  }
                                | null
                                | undefined
                              >
                            | null
                            | undefined
                        }
                      | null
                      | undefined
                  }>
                | null
                | undefined
              enterpriseProfile?:
                | {
                    __typename?: 'UserManagementEnterpriseProfile'
                    webMedCountry?:
                      | TUserManagementEnterpriseWebMedCountryEnum
                      | null
                      | undefined
                  }
                | null
                | undefined
              news?:
                | Array<{
                    __typename?: 'NewsServiceNews'
                    countries?:
                      | Array<TNewsServiceNewsCountryEnum | null | undefined>
                      | null
                      | undefined
                    externalLink?: string | null | undefined
                    image?: string | null | undefined
                    overwriteCanHideAds?: boolean | null | undefined
                    preview?: boolean | null | undefined
                    priority?: TNewsServiceNewsPriorityEnum | null | undefined
                    title?: string | null | undefined
                    uuid?: string | null | undefined
                  }>
                | null
                | undefined
              organisation?:
                | {
                    __typename?: 'UserManagementOrganisation'
                    name?: string | null | undefined
                    uuid?: string | null | undefined
                  }
                | null
                | undefined
              profile?:
                | {
                    __typename?: 'UserManagementProfile'
                    country?: TUserManagementCountryEnum | null | undefined
                    fieldsOfStudy?:
                      | Array<
                          TUserManagementFieldOfStudyEnum | null | undefined
                        >
                      | null
                      | undefined
                    firstName?: string | null | undefined
                    gender?: TUserManagementGenderEnum | null | undefined
                    graduationYear?: number | null | undefined
                    hasOnboardingFinished?: boolean | null | undefined
                    image?: string | null | undefined
                    lastName?: string | null | undefined
                    occupation?:
                      | TUserManagementOccupationEnum
                      | null
                      | undefined
                    specialities?:
                      | Array<TUserManagementSpecialityEnum | null | undefined>
                      | null
                      | undefined
                    interests?:
                      | Array<TUserManagementInterestEnum | null | undefined>
                      | null
                      | undefined
                    title?: string | null | undefined
                    suffix?: string | null | undefined
                    trialDuration?: number | null | undefined
                    verificationNumber?: string | null | undefined
                    verificationNumberType?:
                      | TUserManagementVerificationNumberTypeEnum
                      | null
                      | undefined
                    verifiedProfessional?: boolean | null | undefined
                    webMedCountry?:
                      | TUserManagementWebMedCountryEnum
                      | null
                      | undefined
                    workplace?: TUserManagementWorkplaceEnum | null | undefined
                    zip?: string | null | undefined
                    invoiceData?:
                      | {
                          __typename?: 'UserManagementInvoiceData'
                          city?: string | null | undefined
                          employment?:
                            | TUserManagementInvoiceDataEmploymentEnum
                            | null
                            | undefined
                          iban?: string | null | undefined
                          invoiceWithUst?: boolean | null | undefined
                          street?: string | null | undefined
                          taxNumber?: string | null | undefined
                          zip?: string | null | undefined
                        }
                      | null
                      | undefined
                  }
                | null
                | undefined
              verificationProcess?:
                | {
                    __typename?: 'UserManagementVerificationProcess'
                    denyReason?: string | null | undefined
                    state?:
                      | TUserManagementVerificationProcessStateEnum
                      | null
                      | undefined
                  }
                | null
                | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type TFetchUserQueryVariables = Exact<{ [key: string]: never }>

export type TFetchUserQuery = {
  __typename?: 'RootQueryType'
  userManagementUser?:
    | {
        __typename?: 'UserManagementUser'
        email?: string | null | undefined
        emailConfirmed?: boolean | null | undefined
        permissionSet?: Array<TUserManagementPermissionsEnum> | null | undefined
        uuid?: string | null | undefined
        ads?:
          | Array<{
              __typename?: 'AdServiceAd'
              countries?:
                | Array<TAdServiceAdCountryEnum | null | undefined>
                | null
                | undefined
              preview?: boolean | null | undefined
              uuid?: string | null | undefined
              placement?:
                | {
                    __typename?: 'AdServicePlacementAtcDetail'
                    atcCodes?:
                      | Array<string | null | undefined>
                      | null
                      | undefined
                    placement?: TAdServiceAdPlacementEnum | null | undefined
                  }
                | { __typename?: 'AdServicePlacementAtcDetailTop' }
                | {
                    __typename?: 'AdServicePlacementProductDetail'
                    placement?: TAdServiceAdPlacementEnum | null | undefined
                    productIds?:
                      | Array<string | null | undefined>
                      | null
                      | undefined
                  }
                | { __typename?: 'AdServicePlacementProductDetailBottom' }
                | { __typename?: 'AdServicePlacementProductDetailTop' }
                | {
                    __typename?: 'AdServicePlacementSubstanceDetail'
                    placement?: TAdServiceAdPlacementEnum | null | undefined
                    substances?:
                      | Array<string | null | undefined>
                      | null
                      | undefined
                  }
                | { __typename?: 'AdServicePlacementSubstanceDetailTop' }
                | null
                | undefined
              type?:
                | { __typename?: 'AdServiceTypeBanner32050' }
                | {
                    __typename?: 'AdServiceTypeBanner46860'
                    image?: string | null | undefined
                    link?: string | null | undefined
                    type?: TAdServiceAdTypeEnum | null | undefined
                  }
                | { __typename?: 'AdServiceTypeBanner320100' }
                | {
                    __typename?: 'AdServiceTypeProductIntegration'
                    disclosure?: string | null | undefined
                    type?: TAdServiceAdTypeEnum | null | undefined
                    entries?:
                      | Array<
                          | {
                              __typename?: 'AdServiceTypeProductIntegrationEntry'
                              description?: string | null | undefined
                              link?: string | null | undefined
                              title?: string | null | undefined
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined
                  }
                | null
                | undefined
            }>
          | null
          | undefined
        enterpriseProfile?:
          | {
              __typename?: 'UserManagementEnterpriseProfile'
              webMedCountry?:
                | TUserManagementEnterpriseWebMedCountryEnum
                | null
                | undefined
            }
          | null
          | undefined
        news?:
          | Array<{
              __typename?: 'NewsServiceNews'
              countries?:
                | Array<TNewsServiceNewsCountryEnum | null | undefined>
                | null
                | undefined
              externalLink?: string | null | undefined
              image?: string | null | undefined
              overwriteCanHideAds?: boolean | null | undefined
              preview?: boolean | null | undefined
              priority?: TNewsServiceNewsPriorityEnum | null | undefined
              title?: string | null | undefined
              uuid?: string | null | undefined
            }>
          | null
          | undefined
        organisation?:
          | {
              __typename?: 'UserManagementOrganisation'
              name?: string | null | undefined
              uuid?: string | null | undefined
            }
          | null
          | undefined
        profile?:
          | {
              __typename?: 'UserManagementProfile'
              country?: TUserManagementCountryEnum | null | undefined
              fieldsOfStudy?:
                | Array<TUserManagementFieldOfStudyEnum | null | undefined>
                | null
                | undefined
              firstName?: string | null | undefined
              gender?: TUserManagementGenderEnum | null | undefined
              graduationYear?: number | null | undefined
              hasOnboardingFinished?: boolean | null | undefined
              image?: string | null | undefined
              lastName?: string | null | undefined
              occupation?: TUserManagementOccupationEnum | null | undefined
              specialities?:
                | Array<TUserManagementSpecialityEnum | null | undefined>
                | null
                | undefined
              interests?:
                | Array<TUserManagementInterestEnum | null | undefined>
                | null
                | undefined
              title?: string | null | undefined
              suffix?: string | null | undefined
              trialDuration?: number | null | undefined
              verificationNumber?: string | null | undefined
              verificationNumberType?:
                | TUserManagementVerificationNumberTypeEnum
                | null
                | undefined
              verifiedProfessional?: boolean | null | undefined
              webMedCountry?:
                | TUserManagementWebMedCountryEnum
                | null
                | undefined
              workplace?: TUserManagementWorkplaceEnum | null | undefined
              zip?: string | null | undefined
              invoiceData?:
                | {
                    __typename?: 'UserManagementInvoiceData'
                    city?: string | null | undefined
                    employment?:
                      | TUserManagementInvoiceDataEmploymentEnum
                      | null
                      | undefined
                    iban?: string | null | undefined
                    invoiceWithUst?: boolean | null | undefined
                    street?: string | null | undefined
                    taxNumber?: string | null | undefined
                    zip?: string | null | undefined
                  }
                | null
                | undefined
            }
          | null
          | undefined
        verificationProcess?:
          | {
              __typename?: 'UserManagementVerificationProcess'
              denyReason?: string | null | undefined
              state?:
                | TUserManagementVerificationProcessStateEnum
                | null
                | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type TFetchUserDrugNoteQueryVariables = Exact<{
  id: Scalars['Int']
}>

export type TFetchUserDrugNoteQuery = {
  __typename?: 'RootQueryType'
  userDrugNote?:
    | {
        __typename?: 'UserDrugNote'
        drugs: Array<number>
        id: number
        note: string
        insertedAt: string
      }
    | null
    | undefined
}

export type TFetchUserDrugNoteForDrugQueryVariables = Exact<{
  drugId: Scalars['Int']
}>

export type TFetchUserDrugNoteForDrugQuery = {
  __typename?: 'RootQueryType'
  userDrugNoteForDrug: Array<{
    __typename?: 'UserDrugNote'
    drugs: Array<number>
    id: number
    note: string
    insertedAt: string
  }>
}

export type TFetchUserBookmarksQueryVariables = Exact<{ [key: string]: never }>

export type TFetchUserBookmarksQuery = {
  __typename?: 'RootQueryType'
  userManagementBookmarks?:
    | Array<
        | {
            __typename?: 'UserManagementBookmark'
            atc?: string | null | undefined
            country?: TSchemaCountryEnum | null | undefined
            drugs?: Array<string | null | undefined> | null | undefined
            icd10?: string | null | undefined
            id?: number | null | undefined
            name?: string | null | undefined
            substances?: Array<string | null | undefined> | null | undefined
            substitute?: string | null | undefined
            type?: TUserManagementBookmarkTypeEnum | null | undefined
            filter?:
              | {
                  __typename?: 'UserManagementBookmarkFilter'
                  grav?: boolean | null | undefined
                  hepar?: string | null | undefined
                  lact?: boolean | null | undefined
                  renal?: string | null | undefined
                }
              | null
              | undefined
          }
        | null
        | undefined
      >
    | null
    | undefined
}

export type TUserManagementConfigQueryVariables = Exact<{
  [key: string]: never
}>

export type TUserManagementConfigQuery = {
  __typename?: 'RootQueryType'
  userManagementConfig?:
    | {
        __typename?: 'UserManagementConfig'
        countries?:
          | Array<
              | {
                  __typename?: 'UserManagementItems'
                  isActive?: boolean | null | undefined
                  key?: string | null | undefined
                  sort?: number | null | undefined
                  translations?:
                    | Array<
                        | {
                            __typename?: 'UserManagementTranslation'
                            language?: string | null | undefined
                            text?: string | null | undefined
                          }
                        | null
                        | undefined
                      >
                    | null
                    | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
        fieldsOfStudy?:
          | Array<
              | {
                  __typename?: 'UserManagementItems'
                  isActive?: boolean | null | undefined
                  key?: string | null | undefined
                  sort?: number | null | undefined
                  translations?:
                    | Array<
                        | {
                            __typename?: 'UserManagementTranslation'
                            language?: string | null | undefined
                            text?: string | null | undefined
                          }
                        | null
                        | undefined
                      >
                    | null
                    | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
        genders?:
          | Array<
              | {
                  __typename?: 'UserManagementItems'
                  isActive?: boolean | null | undefined
                  key?: string | null | undefined
                  sort?: number | null | undefined
                  translations?:
                    | Array<
                        | {
                            __typename?: 'UserManagementTranslation'
                            language?: string | null | undefined
                            text?: string | null | undefined
                          }
                        | null
                        | undefined
                      >
                    | null
                    | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
        interests?:
          | Array<
              | {
                  __typename?: 'UserManagementItems'
                  isActive?: boolean | null | undefined
                  key?: string | null | undefined
                  sort?: number | null | undefined
                  translations?:
                    | Array<
                        | {
                            __typename?: 'UserManagementTranslation'
                            language?: string | null | undefined
                            text?: string | null | undefined
                          }
                        | null
                        | undefined
                      >
                    | null
                    | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
        occupations?:
          | Array<
              | {
                  __typename?: 'UserManagementItems'
                  isActive?: boolean | null | undefined
                  key?: string | null | undefined
                  sort?: number | null | undefined
                  translations?:
                    | Array<
                        | {
                            __typename?: 'UserManagementTranslation'
                            language?: string | null | undefined
                            text?: string | null | undefined
                          }
                        | null
                        | undefined
                      >
                    | null
                    | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
        specialities?:
          | Array<
              | {
                  __typename?: 'UserManagementItems'
                  isActive?: boolean | null | undefined
                  key?: string | null | undefined
                  sort?: number | null | undefined
                  translations?:
                    | Array<
                        | {
                            __typename?: 'UserManagementTranslation'
                            language?: string | null | undefined
                            text?: string | null | undefined
                          }
                        | null
                        | undefined
                      >
                    | null
                    | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
        verificationNumberTypes?:
          | Array<
              | {
                  __typename?: 'UserManagementItems'
                  isActive?: boolean | null | undefined
                  key?: string | null | undefined
                  sort?: number | null | undefined
                  translations?:
                    | Array<
                        | {
                            __typename?: 'UserManagementTranslation'
                            language?: string | null | undefined
                            text?: string | null | undefined
                          }
                        | null
                        | undefined
                      >
                    | null
                    | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
        webMedCountries?:
          | Array<
              | {
                  __typename?: 'UserManagementItems'
                  isActive?: boolean | null | undefined
                  key?: string | null | undefined
                  sort?: number | null | undefined
                  translations?:
                    | Array<
                        | {
                            __typename?: 'UserManagementTranslation'
                            language?: string | null | undefined
                            text?: string | null | undefined
                          }
                        | null
                        | undefined
                      >
                    | null
                    | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
        workplaces?:
          | Array<
              | {
                  __typename?: 'UserManagementItems'
                  isActive?: boolean | null | undefined
                  key?: string | null | undefined
                  sort?: number | null | undefined
                  translations?:
                    | Array<
                        | {
                            __typename?: 'UserManagementTranslation'
                            language?: string | null | undefined
                            text?: string | null | undefined
                          }
                        | null
                        | undefined
                      >
                    | null
                    | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
      }
    | null
    | undefined
}

export type TRegisterMutationVariables = Exact<{
  email: Scalars['String']
  password: Scalars['String']
  occupation: TUserManagementOccupationEnum
}>

export type TRegisterMutation = {
  __typename?: 'RootMutationType'
  userManagementRegister?:
    | {
        __typename?: 'UserManagementUserAndSession'
        session?:
          | {
              __typename?: 'AuthenticationServiceSession'
              accessToken?: string | null | undefined
              legacyToken?: string | null | undefined
              refreshToken?: string | null | undefined
            }
          | null
          | undefined
        user?:
          | {
              __typename?: 'UserManagementUser'
              email?: string | null | undefined
              emailConfirmed?: boolean | null | undefined
              permissionSet?:
                | Array<TUserManagementPermissionsEnum>
                | null
                | undefined
              uuid?: string | null | undefined
              ads?:
                | Array<{
                    __typename?: 'AdServiceAd'
                    countries?:
                      | Array<TAdServiceAdCountryEnum | null | undefined>
                      | null
                      | undefined
                    preview?: boolean | null | undefined
                    uuid?: string | null | undefined
                    placement?:
                      | {
                          __typename?: 'AdServicePlacementAtcDetail'
                          atcCodes?:
                            | Array<string | null | undefined>
                            | null
                            | undefined
                          placement?:
                            | TAdServiceAdPlacementEnum
                            | null
                            | undefined
                        }
                      | { __typename?: 'AdServicePlacementAtcDetailTop' }
                      | {
                          __typename?: 'AdServicePlacementProductDetail'
                          placement?:
                            | TAdServiceAdPlacementEnum
                            | null
                            | undefined
                          productIds?:
                            | Array<string | null | undefined>
                            | null
                            | undefined
                        }
                      | { __typename?: 'AdServicePlacementProductDetailBottom' }
                      | { __typename?: 'AdServicePlacementProductDetailTop' }
                      | {
                          __typename?: 'AdServicePlacementSubstanceDetail'
                          placement?:
                            | TAdServiceAdPlacementEnum
                            | null
                            | undefined
                          substances?:
                            | Array<string | null | undefined>
                            | null
                            | undefined
                        }
                      | { __typename?: 'AdServicePlacementSubstanceDetailTop' }
                      | null
                      | undefined
                    type?:
                      | { __typename?: 'AdServiceTypeBanner32050' }
                      | {
                          __typename?: 'AdServiceTypeBanner46860'
                          image?: string | null | undefined
                          link?: string | null | undefined
                          type?: TAdServiceAdTypeEnum | null | undefined
                        }
                      | { __typename?: 'AdServiceTypeBanner320100' }
                      | {
                          __typename?: 'AdServiceTypeProductIntegration'
                          disclosure?: string | null | undefined
                          type?: TAdServiceAdTypeEnum | null | undefined
                          entries?:
                            | Array<
                                | {
                                    __typename?: 'AdServiceTypeProductIntegrationEntry'
                                    description?: string | null | undefined
                                    link?: string | null | undefined
                                    title?: string | null | undefined
                                  }
                                | null
                                | undefined
                              >
                            | null
                            | undefined
                        }
                      | null
                      | undefined
                  }>
                | null
                | undefined
              enterpriseProfile?:
                | {
                    __typename?: 'UserManagementEnterpriseProfile'
                    webMedCountry?:
                      | TUserManagementEnterpriseWebMedCountryEnum
                      | null
                      | undefined
                  }
                | null
                | undefined
              news?:
                | Array<{
                    __typename?: 'NewsServiceNews'
                    countries?:
                      | Array<TNewsServiceNewsCountryEnum | null | undefined>
                      | null
                      | undefined
                    externalLink?: string | null | undefined
                    image?: string | null | undefined
                    overwriteCanHideAds?: boolean | null | undefined
                    preview?: boolean | null | undefined
                    priority?: TNewsServiceNewsPriorityEnum | null | undefined
                    title?: string | null | undefined
                    uuid?: string | null | undefined
                  }>
                | null
                | undefined
              organisation?:
                | {
                    __typename?: 'UserManagementOrganisation'
                    name?: string | null | undefined
                    uuid?: string | null | undefined
                  }
                | null
                | undefined
              profile?:
                | {
                    __typename?: 'UserManagementProfile'
                    country?: TUserManagementCountryEnum | null | undefined
                    fieldsOfStudy?:
                      | Array<
                          TUserManagementFieldOfStudyEnum | null | undefined
                        >
                      | null
                      | undefined
                    firstName?: string | null | undefined
                    gender?: TUserManagementGenderEnum | null | undefined
                    graduationYear?: number | null | undefined
                    hasOnboardingFinished?: boolean | null | undefined
                    image?: string | null | undefined
                    lastName?: string | null | undefined
                    occupation?:
                      | TUserManagementOccupationEnum
                      | null
                      | undefined
                    specialities?:
                      | Array<TUserManagementSpecialityEnum | null | undefined>
                      | null
                      | undefined
                    interests?:
                      | Array<TUserManagementInterestEnum | null | undefined>
                      | null
                      | undefined
                    title?: string | null | undefined
                    suffix?: string | null | undefined
                    trialDuration?: number | null | undefined
                    verificationNumber?: string | null | undefined
                    verificationNumberType?:
                      | TUserManagementVerificationNumberTypeEnum
                      | null
                      | undefined
                    verifiedProfessional?: boolean | null | undefined
                    webMedCountry?:
                      | TUserManagementWebMedCountryEnum
                      | null
                      | undefined
                    workplace?: TUserManagementWorkplaceEnum | null | undefined
                    zip?: string | null | undefined
                    invoiceData?:
                      | {
                          __typename?: 'UserManagementInvoiceData'
                          city?: string | null | undefined
                          employment?:
                            | TUserManagementInvoiceDataEmploymentEnum
                            | null
                            | undefined
                          iban?: string | null | undefined
                          invoiceWithUst?: boolean | null | undefined
                          street?: string | null | undefined
                          taxNumber?: string | null | undefined
                          zip?: string | null | undefined
                        }
                      | null
                      | undefined
                  }
                | null
                | undefined
              verificationProcess?:
                | {
                    __typename?: 'UserManagementVerificationProcess'
                    denyReason?: string | null | undefined
                    state?:
                      | TUserManagementVerificationProcessStateEnum
                      | null
                      | undefined
                  }
                | null
                | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type TStoreUserDrugNoteMutationVariables = Exact<{
  drugs: Array<Scalars['Int']> | Scalars['Int']
  id?: InputMaybe<Scalars['Int']>
  note: Scalars['String']
}>

export type TStoreUserDrugNoteMutation = {
  __typename?: 'RootMutationType'
  userDrugNote?:
    | {
        __typename?: 'UserDrugNote'
        drugs: Array<number>
        id: number
        note: string
        insertedAt: string
      }
    | null
    | undefined
}

export type TDeleteUserDrugNoteMutationVariables = Exact<{
  id: Scalars['Int']
}>

export type TDeleteUserDrugNoteMutation = {
  __typename?: 'RootMutationType'
  userDrugNoteDelete?:
    | { __typename?: 'SchemaEmpty'; empty?: string | null | undefined }
    | null
    | undefined
}

export type TStoreUserBookmarkMutationVariables = Exact<{
  atc?: InputMaybe<Scalars['String']>
  country?: InputMaybe<TSchemaCountryEnum>
  drugs?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >
  filterGrav?: InputMaybe<Scalars['Boolean']>
  filterHepar?: InputMaybe<Scalars['String']>
  filterLact?: InputMaybe<Scalars['Boolean']>
  filterRenal?: InputMaybe<Scalars['String']>
  icd10?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  substances?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >
  substitute?: InputMaybe<Scalars['String']>
  type?: InputMaybe<TUserManagementBookmarkTypeEnum>
}>

export type TStoreUserBookmarkMutation = {
  __typename?: 'RootMutationType'
  userManagementBookmark?:
    | {
        __typename?: 'UserManagementBookmark'
        atc?: string | null | undefined
        country?: TSchemaCountryEnum | null | undefined
        drugs?: Array<string | null | undefined> | null | undefined
        icd10?: string | null | undefined
        id?: number | null | undefined
        name?: string | null | undefined
        substances?: Array<string | null | undefined> | null | undefined
        substitute?: string | null | undefined
        type?: TUserManagementBookmarkTypeEnum | null | undefined
        filter?:
          | {
              __typename?: 'UserManagementBookmarkFilter'
              grav?: boolean | null | undefined
              hepar?: string | null | undefined
              lact?: boolean | null | undefined
              renal?: string | null | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type TDeleteUserBookmarkMutationVariables = Exact<{
  id: Scalars['Int']
}>

export type TDeleteUserBookmarkMutation = {
  __typename?: 'RootMutationType'
  userManagementBookmarkDelete?:
    | { __typename?: 'SchemaEmpty'; empty?: string | null | undefined }
    | null
    | undefined
}

export type TUploadImagesMutationVariables = Exact<{
  images: Array<InputMaybe<Scalars['Upload']>> | InputMaybe<Scalars['Upload']>
}>

export type TUploadImagesMutation = {
  __typename?: 'RootMutationType'
  userManagementVerificationProcess?:
    | {
        __typename?: 'UserManagementUser'
        email?: string | null | undefined
        emailConfirmed?: boolean | null | undefined
        permissionSet?: Array<TUserManagementPermissionsEnum> | null | undefined
        uuid?: string | null | undefined
        ads?:
          | Array<{
              __typename?: 'AdServiceAd'
              countries?:
                | Array<TAdServiceAdCountryEnum | null | undefined>
                | null
                | undefined
              preview?: boolean | null | undefined
              uuid?: string | null | undefined
              placement?:
                | {
                    __typename?: 'AdServicePlacementAtcDetail'
                    atcCodes?:
                      | Array<string | null | undefined>
                      | null
                      | undefined
                    placement?: TAdServiceAdPlacementEnum | null | undefined
                  }
                | { __typename?: 'AdServicePlacementAtcDetailTop' }
                | {
                    __typename?: 'AdServicePlacementProductDetail'
                    placement?: TAdServiceAdPlacementEnum | null | undefined
                    productIds?:
                      | Array<string | null | undefined>
                      | null
                      | undefined
                  }
                | { __typename?: 'AdServicePlacementProductDetailBottom' }
                | { __typename?: 'AdServicePlacementProductDetailTop' }
                | {
                    __typename?: 'AdServicePlacementSubstanceDetail'
                    placement?: TAdServiceAdPlacementEnum | null | undefined
                    substances?:
                      | Array<string | null | undefined>
                      | null
                      | undefined
                  }
                | { __typename?: 'AdServicePlacementSubstanceDetailTop' }
                | null
                | undefined
              type?:
                | { __typename?: 'AdServiceTypeBanner32050' }
                | {
                    __typename?: 'AdServiceTypeBanner46860'
                    image?: string | null | undefined
                    link?: string | null | undefined
                    type?: TAdServiceAdTypeEnum | null | undefined
                  }
                | { __typename?: 'AdServiceTypeBanner320100' }
                | {
                    __typename?: 'AdServiceTypeProductIntegration'
                    disclosure?: string | null | undefined
                    type?: TAdServiceAdTypeEnum | null | undefined
                    entries?:
                      | Array<
                          | {
                              __typename?: 'AdServiceTypeProductIntegrationEntry'
                              description?: string | null | undefined
                              link?: string | null | undefined
                              title?: string | null | undefined
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined
                  }
                | null
                | undefined
            }>
          | null
          | undefined
        enterpriseProfile?:
          | {
              __typename?: 'UserManagementEnterpriseProfile'
              webMedCountry?:
                | TUserManagementEnterpriseWebMedCountryEnum
                | null
                | undefined
            }
          | null
          | undefined
        news?:
          | Array<{
              __typename?: 'NewsServiceNews'
              countries?:
                | Array<TNewsServiceNewsCountryEnum | null | undefined>
                | null
                | undefined
              externalLink?: string | null | undefined
              image?: string | null | undefined
              overwriteCanHideAds?: boolean | null | undefined
              preview?: boolean | null | undefined
              priority?: TNewsServiceNewsPriorityEnum | null | undefined
              title?: string | null | undefined
              uuid?: string | null | undefined
            }>
          | null
          | undefined
        organisation?:
          | {
              __typename?: 'UserManagementOrganisation'
              name?: string | null | undefined
              uuid?: string | null | undefined
            }
          | null
          | undefined
        profile?:
          | {
              __typename?: 'UserManagementProfile'
              country?: TUserManagementCountryEnum | null | undefined
              fieldsOfStudy?:
                | Array<TUserManagementFieldOfStudyEnum | null | undefined>
                | null
                | undefined
              firstName?: string | null | undefined
              gender?: TUserManagementGenderEnum | null | undefined
              graduationYear?: number | null | undefined
              hasOnboardingFinished?: boolean | null | undefined
              image?: string | null | undefined
              lastName?: string | null | undefined
              occupation?: TUserManagementOccupationEnum | null | undefined
              specialities?:
                | Array<TUserManagementSpecialityEnum | null | undefined>
                | null
                | undefined
              interests?:
                | Array<TUserManagementInterestEnum | null | undefined>
                | null
                | undefined
              title?: string | null | undefined
              suffix?: string | null | undefined
              trialDuration?: number | null | undefined
              verificationNumber?: string | null | undefined
              verificationNumberType?:
                | TUserManagementVerificationNumberTypeEnum
                | null
                | undefined
              verifiedProfessional?: boolean | null | undefined
              webMedCountry?:
                | TUserManagementWebMedCountryEnum
                | null
                | undefined
              workplace?: TUserManagementWorkplaceEnum | null | undefined
              zip?: string | null | undefined
              invoiceData?:
                | {
                    __typename?: 'UserManagementInvoiceData'
                    city?: string | null | undefined
                    employment?:
                      | TUserManagementInvoiceDataEmploymentEnum
                      | null
                      | undefined
                    iban?: string | null | undefined
                    invoiceWithUst?: boolean | null | undefined
                    street?: string | null | undefined
                    taxNumber?: string | null | undefined
                    zip?: string | null | undefined
                  }
                | null
                | undefined
            }
          | null
          | undefined
        verificationProcess?:
          | {
              __typename?: 'UserManagementVerificationProcess'
              denyReason?: string | null | undefined
              state?:
                | TUserManagementVerificationProcessStateEnum
                | null
                | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type TUploadProfileImageMutationVariables = Exact<{
  image: Scalars['Upload']
}>

export type TUploadProfileImageMutation = {
  __typename?: 'RootMutationType'
  userManagementProfileImage?:
    | { __typename?: 'UserManagementUser'; email?: string | null | undefined }
    | null
    | undefined
}

export type TUpdateUserInvoiceDataMutationVariables = Exact<{
  city?: InputMaybe<Scalars['String']>
  employment?: InputMaybe<TUserManagementInvoiceDataEmploymentEnum>
  iban?: InputMaybe<Scalars['String']>
  invoiceWithUst?: InputMaybe<Scalars['Boolean']>
  street?: InputMaybe<Scalars['String']>
  taxNumber?: InputMaybe<Scalars['String']>
  zip?: InputMaybe<Scalars['String']>
}>

export type TUpdateUserInvoiceDataMutation = {
  __typename?: 'RootMutationType'
  userManagementInvoiceData?:
    | {
        __typename?: 'UserManagementUser'
        email?: string | null | undefined
        emailConfirmed?: boolean | null | undefined
        permissionSet?: Array<TUserManagementPermissionsEnum> | null | undefined
        uuid?: string | null | undefined
        ads?:
          | Array<{
              __typename?: 'AdServiceAd'
              countries?:
                | Array<TAdServiceAdCountryEnum | null | undefined>
                | null
                | undefined
              preview?: boolean | null | undefined
              uuid?: string | null | undefined
              placement?:
                | {
                    __typename?: 'AdServicePlacementAtcDetail'
                    atcCodes?:
                      | Array<string | null | undefined>
                      | null
                      | undefined
                    placement?: TAdServiceAdPlacementEnum | null | undefined
                  }
                | { __typename?: 'AdServicePlacementAtcDetailTop' }
                | {
                    __typename?: 'AdServicePlacementProductDetail'
                    placement?: TAdServiceAdPlacementEnum | null | undefined
                    productIds?:
                      | Array<string | null | undefined>
                      | null
                      | undefined
                  }
                | { __typename?: 'AdServicePlacementProductDetailBottom' }
                | { __typename?: 'AdServicePlacementProductDetailTop' }
                | {
                    __typename?: 'AdServicePlacementSubstanceDetail'
                    placement?: TAdServiceAdPlacementEnum | null | undefined
                    substances?:
                      | Array<string | null | undefined>
                      | null
                      | undefined
                  }
                | { __typename?: 'AdServicePlacementSubstanceDetailTop' }
                | null
                | undefined
              type?:
                | { __typename?: 'AdServiceTypeBanner32050' }
                | {
                    __typename?: 'AdServiceTypeBanner46860'
                    image?: string | null | undefined
                    link?: string | null | undefined
                    type?: TAdServiceAdTypeEnum | null | undefined
                  }
                | { __typename?: 'AdServiceTypeBanner320100' }
                | {
                    __typename?: 'AdServiceTypeProductIntegration'
                    disclosure?: string | null | undefined
                    type?: TAdServiceAdTypeEnum | null | undefined
                    entries?:
                      | Array<
                          | {
                              __typename?: 'AdServiceTypeProductIntegrationEntry'
                              description?: string | null | undefined
                              link?: string | null | undefined
                              title?: string | null | undefined
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined
                  }
                | null
                | undefined
            }>
          | null
          | undefined
        enterpriseProfile?:
          | {
              __typename?: 'UserManagementEnterpriseProfile'
              webMedCountry?:
                | TUserManagementEnterpriseWebMedCountryEnum
                | null
                | undefined
            }
          | null
          | undefined
        news?:
          | Array<{
              __typename?: 'NewsServiceNews'
              countries?:
                | Array<TNewsServiceNewsCountryEnum | null | undefined>
                | null
                | undefined
              externalLink?: string | null | undefined
              image?: string | null | undefined
              overwriteCanHideAds?: boolean | null | undefined
              preview?: boolean | null | undefined
              priority?: TNewsServiceNewsPriorityEnum | null | undefined
              title?: string | null | undefined
              uuid?: string | null | undefined
            }>
          | null
          | undefined
        organisation?:
          | {
              __typename?: 'UserManagementOrganisation'
              name?: string | null | undefined
              uuid?: string | null | undefined
            }
          | null
          | undefined
        profile?:
          | {
              __typename?: 'UserManagementProfile'
              country?: TUserManagementCountryEnum | null | undefined
              fieldsOfStudy?:
                | Array<TUserManagementFieldOfStudyEnum | null | undefined>
                | null
                | undefined
              firstName?: string | null | undefined
              gender?: TUserManagementGenderEnum | null | undefined
              graduationYear?: number | null | undefined
              hasOnboardingFinished?: boolean | null | undefined
              image?: string | null | undefined
              lastName?: string | null | undefined
              occupation?: TUserManagementOccupationEnum | null | undefined
              specialities?:
                | Array<TUserManagementSpecialityEnum | null | undefined>
                | null
                | undefined
              interests?:
                | Array<TUserManagementInterestEnum | null | undefined>
                | null
                | undefined
              title?: string | null | undefined
              suffix?: string | null | undefined
              trialDuration?: number | null | undefined
              verificationNumber?: string | null | undefined
              verificationNumberType?:
                | TUserManagementVerificationNumberTypeEnum
                | null
                | undefined
              verifiedProfessional?: boolean | null | undefined
              webMedCountry?:
                | TUserManagementWebMedCountryEnum
                | null
                | undefined
              workplace?: TUserManagementWorkplaceEnum | null | undefined
              zip?: string | null | undefined
              invoiceData?:
                | {
                    __typename?: 'UserManagementInvoiceData'
                    city?: string | null | undefined
                    employment?:
                      | TUserManagementInvoiceDataEmploymentEnum
                      | null
                      | undefined
                    iban?: string | null | undefined
                    invoiceWithUst?: boolean | null | undefined
                    street?: string | null | undefined
                    taxNumber?: string | null | undefined
                    zip?: string | null | undefined
                  }
                | null
                | undefined
            }
          | null
          | undefined
        verificationProcess?:
          | {
              __typename?: 'UserManagementVerificationProcess'
              denyReason?: string | null | undefined
              state?:
                | TUserManagementVerificationProcessStateEnum
                | null
                | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type TUpdateUserProfileMutationVariables = Exact<{
  occupation?: InputMaybe<TUserManagementOccupationEnum>
  webMedCountry?: InputMaybe<TUserManagementWebMedCountryEnum>
  suffix?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  firstName?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  gender?: InputMaybe<TUserManagementGenderEnum>
  interests?: InputMaybe<
    | Array<InputMaybe<TUserManagementInterestEnum>>
    | InputMaybe<TUserManagementInterestEnum>
  >
  country?: InputMaybe<TUserManagementCountryEnum>
  workplace?: InputMaybe<TUserManagementWorkplaceEnum>
  zip?: InputMaybe<Scalars['String']>
  specialities?: InputMaybe<
    | Array<InputMaybe<TUserManagementSpecialityEnum>>
    | InputMaybe<TUserManagementSpecialityEnum>
  >
  fieldsOfStudy?: InputMaybe<
    | Array<InputMaybe<TUserManagementFieldOfStudyEnum>>
    | InputMaybe<TUserManagementFieldOfStudyEnum>
  >
  graduationYear?: InputMaybe<Scalars['Int']>
  verificationNumber?: InputMaybe<Scalars['String']>
  verificationNumberType?: InputMaybe<TUserManagementVerificationNumberTypeEnum>
}>

export type TUpdateUserProfileMutation = {
  __typename?: 'RootMutationType'
  userManagementProfile?:
    | {
        __typename?: 'UserManagementUser'
        email?: string | null | undefined
        emailConfirmed?: boolean | null | undefined
        permissionSet?: Array<TUserManagementPermissionsEnum> | null | undefined
        uuid?: string | null | undefined
        ads?:
          | Array<{
              __typename?: 'AdServiceAd'
              countries?:
                | Array<TAdServiceAdCountryEnum | null | undefined>
                | null
                | undefined
              preview?: boolean | null | undefined
              uuid?: string | null | undefined
              placement?:
                | {
                    __typename?: 'AdServicePlacementAtcDetail'
                    atcCodes?:
                      | Array<string | null | undefined>
                      | null
                      | undefined
                    placement?: TAdServiceAdPlacementEnum | null | undefined
                  }
                | { __typename?: 'AdServicePlacementAtcDetailTop' }
                | {
                    __typename?: 'AdServicePlacementProductDetail'
                    placement?: TAdServiceAdPlacementEnum | null | undefined
                    productIds?:
                      | Array<string | null | undefined>
                      | null
                      | undefined
                  }
                | { __typename?: 'AdServicePlacementProductDetailBottom' }
                | { __typename?: 'AdServicePlacementProductDetailTop' }
                | {
                    __typename?: 'AdServicePlacementSubstanceDetail'
                    placement?: TAdServiceAdPlacementEnum | null | undefined
                    substances?:
                      | Array<string | null | undefined>
                      | null
                      | undefined
                  }
                | { __typename?: 'AdServicePlacementSubstanceDetailTop' }
                | null
                | undefined
              type?:
                | { __typename?: 'AdServiceTypeBanner32050' }
                | {
                    __typename?: 'AdServiceTypeBanner46860'
                    image?: string | null | undefined
                    link?: string | null | undefined
                    type?: TAdServiceAdTypeEnum | null | undefined
                  }
                | { __typename?: 'AdServiceTypeBanner320100' }
                | {
                    __typename?: 'AdServiceTypeProductIntegration'
                    disclosure?: string | null | undefined
                    type?: TAdServiceAdTypeEnum | null | undefined
                    entries?:
                      | Array<
                          | {
                              __typename?: 'AdServiceTypeProductIntegrationEntry'
                              description?: string | null | undefined
                              link?: string | null | undefined
                              title?: string | null | undefined
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined
                  }
                | null
                | undefined
            }>
          | null
          | undefined
        enterpriseProfile?:
          | {
              __typename?: 'UserManagementEnterpriseProfile'
              webMedCountry?:
                | TUserManagementEnterpriseWebMedCountryEnum
                | null
                | undefined
            }
          | null
          | undefined
        news?:
          | Array<{
              __typename?: 'NewsServiceNews'
              countries?:
                | Array<TNewsServiceNewsCountryEnum | null | undefined>
                | null
                | undefined
              externalLink?: string | null | undefined
              image?: string | null | undefined
              overwriteCanHideAds?: boolean | null | undefined
              preview?: boolean | null | undefined
              priority?: TNewsServiceNewsPriorityEnum | null | undefined
              title?: string | null | undefined
              uuid?: string | null | undefined
            }>
          | null
          | undefined
        organisation?:
          | {
              __typename?: 'UserManagementOrganisation'
              name?: string | null | undefined
              uuid?: string | null | undefined
            }
          | null
          | undefined
        profile?:
          | {
              __typename?: 'UserManagementProfile'
              country?: TUserManagementCountryEnum | null | undefined
              fieldsOfStudy?:
                | Array<TUserManagementFieldOfStudyEnum | null | undefined>
                | null
                | undefined
              firstName?: string | null | undefined
              gender?: TUserManagementGenderEnum | null | undefined
              graduationYear?: number | null | undefined
              hasOnboardingFinished?: boolean | null | undefined
              image?: string | null | undefined
              lastName?: string | null | undefined
              occupation?: TUserManagementOccupationEnum | null | undefined
              specialities?:
                | Array<TUserManagementSpecialityEnum | null | undefined>
                | null
                | undefined
              interests?:
                | Array<TUserManagementInterestEnum | null | undefined>
                | null
                | undefined
              title?: string | null | undefined
              suffix?: string | null | undefined
              trialDuration?: number | null | undefined
              verificationNumber?: string | null | undefined
              verificationNumberType?:
                | TUserManagementVerificationNumberTypeEnum
                | null
                | undefined
              verifiedProfessional?: boolean | null | undefined
              webMedCountry?:
                | TUserManagementWebMedCountryEnum
                | null
                | undefined
              workplace?: TUserManagementWorkplaceEnum | null | undefined
              zip?: string | null | undefined
              invoiceData?:
                | {
                    __typename?: 'UserManagementInvoiceData'
                    city?: string | null | undefined
                    employment?:
                      | TUserManagementInvoiceDataEmploymentEnum
                      | null
                      | undefined
                    iban?: string | null | undefined
                    invoiceWithUst?: boolean | null | undefined
                    street?: string | null | undefined
                    taxNumber?: string | null | undefined
                    zip?: string | null | undefined
                  }
                | null
                | undefined
            }
          | null
          | undefined
        verificationProcess?:
          | {
              __typename?: 'UserManagementVerificationProcess'
              denyReason?: string | null | undefined
              state?:
                | TUserManagementVerificationProcessStateEnum
                | null
                | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type TConfirmEmailMutationVariables = Exact<{
  emailConfirmationToken: Scalars['String']
}>

export type TConfirmEmailMutation = {
  __typename?: 'RootMutationType'
  userManagementConfirmEmail?:
    | { __typename?: 'SchemaEmpty'; empty?: string | null | undefined }
    | null
    | undefined
}

export type TConfirmNewEmailMutationVariables = Exact<{
  emailConfirmationToken: Scalars['String']
}>

export type TConfirmNewEmailMutation = {
  __typename?: 'RootMutationType'
  userManagementConfirmNewEmail?:
    | { __typename?: 'SchemaEmpty'; empty?: string | null | undefined }
    | null
    | undefined
}

export type TResendConfirmationEmailMutationVariables = Exact<{
  emailAddress: Scalars['String']
}>

export type TResendConfirmationEmailMutation = {
  __typename?: 'RootMutationType'
  userManagementConfirmEmailResend?:
    | { __typename?: 'SchemaEmpty'; empty?: string | null | undefined }
    | null
    | undefined
}

export type TResetEmailMutationVariables = Exact<{
  email: Scalars['String']
}>

export type TResetEmailMutation = {
  __typename?: 'RootMutationType'
  userManagementNewEmail?:
    | { __typename?: 'SchemaEmpty'; empty?: string | null | undefined }
    | null
    | undefined
}

export type TPasswordResetEmailMutationVariables = Exact<{
  emailAddress: Scalars['String']
}>

export type TPasswordResetEmailMutation = {
  __typename?: 'RootMutationType'
  userManagementPasswordResetEmail?:
    | { __typename?: 'SchemaEmpty'; empty?: string | null | undefined }
    | null
    | undefined
}

export type TResetPasswordMutationVariables = Exact<{
  newPassword: Scalars['String']
  resetToken: Scalars['String']
}>

export type TResetPasswordMutation = {
  __typename?: 'RootMutationType'
  userManagementPasswordReset?:
    | { __typename?: 'SchemaEmpty'; empty?: string | null | undefined }
    | null
    | undefined
}

export type TUserDeleteMutationVariables = Exact<{
  password: Scalars['String']
}>

export type TUserDeleteMutation = {
  __typename?: 'RootMutationType'
  userManagementUserDelete?:
    | { __typename?: 'SchemaEmpty'; empty?: string | null | undefined }
    | null
    | undefined
}

export type TOnUserBookmarksUpdatedSubscriptionVariables = Exact<{
  [key: string]: never
}>

export type TOnUserBookmarksUpdatedSubscription = {
  __typename?: 'RootSubscriptionType'
  userManagementBookmarks?:
    | Array<
        | {
            __typename?: 'UserManagementBookmark'
            atc?: string | null | undefined
            country?: TSchemaCountryEnum | null | undefined
            drugs?: Array<string | null | undefined> | null | undefined
            icd10?: string | null | undefined
            id?: number | null | undefined
            name?: string | null | undefined
            substances?: Array<string | null | undefined> | null | undefined
            substitute?: string | null | undefined
            type?: TUserManagementBookmarkTypeEnum | null | undefined
            filter?:
              | {
                  __typename?: 'UserManagementBookmarkFilter'
                  grav?: boolean | null | undefined
                  hepar?: string | null | undefined
                  lact?: boolean | null | undefined
                  renal?: string | null | undefined
                }
              | null
              | undefined
          }
        | null
        | undefined
      >
    | null
    | undefined
}

export type TOnUserDataUpdatedSubscriptionVariables = Exact<{
  [key: string]: never
}>

export type TOnUserDataUpdatedSubscription = {
  __typename?: 'RootSubscriptionType'
  userManagementUser?:
    | {
        __typename?: 'UserManagementUser'
        email?: string | null | undefined
        emailConfirmed?: boolean | null | undefined
        permissionSet?: Array<TUserManagementPermissionsEnum> | null | undefined
        uuid?: string | null | undefined
        ads?:
          | Array<{
              __typename?: 'AdServiceAd'
              countries?:
                | Array<TAdServiceAdCountryEnum | null | undefined>
                | null
                | undefined
              preview?: boolean | null | undefined
              uuid?: string | null | undefined
              placement?:
                | {
                    __typename?: 'AdServicePlacementAtcDetail'
                    atcCodes?:
                      | Array<string | null | undefined>
                      | null
                      | undefined
                    placement?: TAdServiceAdPlacementEnum | null | undefined
                  }
                | { __typename?: 'AdServicePlacementAtcDetailTop' }
                | {
                    __typename?: 'AdServicePlacementProductDetail'
                    placement?: TAdServiceAdPlacementEnum | null | undefined
                    productIds?:
                      | Array<string | null | undefined>
                      | null
                      | undefined
                  }
                | { __typename?: 'AdServicePlacementProductDetailBottom' }
                | { __typename?: 'AdServicePlacementProductDetailTop' }
                | {
                    __typename?: 'AdServicePlacementSubstanceDetail'
                    placement?: TAdServiceAdPlacementEnum | null | undefined
                    substances?:
                      | Array<string | null | undefined>
                      | null
                      | undefined
                  }
                | { __typename?: 'AdServicePlacementSubstanceDetailTop' }
                | null
                | undefined
              type?:
                | { __typename?: 'AdServiceTypeBanner32050' }
                | {
                    __typename?: 'AdServiceTypeBanner46860'
                    image?: string | null | undefined
                    link?: string | null | undefined
                    type?: TAdServiceAdTypeEnum | null | undefined
                  }
                | { __typename?: 'AdServiceTypeBanner320100' }
                | {
                    __typename?: 'AdServiceTypeProductIntegration'
                    disclosure?: string | null | undefined
                    type?: TAdServiceAdTypeEnum | null | undefined
                    entries?:
                      | Array<
                          | {
                              __typename?: 'AdServiceTypeProductIntegrationEntry'
                              description?: string | null | undefined
                              link?: string | null | undefined
                              title?: string | null | undefined
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined
                  }
                | null
                | undefined
            }>
          | null
          | undefined
        enterpriseProfile?:
          | {
              __typename?: 'UserManagementEnterpriseProfile'
              webMedCountry?:
                | TUserManagementEnterpriseWebMedCountryEnum
                | null
                | undefined
            }
          | null
          | undefined
        news?:
          | Array<{
              __typename?: 'NewsServiceNews'
              countries?:
                | Array<TNewsServiceNewsCountryEnum | null | undefined>
                | null
                | undefined
              externalLink?: string | null | undefined
              image?: string | null | undefined
              overwriteCanHideAds?: boolean | null | undefined
              preview?: boolean | null | undefined
              priority?: TNewsServiceNewsPriorityEnum | null | undefined
              title?: string | null | undefined
              uuid?: string | null | undefined
            }>
          | null
          | undefined
        organisation?:
          | {
              __typename?: 'UserManagementOrganisation'
              name?: string | null | undefined
              uuid?: string | null | undefined
            }
          | null
          | undefined
        profile?:
          | {
              __typename?: 'UserManagementProfile'
              country?: TUserManagementCountryEnum | null | undefined
              fieldsOfStudy?:
                | Array<TUserManagementFieldOfStudyEnum | null | undefined>
                | null
                | undefined
              firstName?: string | null | undefined
              gender?: TUserManagementGenderEnum | null | undefined
              graduationYear?: number | null | undefined
              hasOnboardingFinished?: boolean | null | undefined
              image?: string | null | undefined
              lastName?: string | null | undefined
              occupation?: TUserManagementOccupationEnum | null | undefined
              specialities?:
                | Array<TUserManagementSpecialityEnum | null | undefined>
                | null
                | undefined
              interests?:
                | Array<TUserManagementInterestEnum | null | undefined>
                | null
                | undefined
              title?: string | null | undefined
              suffix?: string | null | undefined
              trialDuration?: number | null | undefined
              verificationNumber?: string | null | undefined
              verificationNumberType?:
                | TUserManagementVerificationNumberTypeEnum
                | null
                | undefined
              verifiedProfessional?: boolean | null | undefined
              webMedCountry?:
                | TUserManagementWebMedCountryEnum
                | null
                | undefined
              workplace?: TUserManagementWorkplaceEnum | null | undefined
              zip?: string | null | undefined
              invoiceData?:
                | {
                    __typename?: 'UserManagementInvoiceData'
                    city?: string | null | undefined
                    employment?:
                      | TUserManagementInvoiceDataEmploymentEnum
                      | null
                      | undefined
                    iban?: string | null | undefined
                    invoiceWithUst?: boolean | null | undefined
                    street?: string | null | undefined
                    taxNumber?: string | null | undefined
                    zip?: string | null | undefined
                  }
                | null
                | undefined
            }
          | null
          | undefined
        verificationProcess?:
          | {
              __typename?: 'UserManagementVerificationProcess'
              denyReason?: string | null | undefined
              state?:
                | TUserManagementVerificationProcessStateEnum
                | null
                | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export const FullSessionFragmentDoc = gql`
  fragment FullSession on AuthenticationServiceSession {
    accessToken
    legacyToken
    refreshToken
  }
`
export const FullUserFragmentDoc = gql`
  fragment FullUser on UserManagementUser {
    ads {
      countries
      placement {
        ... on AdServicePlacementAtcDetail {
          atcCodes
          placement
        }
        ... on AdServicePlacementProductDetail {
          placement
          productIds
        }
        ... on AdServicePlacementSubstanceDetail {
          placement
          substances
        }
      }
      preview
      type {
        ... on AdServiceTypeBanner46860 {
          image
          link
          type
        }
        ... on AdServiceTypeProductIntegration {
          disclosure
          entries {
            description
            link
            title
          }
          type
        }
      }
      uuid
    }
    email
    emailConfirmed
    enterpriseProfile {
      webMedCountry
    }
    news(app: NEOCORTEX_WEB) {
      countries
      externalLink
      image
      overwriteCanHideAds
      preview
      priority
      title
      uuid
    }
    organisation {
      name
      uuid
    }
    permissionSet
    profile {
      country
      fieldsOfStudy
      firstName
      gender
      graduationYear
      hasOnboardingFinished
      invoiceData {
        city
        employment
        iban
        invoiceWithUst
        street
        taxNumber
        zip
      }
      image
      lastName
      occupation
      specialities
      interests
      title
      suffix
      trialDuration
      verificationNumber
      verificationNumberType
      verifiedProfessional
      webMedCountry
      workplace
      zip
    }
    uuid
    verificationProcess {
      denyReason
      state
    }
  }
`
export const FullUserAndSessionFragmentDoc = gql`
  fragment FullUserAndSession on UserManagementUserAndSession {
    session {
      ...FullSession
    }
    user {
      ...FullUser
    }
  }
  ${FullSessionFragmentDoc}
  ${FullUserFragmentDoc}
`
export const DrugNoteFragmentDoc = gql`
  fragment DrugNote on UserDrugNote {
    drugs
    id
    note
    insertedAt
  }
`
export const BookmarkFragmentDoc = gql`
  fragment Bookmark on UserManagementBookmark {
    atc
    country
    drugs
    filter {
      grav
      hepar
      lact
      renal
    }
    icd10
    id
    name
    substances
    substitute
    type
  }
`
export const FullUserManagementItemFragmentDoc = gql`
  fragment FullUserManagementItem on UserManagementItems {
    isActive
    key
    sort
    translations {
      language
      text
    }
  }
`
export const LoginDocument = gql`
  query Login($email: String!, $password: String!) {
    authenticationServiceLogin(email: $email, password: $password) {
      ...FullUserAndSession
    }
  }
  ${FullUserAndSessionFragmentDoc}
`

/**
 * __useLoginQuery__
 *
 * To run a query within a React component, call `useLoginQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginQuery({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginQuery(
  baseOptions: Apollo.QueryHookOptions<TLoginQuery, TLoginQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TLoginQuery, TLoginQueryVariables>(
    LoginDocument,
    options,
  )
}
export function useLoginLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TLoginQuery, TLoginQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TLoginQuery, TLoginQueryVariables>(
    LoginDocument,
    options,
  )
}
export type LoginQueryHookResult = ReturnType<typeof useLoginQuery>
export type LoginLazyQueryHookResult = ReturnType<typeof useLoginLazyQuery>
export type LoginQueryResult = Apollo.QueryResult<
  TLoginQuery,
  TLoginQueryVariables
>
export const TokenLoginDocument = gql`
  query TokenLogin($token: String!) {
    authenticationServiceTokenLogin(token: $token) {
      ...FullUserAndSession
    }
  }
  ${FullUserAndSessionFragmentDoc}
`

/**
 * __useTokenLoginQuery__
 *
 * To run a query within a React component, call `useTokenLoginQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokenLoginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokenLoginQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useTokenLoginQuery(
  baseOptions: Apollo.QueryHookOptions<
    TTokenLoginQuery,
    TTokenLoginQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TTokenLoginQuery, TTokenLoginQueryVariables>(
    TokenLoginDocument,
    options,
  )
}
export function useTokenLoginLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TTokenLoginQuery,
    TTokenLoginQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TTokenLoginQuery, TTokenLoginQueryVariables>(
    TokenLoginDocument,
    options,
  )
}
export type TokenLoginQueryHookResult = ReturnType<typeof useTokenLoginQuery>
export type TokenLoginLazyQueryHookResult = ReturnType<
  typeof useTokenLoginLazyQuery
>
export type TokenLoginQueryResult = Apollo.QueryResult<
  TTokenLoginQuery,
  TTokenLoginQueryVariables
>
export const FetchRefreshTokenDocument = gql`
  query FetchRefreshToken($token: String!) {
    authenticationServiceRefreshLogin(token: $token) {
      ...FullUserAndSession
    }
  }
  ${FullUserAndSessionFragmentDoc}
`

/**
 * __useFetchRefreshTokenQuery__
 *
 * To run a query within a React component, call `useFetchRefreshTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchRefreshTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchRefreshTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useFetchRefreshTokenQuery(
  baseOptions: Apollo.QueryHookOptions<
    TFetchRefreshTokenQuery,
    TFetchRefreshTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    TFetchRefreshTokenQuery,
    TFetchRefreshTokenQueryVariables
  >(FetchRefreshTokenDocument, options)
}
export function useFetchRefreshTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TFetchRefreshTokenQuery,
    TFetchRefreshTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    TFetchRefreshTokenQuery,
    TFetchRefreshTokenQueryVariables
  >(FetchRefreshTokenDocument, options)
}
export type FetchRefreshTokenQueryHookResult = ReturnType<
  typeof useFetchRefreshTokenQuery
>
export type FetchRefreshTokenLazyQueryHookResult = ReturnType<
  typeof useFetchRefreshTokenLazyQuery
>
export type FetchRefreshTokenQueryResult = Apollo.QueryResult<
  TFetchRefreshTokenQuery,
  TFetchRefreshTokenQueryVariables
>
export const FetchUserDocument = gql`
  query FetchUser {
    userManagementUser {
      ...FullUser
    }
  }
  ${FullUserFragmentDoc}
`

/**
 * __useFetchUserQuery__
 *
 * To run a query within a React component, call `useFetchUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TFetchUserQuery,
    TFetchUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TFetchUserQuery, TFetchUserQueryVariables>(
    FetchUserDocument,
    options,
  )
}
export function useFetchUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TFetchUserQuery,
    TFetchUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TFetchUserQuery, TFetchUserQueryVariables>(
    FetchUserDocument,
    options,
  )
}
export type FetchUserQueryHookResult = ReturnType<typeof useFetchUserQuery>
export type FetchUserLazyQueryHookResult = ReturnType<
  typeof useFetchUserLazyQuery
>
export type FetchUserQueryResult = Apollo.QueryResult<
  TFetchUserQuery,
  TFetchUserQueryVariables
>
export const FetchUserDrugNoteDocument = gql`
  query FetchUserDrugNote($id: Int!) {
    userDrugNote(id: $id) {
      ...DrugNote
    }
  }
  ${DrugNoteFragmentDoc}
`

/**
 * __useFetchUserDrugNoteQuery__
 *
 * To run a query within a React component, call `useFetchUserDrugNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchUserDrugNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchUserDrugNoteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchUserDrugNoteQuery(
  baseOptions: Apollo.QueryHookOptions<
    TFetchUserDrugNoteQuery,
    TFetchUserDrugNoteQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    TFetchUserDrugNoteQuery,
    TFetchUserDrugNoteQueryVariables
  >(FetchUserDrugNoteDocument, options)
}
export function useFetchUserDrugNoteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TFetchUserDrugNoteQuery,
    TFetchUserDrugNoteQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    TFetchUserDrugNoteQuery,
    TFetchUserDrugNoteQueryVariables
  >(FetchUserDrugNoteDocument, options)
}
export type FetchUserDrugNoteQueryHookResult = ReturnType<
  typeof useFetchUserDrugNoteQuery
>
export type FetchUserDrugNoteLazyQueryHookResult = ReturnType<
  typeof useFetchUserDrugNoteLazyQuery
>
export type FetchUserDrugNoteQueryResult = Apollo.QueryResult<
  TFetchUserDrugNoteQuery,
  TFetchUserDrugNoteQueryVariables
>
export const FetchUserDrugNoteForDrugDocument = gql`
  query FetchUserDrugNoteForDrug($drugId: Int!) {
    userDrugNoteForDrug(drugId: $drugId) {
      ...DrugNote
    }
  }
  ${DrugNoteFragmentDoc}
`

/**
 * __useFetchUserDrugNoteForDrugQuery__
 *
 * To run a query within a React component, call `useFetchUserDrugNoteForDrugQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchUserDrugNoteForDrugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchUserDrugNoteForDrugQuery({
 *   variables: {
 *      drugId: // value for 'drugId'
 *   },
 * });
 */
export function useFetchUserDrugNoteForDrugQuery(
  baseOptions: Apollo.QueryHookOptions<
    TFetchUserDrugNoteForDrugQuery,
    TFetchUserDrugNoteForDrugQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    TFetchUserDrugNoteForDrugQuery,
    TFetchUserDrugNoteForDrugQueryVariables
  >(FetchUserDrugNoteForDrugDocument, options)
}
export function useFetchUserDrugNoteForDrugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TFetchUserDrugNoteForDrugQuery,
    TFetchUserDrugNoteForDrugQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    TFetchUserDrugNoteForDrugQuery,
    TFetchUserDrugNoteForDrugQueryVariables
  >(FetchUserDrugNoteForDrugDocument, options)
}
export type FetchUserDrugNoteForDrugQueryHookResult = ReturnType<
  typeof useFetchUserDrugNoteForDrugQuery
>
export type FetchUserDrugNoteForDrugLazyQueryHookResult = ReturnType<
  typeof useFetchUserDrugNoteForDrugLazyQuery
>
export type FetchUserDrugNoteForDrugQueryResult = Apollo.QueryResult<
  TFetchUserDrugNoteForDrugQuery,
  TFetchUserDrugNoteForDrugQueryVariables
>
export const FetchUserBookmarksDocument = gql`
  query FetchUserBookmarks {
    userManagementBookmarks {
      ...Bookmark
    }
  }
  ${BookmarkFragmentDoc}
`

/**
 * __useFetchUserBookmarksQuery__
 *
 * To run a query within a React component, call `useFetchUserBookmarksQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchUserBookmarksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchUserBookmarksQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchUserBookmarksQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TFetchUserBookmarksQuery,
    TFetchUserBookmarksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    TFetchUserBookmarksQuery,
    TFetchUserBookmarksQueryVariables
  >(FetchUserBookmarksDocument, options)
}
export function useFetchUserBookmarksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TFetchUserBookmarksQuery,
    TFetchUserBookmarksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    TFetchUserBookmarksQuery,
    TFetchUserBookmarksQueryVariables
  >(FetchUserBookmarksDocument, options)
}
export type FetchUserBookmarksQueryHookResult = ReturnType<
  typeof useFetchUserBookmarksQuery
>
export type FetchUserBookmarksLazyQueryHookResult = ReturnType<
  typeof useFetchUserBookmarksLazyQuery
>
export type FetchUserBookmarksQueryResult = Apollo.QueryResult<
  TFetchUserBookmarksQuery,
  TFetchUserBookmarksQueryVariables
>
export const UserManagementConfigDocument = gql`
  query UserManagementConfig {
    userManagementConfig {
      countries {
        ...FullUserManagementItem
      }
      fieldsOfStudy {
        ...FullUserManagementItem
      }
      genders {
        ...FullUserManagementItem
      }
      interests {
        ...FullUserManagementItem
      }
      occupations {
        ...FullUserManagementItem
      }
      specialities {
        ...FullUserManagementItem
      }
      verificationNumberTypes {
        ...FullUserManagementItem
      }
      webMedCountries {
        ...FullUserManagementItem
      }
      workplaces {
        ...FullUserManagementItem
      }
    }
  }
  ${FullUserManagementItemFragmentDoc}
`

/**
 * __useUserManagementConfigQuery__
 *
 * To run a query within a React component, call `useUserManagementConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserManagementConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserManagementConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserManagementConfigQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TUserManagementConfigQuery,
    TUserManagementConfigQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    TUserManagementConfigQuery,
    TUserManagementConfigQueryVariables
  >(UserManagementConfigDocument, options)
}
export function useUserManagementConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TUserManagementConfigQuery,
    TUserManagementConfigQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    TUserManagementConfigQuery,
    TUserManagementConfigQueryVariables
  >(UserManagementConfigDocument, options)
}
export type UserManagementConfigQueryHookResult = ReturnType<
  typeof useUserManagementConfigQuery
>
export type UserManagementConfigLazyQueryHookResult = ReturnType<
  typeof useUserManagementConfigLazyQuery
>
export type UserManagementConfigQueryResult = Apollo.QueryResult<
  TUserManagementConfigQuery,
  TUserManagementConfigQueryVariables
>
export const RegisterDocument = gql`
  mutation Register(
    $email: String!
    $password: String!
    $occupation: UserManagementOccupationEnum!
  ) {
    userManagementRegister(
      emailAddress: $email
      password: $password
      occupation: $occupation
    ) {
      ...FullUserAndSession
    }
  }
  ${FullUserAndSessionFragmentDoc}
`
export type TRegisterMutationFn = Apollo.MutationFunction<
  TRegisterMutation,
  TRegisterMutationVariables
>

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      occupation: // value for 'occupation'
 *   },
 * });
 */
export function useRegisterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TRegisterMutation,
    TRegisterMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<TRegisterMutation, TRegisterMutationVariables>(
    RegisterDocument,
    options,
  )
}
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>
export type RegisterMutationResult = Apollo.MutationResult<TRegisterMutation>
export type RegisterMutationOptions = Apollo.BaseMutationOptions<
  TRegisterMutation,
  TRegisterMutationVariables
>
export const StoreUserDrugNoteDocument = gql`
  mutation StoreUserDrugNote($drugs: [Int!]!, $id: Int, $note: String!) {
    userDrugNote(drugs: $drugs, id: $id, note: $note) {
      ...DrugNote
    }
  }
  ${DrugNoteFragmentDoc}
`
export type TStoreUserDrugNoteMutationFn = Apollo.MutationFunction<
  TStoreUserDrugNoteMutation,
  TStoreUserDrugNoteMutationVariables
>

/**
 * __useStoreUserDrugNoteMutation__
 *
 * To run a mutation, you first call `useStoreUserDrugNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoreUserDrugNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storeUserDrugNoteMutation, { data, loading, error }] = useStoreUserDrugNoteMutation({
 *   variables: {
 *      drugs: // value for 'drugs'
 *      id: // value for 'id'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useStoreUserDrugNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TStoreUserDrugNoteMutation,
    TStoreUserDrugNoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    TStoreUserDrugNoteMutation,
    TStoreUserDrugNoteMutationVariables
  >(StoreUserDrugNoteDocument, options)
}
export type StoreUserDrugNoteMutationHookResult = ReturnType<
  typeof useStoreUserDrugNoteMutation
>
export type StoreUserDrugNoteMutationResult =
  Apollo.MutationResult<TStoreUserDrugNoteMutation>
export type StoreUserDrugNoteMutationOptions = Apollo.BaseMutationOptions<
  TStoreUserDrugNoteMutation,
  TStoreUserDrugNoteMutationVariables
>
export const DeleteUserDrugNoteDocument = gql`
  mutation DeleteUserDrugNote($id: Int!) {
    userDrugNoteDelete(id: $id) {
      empty
    }
  }
`
export type TDeleteUserDrugNoteMutationFn = Apollo.MutationFunction<
  TDeleteUserDrugNoteMutation,
  TDeleteUserDrugNoteMutationVariables
>

/**
 * __useDeleteUserDrugNoteMutation__
 *
 * To run a mutation, you first call `useDeleteUserDrugNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserDrugNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserDrugNoteMutation, { data, loading, error }] = useDeleteUserDrugNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserDrugNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TDeleteUserDrugNoteMutation,
    TDeleteUserDrugNoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    TDeleteUserDrugNoteMutation,
    TDeleteUserDrugNoteMutationVariables
  >(DeleteUserDrugNoteDocument, options)
}
export type DeleteUserDrugNoteMutationHookResult = ReturnType<
  typeof useDeleteUserDrugNoteMutation
>
export type DeleteUserDrugNoteMutationResult =
  Apollo.MutationResult<TDeleteUserDrugNoteMutation>
export type DeleteUserDrugNoteMutationOptions = Apollo.BaseMutationOptions<
  TDeleteUserDrugNoteMutation,
  TDeleteUserDrugNoteMutationVariables
>
export const StoreUserBookmarkDocument = gql`
  mutation StoreUserBookmark(
    $atc: String
    $country: SchemaCountryEnum
    $drugs: [String]
    $filterGrav: Boolean
    $filterHepar: String
    $filterLact: Boolean
    $filterRenal: String
    $icd10: String
    $name: String
    $substances: [String]
    $substitute: String
    $type: UserManagementBookmarkTypeEnum
  ) {
    userManagementBookmark(
      atc: $atc
      country: $country
      drugs: $drugs
      filterGrav: $filterGrav
      filterHepar: $filterHepar
      filterLact: $filterLact
      filterRenal: $filterRenal
      icd10: $icd10
      name: $name
      substances: $substances
      substitute: $substitute
      type: $type
    ) {
      ...Bookmark
    }
  }
  ${BookmarkFragmentDoc}
`
export type TStoreUserBookmarkMutationFn = Apollo.MutationFunction<
  TStoreUserBookmarkMutation,
  TStoreUserBookmarkMutationVariables
>

/**
 * __useStoreUserBookmarkMutation__
 *
 * To run a mutation, you first call `useStoreUserBookmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoreUserBookmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storeUserBookmarkMutation, { data, loading, error }] = useStoreUserBookmarkMutation({
 *   variables: {
 *      atc: // value for 'atc'
 *      country: // value for 'country'
 *      drugs: // value for 'drugs'
 *      filterGrav: // value for 'filterGrav'
 *      filterHepar: // value for 'filterHepar'
 *      filterLact: // value for 'filterLact'
 *      filterRenal: // value for 'filterRenal'
 *      icd10: // value for 'icd10'
 *      name: // value for 'name'
 *      substances: // value for 'substances'
 *      substitute: // value for 'substitute'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useStoreUserBookmarkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TStoreUserBookmarkMutation,
    TStoreUserBookmarkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    TStoreUserBookmarkMutation,
    TStoreUserBookmarkMutationVariables
  >(StoreUserBookmarkDocument, options)
}
export type StoreUserBookmarkMutationHookResult = ReturnType<
  typeof useStoreUserBookmarkMutation
>
export type StoreUserBookmarkMutationResult =
  Apollo.MutationResult<TStoreUserBookmarkMutation>
export type StoreUserBookmarkMutationOptions = Apollo.BaseMutationOptions<
  TStoreUserBookmarkMutation,
  TStoreUserBookmarkMutationVariables
>
export const DeleteUserBookmarkDocument = gql`
  mutation DeleteUserBookmark($id: Int!) {
    userManagementBookmarkDelete(id: $id) {
      empty
    }
  }
`
export type TDeleteUserBookmarkMutationFn = Apollo.MutationFunction<
  TDeleteUserBookmarkMutation,
  TDeleteUserBookmarkMutationVariables
>

/**
 * __useDeleteUserBookmarkMutation__
 *
 * To run a mutation, you first call `useDeleteUserBookmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserBookmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserBookmarkMutation, { data, loading, error }] = useDeleteUserBookmarkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserBookmarkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TDeleteUserBookmarkMutation,
    TDeleteUserBookmarkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    TDeleteUserBookmarkMutation,
    TDeleteUserBookmarkMutationVariables
  >(DeleteUserBookmarkDocument, options)
}
export type DeleteUserBookmarkMutationHookResult = ReturnType<
  typeof useDeleteUserBookmarkMutation
>
export type DeleteUserBookmarkMutationResult =
  Apollo.MutationResult<TDeleteUserBookmarkMutation>
export type DeleteUserBookmarkMutationOptions = Apollo.BaseMutationOptions<
  TDeleteUserBookmarkMutation,
  TDeleteUserBookmarkMutationVariables
>
export const UploadImagesDocument = gql`
  mutation UploadImages($images: [Upload]!) {
    userManagementVerificationProcess(images: $images) {
      ...FullUser
    }
  }
  ${FullUserFragmentDoc}
`
export type TUploadImagesMutationFn = Apollo.MutationFunction<
  TUploadImagesMutation,
  TUploadImagesMutationVariables
>

/**
 * __useUploadImagesMutation__
 *
 * To run a mutation, you first call `useUploadImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadImagesMutation, { data, loading, error }] = useUploadImagesMutation({
 *   variables: {
 *      images: // value for 'images'
 *   },
 * });
 */
export function useUploadImagesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TUploadImagesMutation,
    TUploadImagesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    TUploadImagesMutation,
    TUploadImagesMutationVariables
  >(UploadImagesDocument, options)
}
export type UploadImagesMutationHookResult = ReturnType<
  typeof useUploadImagesMutation
>
export type UploadImagesMutationResult =
  Apollo.MutationResult<TUploadImagesMutation>
export type UploadImagesMutationOptions = Apollo.BaseMutationOptions<
  TUploadImagesMutation,
  TUploadImagesMutationVariables
>
export const UploadProfileImageDocument = gql`
  mutation UploadProfileImage($image: Upload!) {
    userManagementProfileImage(image: $image) {
      email
    }
  }
`
export type TUploadProfileImageMutationFn = Apollo.MutationFunction<
  TUploadProfileImageMutation,
  TUploadProfileImageMutationVariables
>

/**
 * __useUploadProfileImageMutation__
 *
 * To run a mutation, you first call `useUploadProfileImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadProfileImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadProfileImageMutation, { data, loading, error }] = useUploadProfileImageMutation({
 *   variables: {
 *      image: // value for 'image'
 *   },
 * });
 */
export function useUploadProfileImageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TUploadProfileImageMutation,
    TUploadProfileImageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    TUploadProfileImageMutation,
    TUploadProfileImageMutationVariables
  >(UploadProfileImageDocument, options)
}
export type UploadProfileImageMutationHookResult = ReturnType<
  typeof useUploadProfileImageMutation
>
export type UploadProfileImageMutationResult =
  Apollo.MutationResult<TUploadProfileImageMutation>
export type UploadProfileImageMutationOptions = Apollo.BaseMutationOptions<
  TUploadProfileImageMutation,
  TUploadProfileImageMutationVariables
>
export const UpdateUserInvoiceDataDocument = gql`
  mutation UpdateUserInvoiceData(
    $city: String
    $employment: UserManagementInvoiceDataEmploymentEnum
    $iban: String
    $invoiceWithUst: Boolean
    $street: String
    $taxNumber: String
    $zip: String
  ) {
    userManagementInvoiceData(
      city: $city
      employment: $employment
      iban: $iban
      invoiceWithUst: $invoiceWithUst
      street: $street
      taxNumber: $taxNumber
      zip: $zip
    ) {
      ...FullUser
    }
  }
  ${FullUserFragmentDoc}
`
export type TUpdateUserInvoiceDataMutationFn = Apollo.MutationFunction<
  TUpdateUserInvoiceDataMutation,
  TUpdateUserInvoiceDataMutationVariables
>

/**
 * __useUpdateUserInvoiceDataMutation__
 *
 * To run a mutation, you first call `useUpdateUserInvoiceDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserInvoiceDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserInvoiceDataMutation, { data, loading, error }] = useUpdateUserInvoiceDataMutation({
 *   variables: {
 *      city: // value for 'city'
 *      employment: // value for 'employment'
 *      iban: // value for 'iban'
 *      invoiceWithUst: // value for 'invoiceWithUst'
 *      street: // value for 'street'
 *      taxNumber: // value for 'taxNumber'
 *      zip: // value for 'zip'
 *   },
 * });
 */
export function useUpdateUserInvoiceDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TUpdateUserInvoiceDataMutation,
    TUpdateUserInvoiceDataMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    TUpdateUserInvoiceDataMutation,
    TUpdateUserInvoiceDataMutationVariables
  >(UpdateUserInvoiceDataDocument, options)
}
export type UpdateUserInvoiceDataMutationHookResult = ReturnType<
  typeof useUpdateUserInvoiceDataMutation
>
export type UpdateUserInvoiceDataMutationResult =
  Apollo.MutationResult<TUpdateUserInvoiceDataMutation>
export type UpdateUserInvoiceDataMutationOptions = Apollo.BaseMutationOptions<
  TUpdateUserInvoiceDataMutation,
  TUpdateUserInvoiceDataMutationVariables
>
export const UpdateUserProfileDocument = gql`
  mutation UpdateUserProfile(
    $occupation: UserManagementOccupationEnum
    $webMedCountry: UserManagementWebMedCountryEnum
    $suffix: String
    $title: String
    $firstName: String
    $lastName: String
    $gender: UserManagementGenderEnum
    $interests: [UserManagementInterestEnum]
    $country: UserManagementCountryEnum
    $workplace: UserManagementWorkplaceEnum
    $zip: String
    $specialities: [UserManagementSpecialityEnum]
    $fieldsOfStudy: [UserManagementFieldOfStudyEnum]
    $graduationYear: Int
    $verificationNumber: String
    $verificationNumberType: UserManagementVerificationNumberTypeEnum
  ) {
    userManagementProfile(
      occupation: $occupation
      webMedCountry: $webMedCountry
      firstName: $firstName
      lastName: $lastName
      title: $title
      suffix: $suffix
      gender: $gender
      interests: $interests
      country: $country
      workplace: $workplace
      zip: $zip
      specialities: $specialities
      fieldsOfStudy: $fieldsOfStudy
      graduationYear: $graduationYear
      verificationNumber: $verificationNumber
      verificationNumberType: $verificationNumberType
    ) {
      ...FullUser
    }
  }
  ${FullUserFragmentDoc}
`
export type TUpdateUserProfileMutationFn = Apollo.MutationFunction<
  TUpdateUserProfileMutation,
  TUpdateUserProfileMutationVariables
>

/**
 * __useUpdateUserProfileMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileMutation, { data, loading, error }] = useUpdateUserProfileMutation({
 *   variables: {
 *      occupation: // value for 'occupation'
 *      webMedCountry: // value for 'webMedCountry'
 *      suffix: // value for 'suffix'
 *      title: // value for 'title'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      gender: // value for 'gender'
 *      interests: // value for 'interests'
 *      country: // value for 'country'
 *      workplace: // value for 'workplace'
 *      zip: // value for 'zip'
 *      specialities: // value for 'specialities'
 *      fieldsOfStudy: // value for 'fieldsOfStudy'
 *      graduationYear: // value for 'graduationYear'
 *      verificationNumber: // value for 'verificationNumber'
 *      verificationNumberType: // value for 'verificationNumberType'
 *   },
 * });
 */
export function useUpdateUserProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TUpdateUserProfileMutation,
    TUpdateUserProfileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    TUpdateUserProfileMutation,
    TUpdateUserProfileMutationVariables
  >(UpdateUserProfileDocument, options)
}
export type UpdateUserProfileMutationHookResult = ReturnType<
  typeof useUpdateUserProfileMutation
>
export type UpdateUserProfileMutationResult =
  Apollo.MutationResult<TUpdateUserProfileMutation>
export type UpdateUserProfileMutationOptions = Apollo.BaseMutationOptions<
  TUpdateUserProfileMutation,
  TUpdateUserProfileMutationVariables
>
export const ConfirmEmailDocument = gql`
  mutation ConfirmEmail($emailConfirmationToken: String!) {
    userManagementConfirmEmail(
      emailConfirmationToken: $emailConfirmationToken
    ) {
      empty
    }
  }
`
export type TConfirmEmailMutationFn = Apollo.MutationFunction<
  TConfirmEmailMutation,
  TConfirmEmailMutationVariables
>

/**
 * __useConfirmEmailMutation__
 *
 * To run a mutation, you first call `useConfirmEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmEmailMutation, { data, loading, error }] = useConfirmEmailMutation({
 *   variables: {
 *      emailConfirmationToken: // value for 'emailConfirmationToken'
 *   },
 * });
 */
export function useConfirmEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TConfirmEmailMutation,
    TConfirmEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    TConfirmEmailMutation,
    TConfirmEmailMutationVariables
  >(ConfirmEmailDocument, options)
}
export type ConfirmEmailMutationHookResult = ReturnType<
  typeof useConfirmEmailMutation
>
export type ConfirmEmailMutationResult =
  Apollo.MutationResult<TConfirmEmailMutation>
export type ConfirmEmailMutationOptions = Apollo.BaseMutationOptions<
  TConfirmEmailMutation,
  TConfirmEmailMutationVariables
>
export const ConfirmNewEmailDocument = gql`
  mutation ConfirmNewEmail($emailConfirmationToken: String!) {
    userManagementConfirmNewEmail(
      emailConfirmationToken: $emailConfirmationToken
    ) {
      empty
    }
  }
`
export type TConfirmNewEmailMutationFn = Apollo.MutationFunction<
  TConfirmNewEmailMutation,
  TConfirmNewEmailMutationVariables
>

/**
 * __useConfirmNewEmailMutation__
 *
 * To run a mutation, you first call `useConfirmNewEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmNewEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmNewEmailMutation, { data, loading, error }] = useConfirmNewEmailMutation({
 *   variables: {
 *      emailConfirmationToken: // value for 'emailConfirmationToken'
 *   },
 * });
 */
export function useConfirmNewEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TConfirmNewEmailMutation,
    TConfirmNewEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    TConfirmNewEmailMutation,
    TConfirmNewEmailMutationVariables
  >(ConfirmNewEmailDocument, options)
}
export type ConfirmNewEmailMutationHookResult = ReturnType<
  typeof useConfirmNewEmailMutation
>
export type ConfirmNewEmailMutationResult =
  Apollo.MutationResult<TConfirmNewEmailMutation>
export type ConfirmNewEmailMutationOptions = Apollo.BaseMutationOptions<
  TConfirmNewEmailMutation,
  TConfirmNewEmailMutationVariables
>
export const ResendConfirmationEmailDocument = gql`
  mutation ResendConfirmationEmail($emailAddress: String!) {
    userManagementConfirmEmailResend(emailAddress: $emailAddress) {
      empty
    }
  }
`
export type TResendConfirmationEmailMutationFn = Apollo.MutationFunction<
  TResendConfirmationEmailMutation,
  TResendConfirmationEmailMutationVariables
>

/**
 * __useResendConfirmationEmailMutation__
 *
 * To run a mutation, you first call `useResendConfirmationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendConfirmationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendConfirmationEmailMutation, { data, loading, error }] = useResendConfirmationEmailMutation({
 *   variables: {
 *      emailAddress: // value for 'emailAddress'
 *   },
 * });
 */
export function useResendConfirmationEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TResendConfirmationEmailMutation,
    TResendConfirmationEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    TResendConfirmationEmailMutation,
    TResendConfirmationEmailMutationVariables
  >(ResendConfirmationEmailDocument, options)
}
export type ResendConfirmationEmailMutationHookResult = ReturnType<
  typeof useResendConfirmationEmailMutation
>
export type ResendConfirmationEmailMutationResult =
  Apollo.MutationResult<TResendConfirmationEmailMutation>
export type ResendConfirmationEmailMutationOptions = Apollo.BaseMutationOptions<
  TResendConfirmationEmailMutation,
  TResendConfirmationEmailMutationVariables
>
export const ResetEmailDocument = gql`
  mutation ResetEmail($email: String!) {
    userManagementNewEmail(email: $email) {
      empty
    }
  }
`
export type TResetEmailMutationFn = Apollo.MutationFunction<
  TResetEmailMutation,
  TResetEmailMutationVariables
>

/**
 * __useResetEmailMutation__
 *
 * To run a mutation, you first call `useResetEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetEmailMutation, { data, loading, error }] = useResetEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResetEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TResetEmailMutation,
    TResetEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<TResetEmailMutation, TResetEmailMutationVariables>(
    ResetEmailDocument,
    options,
  )
}
export type ResetEmailMutationHookResult = ReturnType<
  typeof useResetEmailMutation
>
export type ResetEmailMutationResult =
  Apollo.MutationResult<TResetEmailMutation>
export type ResetEmailMutationOptions = Apollo.BaseMutationOptions<
  TResetEmailMutation,
  TResetEmailMutationVariables
>
export const PasswordResetEmailDocument = gql`
  mutation PasswordResetEmail($emailAddress: String!) {
    userManagementPasswordResetEmail(emailAddress: $emailAddress) {
      empty
    }
  }
`
export type TPasswordResetEmailMutationFn = Apollo.MutationFunction<
  TPasswordResetEmailMutation,
  TPasswordResetEmailMutationVariables
>

/**
 * __usePasswordResetEmailMutation__
 *
 * To run a mutation, you first call `usePasswordResetEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordResetEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordResetEmailMutation, { data, loading, error }] = usePasswordResetEmailMutation({
 *   variables: {
 *      emailAddress: // value for 'emailAddress'
 *   },
 * });
 */
export function usePasswordResetEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TPasswordResetEmailMutation,
    TPasswordResetEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    TPasswordResetEmailMutation,
    TPasswordResetEmailMutationVariables
  >(PasswordResetEmailDocument, options)
}
export type PasswordResetEmailMutationHookResult = ReturnType<
  typeof usePasswordResetEmailMutation
>
export type PasswordResetEmailMutationResult =
  Apollo.MutationResult<TPasswordResetEmailMutation>
export type PasswordResetEmailMutationOptions = Apollo.BaseMutationOptions<
  TPasswordResetEmailMutation,
  TPasswordResetEmailMutationVariables
>
export const ResetPasswordDocument = gql`
  mutation ResetPassword($newPassword: String!, $resetToken: String!) {
    userManagementPasswordReset(
      newPassword: $newPassword
      resetToken: $resetToken
    ) {
      empty
    }
  }
`
export type TResetPasswordMutationFn = Apollo.MutationFunction<
  TResetPasswordMutation,
  TResetPasswordMutationVariables
>

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      resetToken: // value for 'resetToken'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TResetPasswordMutation,
    TResetPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    TResetPasswordMutation,
    TResetPasswordMutationVariables
  >(ResetPasswordDocument, options)
}
export type ResetPasswordMutationHookResult = ReturnType<
  typeof useResetPasswordMutation
>
export type ResetPasswordMutationResult =
  Apollo.MutationResult<TResetPasswordMutation>
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  TResetPasswordMutation,
  TResetPasswordMutationVariables
>
export const UserDeleteDocument = gql`
  mutation UserDelete($password: String!) {
    userManagementUserDelete(password: $password) {
      empty
    }
  }
`
export type TUserDeleteMutationFn = Apollo.MutationFunction<
  TUserDeleteMutation,
  TUserDeleteMutationVariables
>

/**
 * __useUserDeleteMutation__
 *
 * To run a mutation, you first call `useUserDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userDeleteMutation, { data, loading, error }] = useUserDeleteMutation({
 *   variables: {
 *      password: // value for 'password'
 *   },
 * });
 */
export function useUserDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TUserDeleteMutation,
    TUserDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<TUserDeleteMutation, TUserDeleteMutationVariables>(
    UserDeleteDocument,
    options,
  )
}
export type UserDeleteMutationHookResult = ReturnType<
  typeof useUserDeleteMutation
>
export type UserDeleteMutationResult =
  Apollo.MutationResult<TUserDeleteMutation>
export type UserDeleteMutationOptions = Apollo.BaseMutationOptions<
  TUserDeleteMutation,
  TUserDeleteMutationVariables
>
export const OnUserBookmarksUpdatedDocument = gql`
  subscription OnUserBookmarksUpdated {
    userManagementBookmarks {
      ...Bookmark
    }
  }
  ${BookmarkFragmentDoc}
`

/**
 * __useOnUserBookmarksUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnUserBookmarksUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnUserBookmarksUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUserBookmarksUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnUserBookmarksUpdatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    TOnUserBookmarksUpdatedSubscription,
    TOnUserBookmarksUpdatedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    TOnUserBookmarksUpdatedSubscription,
    TOnUserBookmarksUpdatedSubscriptionVariables
  >(OnUserBookmarksUpdatedDocument, options)
}
export type OnUserBookmarksUpdatedSubscriptionHookResult = ReturnType<
  typeof useOnUserBookmarksUpdatedSubscription
>
export type OnUserBookmarksUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<TOnUserBookmarksUpdatedSubscription>
export const OnUserDataUpdatedDocument = gql`
  subscription OnUserDataUpdated {
    userManagementUser {
      ...FullUser
    }
  }
  ${FullUserFragmentDoc}
`

/**
 * __useOnUserDataUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnUserDataUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnUserDataUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUserDataUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnUserDataUpdatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    TOnUserDataUpdatedSubscription,
    TOnUserDataUpdatedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    TOnUserDataUpdatedSubscription,
    TOnUserDataUpdatedSubscriptionVariables
  >(OnUserDataUpdatedDocument, options)
}
export type OnUserDataUpdatedSubscriptionHookResult = ReturnType<
  typeof useOnUserDataUpdatedSubscription
>
export type OnUserDataUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<TOnUserDataUpdatedSubscription>

import { useState } from 'react'
import { Navigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { Form, Button } from 'react-bootstrap'
import Select from 'react-select'
import { Formik } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { routeConstants } from '_constants'
import OnboardingDialog from '_components/Common/OnboardingDialog'
import {
  useUserManagementConfigQuery,
  TUserManagementOccupationEnum as EOccupation,
} from '_generated/plexus.graphql'
import { gql, TAppDispatch, TRootState } from '_helpers'
import {
  getPlexusConfig,
  TPlexusUserManagementConfigFilteredItem,
} from '_helpers/plexus.helper'
import { registrationValidationSchema } from '_helpers/onboarding.helper'
import { clearAlert, EAlertActionTypeKeys } from '_slices/alert.slice'
import { registerRequest } from '_slices/authentication.slice'
import { setEmail } from '_slices/temp.slice'
import type { Maybe } from 'graphql/jsutils/Maybe'

export enum ERegistrationPageTestIds {
  occupationSelect = 'registration-form-occupation-select',
  occupationError = 'registration-form-occupation-error',
  emailError = 'registration-form-email-error',
  passwordError = 'registration-form-password-error',
}

type TProps = {
  alertMessage: string
  alertType: EAlertActionTypeKeys | null
  tempEmail: Maybe<string>
  isAuthenticated: boolean
  isAuthenticating: boolean
  clearAlerts: () => void
  email: (email: string) => void
  register: (email: string, password: string, occupation: EOccupation) => void
}

const mapStateToProps = (state: TRootState) => {
  const { alert, authentication, temp } = state
  return {
    alertMessage: alert.message,
    alertType: alert.type,
    tempEmail: temp.email,
    isAuthenticated: authentication.isAuthenticated,
    isAuthenticating: authentication.isAuthenticating,
  }
}

const mapDispatchToProps = (dispatch: TAppDispatch) => {
  return {
    clearAlerts: () => dispatch(clearAlert()),
    email: (email: string) => dispatch(setEmail(email)),
    register: (email: string, password: string, occupation: EOccupation) =>
      dispatch(registerRequest(email, password, occupation)),
  }
}

function ConnectedRegistrationPage({
  alertMessage,
  alertType,
  tempEmail,
  isAuthenticated,
  isAuthenticating,
  clearAlerts,
  email,
  register,
}: TProps) {
  const [isPasswordShown, setIsPasswordShown] = useState(false)

  const {
    data: userManagementConfigData,
    loading: userManagementConfigLoading,
    error: userManagementConfigError,
  } = useUserManagementConfigQuery({
    client: gql.plexusClient,
  })

  // Navigate to index page when user is already logged in
  if (isAuthenticated) {
    return <Navigate to={routeConstants.INDEX_PAGE} />
  }

  function togglePasswordVisiblity() {
    setIsPasswordShown(!isPasswordShown)
  }

  // sort the react-select options from Plexus
  let occupationOptions = Array<TPlexusUserManagementConfigFilteredItem>()
  if (userManagementConfigData?.userManagementConfig?.occupations?.length) {
    occupationOptions = getPlexusConfig(
      userManagementConfigData.userManagementConfig?.occupations,
    )
  }

  let combinedAlertMessage = '' + alertMessage
  if (userManagementConfigError) {
    combinedAlertMessage += '\n' + userManagementConfigError.message
  }

  return (
    <OnboardingDialog
      title="Konto erstellen"
      paragraph="Aus rechtlichen Gründen ist die Nutzung von Diagnosia medizinischem Personal vorbehalten. Wähle bitte deinen Fachkreis."
      showCreateAccountLink={false}
      showLoginLink={true}
      alertMessage={combinedAlertMessage}
      alertType={alertType}
    >
      <Formik
        initialValues={{
          occupation: EOccupation.Undefined,
          email: tempEmail ?? '',
          password: '',
        }}
        validationSchema={registrationValidationSchema}
        onSubmit={(values) => {
          clearAlerts()
          register(values.email, values.password, values.occupation)
          email(values.email)
        }}
      >
        {({
          setFieldValue,
          setFieldTouched,
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form
            aria-label="Registrieren"
            className="mt-4"
            onSubmit={handleSubmit}
          >
            <Form.Group
              data-testid={ERegistrationPageTestIds.occupationSelect}
              controlId="occupation"
              className="mx-sm-3 "
            >
              <Select
                aria-label="Wähle deinen Fachkreis aus"
                className={
                  !!errors.occupation && !!touched.occupation
                    ? 'select-wrapper is-invalid'
                    : 'select-wrapper'
                }
                classNamePrefix="select"
                inputId="occupation-input"
                isDisabled={isAuthenticating || userManagementConfigLoading}
                isLoading={userManagementConfigLoading}
                name="occupation"
                options={occupationOptions}
                onBlur={() => setFieldTouched('occupation', true)}
                placeholder="Wähle deinen Fachkreis"
                onChange={(value, actionMeta) => {
                  let v = value as TPlexusUserManagementConfigFilteredItem
                  if (actionMeta.name && v) {
                    setFieldValue(actionMeta.name, v.value)
                  }
                }}
                value={occupationOptions.find(
                  (o) => o.value === values.occupation,
                )}
              />
              <Form.Control.Feedback
                data-testid={ERegistrationPageTestIds.occupationError}
                type="invalid"
                className="text-end"
              >
                {errors.occupation}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="email" className="mx-sm-3">
              <Form.Label visuallyHidden={true}>E-Mail Adresse</Form.Label>
              <Form.Control
                className="onboarding"
                disabled={isAuthenticating || userManagementConfigLoading}
                isInvalid={!!errors.email && !!touched.email}
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="E-Mail Ad­res­se"
                type="email"
                value={values.email}
              />
              <Form.Control.Feedback
                data-testid={ERegistrationPageTestIds.emailError}
                type="invalid"
                className="text-end"
              >
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              controlId="password"
              className="mx-sm-3 position-relative"
            >
              <Form.Label visuallyHidden={true}>Passwort</Form.Label>
              <Form.Control
                className="onboarding"
                disabled={isAuthenticating || userManagementConfigLoading}
                isInvalid={!!errors.password && !!touched.password}
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Passwort"
                role="textbox"
                type={isPasswordShown ? 'text' : 'password'}
                value={values.password}
              />
              <Form.Control.Feedback
                data-testid={ERegistrationPageTestIds.passwordError}
                type="invalid"
                className="text-end"
              >
                {errors.password}
              </Form.Control.Feedback>
              <span
                role="button"
                className="position-absolute pw-toggle font-small-body pointer color-mobile-blue"
                onClick={togglePasswordVisiblity}
              >
                {isPasswordShown ? 'VERSTECKEN' : 'ZEIGEN'}
              </span>
            </Form.Group>
            <Form.Group className="mb-4 mt-5 mx-sm-3">
              <div className="d-grid">
                <Button
                  aria-label="Konto erstellen"
                  className="btn-green"
                  disabled={isAuthenticating}
                  type="submit"
                  variant="primary"
                >
                  {isAuthenticating ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : (
                    'Konto erstellen'
                  )}
                </Button>
              </div>
            </Form.Group>
          </Form>
        )}
      </Formik>
    </OnboardingDialog>
  )
}

const RegistrationPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConnectedRegistrationPage)

export { RegistrationPage, ConnectedRegistrationPage }

import { Row, Col } from 'react-bootstrap'
import Courses from './Courses'
import Sessions from './Sessions'
import Webinars from './Webinars'

export default function LearnContainer() {
  return (
    <>
      <Row>
        <Col>
          <Courses />
        </Col>
      </Row>
      <Row>
        <Col lg={6} className="my-5">
          <Sessions />
        </Col>
        <Col lg={6} className="my-5">
          <Webinars />
        </Col>
      </Row>
    </>
  )
}

import { Formik } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import {
  TUserManagementConfigQuery,
  TUserManagementFieldOfStudyEnum,
  TUserManagementCountryEnum,
  TUserManagementWebMedCountryEnum,
  TUserManagementUser,
} from '_generated/plexus.graphql'
import { Form, Button, ToggleButtonGroup, ToggleButton } from 'react-bootstrap'
import Select from 'react-select'
import {
  TPlexusUserManagementConfigFilteredItem,
  getPlexusConfig,
} from '_helpers/plexus.helper'

type TFormValues = {
  singleFieldOfStudy: TUserManagementFieldOfStudyEnum
  fieldsOfStudy: TUserManagementFieldOfStudyEnum[]
  graduationYearString: string
  graduationYear: number
  country: TUserManagementCountryEnum
}

type TProps = {
  userManagementConfigLoading: boolean
  userManagementConfigData?: TUserManagementConfigQuery
  user?: TUserManagementUser
  prepareUpdateUserProfile: (values: any) => void
  isUserProfileUpdating: boolean
}

export function StudyFieldsDialog({
  userManagementConfigLoading,
  userManagementConfigData,
  user,
  prepareUpdateUserProfile,
  isUserProfileUpdating,
}: TProps) {
  let initialWebMedCountry =
    (user?.profile?.webMedCountry as TUserManagementWebMedCountryEnum) ||
    TUserManagementWebMedCountryEnum.Undefined

  let initialCountry =
    (user?.profile?.country as TUserManagementCountryEnum) ||
    TUserManagementCountryEnum.Undefined

  if (initialCountry === TUserManagementCountryEnum.Undefined) {
    switch (initialWebMedCountry) {
      default:
      case TUserManagementWebMedCountryEnum.At:
        initialCountry = TUserManagementCountryEnum.At
        break

      case TUserManagementWebMedCountryEnum.De:
        initialCountry = TUserManagementCountryEnum.De
        break
    }
  }

  let initialGraduationYear = user?.profile?.graduationYear || 0

  let initialGraduationYearString =
    initialGraduationYear === 0 ? '' : initialGraduationYear.toString()

  let initialStudyFieldsValues: TFormValues = {
    singleFieldOfStudy:
      (user?.profile?.fieldsOfStudy?.[0] as TUserManagementFieldOfStudyEnum) ||
      TUserManagementFieldOfStudyEnum.Undefined,
    fieldsOfStudy: user?.profile
      ?.fieldsOfStudy as TUserManagementFieldOfStudyEnum[],
    graduationYearString: initialGraduationYearString,
    graduationYear: initialGraduationYear,
    country: initialCountry,
  }

  let studyFieldsOptions = Array<TPlexusUserManagementConfigFilteredItem>()
  if (userManagementConfigData?.userManagementConfig?.fieldsOfStudy?.length) {
    studyFieldsOptions = getPlexusConfig(
      userManagementConfigData.userManagementConfig?.fieldsOfStudy,
    )
  }

  let countryOptions = Array<TPlexusUserManagementConfigFilteredItem>()
  if (userManagementConfigData?.userManagementConfig?.countries?.length) {
    countryOptions = getPlexusConfig(
      userManagementConfigData.userManagementConfig?.countries,
    )
  }

  return (
    <Formik
      initialValues={initialStudyFieldsValues}
      validate={(values) => {
        type TVerificationError = {
          singleFieldOfStudy?: string
          graduationYearString?: string
          country?: string
        }

        const errors: TVerificationError = {}
        if (
          !values.singleFieldOfStudy ||
          values?.singleFieldOfStudy ===
            TUserManagementFieldOfStudyEnum.Undefined
        ) {
          errors.singleFieldOfStudy = 'Bitte gib deine Studienrichtung an.'
        } else {
          // convert for GraphQL mutation
          values.fieldsOfStudy = [values.singleFieldOfStudy]
        }

        if (
          !values.country ||
          values?.country === TUserManagementCountryEnum.Undefined
        ) {
          errors.country = 'Bitte wähle ein Land aus.'
        }

        let gradYear = Number(values.graduationYearString)
        let year = new Date().getFullYear()
        if (!gradYear || gradYear < year - 2 || gradYear > year + 20) {
          errors.graduationYearString =
            'Bitte gib dein voraussichtliches Abschlussjahr an.'
        } else {
          // convert for GraphQL mutation
          values.graduationYear = gradYear
        }

        return errors
      }}
      onSubmit={(values) => {
        prepareUpdateUserProfile(values)
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldTouched,
        setFieldValue,
      }) => (
        <Form
          aria-label="Studienrichtung"
          className="mt-4"
          onSubmit={handleSubmit}
        >
          <Form.Group controlId="singleFieldOfStudy" className="mx-sm-3 my-5">
            <ToggleButtonGroup
              aria-label="Studienrichtung Auswahl"
              vertical
              type="radio"
              name="singleFieldOfStudy"
              className={
                !!errors.singleFieldOfStudy && !!touched.singleFieldOfStudy
                  ? 'w-100 is-invalid'
                  : 'w-100'
              }
              value={values.singleFieldOfStudy}
            >
              {studyFieldsOptions.map((item, key) => (
                <ToggleButton
                  value={item.value}
                  disabled={
                    isUserProfileUpdating || userManagementConfigLoading
                  }
                  id={`studyfield-${item.value}`}
                  key={key}
                  name="singleFieldOfStudy"
                  variant="secondary"
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  {item.label}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
            <Form.Control.Feedback
              type="invalid"
              className="text-end"
              role="alert"
              aria-label="feedback-fieldOfStudy"
            >
              {errors.singleFieldOfStudy}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group
            data-testid="country"
            controlId="country"
            className="mx-sm-3"
          >
            <Select
              className={
                !!errors.country && !!touched.country
                  ? 'select-wrapper is-invalid'
                  : 'select-wrapper'
              }
              isDisabled={isUserProfileUpdating || userManagementConfigLoading}
              classNamePrefix="select"
              placeholder="Land"
              name="country"
              options={countryOptions}
              isLoading={userManagementConfigLoading}
              onBlur={() => {
                setFieldTouched('country', true)
              }}
              value={countryOptions.find((o) => o.value === values.country)}
              onChange={(value, actionMeta) => {
                let v = value as TPlexusUserManagementConfigFilteredItem
                if (actionMeta.name && v) {
                  setFieldValue(actionMeta.name, v.value)
                }
              }}
            />
            <Form.Control.Feedback
              type="invalid"
              className="text-end"
              role="alert"
              aria-label="feedback-country"
            >
              {errors.country}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="graduationYearString" className="mx-sm-3">
            <Form.Label visuallyHidden={true}>
              Abschlussjahr voraussichtlich
            </Form.Label>
            <Form.Control
              className="onboarding"
              disabled={isUserProfileUpdating || userManagementConfigLoading}
              isInvalid={
                !!errors.graduationYearString && !!touched.graduationYearString
              }
              name="graduationYearString"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Abschlussjahr voraussichtlich"
              type="text"
              value={values.graduationYearString}
            />
            <Form.Control.Feedback
              type="invalid"
              className="text-end"
              role="alert"
              aria-label="feedback-graduationYear"
            >
              {errors.graduationYearString}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-4 mt-5 mx-sm-3">
            <Button
              aria-label="Weiter"
              className="w-100 btn-green"
              variant="primary"
              type="submit"
              disabled={isUserProfileUpdating || userManagementConfigLoading}
            >
              {isUserProfileUpdating ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                'Weiter'
              )}
            </Button>
          </Form.Group>
        </Form>
      )}
    </Formik>
  )
}

import { ReactComponent as LinkIcon } from '_components/Common/img/webinar-link.svg'
import DefaultBtn from '_components/Common/Buttons/DefaultBtn'
import type { TEventSession } from '_generated/axon.graphql'
import { utils } from '_helpers'

type TProps = {
  session: TEventSession
}

export default function SessionItem({ session }: TProps) {
  const fromDate = utils.buildPrettyFromToDate(session.from, session.to)

  return (
    <div className="session-item">
      {session.thumbnail ? (
        <img
          alt={session.title ?? ''}
          className="session-img flex-shrink-0"
          src={session.thumbnail}
        />
      ) : (
        <div className="session-img flex-shrink-0"></div>
      )}
      <div className="session-content me-3">
        <a
          href={session.fullUrl ?? 'https://learn.diagnosia.com/profile'}
          rel="noreferrer"
          target="_blank"
        >
          <div className="session-description">{session.title}</div>
        </a>
        <div className="mt-1 color-blue-grey-70 text-sm">{fromDate}</div>
        {session.zoomLink ? (
          <div className="d-flex align-items-center">
            <LinkIcon className="session-link-icon me-2" />
            <a
              href={session.zoomLink}
              className="text-sm color-mobile-blue"
              target="_blank"
              rel="noreferrer"
            >
              {session.zoomLink}
            </a>
          </div>
        ) : null}
      </div>
      <DefaultBtn
        buttonText="Details"
        link={session.fullUrl ?? 'https://learn.diagnosia.com/events'}
        type="outlined"
      />
    </div>
  )
}

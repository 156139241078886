import { useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import { useParams, useNavigate } from 'react-router-dom'
import { Formik } from 'formik'
import * as Sentry from '@sentry/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { routeConstants } from '_constants'
import OnboardingDialog from '_components/Common/OnboardingDialog'
import { passwordResetValidationSchema } from '_helpers/onboarding.helper'
import { Telemetry, IntercomWrapper } from '_services'
import { useResetPasswordMutation } from '_generated/plexus.graphql'
import { gql } from '_helpers/gql.helper'
import {
  AlertMessage,
  EAlertMessageType,
} from '_components/Common/AlertMessage'
import { EComponentState } from '../PasswordRecoveryPage'
import { getCombinedPlexusErrorMessage } from '_helpers/plexus.helper'

export enum ETestIds {
  passwordError = 'password-reset-password-error',
}

type TProps = {
  initialComponentState?: EComponentState
}

export function PasswordResetPage({
  initialComponentState = EComponentState.default,
}: TProps) {
  const navigate = useNavigate()
  const { resetToken } = useParams()
  const [internalState, setInternalState] = useState(initialComponentState)
  const [isPasswordShown, setIsPasswordShown] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const updateComponentState = (newState: EComponentState) => {
    if (internalState !== newState) {
      setInternalState(newState)
    }
  }

  const [resetPasswordMutation] = useResetPasswordMutation({
    client: gql.plexusClient,
    onError: (error) => {
      let errorMessage = getCombinedPlexusErrorMessage(error)
      setErrorMessage(errorMessage)
      updateComponentState(EComponentState.error)
    },
    onCompleted: (data) => {
      if (data?.userManagementPasswordReset) {
        Telemetry.resetPassword()
        updateComponentState(EComponentState.success)
      } else {
        updateComponentState(EComponentState.error)
      }
    },
  })

  const togglePasswordVisiblity = () => {
    setIsPasswordShown(!isPasswordShown)
  }

  return (
    <OnboardingDialog
      title="Neues Passwort"
      paragraph={
        internalState === EComponentState.default ||
        internalState === EComponentState.loading
          ? 'Setze ein neues sicheres Passwort.'
          : internalState === EComponentState.success
          ? 'Du kannst dich jetzt mit deinem neuen Passwort einloggen.'
          : ''
      }
    >
      {(internalState === EComponentState.default ||
        internalState === EComponentState.loading) && (
        <Formik
          initialValues={{ password: '' }}
          validationSchema={passwordResetValidationSchema}
          onSubmit={(values) => {
            if (resetToken && values.password) {
              updateComponentState(EComponentState.loading)
              resetPasswordMutation({
                variables: {
                  newPassword: values.password,
                  resetToken: resetToken,
                },
              }).catch((error) => Sentry.captureException(error)) // To avoid unhandled rejection
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Form
              aria-label="Neues Passwort"
              className="mt-4"
              onSubmit={handleSubmit}
            >
              <Form.Group
                controlId="password"
                className="mx-sm-3 position-relative"
              >
                <Form.Label visuallyHidden={true}>Passwort</Form.Label>
                <Form.Control
                  className="onboarding"
                  disabled={internalState === EComponentState.loading}
                  isInvalid={!!errors.password && !!touched.password}
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Passwort"
                  role="textbox"
                  type={isPasswordShown ? 'text' : 'password'}
                  value={values.password}
                />
                <Form.Control.Feedback
                  data-testid={ETestIds.passwordError}
                  type="invalid"
                  className="text-end"
                >
                  {errors.password}
                </Form.Control.Feedback>
                <span
                  className="position-absolute pw-toggle font-small-body pointer color-mobile-blue"
                  onClick={togglePasswordVisiblity}
                  role="button"
                >
                  {isPasswordShown ? 'VERSTECKEN' : 'ZEIGEN'}
                </span>
              </Form.Group>
              <Form.Group className="mb-4 mt-5 mx-sm-3">
                <div className="d-grid">
                  <Button
                    aria-label="Speichern"
                    className="btn-green"
                    disabled={internalState === EComponentState.loading}
                    type="submit"
                    variant="primary"
                  >
                    {internalState === EComponentState.loading ? (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    ) : (
                      'Speichern'
                    )}
                  </Button>
                </div>
              </Form.Group>
            </Form>
          )}
        </Formik>
      )}

      {internalState === EComponentState.error && (
        <AlertMessage
          type={EAlertMessageType.error}
          message={errorMessage}
          text="Melde dich beim Support, falls du Unterstützung benötigst."
          buttonText="Support kontaktieren"
          onClick={() => {
            IntercomWrapper.show()
          }}
          onBackButtonClick={() => {
            updateComponentState(EComponentState.default)
          }}
        />
      )}

      {internalState === EComponentState.success && (
        <AlertMessage
          type={EAlertMessageType.success}
          message="Passwort gespeichert"
          buttonText="Zurück"
          onClick={() => {
            navigate(routeConstants.LOGIN_PAGE)
          }}
        />
      )}
    </OnboardingDialog>
  )
}

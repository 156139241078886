import { Formik } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import {
  TUserManagementGenderEnum,
  TUserManagementUser,
} from '_generated/plexus.graphql'
import { Form, ToggleButtonGroup, ToggleButton, Button } from 'react-bootstrap'

type TFormValues = {
  gender: TUserManagementGenderEnum
  firstName: string
  lastName: string
}

type TProps = {
  userManagementConfigLoading: boolean
  user?: TUserManagementUser
  prepareUpdateUserProfile: (values: any) => void
  isUserProfileUpdating: boolean
}

export function NameSexDialog({
  userManagementConfigLoading,
  user,
  prepareUpdateUserProfile,
  isUserProfileUpdating,
}: TProps) {
  const initialValues: TFormValues = {
    gender: user?.profile?.gender || TUserManagementGenderEnum.Undefined,
    firstName: user?.profile?.firstName || '',
    lastName: user?.profile?.lastName || '',
  }

  return (
    <Formik
      initialValues={initialValues}
      validate={(values) => {
        const errors: {
          gender?: string
          firstName?: string
          lastName?: string
        } = {}

        if (
          !values.gender ||
          (values?.gender !== TUserManagementGenderEnum.Male &&
            values?.gender !== TUserManagementGenderEnum.Female &&
            values?.gender !== TUserManagementGenderEnum.Other)
        ) {
          errors.gender = 'Bitte wähle dein Geschlecht aus.'
        }

        if (!values.firstName || values?.firstName.length === 0) {
          errors.firstName = 'Bitte gib deinen Vornamen an.'
        }

        if (!values.lastName || values?.lastName.length === 0) {
          errors.lastName = 'Bitte gib deinen Nachnamen an.'
        }

        return errors
      }}
      onSubmit={(values) => {
        prepareUpdateUserProfile(values)
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <Form
          aria-label="Persönliche Daten"
          className="mt-4"
          onSubmit={handleSubmit}
        >
          <Form.Group controlId="gender" className="mx-sm-3 my-5">
            <ToggleButtonGroup
              aria-label="Geschlecht Auswahl"
              type="radio"
              name="gender"
              className={
                !!errors.gender && !!touched.gender
                  ? 'w-100 gender-select is-invalid'
                  : 'w-100 gender-select'
              }
              value={values.gender}
            >
              <ToggleButton
                id="gender-male"
                disabled={isUserProfileUpdating || userManagementConfigLoading}
                variant="secondary"
                onChange={handleChange}
                onBlur={handleBlur}
                value={TUserManagementGenderEnum.Male}
                className="bg-icon-male"
              >
                Herr
              </ToggleButton>
              <ToggleButton
                id="gender-female"
                disabled={isUserProfileUpdating || userManagementConfigLoading}
                variant="secondary"
                onChange={handleChange}
                onBlur={handleBlur}
                value={TUserManagementGenderEnum.Female}
                className="bg-icon-female"
              >
                Frau
              </ToggleButton>
              <ToggleButton
                id="gender-other"
                disabled={isUserProfileUpdating || userManagementConfigLoading}
                variant="secondary"
                onChange={handleChange}
                onBlur={handleBlur}
                value={TUserManagementGenderEnum.Other}
                className="bg-icon-other"
              >
                Anders
              </ToggleButton>
            </ToggleButtonGroup>
            <Form.Control.Feedback
              type="invalid"
              role="alert"
              aria-label="feedback-gender"
            >
              {errors.gender}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="firstName" className="mx-sm-3">
            <Form.Label visuallyHidden={true}>Vorname</Form.Label>
            <Form.Control
              className="onboarding"
              disabled={isUserProfileUpdating || userManagementConfigLoading}
              isInvalid={!!errors.firstName && !!touched.firstName}
              name="firstName"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Vorname"
              type="text"
              value={values.firstName}
            />
            <Form.Control.Feedback
              type="invalid"
              className="text-end"
              role="alert"
              aria-label="feedback-firstName"
            >
              {errors.firstName}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="lastName" className="mx-sm-3">
            <Form.Label visuallyHidden={true}>Nachname</Form.Label>
            <Form.Control
              className="onboarding"
              disabled={isUserProfileUpdating || userManagementConfigLoading}
              isInvalid={!!errors.lastName && !!touched.lastName}
              name="lastName"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Nachname"
              type="text"
              value={values.lastName}
            />
            <Form.Control.Feedback
              type="invalid"
              className="text-end"
              role="alert"
              aria-label="feedback-lastName"
            >
              {errors.lastName}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-4 mt-5 mx-sm-3">
            <Button
              aria-label="Weiter"
              className="w-100 btn-green"
              variant="primary"
              type="submit"
              disabled={isUserProfileUpdating || userManagementConfigLoading}
            >
              {isUserProfileUpdating ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                'Weiter'
              )}
            </Button>
          </Form.Group>
        </Form>
      )}
    </Formik>
  )
}

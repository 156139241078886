import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type TAlertState = {
  message: string
  type: EAlertActionTypeKeys | null
}

export type TFlash = {
  message: string
  type?: EAlertActionTypeKeys
}

export enum EAlertActionTypeKeys {
  Clear = 'Clear',
  Error = 'Error',
  Success = 'Success',
}

const initialState: TAlertState = {
  message: '',
  type: null,
}

const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    clearAlert(state) {
      state.message = ''
      state.type = null
    },
    errorAlert(state, action: PayloadAction<string>) {
      const message = action.payload
      state.message = message
      state.type = EAlertActionTypeKeys.Error
    },
    flash(state, action: PayloadAction<TFlash>) {
      state.message = action.payload.message
      state.type = action.payload.type ?? EAlertActionTypeKeys.Error
    },
  },
})

export const { clearAlert, errorAlert, flash } = alertSlice.actions
export default alertSlice.reducer

import { Container, Row, Col } from 'react-bootstrap'
import ApolloErrorMsg from '_components/Common/ApolloErrorMsg'
import Loading from '_components/Common/Loading'
import { useUserSessionRecommendationsQuery } from '_generated/axon.graphql'
import { gql } from '_helpers/gql.helper'
import WebinarItem from './WebinarItem'
import WebinarItemIdle from './WebinarItemIdle'

export default function Webinars() {
  const { loading, error, data } = useUserSessionRecommendationsQuery({
    client: gql.axonClient,
  })

  if (error) {
    return (
      <Container>
        <Row>
          <Col className="pt-4" md={{ span: 6, offset: 3 }}>
            <ApolloErrorMsg error={error} />
          </Col>
        </Row>
      </Container>
    )
  }

  const webinars = data?.userSessionRecommendations

  return (
    <>
      <div className="mb-3 d-flex justify-content-between">
        <span className="text-sm text-uppercase color-blue-grey-90 font-bold">
          Das könnte dich interessieren
        </span>
        <a
          className="text-sm color-blue-grey-50 text-underline"
          href="https://learn.diagnosia.com/events"
          target="_blank"
          rel="noreferrer"
        >
          Mehr anzeigen
        </a>
      </div>
      {loading ? (
        <div className="pt-3">
          <Loading />
        </div>
      ) : webinars && webinars.length > 0 ? (
        webinars.map((webinar, index) => {
          return (
            <div className="mb-3" key={index}>
              <WebinarItem webinar={webinar} />
            </div>
          )
        })
      ) : (
        <WebinarItemIdle
          buttonText={'Zum E-Learning'}
          webinarDescription={
            'Zukünftige Webinare werden hier gelistet. Schau inzwischen beim E-Learning vorbei.'
          }
          link="https://learn.diagnosia.com/congresses"
        />
      )}
    </>
  )
}

const _oeakPattern = new RegExp('^([0-9]{3,})-([0-9]{2})$')
const _efnPattern = new RegExp('^[0-9]{15}$')

export function isValidOeaekNumber(number: string | null | undefined): boolean {
  if (!number) {
    return false
  }

  if (!_oeakPattern.test(number)) {
    return false
  }

  const splitted = number.split('-')
  if (splitted.length !== 2) {
    // should never happen
    return false
  }

  const checkSum = Number(splitted[1])
  if (checkSum === null) {
    return false
  }

  const oeakWeights = [7, 3, 1]
  const verificationNumber = splitted[0]
  let calculatedChecksum = 0

  for (let i = 0; i < verificationNumber.length; i++) {
    const number = Number(verificationNumber[i])
    if (number === null) {
      return false
    }
    calculatedChecksum += number * oeakWeights[i % oeakWeights.length]
  }

  calculatedChecksum = calculatedChecksum % 100

  return calculatedChecksum === checkSum
}

export function isValidEfnNumber(number: string | null): boolean {
  if (number === null) {
    return false
  }
  if (!_efnPattern.test(number)) {
    return false
  }

  let sum = 0
  let numDigits = number.length
  let parity = numDigits % 2
  for (let i = 0; i < numDigits; i++) {
    let digit = Number(number[i])
    if (parity === i % 2) {
      digit <<= 1
      if (9 < digit) {
        digit = digit - 9
      }
    }
    sum += digit
  }
  return 0 === sum % 10
}

// import * as jose from 'jose'
import { configHelper, Cookie, LocalStorage, gql, TAppDispatch } from '_helpers'
import { EAppConstantsKeys } from '_types'
import type { Maybe } from 'graphql/jsutils/Maybe'
import { TUserManagementUserAndSession } from '_types'
import {
  LOGIN,
  FETCH_REFRESH_TOKEN,
  REGISTER,
  UPDATE_USER_PROFILE_MUTATION,
  FETCH_USER,
  SUBSCRIBE_USER_DATA,
  TOKEN_LOGIN,
  UPDATE_USER_INVOICE_DATA_MUTATION,
  PROFILE_IMAGE_UPLOAD_MUTATION,
} from '_constants/plexus.gql.queries'
import {
  TFetchRefreshTokenQuery,
  TFetchRefreshTokenQueryVariables,
  TLoginQuery,
  TLoginQueryVariables,
  TTokenLoginQuery,
  TTokenLoginQueryVariables,
  TFetchUserQuery,
  TFetchUserQueryVariables,
  TOnUserDataUpdatedSubscription,
  TOnUserDataUpdatedSubscriptionVariables,
  TRegisterMutation,
  TRegisterMutationVariables,
  TUserManagementProfile,
  TUpdateUserProfileMutation,
  TUpdateUserProfileMutationVariables,
  TUserManagementInvoiceData,
  TUpdateUserInvoiceDataMutation,
  TUpdateUserInvoiceDataMutationVariables,
} from '_generated/plexus.graphql'
import {
  TUserManagementUser,
  TUserManagementOccupationEnum,
} from '_generated/plexus.graphql'
import { TLoginTokens, fetchUserSuccess } from '_slices/authentication.slice'
import cloneDeep from 'lodash/cloneDeep'

let userSubscribedToUserData = false

// Return time when the token expires in seconds
function getExpirationTime(accessToken: Maybe<string>): number {
  const fiftyFiveMinutesInSeconds = 55 * 60
  // const fiveMinutesInSeconds = 5 * 60

  // AccessToken will expire in 1 hour. So take the current time
  // and add 55 mins in seconds and store that time to know
  // when to refetch new tokens. (Will be checked in
  // the authorization link in gql.helper.ts)
  // https://gitlab.com/apoverlag/plexus/-/blob/master/lib/authentication_service/guardian.ex#L42
  let expiresAt = Math.floor(Date.now() / 1000) + fiftyFiveMinutesInSeconds

  // If access token is given and the decoded payload has the
  // exp key, take that time as expiration time (is in secs).
  /*if (accessToken) {
    const decoded: any = jose.decodeJwt(accessToken)
    if (decoded && decoded.exp) {
      // Substract 5 mins from the exp time to have some time
      // to refresh the tokens
      expiresAt = parseInt(decoded.exp) - fiveMinutesInSeconds
    }
  }*/

  return expiresAt
}

// -- SESSION

function _storeSession(session: TLoginTokens): number {
  const expiresAt = getExpirationTime(session.accessToken)
  LocalStorage.set('expiresAt', String(expiresAt))
  return expiresAt
}

function _storeUser(user: TUserManagementUser) {
  LocalStorage.set('user', JSON.stringify(user))
}

function _deleteSession() {
  LocalStorage.remove('expiresAt')
  LocalStorage.remove('user')

  const domain = configHelper.get(EAppConstantsKeys.BASE_DOMAIN)
  Cookie.remove('plexus_access_token', domain)
  Cookie.remove('plexus_refresh_token', domain)
  Cookie.remove('jwt', domain)
}

// -- LOGIN

async function login(
  email: string,
  password: string,
): Promise<TUserManagementUserAndSession> {
  const response = await gql.plexusClient.query<
    TLoginQuery,
    TLoginQueryVariables
  >({
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
    query: LOGIN,
    variables: { email, password },
  })

  if (
    !response.loading &&
    !response.errors &&
    response.data?.authenticationServiceLogin
  ) {
    const user = response.data.authenticationServiceLogin.user
    const session = response.data.authenticationServiceLogin
      .session as TLoginTokens

    if (user && session) {
      _storeUser(user)
      _storeSession(session)

      return Promise.resolve({
        user,
        session,
      })
    }
  }

  if (response.errors) {
    return Promise.reject({ graphQLErrors: response.errors })
  }

  return Promise.reject(Error('No data or errors returned from API.'))
}

async function tokenLogin(
  token: string,
): Promise<TUserManagementUserAndSession> {
  const response = await gql.plexusClient.query<
    TTokenLoginQuery,
    TTokenLoginQueryVariables
  >({
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
    query: TOKEN_LOGIN,
    variables: { token },
  })

  if (
    !response.loading &&
    !response.errors &&
    response.data?.authenticationServiceTokenLogin
  ) {
    const user = response.data.authenticationServiceTokenLogin.user
    const session = response.data.authenticationServiceTokenLogin
      .session as TLoginTokens

    if (user && session) {
      _storeUser(user)
      _storeSession(session)

      return Promise.resolve({
        user,
        session,
      })
    }
  }

  if (response.errors) {
    return Promise.reject({ graphQLErrors: response.errors })
  }

  return Promise.reject(Error('No data or errors returned from API.'))
}

// -- REGISTER

async function register(
  email: string,
  password: string,
  occupation: TUserManagementOccupationEnum,
): Promise<TUserManagementUserAndSession> {
  const response = await gql.plexusClient.query<
    TRegisterMutation,
    TRegisterMutationVariables
  >({
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
    query: REGISTER,
    variables: { email, password, occupation },
  })

  if (
    !response.loading &&
    !response.errors &&
    response.data?.userManagementRegister
  ) {
    const user = response.data.userManagementRegister.user
    const session = response.data.userManagementRegister.session as TLoginTokens

    if (user && session) {
      _storeUser(user)
      _storeSession(session)

      return Promise.resolve({
        user,
        session,
      })
    }
  }

  if (response.errors) {
    return Promise.reject({ graphQLErrors: response.errors })
  }

  return Promise.reject(Error('No data or errors returned from API.'))
}

// -- LOGOUT

function logout() {
  _deleteSession()
}

// -- REFRESH TOKEN

async function refreshToken(
  refreshToken: string,
): Promise<TUserManagementUserAndSession> {
  const response = await gql.plexusClient.query<
    TFetchRefreshTokenQuery,
    TFetchRefreshTokenQueryVariables
  >({
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
    query: FETCH_REFRESH_TOKEN,
    variables: {
      token: refreshToken,
    },
  })

  if (
    !response.loading &&
    !response.errors &&
    response.data?.authenticationServiceRefreshLogin
  ) {
    const user = response.data.authenticationServiceRefreshLogin.user
    const session = response.data.authenticationServiceRefreshLogin
      .session as TLoginTokens

    if (user && session) {
      _storeUser(user)
      _storeSession(session)

      return Promise.resolve({ session, user })
    }
  }

  if (response.errors) {
    return Promise.reject({ graphQLErrors: response.errors })
  }

  return Promise.reject(Error('No data or errors returned from API.'))
}

// -- USER DATA
async function fetchUser(accessToken: string): Promise<TUserManagementUser> {
  const response = await gql.plexusClient.query<
    TFetchUserQuery,
    TFetchUserQueryVariables
  >({
    fetchPolicy: 'no-cache',
    query: FETCH_USER,
    context: {
      headers: { Authorization: accessToken },
    },
  })

  if (
    !response.loading &&
    !response.errors &&
    response.data?.userManagementUser
  ) {
    const user = response.data.userManagementUser
    if (user) {
      _storeUser(user)

      return Promise.resolve(user)
    }
  }

  if (response.errors) {
    return Promise.reject({ graphQLErrors: response.errors })
  }

  return Promise.reject(Error('No data or errors returned from API.'))
}

// -- USER PROFILE

async function profileUpdate(
  profile: TUserManagementProfile,
): Promise<TUserManagementUser> {
  const response = await gql.plexusClient.query<
    TUpdateUserProfileMutation,
    TUpdateUserProfileMutationVariables
  >({
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
    query: UPDATE_USER_PROFILE_MUTATION,
    variables: {
      ...profile,
    },
  })

  if (
    !response.loading &&
    !response.errors &&
    response.data?.userManagementProfile
  ) {
    const user = response.data.userManagementProfile
    if (user) {
      _storeUser(user)

      return Promise.resolve(user)
    }
  }

  if (response.errors) {
    return Promise.reject({ graphQLErrors: response.errors })
  }

  return Promise.reject(Error('No data or errors returned from API.'))
}

async function invoiceDataUpdate(
  invoiceData: TUserManagementInvoiceData,
): Promise<TUserManagementUser> {
  const response = await gql.plexusClient.query<
    TUpdateUserInvoiceDataMutation,
    TUpdateUserInvoiceDataMutationVariables
  >({
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
    query: UPDATE_USER_INVOICE_DATA_MUTATION,
    variables: {
      ...invoiceData,
    },
  })

  if (
    !response.loading &&
    !response.errors &&
    response.data?.userManagementInvoiceData
  ) {
    const invoiceData = response.data.userManagementInvoiceData
    if (invoiceData) {
      return Promise.resolve(invoiceData)
    }
  }

  if (response.errors) {
    return Promise.reject({ graphQLErrors: response.errors })
  }

  return Promise.reject(Error('No data or errors returned from API.'))
}

function subscribe(dispatch: TAppDispatch): void {
  // If user already is subscribed
  if (userSubscribedToUserData === true) {
    return
  }

  try {
    gql.plexusClient
      .subscribe<
        TOnUserDataUpdatedSubscription,
        TOnUserDataUpdatedSubscriptionVariables
      >({
        query: SUBSCRIBE_USER_DATA,
      })
      .subscribe({
        next(data) {
          const newUserData = data.data?.userManagementUser
            ? cloneDeep(data.data?.userManagementUser)
            : null

          if (newUserData) {
            _storeUser(newUserData)

            dispatch(fetchUserSuccess(newUserData))
          }
        },
      })
  } catch (error) {
    return
  }

  userSubscribedToUserData = true
}

async function imageUpload(file: File): Promise<any> {
  const response = await gql.plexusUploadClient.query({
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
    query: PROFILE_IMAGE_UPLOAD_MUTATION,
    variables: { image: file },
  })

  if (!response.loading && !response.errors) {
    return Promise.resolve(true)
  }

  if (response.errors) {
    return Promise.reject({ graphQLErrors: response.errors })
  }

  return Promise.reject(Error('No data or errors returned from API.'))
}

// -- INIT

export const userService = {
  fetchUser,
  login,
  logout,
  profileUpdate,
  invoiceDataUpdate,
  imageUpload,
  refreshToken,
  register,
  subscribe,
  tokenLogin,
}

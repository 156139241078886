import { ReactComponent as LearnLogo } from '_components/Common/img/diagnosia-learn-logo-horizontal.svg'

export default function Footer() {
  return (
    <div className="d-flex flex-column flex-md-row justify-content-between py-3 align-items-center">
      <LearnLogo className="flex-shrink-0" height="auto" width="170" />
      <span className="text-sm color-blue-grey-50 my-2 my-md-0 text-center">
        Kostenlose Fortbildungs-Events für Ärztinnen und Ärzte
      </span>
      <a
        href="https://learn.diagnosia.com"
        className="text-sm color-grey text-underline"
        target="_blank"
        rel="noreferrer"
      >
        https://learn.diagnosia.com
      </a>
    </div>
  )
}

import {
  TNewsServiceNews,
  TNewsServiceNewsPriorityEnum,
} from '_generated/plexus.graphql'
import { shuffle } from 'lodash'

const priorityMapping = {
  [TNewsServiceNewsPriorityEnum.Normal]: 0,
  [TNewsServiceNewsPriorityEnum.High]: 1,
  [TNewsServiceNewsPriorityEnum.VeryHigh]: 2,
}

const sortByPriority = (
  newsItems: Array<TNewsServiceNews>,
): Array<TNewsServiceNews> => {
  return [...newsItems].sort((a, b) => {
    if (!a.priority || !b.priority) return 0

    if (priorityMapping[a.priority] > priorityMapping[b.priority]) {
      return -1
    }

    if (priorityMapping[a.priority] < priorityMapping[b.priority]) {
      return 1
    }

    return 0
  })
}

const sortByRandom = (
  newsItems: Array<TNewsServiceNews>,
): Array<TNewsServiceNews> => {
  return shuffle(newsItems)
}

export const newsHelper = {
  sortByPriority,
  sortByRandom,
}

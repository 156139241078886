import {
  TGravbaseArticle,
  TLactbaseArticle,
  TRenbaseArticle,
  THeparbaseArticle,
  TDgnsAtc,
  TReferenceDetails,
  TDgnsProduct,
} from '_generated/cerebro.graphql'
import type { Maybe } from 'graphql/jsutils/Maybe'
import { TDgnsSubstance } from '_generated/cerebro.graphql'
import { Location } from 'history'
import { TBookmarkItem, THistoryItem } from './alias.types'

export type TMatchesCount = {
  [key: string]: number
}

export type TLocationState = {
  from: Location
}

export type TFoundMatchesCount = {
  composition: number
  dosage: number
  indication: number
  interactions: number
}

export type TFoundMatchesCountKey = keyof TFoundMatchesCount

export type TFoundMatchesAllCount = {
  composition: number
  contraindications: number
  dosage: number
  indication: number
  interaction: number
  prescriptionPharmacy: number
  administration: number
  notesBreastfeeding: number
  pregnancy: number
  sideEffects: number
  undesireableEffects: number
  vendor: number
  warningsAdditives: number
  warnings: number
  warningsBreastfeeding: number
  warningsMethodOfAdministrationAndDosage: number
  warningsPregnancy: number
}

export type TFoundMatchesAllCountAT = {
  composition: number
  contraindications: number
  dosage: number
  indication: number
  interaction: number
  prescriptionPharmacy: number
  administration: number
  notesBreastfeeding: number
  pregnancy: number
  sideEffects: number
  undesireableEffects: number
  vendor: number
  warningsAdditives: number
  warnings: number
  warningsBreastfeeding: number
  warningsMethodOfAdministrationAndDosage: number
  warningsPregnancy: number
}

export type TFoundMatchesAllCountKey = keyof TFoundMatchesAllCount

export enum ERiskLevel {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
}

export enum ERiskLevelLabelTypes {
  NOEFFECT = 'noeffect',
  MILD = 'mild',
  MODERATE = 'moderate',
  SEVERE = 'severe',
}

export type TSeverityLevelCounts = {
  severe: number
  moderate: number
  mild: number
  noeffect: number
}

export type TSeverityLevelGraphHeightsInPx = TSeverityLevelCounts

export enum ERiskScoreAlgorithm {
  Sum = 'Sum',
  Max = 'Max',
}

export type TRiskLevel = {
  label: ERiskLevelLabelTypes
  text: {
    en: string
    de: string
  }
}

export type TRiskLevels = Record<ERiskLevel, TRiskLevel>

export type TRiskLevelsKey = keyof TRiskLevels
export type TInteractionsLevels = Record<
  ERiskLevelLabelTypes,
  Array<TNormalizedInteraction>
>
export type TGravArticlesLevels = Record<
  ERiskLevelLabelTypes,
  Array<TGravbaseArticle>
>
export type TLactArticlesLevels = Record<
  ERiskLevelLabelTypes,
  Array<TLactbaseArticle>
>
export type TRenalArticlesLevels = Record<
  ERiskLevelLabelTypes,
  Array<TRenbaseArticle>
>
export type THeparArticlesLevels = Record<
  ERiskLevelLabelTypes,
  Array<THeparbaseArticle>
>
export type TAggregatedRisksLevels = Record<
  ERiskLevelLabelTypes,
  Array<TNormalizedRisk>
>

export enum ECountryTypes {
  AT = 'AT',
  CH = 'CH',
  DE = 'DE',
}

export enum ELanguageTypes {
  DE = 'de',
  EN = 'en',
}

export enum ESectionTypes {
  INDEX = 'INDEX',
  INTERACTIONS = 'INTERACTIONS',
}

export enum EObjectTypes {
  ATC = 'ATC',
  DRUG = 'DRUG',
  INTERACTION = 'INTERACTION',
  SUBSTANCE = 'SUBSTANCE',
  SUBSTITUTE = 'SUBSTITUTE',
}

export enum TItemType {
  Archived = 'DgnsProductArchived',
  Product = 'DgnsProduct',
  Substance = 'DgnsSubstance',
  Warning = 'Warning',
}

export type TMultiSearchOption = {
  label: string
  products?: Array<TDgnsProduct> | null
  substances?: Array<TDgnsSubstance> | null
  totalCountProducts: number
  totalCountSubstances: number
  type: TItemType
  searchValue: string
  value: string
}

export enum ERenalGFROptions {
  Mild = '0',
  Moderate = '1',
  Severe = '2',
  Terminal = '3',
}

export enum EHeparChildPughOptions {
  Mild = '0',
  Moderate = '1',
  Severe = '2',
}

export enum EGravLactType {
  Grav = 'grav',
  Lact = 'lact',
}

export type TStateRenalArticles = {
  items: Array<TRenbaseArticle>
  selectedGfr: Maybe<string>
}

export type TStateHeparArticles = {
  items: Array<THeparbaseArticle>
  selectedChildPugh: Maybe<string>
}

export enum EInteractionsTypes {
  INTERACTION = 'interaction',
  RISK = 'risk',
  GRAV = 'grav',
  LACT = 'lact',
  RENAL = 'renal',
  HEPAR = 'hepar',
}

type TInteractionsCount = Record<EInteractionsTypes, number>

export type TInteractionsCounts = Record<
  ERiskLevelLabelTypes,
  TInteractionsCount
>

export type TInteractionsFilters = Record<ERiskLevelLabelTypes, boolean>

export type TRiskLevelTuple = {
  riskLevel: ERiskLevel
  riskLevelNumber: Maybe<number>
}

export type TBookmarkState = Array<TBookmarkItem>

export enum EInteractionSectionTypes {
  CONSEQUENCES,
  RECOMMENDATION,
  MECHANISM,
  BACKGROUND,
  PATIENT,
}

export enum EIndexTabTypes {
  ALL = 'all',
  PRODUCTS = 'products',
  SUBSTANCES = 'substances',
  ATCS = 'atcs',
  ICDS = 'icds',
}

export enum EProductFachinfoTabTypes {
  Dosage = 'dosage',
  Interactions = 'interactions',
  Indication = 'indication',
  Prescriptions = 'prescriptions',
  Composition = 'composition',
  All = 'all',
  Notes = 'notes',
}

export enum EAnnouncementsTabTypes {
  Announcements = 'announcements',
  PharmaNotifications = 'pharma-notifications',
}

export enum EDgnsArticlePriceType {
  Insurance = 'insurance',
  Retail = 'retail',
  Pharmacy = 'pharmacy',
}

export type TInteractionsFilterStates = {
  severe: boolean
  moderate: boolean
  mild: boolean
  noeffect: boolean
}

export type THistoryState = Array<THistoryItem>

export type TAtcInfo = Pick<TDgnsAtc, 'code' | 'description'>
export type TAtcTree = {
  a1: TAtcInfo | null
  a2: TAtcInfo | null
  a3: TAtcInfo | null
  a4: TAtcInfo | null
  a5: TAtcInfo | null
}

// Interaction Type
export type TNormalizedInteraction = {
  evidence: Maybe<number>
  severity: ERiskLevel
  references: Array<Pick<TReferenceDetails, 'text' | 'pubMedUrl'>>
  sectionConsequence: Maybe<string>
  sectionRecommendation: Maybe<string>
  sectionMechanism: Maybe<string>
  sectionBackground: Maybe<string>
  sectionPatient: Maybe<string>
  substance_1_id: Maybe<number>
  substance_1_name: Maybe<string>
  substance_2_id: Maybe<number>
  substance_2_name: Maybe<string>
  product_1_id: Maybe<number>
  product_1_name: Maybe<string>
  product_2_id: Maybe<number>
  product_2_name: Maybe<string>
}

// Aggregated Risk Type

export type TNormalizedCalculation = {
  calcBadge: string
  infoText: Maybe<string>
  severity: Maybe<ERiskLevel>
}

export type TNormalizedSubstance = {
  drugName: Maybe<string>
  riskValue: Maybe<number>
  name: Maybe<string>
}

export type TNormalizedRisk = {
  calculations: Array<TNormalizedCalculation>
  categoryName: Maybe<string>
  consequence: Maybe<string>
  recommendation: Maybe<string>
  riskBaseCategoryId: Maybe<number>
  score: number
  scoreAlgorithm: ERiskScoreAlgorithm | undefined
  scoreAlgorithmDisplay: Maybe<string>
  severity: ERiskLevel
  shortInfo: Maybe<string>
  substances: Array<TNormalizedSubstance>
}

export enum EChangeStatus {
  Changed = 'changed',
  New = 'new',
  Removed = 'removed',
  Unchanged = 'unchanged',
}

export enum EQueryParams {
  BrainSubstances = 'brainSubstances',
  Drugs = 'drugs',
  Grav = 'grav',
  Hepar = 'hepar',
  Lact = 'lact',
  Pzns = 'pzns',
  Renal = 'renal',
  SubstanceNames = 'substanceNames',
  Substances = 'substances',
  Ukeys = 'drugUkeys',
}

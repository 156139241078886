import { ReactNode } from 'react'

type TProps = {
  children?: ReactNode
  classNames?: string
  onClick?: (event: any) => void
  title?: string
}

export default function DgnsButton(props: TProps) {
  return (
    <button
      className={`btn btn-dgns ${props.classNames}`}
      type="button"
      onClick={props.onClick}
      title={props.title}
    >
      {props.children}
    </button>
  )
}

import { gql } from '@apollo/client'

//
// Fragments
//

const SESSION_FRAGMENT = gql`
  fragment FullSession on AuthenticationServiceSession {
    accessToken
    legacyToken
    refreshToken
  }
`

const USER_FRAGMENT = gql`
  fragment FullUser on UserManagementUser {
    ads {
      countries
      placement {
        ... on AdServicePlacementAtcDetail {
          atcCodes
          placement
        }
        ... on AdServicePlacementProductDetail {
          placement
          productIds
        }
        ... on AdServicePlacementSubstanceDetail {
          placement
          substances
        }
      }
      preview
      type {
        ... on AdServiceTypeBanner46860 {
          image
          link
          type
        }
        ... on AdServiceTypeProductIntegration {
          disclosure
          entries {
            description
            link
            title
          }
          type
        }
      }
      uuid
    }
    email
    emailConfirmed
    enterpriseProfile {
      webMedCountry
    }
    news(app: NEOCORTEX_WEB) {
      countries
      externalLink
      image
      overwriteCanHideAds
      preview
      priority
      title
      uuid
    }
    organisation {
      name
      uuid
    }
    permissionSet
    profile {
      country
      fieldsOfStudy
      firstName
      gender
      graduationYear
      hasOnboardingFinished
      invoiceData {
        city
        employment
        iban
        invoiceWithUst
        street
        taxNumber
        zip
      }
      image
      lastName
      occupation
      specialities
      interests
      title
      suffix
      trialDuration
      verificationNumber
      verificationNumberType
      verifiedProfessional
      webMedCountry
      workplace
      zip
    }
    uuid
    verificationProcess {
      denyReason
      state
    }
  }
`

const USER_AND_SESSION_FRAGMENT = gql`
  fragment FullUserAndSession on UserManagementUserAndSession {
    session {
      ...FullSession
    }
    user {
      ...FullUser
    }
  }
  ${SESSION_FRAGMENT}
  ${USER_FRAGMENT}
`

const USER_DRUG_NOTE_FRAGMENT = gql`
  fragment DrugNote on UserDrugNote {
    drugs
    id
    note
    insertedAt
  }
`

const USER_BOOKMARK_FRAGMENT = gql`
  fragment Bookmark on UserManagementBookmark {
    atc
    country
    drugs
    filter {
      grav
      hepar
      lact
      renal
    }
    icd10
    id
    name
    substances
    substitute
    type
  }
`

const USER_MANAGEMENT_ITEM_FRAGMENT = gql`
  fragment FullUserManagementItem on UserManagementItems {
    isActive
    key
    sort
    translations {
      language
      text
    }
  }
`

//
// Queries
//

export const LOGIN = gql`
  query Login($email: String!, $password: String!) {
    authenticationServiceLogin(email: $email, password: $password) {
      ...FullUserAndSession
    }
  }
  ${USER_AND_SESSION_FRAGMENT}
`

export const TOKEN_LOGIN = gql`
  query TokenLogin($token: String!) {
    authenticationServiceTokenLogin(token: $token) {
      ...FullUserAndSession
    }
  }
  ${USER_AND_SESSION_FRAGMENT}
`

export const FETCH_REFRESH_TOKEN = gql`
  query FetchRefreshToken($token: String!) {
    authenticationServiceRefreshLogin(token: $token) {
      ...FullUserAndSession
    }
  }
  ${USER_AND_SESSION_FRAGMENT}
`

export const FETCH_USER = gql`
  query FetchUser {
    userManagementUser {
      ...FullUser
    }
  }
  ${USER_FRAGMENT}
`

export const FETCH_USER_DRUG_NOTE = gql`
  query FetchUserDrugNote($id: Int!) {
    userDrugNote(id: $id) {
      ...DrugNote
    }
  }
  ${USER_DRUG_NOTE_FRAGMENT}
`

export const FETCH_USER_DRUG_NOTE_FOR_DRUG = gql`
  query FetchUserDrugNoteForDrug($drugId: Int!) {
    userDrugNoteForDrug(drugId: $drugId) {
      ...DrugNote
    }
  }
  ${USER_DRUG_NOTE_FRAGMENT}
`

export const FETCH_USER_BOOKMARKS = gql`
  query FetchUserBookmarks {
    userManagementBookmarks {
      ...Bookmark
    }
  }
  ${USER_BOOKMARK_FRAGMENT}
`

export const FETCH_USER_MANAGEMENT_CONFIG = gql`
  query UserManagementConfig {
    userManagementConfig {
      countries {
        ...FullUserManagementItem
      }
      fieldsOfStudy {
        ...FullUserManagementItem
      }
      genders {
        ...FullUserManagementItem
      }
      interests {
        ...FullUserManagementItem
      }
      occupations {
        ...FullUserManagementItem
      }
      specialities {
        ...FullUserManagementItem
      }
      verificationNumberTypes {
        ...FullUserManagementItem
      }
      webMedCountries {
        ...FullUserManagementItem
      }
      workplaces {
        ...FullUserManagementItem
      }
    }
  }
  ${USER_MANAGEMENT_ITEM_FRAGMENT}
`

//
// Mutations
//

export const REGISTER = gql`
  mutation Register(
    $email: String!
    $password: String!
    $occupation: UserManagementOccupationEnum!
  ) {
    userManagementRegister(
      emailAddress: $email
      password: $password
      occupation: $occupation
    ) {
      ...FullUserAndSession
    }
  }
  ${USER_AND_SESSION_FRAGMENT}
`
export const STORE_USER_DRUG_NOTE = gql`
  mutation StoreUserDrugNote($drugs: [Int!]!, $id: Int, $note: String!) {
    userDrugNote(drugs: $drugs, id: $id, note: $note) {
      ...DrugNote
    }
  }
  ${USER_DRUG_NOTE_FRAGMENT}
`

export const DELETE_USER_DRUG_NOTE = gql`
  mutation DeleteUserDrugNote($id: Int!) {
    userDrugNoteDelete(id: $id) {
      empty
    }
  }
`

export const STORE_USER_BOOKMARK = gql`
  mutation StoreUserBookmark(
    $atc: String
    $country: SchemaCountryEnum
    $drugs: [String]
    $filterGrav: Boolean
    $filterHepar: String
    $filterLact: Boolean
    $filterRenal: String
    $icd10: String
    $name: String
    $substances: [String]
    $substitute: String
    $type: UserManagementBookmarkTypeEnum
  ) {
    userManagementBookmark(
      atc: $atc
      country: $country
      drugs: $drugs
      filterGrav: $filterGrav
      filterHepar: $filterHepar
      filterLact: $filterLact
      filterRenal: $filterRenal
      icd10: $icd10
      name: $name
      substances: $substances
      substitute: $substitute
      type: $type
    ) {
      ...Bookmark
    }
  }
  ${USER_BOOKMARK_FRAGMENT}
`

export const DELETE_USER_BOOKMARK = gql`
  mutation DeleteUserBookmark($id: Int!) {
    userManagementBookmarkDelete(id: $id) {
      empty
    }
  }
`

export const ONBOARDING_IMAGE_UPLOAD_MUTATION = gql`
  mutation UploadImages($images: [Upload]!) {
    userManagementVerificationProcess(images: $images) {
      ...FullUser
    }
  }
  ${USER_FRAGMENT}
`

export const PROFILE_IMAGE_UPLOAD_MUTATION = gql`
  mutation UploadProfileImage($image: Upload!) {
    userManagementProfileImage(image: $image) {
      email
    }
  }
`

export const UPDATE_USER_INVOICE_DATA_MUTATION = gql`
  mutation UpdateUserInvoiceData(
    $city: String
    $employment: UserManagementInvoiceDataEmploymentEnum
    $iban: String
    $invoiceWithUst: Boolean
    $street: String
    $taxNumber: String
    $zip: String
  ) {
    userManagementInvoiceData(
      city: $city
      employment: $employment
      iban: $iban
      invoiceWithUst: $invoiceWithUst
      street: $street
      taxNumber: $taxNumber
      zip: $zip
    ) {
      ...FullUser
    }
  }
  ${USER_FRAGMENT}
`
export const UPDATE_USER_PROFILE_MUTATION = gql`
  mutation UpdateUserProfile(
    $occupation: UserManagementOccupationEnum
    $webMedCountry: UserManagementWebMedCountryEnum
    $suffix: String
    $title: String
    $firstName: String
    $lastName: String
    $gender: UserManagementGenderEnum
    $interests: [UserManagementInterestEnum]
    $country: UserManagementCountryEnum
    $workplace: UserManagementWorkplaceEnum
    $zip: String
    $specialities: [UserManagementSpecialityEnum]
    $fieldsOfStudy: [UserManagementFieldOfStudyEnum]
    $graduationYear: Int
    $verificationNumber: String
    $verificationNumberType: UserManagementVerificationNumberTypeEnum
  ) {
    userManagementProfile(
      occupation: $occupation
      webMedCountry: $webMedCountry
      firstName: $firstName
      lastName: $lastName
      title: $title
      suffix: $suffix
      gender: $gender
      interests: $interests
      country: $country
      workplace: $workplace
      zip: $zip
      specialities: $specialities
      fieldsOfStudy: $fieldsOfStudy
      graduationYear: $graduationYear
      verificationNumber: $verificationNumber
      verificationNumberType: $verificationNumberType
    ) {
      ...FullUser
    }
  }
  ${USER_FRAGMENT}
`

export const CONFIRM_EMAIL_MUTATION = gql`
  mutation ConfirmEmail($emailConfirmationToken: String!) {
    userManagementConfirmEmail(
      emailConfirmationToken: $emailConfirmationToken
    ) {
      empty
    }
  }
`

export const CONFIRM_NEW_EMAIL_MUTATION = gql`
  mutation ConfirmNewEmail($emailConfirmationToken: String!) {
    userManagementConfirmNewEmail(
      emailConfirmationToken: $emailConfirmationToken
    ) {
      empty
    }
  }
`

export const RESEND_CONFIRM_EMAIL_MUTATION = gql`
  mutation ResendConfirmationEmail($emailAddress: String!) {
    userManagementConfirmEmailResend(emailAddress: $emailAddress) {
      empty
    }
  }
`

export const RESET_EMAIL_MUTATION = gql`
  mutation ResetEmail($email: String!) {
    userManagementNewEmail(email: $email) {
      empty
    }
  }
`

export const SEND_PASSWORD_RESET_EMAIL_MUTATION = gql`
  mutation PasswordResetEmail($emailAddress: String!) {
    userManagementPasswordResetEmail(emailAddress: $emailAddress) {
      empty
    }
  }
`

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword($newPassword: String!, $resetToken: String!) {
    userManagementPasswordReset(
      newPassword: $newPassword
      resetToken: $resetToken
    ) {
      empty
    }
  }
`

export const USER_DELETE_MUTATION = gql`
  mutation UserDelete($password: String!) {
    userManagementUserDelete(password: $password) {
      empty
    }
  }
`

//
// Subscriptions
//

export const SUBSCRIBE_USER_BOOKMARKS = gql`
  subscription OnUserBookmarksUpdated {
    userManagementBookmarks {
      ...Bookmark
    }
  }
  ${USER_BOOKMARK_FRAGMENT}
`

export const SUBSCRIBE_USER_DATA = gql`
  subscription OnUserDataUpdated {
    userManagementUser {
      ...FullUser
    }
  }
  ${USER_FRAGMENT}
`

import { THistoryItem } from '_types'

function store(
  items: Array<THistoryItem>,
  itemToStore: THistoryItem,
): Array<THistoryItem> {
  // Put the new history item at front and filter out
  // all duplicates from the current state.
  const updatedItems = [
    itemToStore,
    ...items.filter((item) => item.id !== itemToStore.id),
  ].slice(0, 50) // Only store the last 50 items

  localStorage.setItem('history', JSON.stringify(updatedItems))
  return updatedItems
}

function remove(items: Array<THistoryItem>, id: string): Array<THistoryItem> {
  const updatedItems = items.filter((item) => item.id !== id)
  localStorage.setItem('history', JSON.stringify(updatedItems))
  return updatedItems
}

export const historyService = {
  store,
  remove,
}

export default function GeneralDiagnosiaInfoLinks() {
  return (
    <div>
      <a
        className="light"
        href="https://diagnosia.com/impressum"
        rel="noopener noreferrer"
        target="_blank"
        title="zum Impressum von Diagnosia"
      >
        Impressum
      </a>{' '}
      |{' '}
      <a
        className="light"
        href="https://diagnosia.com/agb"
        rel="noopener noreferrer"
        target="_blank"
        title="zu den AGB von Diagnosia"
      >
        AGB
      </a>{' '}
      |{' '}
      <a
        className="light"
        href="https://diagnosia.com/datenschutz"
        rel="noopener noreferrer"
        target="_blank"
        title="zum Datenschutz"
      >
        Datenschutz
      </a>
    </div>
  )
}

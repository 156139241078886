import { Col, Container, Row } from 'react-bootstrap'
import ApolloErrorMsg from '_components/Common/ApolloErrorMsg'
import Loading from '_components/Common/Loading'
import { useUserCourseRecommendationsQuery } from '_generated/axon.graphql'
import { gql } from '_helpers'
import CourseSessionCard from '_components/Learn-Index/CourseSessionCard'

export default function Courses() {
  const { loading, error, data } = useUserCourseRecommendationsQuery({
    client: gql.axonClient,
  })

  if (error) {
    return (
      <Container>
        <Row>
          <Col className="pt-4" md={{ span: 6, offset: 3 }}>
            <ApolloErrorMsg error={error} />
          </Col>
        </Row>
      </Container>
    )
  }

  const courses = data?.userCourseRecommendations
  if (!courses || courses.length === 0) {
    return null
  }

  return (
    <div className="pt-5">
      <div className="mb-3 d-flex flex-wrap flex-sm-nowrap justify-content-between">
        <span className="text-sm text-uppercase color-blue-grey-90 font-bold">
          Neue kostenlose Weiterbildungen
        </span>
        <a
          className="text-sm color-blue-grey-50 text-underline"
          href="https://learn.diagnosia.com/congresses"
          target="_blank"
          rel="noreferrer"
        >
          E-Learning durchstöbern
        </a>
      </div>
      {loading ? (
        <div className="pt-3">
          <Loading />
        </div>
      ) : (
        <div className="courses-container">
          {courses.map((course, index) => {
            return (
              <CourseSessionCard
                key={index}
                className="mb-3 mb-lg-0"
                course={course}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}

import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { routeConstants } from '_constants'
import { gql } from '_helpers'
import { useUserDeleteMutation } from '_generated/plexus.graphql'
import toast from 'react-hot-toast'
import { Button, Form } from 'react-bootstrap'

export default function DeleteUserForm() {
  const navigate = useNavigate()
  const [password, setPassword] = useState('')

  const [deleteUser, { loading: isSendingDeleteUserMutation }] =
    useUserDeleteMutation({
      client: gql.plexusClient,
      onCompleted: (data) => {
        if (data.userManagementUserDelete) {
          toast.success('Dein Account wurde erfolgreich gelöscht.')
          navigate(routeConstants.LOGOUT_PAGE)
        } else {
          toast.error('Es ist ein Fehler aufgetreten')
        }
      },
    })

  function handleDeleteUser() {
    if (password.length === 0) {
      return
    }

    deleteUser({ variables: { password: password } })
  }

  return (
    <>
      <div className="text-lg font-bold">
        Du möchtest deinen Account dauerhaft löschen?
      </div>
      <div className="color-blue-grey-80">
        ACHTUNG: Hiermit bestätigst du die dauerhafte Löschung deines Accounts.
        Bitte beachte, dass die Löschung auch dein Profil von Diagnosia Learn
        betrifft!
      </div>
      <Form.Control
        className="mt-2"
        placeholder="Bitte bestätige die Löschung mit deinem Passwort"
        type="password"
        onChange={(event) => setPassword(event.target.value)}
        value={password}
      />
      <Button
        className="mt-2"
        variant="danger"
        onClick={handleDeleteUser}
        disabled={isSendingDeleteUserMutation}
      >
        Account löschen{' '}
      </Button>
    </>
  )
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { Maybe } from 'graphql/jsutils/Maybe'

export type TTempState = {
  email: Maybe<string>
}

const initialState: TTempState = {
  email: '',
}

const tempSlice = createSlice({
  name: 'temp',
  initialState,
  reducers: {
    clearTemp(state) {
      state.email = null
    },
    setEmail(state, action: PayloadAction<string>) {
      state.email = action.payload
    },
  },
})

export const { clearTemp, setEmail } = tempSlice.actions
export default tempSlice.reducer

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EIndexTabTypes } from '_types'

export type TSearchState = {
  value: string
  tab: EIndexTabTypes
}

const initialState: TSearchState = {
  value: '',
  tab: EIndexTabTypes.ALL,
}

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    clearSearch(state) {
      state.value = ''
      state.tab = EIndexTabTypes.ALL
    },
    setSearch(state, action: PayloadAction<string>) {
      state.value = action.payload
    },
    setTab(state, action: PayloadAction<EIndexTabTypes>) {
      state.tab = action.payload
    },
  },
})

export const { clearSearch, setSearch, setTab } = searchSlice.actions
export default searchSlice.reducer

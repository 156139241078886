import camelCase from 'lodash/camelCase'
import { THistoryItem } from '_types'
import {
  TUserManagementBookmarkTypeEnum,
  TUserManagementCountryEnum,
  TSchemaCountryEnum,
} from '_generated/plexus.graphql'

const getForIndex = (
  userId: string,
  history: Array<THistoryItem>,
): Array<THistoryItem> => {
  return history
    .filter(
      (h) =>
        h?.type === TUserManagementBookmarkTypeEnum.Atc ||
        h?.type === TUserManagementBookmarkTypeEnum.Drug ||
        h?.type === TUserManagementBookmarkTypeEnum.Icd10 ||
        h?.type === TUserManagementBookmarkTypeEnum.Substance ||
        h?.type === TUserManagementBookmarkTypeEnum.Substitute,
    )
    .filter((h) => h.userId === userId)
}

const getForInteractions = (
  userId: string,
  bookmarks: Array<THistoryItem>,
): Array<THistoryItem> => {
  return bookmarks
    .filter(
      (bookmark) =>
        bookmark?.type === TUserManagementBookmarkTypeEnum.Interaction,
    )
    .filter((bm) => bm.userId === userId)
}

const generateId = (data: any): string => {
  const type = data.type ? data.type : TUserManagementBookmarkTypeEnum.Drug
  const country = data.country ? data.country : TSchemaCountryEnum.De
  const name: string = data.name
    ? camelCase((data.name as string).substr(0, 20))
    : 'UnknownName'
  return `${country}-${type}-${name}`
}

const create = (userId: string, data: any): THistoryItem => {
  return {
    atc: data.atc ? data.atc : null,
    country: data.country ? data.country : TUserManagementCountryEnum.At,
    drugs: data.drugs ? data.drugs : null,
    filter: {
      grav: data.filter?.grav ? data.filter.grav : false,
      hepar: data.filter?.hepar ? data.filter.hepar : null,
      lact: data.filter?.lact ? data.filter.lact : false,
      renal: data.filter?.renal ? data.filter.renal : null,
    },
    icd10: data.icd10 ? data.icd10 : null,
    id: generateId(data),
    name: data.name ? data.name : 'Unknown',
    type: data.type ? data.type : TUserManagementBookmarkTypeEnum.Drug,
    substances: data.substances ? data.substances : null,
    substitute: data.substitute ? data.substitute : null,
    userId,
  }
}

export const historyHelper = {
  create,
  generateId,
  getForIndex,
  getForInteractions,
}

import { Formik } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Form, ToggleButtonGroup, ToggleButton, Button } from 'react-bootstrap'
import {
  TUserManagementUser,
  TUserManagementWebMedCountryEnum,
} from '_generated/plexus.graphql'

type TFormValues = {
  webMedCountry: TUserManagementWebMedCountryEnum
}

type TProps = {
  isUserProfileUpdating: boolean
  prepareUpdateUserProfile: (values: TFormValues) => void
  user?: TUserManagementUser
  userManagementConfigLoading: boolean
}

export function ChooseWebMedCountryDialog({
  isUserProfileUpdating,
  prepareUpdateUserProfile,
  user,
  userManagementConfigLoading,
}: TProps) {
  const initialValues: TFormValues = {
    webMedCountry: TUserManagementWebMedCountryEnum.At,
  }

  return (
    <Formik
      initialValues={initialValues}
      validate={(values) => {
        const errors: {
          webMedCountry?: string
        } = {}

        if (
          !values.webMedCountry ||
          values?.webMedCountry === TUserManagementWebMedCountryEnum.Undefined
        ) {
          errors.webMedCountry = 'Bitte wähle ein Land aus.'
        }

        return errors
      }}
      onSubmit={(values) => {
        prepareUpdateUserProfile(values)
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <Form
          aria-label="Land des Arzneimittel-Index"
          className="mt-4"
          onSubmit={handleSubmit}
        >
          <Form.Group controlId="webMedCountry" className="mx-sm-3 my-5">
            <ToggleButtonGroup
              vertical
              type="radio"
              name="webMedCountry"
              className={
                !!errors.webMedCountry && !!touched.webMedCountry
                  ? 'w-100 is-invalid'
                  : 'w-100'
              }
              value={values.webMedCountry}
            >
              <ToggleButton
                value={TUserManagementWebMedCountryEnum.At}
                disabled={true}
                id="web-country-at"
                variant="secondary"
                className="bg-icon-at"
                onChange={handleChange}
                onBlur={handleBlur}
              >
                Österreich
              </ToggleButton>
              {/*<ToggleButton
                value={TUserManagementWebMedCountryEnum.De}
                disabled={true}
                id="web-country-de"
                variant="secondary"
                className="bg-icon-de"
                onChange={handleChange}
                onBlur={handleBlur}
              >
                Deutschland
            </ToggleButton>*/}
            </ToggleButtonGroup>
            <Form.Control.Feedback
              type="invalid"
              className="text-end"
              role="alert"
              aria-label="feedback"
            >
              {errors.webMedCountry}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-4 mt-5 mx-sm-3">
            <Button
              aria-label="Weiter"
              data-testid="login-submit-btn"
              className="w-100 btn-green"
              variant="primary"
              type="submit"
              disabled={isUserProfileUpdating || userManagementConfigLoading}
            >
              {isUserProfileUpdating ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                'Weiter'
              )}
            </Button>
          </Form.Group>
        </Form>
      )}
    </Formik>
  )
}

import { useState } from 'react'
import { Link, Navigate, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { Form, Button } from 'react-bootstrap'
import { Formik } from 'formik'
import { routeConstants } from '_constants'
import type { TRootState, TAppDispatch } from '_helpers'
import type { TLocationState } from '_types'
import type { Maybe } from 'graphql/jsutils/Maybe'
import OnboardingDialog from '_components/Common/OnboardingDialog'
import { loginValidationSchema } from '_helpers/onboarding.helper'
import { clearAlert, EAlertActionTypeKeys } from '_slices/alert.slice'
import { loginRequest } from '_slices/authentication.slice'
import { setEmail } from '_slices/temp.slice'

export enum ELoginPageTestIds {
  emailError = 'login-form-email-error',
  passwordError = 'login-form-password-error',
}

type TProps = {
  alertMessage: string
  alertType: EAlertActionTypeKeys | null
  isAuthenticated: boolean
  isAuthenticating: boolean
  tempEmail: Maybe<string>
  clearAlerts: () => void
  email: (email: string) => void
  login: (email: string, password: string) => void
}

const mapStateToProps = (state: TRootState) => {
  const { alert, authentication, temp } = state
  return {
    alertMessage: alert.message,
    alertType: alert.type,
    isAuthenticated: authentication.isAuthenticated,
    isAuthenticating: authentication.isAuthenticating,
    tempEmail: temp.email,
  }
}

const mapDispatchToProps = (dispatch: TAppDispatch) => {
  return {
    clearAlerts: () => dispatch(clearAlert()),
    email: (email: string) => dispatch(setEmail(email)),
    login: (email: string, password: string) =>
      dispatch(loginRequest(email, password)),
  }
}

function ConnectedLoginPage({
  alertMessage,
  alertType,
  isAuthenticated,
  isAuthenticating,
  tempEmail,
  clearAlerts,
  email,
  login,
}: TProps) {
  const location = useLocation()
  const [isPasswordShown, setIsPasswordShown] = useState(false)

  // Navigate to index page when user is already logged in
  // or to the intended location, if the user wanted to
  // visit a page but was not logged in before.
  if (isAuthenticated) {
    const state = location.state as TLocationState
    const path = state?.from?.pathname
    const from =
      path && path !== '/'
        ? `${path}${state?.from?.search ?? ''}`
        : routeConstants.INDEX_PAGE

    return <Navigate to={from} />
  }

  function togglePasswordVisiblity() {
    setIsPasswordShown(!isPasswordShown)
  }

  return (
    <OnboardingDialog
      title="Einloggen"
      showCreateAccountLink={true}
      showLoginLink={false}
      alertMessage={alertMessage}
      alertType={alertType}
    >
      <Formik
        initialValues={{ email: tempEmail ?? '', password: '' }}
        validationSchema={loginValidationSchema}
        onSubmit={(values) => {
          clearAlerts()
          login(values.email, values.password)
          email(values.email)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit} className="mt-4" aria-label="Login">
            <Form.Group controlId="email" className="mx-sm-3">
              <Form.Label visuallyHidden={true}>E-Mail Adresse</Form.Label>
              <Form.Control
                className="onboarding"
                disabled={isAuthenticating}
                isInvalid={!!errors.email && !!touched.email}
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="E-Mail Adresse"
                type="email"
                value={values.email}
              />
              <Form.Control.Feedback
                data-testid={ELoginPageTestIds.emailError}
                type="invalid"
                className="text-end"
              >
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              controlId="password"
              className="mx-sm-3 position-relative"
            >
              <Form.Label visuallyHidden={true}>Passwort</Form.Label>
              <Form.Control
                className="onboarding"
                disabled={isAuthenticating}
                isInvalid={!!errors.password && !!touched.password}
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Passwort"
                role="textbox"
                type={isPasswordShown ? 'text' : 'password'}
                value={values.password}
              />
              <Form.Control.Feedback
                data-testid={ELoginPageTestIds.passwordError}
                type="invalid"
                className="text-end"
              >
                {errors.password}
              </Form.Control.Feedback>
              <span
                className="position-absolute pw-toggle font-small-body pointer color-mobile-blue"
                onClick={togglePasswordVisiblity}
                role="button"
              >
                {isPasswordShown ? 'VERSTECKEN' : 'ZEIGEN'}
              </span>
            </Form.Group>
            <Form.Group className="text-end mx-sm-3">
              <Link
                className="font-small-body color-mobile-blue"
                to={{
                  pathname: routeConstants.PASSWORD_RECOVERY_PAGE,
                }}
              >
                Passwort vergessen?
              </Link>
            </Form.Group>
            <Form.Group className="mb-4 mt-5 mx-sm-3">
              <Button
                aria-label="Einloggen"
                className="w-100 d-flex align-items-center justify-content-center"
                disabled={isAuthenticating}
                type="submit"
                variant="primary"
              >
                {isAuthenticating ? (
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Einloggen...</span>
                  </div>
                ) : (
                  'Einloggen'
                )}
              </Button>
            </Form.Group>
          </Form>
        )}
      </Formik>
    </OnboardingDialog>
  )
}

const LoginPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConnectedLoginPage)

export { LoginPage, ConnectedLoginPage }

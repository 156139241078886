import { Container, Row, Col } from 'react-bootstrap'
import ApolloErrorMsg from '_components/Common/ApolloErrorMsg'
import Loading from '_components/Common/Loading'
import { useUserRegisteredSessionsQuery } from '_generated/axon.graphql'
import { gql } from '_helpers/gql.helper'
import SessionItem from './SessionItem'
import SessionItemIdle from './SessionItemIdle'

export default function Sessions() {
  const { loading, error, data } = useUserRegisteredSessionsQuery({
    client: gql.axonClient,
  })

  if (error) {
    return (
      <Container>
        <Row>
          <Col className="pt-4" md={{ span: 6, offset: 3 }}>
            <ApolloErrorMsg error={error} />
          </Col>
        </Row>
      </Container>
    )
  }

  const sessions = data?.userRegisteredSessions

  return (
    <>
      <div className="mb-3 d-flex justify-content-between">
        <span className="text-sm text-uppercase color-blue-grey-90 font-bold">
          Meine Online-Sessions
        </span>
        <a
          className="text-sm color-blue-grey-50 text-underline"
          href="https://learn.diagnosia.com/profile"
          target="_blank"
          rel="noreferrer"
        >
          Alle anzeigen
        </a>
      </div>
      {loading ? (
        <div className="pt-3">
          <Loading />
        </div>
      ) : sessions && sessions.length > 0 ? (
        sessions.map((session, index) => {
          return (
            <div className="mb-3" key={`session-item-${index}`}>
              <SessionItem session={session} />
            </div>
          )
        })
      ) : (
        <SessionItemIdle
          buttonText={'Events durchstöbern'}
          webinarDescription={
            'Wenn du dich für Webinare anmeldest, siehst du diese hier im Überblick'
          }
          link="https://learn.diagnosia.com/events"
        />
      )}
    </>
  )
}

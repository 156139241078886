import { useEffect } from 'react'
import { utils } from '_helpers'
import { Telemetry } from '_services'
import type { TNewsServiceNews } from '_generated/plexus.graphql'

type TProps = {
  news: TNewsServiceNews
}

export default function NewsItem({ news }: TProps) {
  const url = utils.sanitizeUrl(news.externalLink)

  useEffect(() => {
    if (news.uuid && news.title) {
      Telemetry.indexNewsPreview(news.title, news.uuid)
    }
  }, [news.uuid, news.title])

  function handleClick() {
    if (news.uuid && news.title) {
      Telemetry.indexNewsSelected(news.title, news.uuid)
    }
  }

  return (
    <a
      className="news-item"
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      title={news.title ?? ''}
      onClick={handleClick}
    >
      <img className="image" src={news.image ?? ''} alt={news.title ?? ''} />
      <div className="body">
        <div className="title">{news.title}</div>
      </div>
    </a>
  )
}

import cookie from 'cookie'

type TCookieNames = 'plexus_access_token' | 'plexus_refresh_token' | 'jwt'

export class Cookie {
  static get(name: TCookieNames): string | null {
    if (!document?.cookie) return null

    const cookies = cookie.parse(document.cookie)
    if (!(name in cookies)) return null

    return cookies[name]
  }

  static remove(name: TCookieNames, domain: string = 'localhost') {
    if (!document?.cookie) return

    const expires = new Date(1970, 0, 1, 1, 0, 1)
    const secure = domain !== 'localhost'
    const cookieToRemove = cookie.serialize(name, '', {
      domain,
      expires,
      path: '/',
      sameSite: 'none',
      secure,
    })

    document.cookie = cookieToRemove
  }
}

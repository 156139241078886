import { IntercomAPI } from 'react-intercom'
import { TUserManagementUser } from '_generated/plexus.graphql'

/**
 * Intercom wrapper
 * Wrap the official Intercom JavaScript API
 * https://developers.intercom.com/installing-intercom/docs/intercom-javascript
 */
export class IntercomWrapper {
  /**
   * Shows Intercom chat
   */
  static show() {
    IntercomAPI('show')
  }

  /**
   * Shows Intercom chat with a predefined message or an empty new message
   */
  static showNewMessage(message: string | undefined) {
    IntercomAPI('showNewMessage', message)
  }

  /**
   * Hide the Intercom chat window
   */
  static hide() {
    IntercomAPI('hide')
  }

  /**
   * Delete the Intercom user session
   */
  static logout() {
    IntercomAPI('shutdown')
  }

  /**
   * Update the current user with detailed information so the support has more context about the current support request
   */
  static updateCurrentUser(user: TUserManagementUser) {
    if (user) {
      IntercomAPI('update', {
        email: user.email,
        name: [user.profile?.firstName, user.profile?.lastName].join(' '),
        user_id: user.uuid,
      })
    }
  }

  /**
   * Track an event so the support can more easily help and understand the user's context
   */
  static trackEvent(event: string) {
    IntercomAPI('trackEvent', event)
  }
}

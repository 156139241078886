import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { THistoryItem } from '_types'
import { historyHelper } from '_helpers/history.helper'
import { historyService } from '_services'
import { TUserManagementBookmarkTypeEnum } from '_generated/plexus.graphql'

export type THistoryState = { items: Array<THistoryItem> }

const getInitialHistoryState = (): THistoryState => {
  const historiesAsJsonString = localStorage.getItem('history')
  let history: Array<THistoryItem> = historiesAsJsonString
    ? JSON.parse(historiesAsJsonString)
    : []

  // Need to update the id of older history items.
  // Filter out all items without a user id.
  // Filter out all ICD items for now.
  history = history
    .map((h) => {
      if (!h.id.startsWith('DE-') || !h.id.startsWith('AT-')) {
        h.id = historyHelper.generateId(h)
      }

      return h
    })
    .filter((h) => h.userId !== undefined)
    .filter((h) => h.type !== TUserManagementBookmarkTypeEnum.Icd10)

  return { items: history }
}

const initialState = getInitialHistoryState()

const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    store(state, action: PayloadAction<THistoryItem>) {
      state.items = historyService.store(state.items, action.payload)
    },
    remove(state, action: PayloadAction<string>) {
      state.items = historyService.remove(state.items, action.payload)
    },
  },
})

export const { store, remove } = historySlice.actions
export default historySlice.reducer

type TProps = {
  buttonText: string
  link: string
  type?: 'normal' | 'outlined' | undefined
}

export default function DefaultBtn({
  buttonText,
  link,
  type = 'normal',
}: TProps) {
  return (
    <a
      type="button"
      className={`btn btn-default ${
        type === 'outlined' ? 'btn-default--outlined' : ''
      }`}
      href={link}
      target="_blank"
      rel="noreferrer"
    >
      {buttonText}
    </a>
  )
}

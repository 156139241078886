import { useEffect, useState } from 'react'
import { ReactComponent as ProfileIcon } from '_components/Common/img/user-avatar.svg'
import { utils } from '_helpers'
import { useAppSelector } from '_hooks/store'

type TProps = {
  width: number
  height: number
}

export default function ProfilePicture({ width, height }: TProps) {
  const profilePicUrl = useAppSelector(
    (state) => state.authentication.user?.profile?.image,
  )
  const [profilePicExists, setProfilePicExists] = useState(false)

  useEffect(() => {
    if (profilePicUrl) {
      utils
        .checkIfResourceExists(profilePicUrl)
        .then((result) => setProfilePicExists(result))
    }
  }, [profilePicUrl])

  return (
    <>
      {profilePicUrl && profilePicExists ? (
        <img
          src={profilePicUrl}
          alt="Profilbild"
          className="rounded-circle cover-fit"
          width={width}
          height={height}
        />
      ) : (
        <ProfileIcon width={width} height={height} />
      )}
    </>
  )
}

import { EPermissions } from '_types'
import { newsHelper, userHelper as uh } from '_helpers'
import type {
  TNewsServiceNews,
  TNewsServiceNewsCountryEnum,
} from '_generated/plexus.graphql'
import NewsItem from './NewsItem'
import { useWebMedCountry } from '_hooks'
import { useAppSelector } from '_hooks/store'

type TProps = {
  newsItems: Array<TNewsServiceNews>
}

export default function News({ newsItems }: TProps) {
  const webMedCountry = useWebMedCountry()
  const permissionSet = useAppSelector(
    (state) => state.authentication.user?.permissionSet,
  )

  const sortedNewsItems = newsHelper
    .sortByRandom(newsItems)
    .filter(
      // only show ads which overwrite the permission on the user
      // or when the user cannot hide ads
      (item) => {
        if (item.overwriteCanHideAds) return true
        if (!uh.isAuthorized(permissionSet, [EPermissions.CanHideAds]))
          return true
        return false
      },
    )
    .filter(
      (n) =>
        webMedCountry &&
        n.countries?.includes(
          webMedCountry as unknown as TNewsServiceNewsCountryEnum,
        ),
    )

  if (sortedNewsItems.length === 0) {
    return null
  }

  return (
    <>
      <div className="text-sm font-bold color-blue-grey-90 text-uppercase">
        News
      </div>
      <div className="news mt-2">
        {sortedNewsItems.map((item) => (
          <NewsItem news={item} key={item.uuid} />
        ))}
      </div>
    </>
  )
}

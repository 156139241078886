import { Formik } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import {
  TUserManagementVerificationNumberTypeEnum as EVerificationNumberType,
  TUserManagementOccupationEnum,
  TUserManagementConfigQuery,
  TUserManagementWebMedCountryEnum,
  TUserManagementUser,
} from '_generated/plexus.graphql'
import { Form, Button } from 'react-bootstrap'
import FileUploader from '_components/Common/FileUploader'
import Select from 'react-select'
import {
  TPlexusUserManagementConfigFilteredItem,
  getPlexusConfig,
  getPlexusErrorMessages,
} from '_helpers/plexus.helper'
import * as Sentry from '@sentry/react'
import { validateProfessionForm } from '_helpers/onboarding.helper'
import { useDispatch } from 'react-redux'
import { errorAlert, clearAlert } from '_slices/alert.slice'

type TFormValues = {
  files: Array<File>
  verificationNumberType: EVerificationNumberType
  verificationNumber: string
}

type TProps = {
  userManagementConfigLoading: boolean
  userManagementConfigData?: TUserManagementConfigQuery
  user?: TUserManagementUser
  prepareUpdateUserProfile: (values: any) => void
  isUserProfileUpdating: boolean
  imageUpload: (files: Array<File>) => Promise<any>
}

export function VerifyProfessionDialog({
  userManagementConfigLoading,
  userManagementConfigData,
  user,
  prepareUpdateUserProfile,
  isUserProfileUpdating,
  imageUpload,
}: TProps) {
  const profile = user?.profile
  const isDoctor = profile?.occupation === TUserManagementOccupationEnum.Doctor

  const dispatch = useDispatch()

  const userManagementConfig = userManagementConfigData?.userManagementConfig
  let verificationNumberTypeOptions: Array<TPlexusUserManagementConfigFilteredItem> =
    []
  if (userManagementConfig?.verificationNumberTypes?.length) {
    verificationNumberTypeOptions = getPlexusConfig(
      userManagementConfig?.verificationNumberTypes,
    )
  }

  let initialWebMedCountry =
    profile?.webMedCountry ?? TUserManagementWebMedCountryEnum.Undefined

  let initialVerificationNumberType =
    profile?.verificationNumberType ?? EVerificationNumberType.Undefined

  if (initialVerificationNumberType === EVerificationNumberType.Undefined) {
    switch (initialWebMedCountry) {
      case TUserManagementWebMedCountryEnum.De:
        initialVerificationNumberType = EVerificationNumberType.Efn
        break

      case TUserManagementWebMedCountryEnum.At:
      default:
        initialVerificationNumberType = EVerificationNumberType.Oeaek
        break
    }
  }

  const initialValues: TFormValues = {
    files: [],
    verificationNumberType: initialVerificationNumberType,
    verificationNumber: user?.profile?.verificationNumber ?? '',
  }

  return (
    <Formik
      initialValues={initialValues}
      validate={(values) => {
        return validateProfessionForm(values, isDoctor)
      }}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true)

        // First upload images if there are any
        if (values?.files?.length > 0) {
          dispatch(clearAlert())

          imageUpload(values.files)
            .then(() => {
              // image upload succeeded, now upload the user profile
              prepareUpdateUserProfile(values)
            })
            .catch((error) => {
              Sentry.captureException(error)
              const msg = getPlexusErrorMessages(error).join('\n')
              dispatch(errorAlert(msg))
            })
            .finally(() => {
              setSubmitting(false)
            })
        } else {
          // no images to upload, upload the new user profile right away
          prepareUpdateUserProfile(values)
          setSubmitting(false)
        }
      }}
    >
      {({
        setFieldValue,
        setFieldTouched,
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form
          aria-label="Verifizierung"
          className="mt-4"
          onSubmit={handleSubmit}
        >
          {!isDoctor && (
            <Form.Group
              data-testid="identificationImage"
              controlId="identificationImage"
              className="mx-sm-3"
            >
              <Form.Label visuallyHidden={true}>Foto hochladen</Form.Label>
              <FileUploader
                className={
                  !!errors.files && !!touched.files ? 'is-invalid' : ''
                }
                isSubmitting={isSubmitting || isUserProfileUpdating}
                onFilesSelected={(files: File[]) => {
                  values.files = files
                }}
              />
              <Form.Control.Feedback
                type="invalid"
                role="alert"
                aria-label="feedback-files"
              >
                <>{errors.files}</>
              </Form.Control.Feedback>
            </Form.Group>
          )}

          {isDoctor && (
            <div>
              <Form.Group
                data-testid="verificationNumberType"
                controlId="verificationNumberType"
                className="mx-sm-3"
              >
                <Select
                  aria-label="Ausweistyp"
                  className={
                    !!errors.verificationNumberType &&
                    !!touched.verificationNumberType
                      ? 'select-wrapper is-invalid'
                      : 'select-wrapper'
                  }
                  isDisabled={
                    isSubmitting ||
                    isUserProfileUpdating ||
                    userManagementConfigLoading
                  }
                  classNamePrefix="select"
                  placeholder="Ausweistyp"
                  name="verificationNumberType"
                  options={verificationNumberTypeOptions}
                  isLoading={userManagementConfigLoading}
                  onBlur={() => {
                    setFieldTouched('verificationNumberType', true)
                  }}
                  value={verificationNumberTypeOptions.find(
                    (o) => o.value === values.verificationNumberType,
                  )}
                  onChange={(value, actionMeta) => {
                    let v = value as TPlexusUserManagementConfigFilteredItem
                    if (actionMeta.name && v) {
                      setFieldValue(actionMeta.name, v.value)
                    }
                  }}
                />
                <Form.Control.Feedback
                  type="invalid"
                  className="text-end"
                  role="alert"
                  aria-label="feedback-verificationNumberType"
                >
                  {errors.verificationNumberType}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="verificationNumber" className="mx-sm-3">
                <Form.Label visuallyHidden={true}>
                  Verifizierungsnummer
                </Form.Label>
                <Form.Control
                  className="onboarding"
                  disabled={
                    isSubmitting ||
                    isUserProfileUpdating ||
                    userManagementConfigLoading
                  }
                  isInvalid={
                    !!errors.verificationNumber && !!touched.verificationNumber
                  }
                  name="verificationNumber"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={
                    verificationNumberTypeOptions?.find(
                      (o) => o.value === values.verificationNumberType,
                    )?.label + ' Nummer'
                  }
                  type="text"
                  value={values.verificationNumber}
                />
                <Form.Control.Feedback
                  type="invalid"
                  className="text-end"
                  role="alert"
                  aria-label="feedback-verificationNumber"
                >
                  {errors.verificationNumber}
                </Form.Control.Feedback>
              </Form.Group>

              <p className="my-4">
                Oder lade ein Foto von deinem Ausweis hoch. Deine{' '}
                {
                  verificationNumberTypeOptions?.find(
                    (o) => o.value === values.verificationNumberType,
                  )?.label
                }{' '}
                Nummer und dein Name müssen erkennbar sein.
              </p>
              <Form.Group
                data-testid="identificationImage"
                controlId="identificationImage"
                className="mx-sm-3"
              >
                <Form.Label visuallyHidden={true}>Foto hochladen</Form.Label>
                <FileUploader
                  isSubmitting={isSubmitting || isUserProfileUpdating}
                  onFilesSelected={(files: File[]) => {
                    values.files = files
                  }}
                />
              </Form.Group>
            </div>
          )}

          <Form.Group className="mb-4 mt-5 mx-sm-3">
            <Button
              aria-label="Weiter"
              data-testid="login-submit-btn"
              className="w-100 btn-green"
              variant="primary"
              type="submit"
              disabled={
                isSubmitting ||
                isUserProfileUpdating ||
                userManagementConfigLoading
              }
            >
              {isSubmitting || isUserProfileUpdating ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                'Weiter'
              )}
            </Button>
          </Form.Group>
        </Form>
      )}
    </Formik>
  )
}

import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { routeConstants } from '_constants'
import { userHelper as uh } from '_helpers'
import type { TUserManagementUser } from '_generated/plexus.graphql'

type TProps = {
  user: TUserManagementUser
}

export default function TrialVersionInfo({ user }: TProps) {
  const navigate = useNavigate()

  if (user.profile?.hasOnboardingFinished) {
    return null
  }

  function onClickTrialButton() {
    navigate(routeConstants.VERIFICATION_PAGE)
  }

  const trialDuration = uh.getTrialDuration(user)
  const daysText = trialDuration === 1 ? 'Tag' : 'Tage'

  return (
    <div className="d-flex align-items-center">
      <span className="text-danger text-sm px-3" role="alert">
        {trialDuration > 0 && `Noch ${trialDuration} ${daysText}`}
      </span>
      <Button
        className="text-sm"
        variant="danger"
        onClick={onClickTrialButton}
        type="button"
      >
        Registrierung abschließen
      </Button>
    </div>
  )
}

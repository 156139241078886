import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null | undefined
export type InputMaybe<T> = T | null | undefined
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
}

export type TCourse = {
  __typename?: 'Course'
  dfp?: Maybe<Scalars['Int']>
  duration?: Maybe<Scalars['String']>
  /** Full URL to the course detail site */
  fullUrl?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  /** Full URL to the course thumbnail image */
  photo?: Maybe<Scalars['String']>
  shortDescription?: Maybe<Scalars['String']>
}

export type TEvent = {
  __typename?: 'Event'
  from?: Maybe<Scalars['String']>
  link?: Maybe<Scalars['String']>
  thumbnail?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  to?: Maybe<Scalars['String']>
}

export type TEventSession = {
  __typename?: 'EventSession'
  from?: Maybe<Scalars['String']>
  /** Full URL to the event session detail site */
  fullUrl?: Maybe<Scalars['String']>
  speakers?: Maybe<Array<TSpeaker>>
  /** Full URL to the event session thumbnail image */
  thumbnail?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  to?: Maybe<Scalars['String']>
  zoomLink?: Maybe<Scalars['String']>
}

export type TRootQueryType = {
  __typename?: 'RootQueryType'
  /** Get a list of the newest courses available */
  userCourseRecommendations?: Maybe<Array<TCourse>>
  /** Get a list of events */
  userEventRecommendations?: Maybe<Array<TEvent>>
  /** Returns all sessions a user has registered for */
  userRegisteredSessions?: Maybe<Array<TEventSession>>
  /** Get a list of recommended sessions */
  userSessionRecommendations?: Maybe<Array<TEventSession>>
}

export type TSpeaker = {
  __typename?: 'Speaker'
  image?: Maybe<Scalars['String']>
  jobTitle?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

export type TUserCourseRecommendationsQueryVariables = Exact<{
  [key: string]: never
}>

export type TUserCourseRecommendationsQuery = {
  __typename?: 'RootQueryType'
  userCourseRecommendations?:
    | Array<{
        __typename?: 'Course'
        dfp?: number | null | undefined
        duration?: string | null | undefined
        fullUrl?: string | null | undefined
        name?: string | null | undefined
        photo?: string | null | undefined
        shortDescription?: string | null | undefined
      }>
    | null
    | undefined
}

export type TUserRegisteredSessionsQueryVariables = Exact<{
  [key: string]: never
}>

export type TUserRegisteredSessionsQuery = {
  __typename?: 'RootQueryType'
  userRegisteredSessions?:
    | Array<{
        __typename?: 'EventSession'
        from?: string | null | undefined
        fullUrl?: string | null | undefined
        title?: string | null | undefined
        thumbnail?: string | null | undefined
        to?: string | null | undefined
        zoomLink?: string | null | undefined
      }>
    | null
    | undefined
}

export type TUserSessionRecommendationsQueryVariables = Exact<{
  [key: string]: never
}>

export type TUserSessionRecommendationsQuery = {
  __typename?: 'RootQueryType'
  userSessionRecommendations?:
    | Array<{
        __typename?: 'EventSession'
        from?: string | null | undefined
        fullUrl?: string | null | undefined
        title?: string | null | undefined
        thumbnail?: string | null | undefined
        to?: string | null | undefined
        zoomLink?: string | null | undefined
      }>
    | null
    | undefined
}

export const UserCourseRecommendationsDocument = gql`
  query UserCourseRecommendations {
    userCourseRecommendations {
      dfp
      duration
      fullUrl
      name
      photo
      shortDescription
    }
  }
`

/**
 * __useUserCourseRecommendationsQuery__
 *
 * To run a query within a React component, call `useUserCourseRecommendationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCourseRecommendationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCourseRecommendationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserCourseRecommendationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TUserCourseRecommendationsQuery,
    TUserCourseRecommendationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    TUserCourseRecommendationsQuery,
    TUserCourseRecommendationsQueryVariables
  >(UserCourseRecommendationsDocument, options)
}
export function useUserCourseRecommendationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TUserCourseRecommendationsQuery,
    TUserCourseRecommendationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    TUserCourseRecommendationsQuery,
    TUserCourseRecommendationsQueryVariables
  >(UserCourseRecommendationsDocument, options)
}
export type UserCourseRecommendationsQueryHookResult = ReturnType<
  typeof useUserCourseRecommendationsQuery
>
export type UserCourseRecommendationsLazyQueryHookResult = ReturnType<
  typeof useUserCourseRecommendationsLazyQuery
>
export type UserCourseRecommendationsQueryResult = Apollo.QueryResult<
  TUserCourseRecommendationsQuery,
  TUserCourseRecommendationsQueryVariables
>
export const UserRegisteredSessionsDocument = gql`
  query UserRegisteredSessions {
    userRegisteredSessions {
      from
      fullUrl
      title
      thumbnail
      to
      zoomLink
    }
  }
`

/**
 * __useUserRegisteredSessionsQuery__
 *
 * To run a query within a React component, call `useUserRegisteredSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRegisteredSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRegisteredSessionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserRegisteredSessionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TUserRegisteredSessionsQuery,
    TUserRegisteredSessionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    TUserRegisteredSessionsQuery,
    TUserRegisteredSessionsQueryVariables
  >(UserRegisteredSessionsDocument, options)
}
export function useUserRegisteredSessionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TUserRegisteredSessionsQuery,
    TUserRegisteredSessionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    TUserRegisteredSessionsQuery,
    TUserRegisteredSessionsQueryVariables
  >(UserRegisteredSessionsDocument, options)
}
export type UserRegisteredSessionsQueryHookResult = ReturnType<
  typeof useUserRegisteredSessionsQuery
>
export type UserRegisteredSessionsLazyQueryHookResult = ReturnType<
  typeof useUserRegisteredSessionsLazyQuery
>
export type UserRegisteredSessionsQueryResult = Apollo.QueryResult<
  TUserRegisteredSessionsQuery,
  TUserRegisteredSessionsQueryVariables
>
export const UserSessionRecommendationsDocument = gql`
  query UserSessionRecommendations {
    userSessionRecommendations {
      from
      fullUrl
      title
      thumbnail
      to
      zoomLink
    }
  }
`

/**
 * __useUserSessionRecommendationsQuery__
 *
 * To run a query within a React component, call `useUserSessionRecommendationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSessionRecommendationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSessionRecommendationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserSessionRecommendationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TUserSessionRecommendationsQuery,
    TUserSessionRecommendationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    TUserSessionRecommendationsQuery,
    TUserSessionRecommendationsQueryVariables
  >(UserSessionRecommendationsDocument, options)
}
export function useUserSessionRecommendationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TUserSessionRecommendationsQuery,
    TUserSessionRecommendationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    TUserSessionRecommendationsQuery,
    TUserSessionRecommendationsQueryVariables
  >(UserSessionRecommendationsDocument, options)
}
export type UserSessionRecommendationsQueryHookResult = ReturnType<
  typeof useUserSessionRecommendationsQuery
>
export type UserSessionRecommendationsLazyQueryHookResult = ReturnType<
  typeof useUserSessionRecommendationsLazyQuery
>
export type UserSessionRecommendationsQueryResult = Apollo.QueryResult<
  TUserSessionRecommendationsQuery,
  TUserSessionRecommendationsQueryVariables
>
